<app-modal-frame [dialogRef]="dialogRef" [data]="data">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'common.data_export'"
        [iconUrl]="'assets/image/export-icon.svg'">
    </app-modal-header>

    <div content class="mb-40px mt-20px">
        <div *ngIf="exportCount > exportCountLimit" class=" d-flex align-items-center info-text mb-2">
            <span class="info-icon mr-2" [inlineSVG]="'assets/image/help-icon.svg'"></span> {{exportInfoText}}
        </div>
        <app-form-field class="d-block mb-10px">
            <app-select
                [options]="exportTypes"
                label="common.format"
                bindLabel="name"
                bindValue="id"
                [formControl]="exportTypeControl"
            >
            </app-select>
        </app-form-field>

        <div *ngIf="loading" class="export-spinner">
            <app-loading></app-loading>
        </div>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [secondaryButtonText]="'common.dismiss'"
        [primaryButtonText]="'common.export'"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="export()">
    </app-modal-footer>

</app-modal-frame>
