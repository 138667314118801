<ng-template let-row="row" let-value="value">
    <div class="d-flex align-items-center overflow-hidden">
        <div class="logo mr-10px">
            <img
                *ngIf="value.textValue && value.imageUrl"
                class="logo-img w-100 h-100"
                [ngClass]="{ 'circle-image': value.isCircleImage }"
                [src]="value.imageUrl"
                [appImgFallback]="value.placeholderImg"
                alt="Partner Logo">
        </div>
        <span>{{value.textValue}}</span>
    </div>
</ng-template>
