import { Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, first, map, startWith } from 'rxjs/operators';
import { FilteringManageService } from '@shared/modules/filter-components/services/filtering-manage.service';
import { UserFilter } from '@shared/classes/users/UserFilter';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { FilterModalData } from '@shared/modules/modals/base-modals/filter-modal/classes/FilterModalConfig';

@UntilDestroy()
@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent extends MatModalBaseComponent<FilterModalData> implements OnInit {
  form: FormGroup;
  filterVisibilityOption = [
    { id: false, name: 'Privát', isPublic: false },
    { id: true, name: 'Nyilvános', isPublic: true },
  ];

  title: string;
  isEditMode: boolean;

  constructor(
    private filteringManageService: FilteringManageService,
    private formBuilder: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;

    this.title = this.data.title;
    this.isEditMode = this.data.editMode;
    this.primaryButtonDisabled = true;

    const selectedFilter = this.filteringManageService.getStateSnapshot().selectedUserFilter;

    this.initForm(this.isEditMode, {
      name: selectedFilter?.name,
      isPublic: selectedFilter?.isPublic,
    });
    this.listenFormValueChanges();

    this.loading = false;
  }

  initForm(isEditMode: boolean, { name, isPublic }: Partial<UserFilter>) {
    this.form = this.formBuilder.group({
      filterName: new FormControl(this.isEditMode ? name : null, [Validators.required]),
      filterVisibility: new FormControl(this.isEditMode ? isPublic : null, [Validators.required]),
    });
  }

  listenFormValueChanges() {
    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        debounceTime(300),
        map(() => {
          return this.form.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.primaryButtonDisabled = isSaveButtonDisabled;

        this.filteringManageService.setFilterManageState({
          name: this.form.get('filterName').value as string,
          isPublic: this.form.get('filterVisibility').value as boolean,
        });
      });
  }

  saveFilter(): void {
    this.filteringManageService
      .callSaveFilter(this.isEditMode)
      .pipe(first())
      .subscribe(() => {
        this.closeModal(true);
      });
  }
}
