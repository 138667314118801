import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EndpointsConfig } from '@config/endpoints.config';

@UntilDestroy()
@Directive({
  selector: '[appCandidateDetailScrollClick]',
})
export class CandidateDetailScrollClickDirective implements OnInit {
  @Input() set appCandidateDetailScrollClick(candidateId: number) {
    this.candidateId = candidateId;
  }

  auxClick$: Observable<Event>;
  candidateId: number;

  constructor(private element: ElementRef<HTMLElement>) {
    this.auxClick$ = fromEvent(this.element.nativeElement, 'mouseup');
  }

  ngOnInit() {
    this.subscribeToAuxClick();
  }

  subscribeToAuxClick() {
    this.auxClick$.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if ((event as MouseEvent).button === 1 && this.candidateId) {
        window.open(`${EndpointsConfig.candidates}/${this.candidateId}`, '_blank');
      }
    });
  }
}
