import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldSelectionFormComponent } from './components/custom-field-selection-form/custom-field-selection-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { AttachNewFormFieldsButtonPipe } from '@shared/modules/selections/utils/attach-new-form-fields-button.pipe';
import { CustomFieldSelectionComponent } from './components/custom-field-selection/custom-field-selection.component';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';

@NgModule({
  declarations: [
    CustomFieldSelectionFormComponent,
    AttachNewFormFieldsButtonPipe,
    CustomFieldSelectionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormComponentsModule,
    PipesModule,
    ReactiveFormsModule,
    ButtonModule,
    FilteringModule,
    FormsModule,
  ],
  exports: [CustomFieldSelectionFormComponent, AttachNewFormFieldsButtonPipe],
})
export class CustomFieldSelectionModule {}
