import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConfig } from '@config/app.config';
import { NavigationService } from '@shared/services/navigation.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { ManageCandidateModalComponent } from '@shared/modules/modals/candidate-modals/manage-candidate-modal/manage-candidate-modal.component';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { AppConstants } from '@config/app.constant';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { HeaderPage } from '@shared/modules/header/classes/HeaderPage';
import { FormControl } from '@angular/forms';
import { kanbanBoardSortListConfig } from '@shared/modules/header/utils/kanban-board-sort-list.config';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { BoardSortMode } from '@shared/modules/header/classes/BoardSortMode';
import { PositionKanbanBoardService } from '@pages/positions/services/candidates/position-kanban-board.service';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { NavigationEnd, Router } from '@angular/router';
import { ManageCandidateModalConfig } from '@shared/modules/modals/candidate-modals/manage-candidate-modal/config/ManageCandidateModalConfig';
import { ModalType } from '@shared/classes/ModalType';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { ConfirmModalConfig } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';
import { PositionsService } from '@pages/positions/services/details/positions.service';

@UntilDestroy()
@Component({
  selector: 'app-position-detail-header',
  templateUrl: './position-detail-header.component.html',
  styleUrls: ['./position-detail-header.component.scss'],
})
export class PositionDetailHeaderComponent implements OnInit {
  isDetailLoaded$: Observable<boolean>;
  positionDetail: PositionDetail;
  pages: HeaderPage[] = [];
  sortForm = new FormControl();
  placeholder = 'assets/image/partner-placeholder.svg';
  logo: string;
  exportPermissions = [UserPermission.ExportTable];
  urls = {
    candidates: 'candidates',
    detail: '',
    advertisements: 'vacancies',
  };
  isPositionInactive: boolean;
  sortModes: BoardSortMode[] = [];
  deleteModalConfig: ConfirmModalConfig;

  isKanbanView = true;
  isTableView = false;
  isDetailView = true;

  constructor(
    private positionState: PositionStateService,
    private navigationService: NavigationService,
    private auth: AuthService,
    private candidateState: CandidateStateService,
    private modalService: MatModalService,
    protected injector: Injector,
    private candidatesKanbanBoardService: PositionKanbanBoardService,
    protected router: Router,
    private headerService: HeaderService,
    private positionService: PositionsService
  ) {
    this.modalService = this.injector.get<MatModalService>(MatModalService);

    this.logo = this.placeholder;
  }

  ngOnInit(): void {
    this.isKanbanView = this.router.url.endsWith('kanban');
    this.isTableView = this.router.url.endsWith('table');
    this.isDetailView = !this.router.url.endsWith(this.urls.advertisements);

    this.listenToRouteChanges();
    this.translateSortModes();
    this.subscribeToPositionDetailChanges();

    this.isDetailLoaded$ = this.positionState.select('positionDetailLoaded');
  }

  private subscribeToPositionDetailChanges() {
    this.positionState
      .select('positionDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        this.positionDetail = detail;
        this.isPositionInactive = detail?.status === PositionStatus.Inactive;
        if (this.positionDetail?.partner?.logo) {
          this.logo = this.positionDetail.partner.logo;
        }
        this.pages = this.getPages(detail?.id);
        this.deleteModalConfig = this.getDeletePositionModalConfig(detail?.id);
      });
    this.translateSortModes();
  }

  triggerExportClick(): void {
    this.headerService.triggerExportButtonClick();
  }

  setSortMode(sortMode: BoardSortMode) {
    if (sortMode) {
      this.positionState.setState({
        selectedSortMode: {
          key: sortMode.key,
          sortBy: sortMode.sortBy,
        },
      });
    }
  }

  private translateSortModes() {
    this.sortModes = kanbanBoardSortListConfig.map(
      (item): BoardSortMode => {
        const translatedKey = TranslateInstance.instant(item.label);

        return {
          ...item,
          label: translatedKey,
        };
      }
    );
  }

  toggleView() {
    this.isKanbanView = !this.isKanbanView;
    this.isTableView = !this.isTableView;

    const routeArray = [AppConfig.positionsUrl, this.positionDetail?.id, 'candidates'];

    if (!this.isKanbanView) {
      routeArray.push('table');
    } else {
      routeArray.push('kanban');
    }

    this.router.navigate(routeArray);
  }

  private listenToRouteChanges(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isKanbanView = this.router.url.endsWith('kanban');
        this.isTableView = this.router.url.endsWith('table');
        this.isDetailView = !this.router.url.endsWith(this.urls.advertisements);
      }
    });
  }

  onNavigation(): void {
    this.navigationService.back(AppConfig.positionsUrl, this.isKanbanView);
  }

  private getDeletePositionModalConfig(positionId: number): ConfirmModalConfig {
    return {
      data: {
        modalText: TranslateInstance.instant(
          'common.delete_confirm_message',
          { message: 'common.position' },
          true
        ),
        confirmObservable: this.positionService.callDeletePosition(positionId),
      },
    };
  }

  onInnerNavigation(): void {
    this.navigationService.popHistory();
  }

  createCandidate(): void {
    this.candidateState.setState({ candidateModalOpenedFromPosition: true });

    this.modalService
      .openDialog<ManageCandidateModalConfig>(ManageCandidateModalComponent, {
        width: AppConstants.threeColumnModalWidth,
        data: {
          type: ModalType.Candidate,
        },
      })
      .subscribe((result) => {
        const candidate = result as CandidateDetail;
        if (candidate) {
          this.candidatesKanbanBoardService.addCandidateToPosition(
            this.positionDetail.id,
            candidate
          );
        }
      });
  }

  showAddNewCandidateButton(): boolean {
    return this.navigationService.location.path().includes(this.urls.candidates);
  }

  private getPages(positionId: number) {
    if (!positionId) {
      return [];
    }

    const menus: HeaderPage[] = [
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.candidates],
        text: 'positions.header.candidates',
        isActive: this.navigationService.location.path().indexOf(this.urls.candidates) > -1,
        permissions: [UserPermission.ManagePositionCandidates],
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId],
        text: 'positions.header.detail',
        isActive: !this.navigationService.location
          .path()
          .split('/')
          .some((part) => [this.urls.candidates, this.urls.advertisements].indexOf(part) > -1),
        permissions: [UserPermission.ReadPosition],
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.advertisements],
        text: 'positions.header.advertisements',
        isActive: this.navigationService.location.path().indexOf(this.urls.advertisements) > -1,
        permissions: [UserPermission.ManageAdvertisement],
      },
    ];

    return menus.filter((menu) => {
      return (
        this.auth.isSuperAdmin ||
        AuthUtils.isContainUserPermission(menu.permissions, this.auth.getUserRoles())
      );
    });
  }
}
