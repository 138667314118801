import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  IExceptionTelemetry,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web/dist/applicationinsights-web.rollup';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private isCurrentEnvironmentSupportsLogging: boolean;
  private appInsights: ApplicationInsights;

  constructor() {
    this.isCurrentEnvironmentSupportsLogging = !!environment.appInsights;

    if (!this.isCurrentEnvironmentSupportsLogging) {
      console.log(
        '%c Current environment does not log to AppInsights!',
        'background: #222; color: #bada55'
      );
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.key,
      },
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
  }

  logPageView(name?: string, uri?: string): void {
    if (!this.isCurrentEnvironmentSupportsLogging) {
      return;
    }

    this.appInsights.trackPageView({ name, uri });
  }

  logException(error: Error): void {
    if (!this.isCurrentEnvironmentSupportsLogging) {
      return;
    }

    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  private loadCustomTelemetryProperties(): void {
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      const item = envelope.baseData;

      if (!item.properties) {
        item.properties = {};
      }

      /* eslint-disable @typescript-eslint/naming-convention */
      Object.assign(item.properties, {
        ApplicationPlatform: 'WEB',
        ApplicationName: environment.appInsights.applicationName,
      });
    });
  }
}
