<ng-template let-item>
    <div class="toggle-cell d-flex align-items-center">
        <app-switch
            [disabled]="isToggleDisabled"
            [isActive]="item.status === statuses.Active"
            [beforeChange$]="beforeChange$"
            (click)="!isToggleDisabled && toggleChange.emit(item); $event.stopPropagation()">
        </app-switch>

        <app-small-image-table-cell
            [imageUrl]="item[imageKey]"
            [value]="item.name" [isImageShown]="isImageShown">
        </app-small-image-table-cell>
    </div>
</ng-template>

