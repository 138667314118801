import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { EndpointsConfig } from '@config/endpoints.config';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';
import { CandidateSearchItem } from '@pages/positions/classes/board/CandidateSearchItem';
import { KanbanBoardTableItem } from '@pages/positions/classes/board/table/KanbanBoardTableItem';
import { FilterDropdown } from '@shared/modules/custom-selectors/custom-filter-selector/classes/FilterDropdown';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PositionKanbanBoardApiService {
  constructor(private http: HttpService) {}

  getColumns(): Observable<BoardColumn[]> {
    return this.http.get(EndpointsConfig.boardColumns);
  }

  getColumnsByPositionId(positionId: number): Observable<BoardColumn[]> {
    return this.http.get(EndpointsConfig.boardColumnsByPositionId(positionId));
  }

  getBoardCandidates(positionId: number): Observable<BoardCandidate[]> {
    return this.http.get(EndpointsConfig.boardCandidates(positionId));
  }

  getBoardCandidatesForTable(
    positionId: number,
    filter: string,
    columnIds: string[]
  ): Observable<KanbanBoardTableItem[]> {
    const params: { [k: string]: string } = {};
    if (filter) {
      params.filter = filter;
    }

    return this.http.post(
      EndpointsConfig.boardCandidatesForTable(positionId),
      { columnIds },
      params
    );
  }

  getBoardCandidate(positionId: number, userId: number): Observable<BoardCandidate> {
    return this.http.get(EndpointsConfig.boardCandidate(positionId, userId));
  }

  searchCandidate(positionId: number, name: string): Observable<CandidateSearchItem[]> {
    return this.http.get(EndpointsConfig.searchBoardCandidate(positionId), { name });
  }

  updateCandidatePosition(
    positionId: number,
    candidateId: number,
    dto: MoveOrAddPositionDto
  ): Observable<BoardCandidate> {
    return this.http.put(EndpointsConfig.moveOrAddBoardCandidate(positionId, candidateId), dto);
  }

  updatePhoneContactPosition(
    positionId: number,
    candidateId: number,
    dto: MoveOrAddPositionDto
  ): Observable<BoardCandidate> {
    return this.http.put(EndpointsConfig.moveOrAddBoardPhoneContact(positionId, candidateId), dto);
  }

  addCandidateToPosition(
    positionId: number,
    candidateId: number,
    dto: MoveOrAddPositionDto
  ): Observable<BoardCandidate> {
    return this.http.post(EndpointsConfig.moveOrAddBoardCandidate(positionId, candidateId), dto);
  }

  getFilterDropdown(): Observable<FilterDropdown[]> {
    return this.http.get(EndpointsConfig.boardTableFilterDropdown);
  }

  getTableFirstLetters(positionId: number): Observable<string[]> {
    return this.http.get(EndpointsConfig.boardTableFirstLetters(positionId));
  }

  getTableFilters(filter?: string): Observable<{ [key: string]: CardFilterInterface }> {
    const params: { [k: string]: string } = {};
    if (filter) {
      params.filter = filter;
    }

    return this.http.get(EndpointsConfig.boardTableFilters, params);
  }

  exportBoardCandidateTable(
    positionId: number,
    filter: string,
    columns: TableColumn[]
  ): Observable<HttpResponse<Blob>> {
    const params = filter ? { filter } : null;

    return this.http.postFile(
      EndpointsConfig.exportBoardCandidateById(positionId),
      columns,
      params
    );
  }

  exportBoardCandidateTableEmail(
    positionId: number,
    filter: string,
    columns: TableColumn[]
  ): Observable<void> {
    const params = filter ? { filter } : null;

    return this.http.post(
      EndpointsConfig.exportEmailBoardCandidateById(positionId),
      { columns },
      params
    );
  }
}
