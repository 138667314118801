import { ComponentType } from '@angular/cdk/overlay';
import { Office } from '@pages/partners/classes/Office';
import { Country } from '@shared/classes/Country';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';
import { CityMinimal } from '@shared/classes/City';
import { Contact } from '@pages/partners/classes/Contact';

export interface AppState {
  currentUrl: string;
  currentHeader: ComponentType<unknown>;

  isLoginSubmitLoading: boolean;

  // lists
  offices: Office[];
  officesLoaded: boolean;

  countries: Country[];
  countriesLoaded: boolean;

  sources: Source[];
  sourcesLoaded: boolean;

  jobTypes: JobType[];
  jobTypesLoaded: boolean;

  specializations: Specialization[];
  specializationsLoaded: boolean;

  languages: Language[];
  languagesLoaded: boolean;

  languageLevels: LanguageLevel[];
  languageLevelsLoaded: boolean;

  documentTypes: CandidateDocumentType[];
  documentTypesLoaded: boolean;

  employmentTypes: EmploymentType[];
  employmentTypesLoaded: boolean;

  educationTypes: EmploymentType[];
  educationTypesLoaded: boolean;

  workExperiences: WorkExperience[];
  workExperiencesLoaded: boolean;

  cities: CityMinimal[];
  citiesLoaded: boolean;

  projectManagers: Contact[];
  projectManagersLoaded: boolean;
}

export const appInitialState: AppState = {
  currentUrl: '',
  currentHeader: undefined,

  isLoginSubmitLoading: false,

  offices: [],
  officesLoaded: false,
  countries: [],
  countriesLoaded: false,
  sources: [],
  sourcesLoaded: false,
  jobTypes: [],
  jobTypesLoaded: false,
  specializations: [],
  specializationsLoaded: false,
  languages: [],
  languagesLoaded: false,
  languageLevels: [],
  languageLevelsLoaded: false,
  documentTypes: [],
  documentTypesLoaded: false,
  employmentTypes: [],
  employmentTypesLoaded: false,
  educationTypes: [],
  educationTypesLoaded: false,
  workExperiences: [],
  workExperiencesLoaded: false,
  cities: [],
  citiesLoaded: false,
  projectManagers: [],
  projectManagersLoaded: false,
};
