import { TranslateInstance } from '@shared/utils/TranslateInstance';

export function getGeneralMessage(message: string, isSuccess = true): string {
  const translatedMessage = TranslateInstance.instant(message);
  const postFix = isSuccess ? 'success' : 'error';

  return TranslateInstance.instant(`common.general_${postFix}`, {
    message: translatedMessage,
  });
}
