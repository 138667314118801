import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';
import { HintType } from '@shared/modules/hint/classes/HintType';

@Component({
  selector: 'app-status-selection-label-template',
  templateUrl: './status-selection-label-template.component.html',
  styleUrls: ['./status-selection-label-template.component.scss'],
})
export class StatusSelectionLabelTemplateComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;

  @Input() hintText: string;
  hintTypes = HintType;
}
