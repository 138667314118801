import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetUserFilter } from '@shared/classes/users/GetUsersFilter';
import { UserFilter } from '@shared/classes/users/UserFilter';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';

@Injectable({
  providedIn: 'root',
})
export class UserFilterApiService {
  constructor(private httpService: HttpService) {}

  getUsersFilters(type: string): Observable<GetUserFilter[]> {
    const params = {
      type,
    };

    return this.httpService.get(EndpointsConfig.usersFilters, params);
  }

  saveUserFilter(body: UserFilter): Observable<GetUserFilter[]> {
    return this.httpService.post(EndpointsConfig.usersFilters, body);
  }

  updateUserFilterById(id: number, body: UserFilter): Observable<GetUserFilter[]> {
    return this.httpService.put(`${EndpointsConfig.usersFilters}/${id}`, body);
  }

  deleteUserFilterById(id: number): Observable<GetUserFilter[]> {
    return this.httpService.delete(`${EndpointsConfig.usersFilters}/${id}`);
  }
}
