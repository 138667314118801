import { Injectable } from '@angular/core';
import {
  InlineEditCellData,
  InlineEditCellId,
  InlineEditCellSaveStatus,
  InlineEditRowId,
} from '@shared/modules/data-table-inline-edit/classes/inline-edit-data-list';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { RendererInstance } from '@shared/utils/renderer-instance.util';
import { TableService } from '@shared/services/table.service';
import { HttpService } from '@shared/modules/http/http.service';
import { DataTableInlineEditService } from '@shared/modules/data-table-inline-edit/services/data-table-inline-edit.service';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { InlineEditRequest } from '@shared/modules/data-table-inline-edit/classes/inline-edit-request';
import { InlineEditResponse } from '@shared/modules/data-table-inline-edit/classes/inline-edit-response';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { UnknownErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { StatusSelectionCellData } from '@shared/modules/data-table/classes/StatusSelectionCellData';
import { InlineEditStatusSelectValue } from '@shared/modules/data-table-inline-edit/classes/inline-edit-status-select-data';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { InlineEditProperties } from '@shared/modules/data-table-inline-edit/classes/inline-edit-properties';

@Injectable({
  providedIn: 'root',
})
export class DataTableFillHandleService {
  private currentActiveCellId: InlineEditCellId;
  private selectedRowIds: InlineEditRowId[] = [];
  private lastSelectedRowId: InlineEditRowId = null;

  private _fillHandleEnabled: boolean;
  private _selectedStartCell: InlineEditCellData;

  private readonly SHOW_CONFIRM_FOR_PROPERTIES: InlineEditProperties[] = [
    InlineEditProperties.Status,
  ];

  constructor(
    private httpService: HttpService,
    private tableService: TableService,
    private inlineEditService: DataTableInlineEditService,
    private toastService: ToastService,
    private modalService: MatModalService
  ) {
    this.endFillHandleOnMouseUp();
  }

  get fillHandleEnabled(): Readonly<boolean> {
    return this._fillHandleEnabled;
  }

  get selectedStartCell(): Readonly<InlineEditCellData> {
    return this._selectedStartCell;
  }

  private get tableHtmlElement() {
    return document.getElementsByTagName('ngx-datatable')[0];
  }

  startFillHandle(row: DataTableRow, rowIndex: number, column: TableColumn, value: unknown) {
    this._fillHandleEnabled = true;

    this.setSelectedStartCell(row, rowIndex, column, value);
    this.disableTextSelect();
  }

  endFillHandle() {
    this._fillHandleEnabled = false;

    if (!this.selectedRowIds.length) {
      return;
    }

    const startCell = this.selectedStartCell;
    const customValue = this.getCustomValueForStartCell(startCell);

    if (this.showConfirmForProperty(startCell.column.prop as InlineEditProperties)) {
      this.modalService
        .openConfirmModal({
          data: {
            modalText: 'common.confirm_selected_items_edit',
            primaryButtonText: 'common.yes',
          },
        })
        .subscribe((result) => {
          if (result) {
            this.saveFillHandle(customValue);
          } else {
            this.resetFillHandleActive();
            this.resetSelectedCells();
            this.resetSelectedStartCell();
          }
        });
    } else {
      this.saveFillHandle(customValue);
    }
  }

  setSelectedStartCell(row: DataTableRow, rowIndex: number, column: TableColumn, value: unknown) {
    this._selectedStartCell = { row, rowIndex, column, value };
  }

  setFillHandleActive(rowIndex: number, column: TableColumn) {
    this.resetFillHandleActive();

    this.inlineEditService.setCellEditingData(rowIndex, column, {
      active: true,
    });

    this.currentActiveCellId = { rowIndex, column };
  }

  resetFillHandleActive() {
    if (this.currentActiveCellId) {
      this.inlineEditService.setCellEditingData(
        this.currentActiveCellId.rowIndex,
        this.currentActiveCellId.column,
        {
          active: false,
        }
      );
    }

    this.currentActiveCellId = null;
  }

  setFillHandleSelected(rowIndex: number, column: TableColumn, row: DataTableRow) {
    if (!this._selectedStartCell || this._selectedStartCell.column.prop === column.prop) {
      const needToUpdate =
        this._selectedStartCell &&
        this._selectedStartCell.rowIndex !== rowIndex &&
        this.getSelectedRowIdIndex(rowIndex, column) === -1;

      if (!this.fillHandleEnabled) {
        this.resetSelectedCells();
      }

      if (needToUpdate) {
        this.addSelectedRowId(rowIndex, column, row);
      }
    }
  }

  toggleFillHandleSelected(rowIndex: number, column: TableColumn, row: DataTableRow) {
    if (this._selectedStartCell && this._selectedStartCell.column.prop === column.prop) {
      const isNotStartCell = this._selectedStartCell.rowIndex !== rowIndex;
      const rowIdIndex = this.getSelectedRowIdIndex(rowIndex, column);

      if (!this.fillHandleEnabled) {
        this.resetSelectedCells();
      }

      if (isNotStartCell && rowIdIndex === -1) {
        this.addSelectedRowId(rowIndex, column, row);
      } else if (this.lastSelectedRowId) {
        this.revertSelectedRowIds(rowIndex);
      }

      this.setLastSelectedRowId(rowIndex, column);
    }
  }

  private revertSelectedRowIds(rowIndex: number) {
    if (
      rowIndex >= this._selectedStartCell.rowIndex &&
      rowIndex < this.lastSelectedRowId.rowIndex
    ) {
      this.filterSelectedRowIds(rowIndex, 'filterHigher');
    } else if (
      rowIndex <= this._selectedStartCell.rowIndex &&
      rowIndex > this.lastSelectedRowId.rowIndex
    ) {
      this.filterSelectedRowIds(rowIndex, 'filterLower');
    }
  }

  private filterSelectedRowIds(rowIndex: number, filterType: 'filterLower' | 'filterHigher') {
    this.selectedRowIds = this.selectedRowIds.filter((selectedRowId) => {
      const keep =
        filterType === 'filterLower'
          ? selectedRowId.rowIndex >= rowIndex
          : selectedRowId.rowIndex <= rowIndex;

      if (!keep) {
        this.inlineEditService.setCellEditingData(selectedRowId.rowIndex, selectedRowId.column, {
          isSelected: false,
        });
      }

      return keep;
    });
  }

  private setLastSelectedRowId(rowIndex: number, column: TableColumn) {
    const index = this.getSelectedRowIdIndex(rowIndex, column);
    if (index !== -1) {
      this.lastSelectedRowId = this.selectedRowIds[index];
    }
  }

  private showConfirmForProperty(property: InlineEditProperties) {
    return this.SHOW_CONFIRM_FOR_PROPERTIES.includes(property);
  }

  private saveFillHandle(customValue: unknown) {
    this.saveFillEditedData(customValue).then(() => {
      this.resetSelectedCells();

      this.enableTextSelect();
      this.resetSelectedStartCell();
    });
  }

  private getCustomValueForStartCell(startCell: InlineEditCellData) {
    if (startCell.column.prop === InlineEditProperties.Status) {
      const cellData = startCell.value as StatusSelectionCellData;

      return <InlineEditStatusSelectValue>{
        primaryKey: startCell.row[this.inlineEditService.primaryKey as keyof DataTableRow],
        secondaryKey: startCell.row[this.inlineEditService.secondaryKey as keyof DataTableRow],
        status: cellData.userPositions.status,
        statusOption: cellData.userPositions.statusOption,
      };
    } else {
      return null;
    }
  }

  private addSelectedRowId(rowIndex: number, column: TableColumn, row: DataTableRow) {
    this.selectedRowIds.push({
      rowIndex,
      column,
      primaryKey: row[this.inlineEditService.primaryKey as keyof DataTableRow],
      secondaryKey: row[this.inlineEditService.secondaryKey as keyof DataTableRow],
    });

    this.inlineEditService.setCellEditingData(rowIndex, column, {
      isSelected: true,
    });
  }

  private getSelectedRowIdIndex(rowIndex: number, column: TableColumn) {
    return this.selectedRowIds.findIndex((selectedRowId) => {
      return (
        selectedRowId['rowIndex'] === rowIndex && selectedRowId['column']['prop'] === column.prop
      );
    });
  }

  private saveFillEditedData(customValue: unknown = null) {
    const data: InlineEditRequest = {
      rowIds: this.selectedRowIds.map((rowId) => {
        return {
          primaryKey: rowId.primaryKey,
          secondaryKey: rowId.secondaryKey,
        } as InlineEditRowId;
      }),
      property: this._selectedStartCell.column.prop as string,
      value: customValue ?? this.convertObjectToArrayOfObjects(this._selectedStartCell.value),
    };

    return new Promise<void>((resolve) => {
      this.setSelectedCellsLoading(true);

      this.httpService.post<InlineEditResponse>('inline-edit', data).subscribe({
        next: (response: InlineEditResponse) => {
          let hasError = false;

          for (const item of response) {
            const selectedRow = this.selectedRowIds.find((selectedRowId) => {
              return (
                selectedRowId.primaryKey === item.primaryKey &&
                (!selectedRowId.secondaryKey || selectedRowId.secondaryKey === item.secondaryKey)
              );
            });

            if (!selectedRow) {
              console.warn('item not found: ', item);
              continue;
            }

            this.inlineEditService.setCellEditingData(selectedRow.rowIndex, selectedRow.column, {
              saveStatus: item.success
                ? InlineEditCellSaveStatus.Updated
                : InlineEditCellSaveStatus.Error,
              errorMessage: item.error,
            });

            if (item.success) {
              this.tableService.updateItemValue({
                rowIndex: selectedRow.rowIndex,
                property: selectedRow.column.prop,
                value: item.updatedValue ?? this._selectedStartCell.value,
              });
            } else {
              hasError = true;
            }
          }

          this.setSelectedCellsLoading(false);

          if (hasError) {
            this.toastService.showError('common.save_element_error');
          }

          resolve();
        },
        error: (error: UnknownErrorResponse) => {
          this.updateSelectedCellsStatus(
            InlineEditCellSaveStatus.Error,
            typeof error.error?.errors === 'string' ? error.error.errors : 'unknown'
          );
          this.setSelectedCellsLoading(false);

          this.toastService.showError('common.save_element_error');

          resolve();
        },
      });
    });
  }

  private setSelectedCellsLoading(loading: boolean) {
    for (const selectedId of this.selectedRowIds) {
      this.inlineEditService.setCellEditingData(selectedId.rowIndex, selectedId.column, {
        isLoading: loading,
      });
    }
  }

  private convertObjectToArrayOfObjects(value: unknown): unknown {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      return [value];
    } else {
      return value;
    }
  }

  private updateSelectedCellsStatus(
    saveStatus: InlineEditCellSaveStatus,
    errorMessage: string = null
  ) {
    for (const selectedId of this.selectedRowIds) {
      this.inlineEditService.setCellEditingData(selectedId.rowIndex, selectedId.column, {
        saveStatus,
        errorMessage,
      });
    }
  }

  private endFillHandleOnMouseUp() {
    RendererInstance.renderer.listen('window', 'mouseup', () => {
      if (this.fillHandleEnabled) {
        this.endFillHandle();
      }
    });
  }

  private disableTextSelect() {
    RendererInstance.renderer.setStyle(this.tableHtmlElement, 'user-select', 'none');
  }

  private enableTextSelect() {
    RendererInstance.renderer.setStyle(this.tableHtmlElement, 'user-select', 'auto');
  }

  private resetSelectedStartCell() {
    this._selectedStartCell = null;
  }

  private resetSelectedCells() {
    for (const selectedId of this.selectedRowIds) {
      this.inlineEditService.setCellEditingData(selectedId.rowIndex, selectedId.column, {
        isSelected: false,
      });
    }
    this.selectedRowIds = [];
  }
}
