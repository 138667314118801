<div class="d-flex align-items-center justify-content-center">
    <ng-container *ngIf="iconData?.icons">
        <app-hint
            *ngFor="let icon of iconData.icons; let i = index"
            [ngClass]="{ 'ml-2': i > 0 }"
            placement="top"
            [hintTypes]="icon.hintType"
            [hintText]="icon.tooltipTexts"
            variation="hint">
        </app-hint>
    </ng-container>
</div>

