<div class="filter-tag-container">
    <p class="basic-white-bold-text truncate">{{value}}</p>

    <div *ngIf="isDeleteIconVisible"
        class="delete-icon d-flex align-items-center ml-5px"
        aria-label="Delete tag Icon"
        [inlineSVG]="'assets/image/times-circle.svg'"
        (click)="delete(id)">
    </div>
</div>
