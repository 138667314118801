import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';

export interface BaseDataSourcesState {
  sourceDetailLoaded: boolean;

  sourceListItem: SourceListItem;
  sources: SourceListItem[];
  selectedSource: SourceListItem;
}

export const baseDataSourcesInitialState: BaseDataSourcesState = {
  sourceDetailLoaded: false,

  sourceListItem: undefined,
  sources: [],
  selectedSource: undefined,
};
