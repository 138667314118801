<div [ngClass]="['status-selection', ngSelectClass || '']" (click)="$event.stopPropagation()">
    <ng-container *ngIf="editable && !isDisabled; else notEditable">
        <app-select
            [appendTo]="appendTo"
            class="d-block w-100"
            [class.loading]="isLoading"
            [customClass]="appSelectCustomClass"
            [options]="statusesSelectable"
            [dropdownPosition]="'auto'"
            bindValue="id"
            bindLabel="name"
            bindValueByPropertyNameToControl="id"
            (selectionChange)="handlePositionStatusChange($event)"
            [formControl]="statusControl">
            <app-status-selection-label-template
              #labelTemplate
              [hintText]="hintText">
            </app-status-selection-label-template>
            <app-status-selection-label-template #optionTemplate></app-status-selection-label-template>
        </app-select>
    </ng-container>

    <ng-template #notEditable>
        <div [ngClass]="notEditableLabelClass" class="d-flex align-items-center">
            <span class="truncate">
                {{status?.name}}
            </span>
        </div>
    </ng-template>

    <app-loading class="d-block" *ngIf="isLoading"></app-loading>
</div>
