<div *ngFor="let control of optionsArray.controls; let i = index">
    <app-form-field>
        <app-select
          (selectionChange)="onItemSelected()"
          (opened)="onSelectionOpened()"
          [options]="availableOptions"
          bindLabel="name"
          bindValue="id"
          bindValueByPropertyNameToControl="id"
          [label]="labelText"
          [formControl]="control.get('id')">
        </app-select>
        <div *ngIf="optionsArray.length > 1" right-side>
            <app-icon-button class="d-block" (buttonClick)="onRemoveItem(i)" textAlign="right"
              hintText="common.delete" iconUrl="assets/image/close-icon.svg"></app-icon-button>
        </div>
    </app-form-field>
</div>
<div *ngIf="hasMore" class="my-20px d-flex flex-column">
    <button type="button" (click)="onAddItem()" class="add-more-item text-left p-0 m-0">
        {{addMoreText | translate}}
    </button>
</div>
