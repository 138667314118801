<div class="whc-form-field has-icon w-100 d-flex justify-content-center align-items-center"
     [class.small-size]="smallSize"
     [class.field-disabled]="control.disabled">
    <input
        matInput
        readonly
        [matDatepicker]="$any(picker)"
        [max]="maxDate"
        [min]="minDate"
        [formControl]="control"
        [placeholder]="(hidePlaceholder ? '' : placeholder) | translate"
    >

    <mat-datepicker
      #picker
      (opened)="onCalendarOpened()"
      [yPosition]="yPosition"
      [xPosition]="xPosition"
      panelClass="custom-date-picker">
    </mat-datepicker>

    <span class="d-flex justify-content-end align-items-center">
        <app-icon-button
            *ngIf="clearable && control.value && control.enabled"
            (buttonClick)="onResetDate()"
            class="mr-10px"
            textAlign="right"
            iconUrl="assets/image/close-icon.svg">
        </app-icon-button>

        <app-icon-button
            (buttonClick)="picker.open()"
            textAlign="right"
            iconUrl="assets/image/calendar.svg">
        </app-icon-button>
    </span>
</div>
