import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  baseDataSourcesInitialState,
  BaseDataSourcesState,
} from '@shared/modules/state-manager/state/base-data/sources/baseDataSourcesState';

@Injectable({
  providedIn: 'root',
})
export class SourcesStateService extends StateService<BaseDataSourcesState> {
  constructor() {
    super(baseDataSourcesInitialState);
  }
}
