import { Component, Input, OnInit } from '@angular/core';
import { UserTableRow } from '@pages/users/classes/UserTableRow';
import { head } from 'lodash-es';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HintType } from '@shared/modules/hint/classes/HintType';
import {
  getUserRoleTranslationKey,
  translateRoleName,
} from '@pages/users/utils/role-to-translation-key.util';
import { UserRole } from '@shared/modules/auth/classes/UserRole';

@UntilDestroy()
@Component({
  selector: 'app-role-value-label-template',
  templateUrl: './role-value-label-template.component.html',
  styleUrls: ['./role-value-label-template.component.scss'],
})
export class RoleValueLabelTemplateComponent implements OnInit {
  @Input() row: UserTableRow;

  isLoading = false;
  isEmptyRole: boolean = false;

  hintText: string;
  roleText: string;

  hintTypes = HintType;

  ngOnInit(): void {
    this.initializeRoleInfo();
  }

  private initializeRoleInfo() {
    this.roleText = this.translateRole();
    this.isEmptyRole = this.checkIfEmptyRole();
  }

  private translateRole(): string {
    const roleText: string[] = [];

    for (const role of this.row?.roles) {
      roleText.push(translateRoleName(getUserRoleTranslationKey(role.name), true));
    }

    return roleText.join(',');
  }

  private checkIfEmptyRole() {
    this.hintText = this.roleText ? 'users.first_login_role_grant_message' : '';
    return head(this.row?.roles)?.name === UserRole.Empty;
  }
}
