import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import { Observable, Subject } from 'rxjs';
import { UpdateTableItemEvent } from '@shared/modules/data-table/classes/UpdateTableItemEvent';

@Injectable({
  providedIn: 'root',
})
export class TableService extends StateService<{}> {
  private resetSelectedItems$: Subject<void> = new Subject<void>();
  private updateTableRowValueSubject$: Subject<UpdateTableItemEvent> = new Subject<UpdateTableItemEvent>();

  constructor() {
    super({});
  }

  triggerResetSelectedItems(): void {
    this.resetSelectedItems$.next();
  }

  onResetSelectedItems(): Observable<void> {
    return this.resetSelectedItems$.asObservable();
  }

  get onUpdateItem$() {
    return this.updateTableRowValueSubject$.asObservable();
  }

  updateItemValue(event: UpdateTableItemEvent) {
    this.updateTableRowValueSubject$.next(event);
  }
}
