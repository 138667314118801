import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  AppHeaderState,
  headerInitialState,
} from '@shared/modules/state-manager/state/app-header/app-header.state';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { saveAs } from 'file-saver';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ExportModalComponent } from '@shared/modules/modals/base-modals/export-modal/export-modal.component';
import { ExportModalConfig } from '@shared/modules/modals/base-modals/export-modal/classes/ExportModalConfig';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends StateService<AppHeaderState> {
  private newItemButtonClick$: Subject<void> = new Subject<void>();
  private exportButtonClick$: Subject<void> = new Subject<void>();

  constructor(private toast: ToastService, private modalService: MatModalService) {
    super(headerInitialState);
  }

  triggerNewItemButtonClick(): void {
    this.newItemButtonClick$.next();
  }

  onNewItemButtonClick(): Observable<void> {
    return this.newItemButtonClick$.asObservable();
  }

  triggerExportButtonClick(): void {
    this.exportButtonClick$.next();
  }

  onExportButtonClick(): Observable<void> {
    return this.exportButtonClick$.asObservable();
  }

  openExportDataModal(exportFunction: () => Observable<unknown>): Observable<void> {
    return this.onExportButtonClick().pipe(
      switchMap(() => {
        return this.modalService.openDialog<ExportModalConfig>(ExportModalComponent, {
          width: '460px',
          data: {
            exportFunction,
            variant: ModalTypes.DynamicHeight,
          },
        }) as Observable<void>;
      })
    );
  }

  handleExportResponse(
    endpoint: Observable<HttpResponse<Blob>>
  ): Observable<HttpResponse<Blob> | HttpErrorResponse> {
    const message = `common.export`;
    return endpoint.pipe(
      tap((resp: HttpResponse<Blob>) => {
        saveAs(resp.body, this.getFileName(resp.headers));
        this.toast.showSuccess(getGeneralMessage(message, true));
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      })
    );
  }

  handleEmailExportResponse(endpoint: Observable<void>): Observable<void | HttpErrorResponse> {
    const message = `common.email_export_success`;

    return endpoint.pipe(
      tap(() => {
        this.toast.showSuccess(message);
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      })
    );
  }

  private getFileName(headers: HttpHeaders): string {
    if (headers?.get('content-disposition')) {
      return headers.get('content-disposition').split('=')[1];
    }

    if (headers?.get('Content-Disposition')) {
      return headers.get('Content-Disposition').split('=')[1];
    }

    return 'unknown_file.xlsx';
  }
}
