import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateWithParamsPipe } from '@shared/pipes/translate-with-params.pipe';
import { AppFirstLetterUppercasePipe } from './first-letter-uppercase.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { TimeagoPipe } from './timeago.pipe';
import { AtsBasedTextPipe } from './ats-based-text.pipe';
import { HasPermissionPipe } from './has-permission.pipe';

@NgModule({
  declarations: [
    TranslateWithParamsPipe,
    AppFirstLetterUppercasePipe,
    FormatDatePipe,
    TimeagoPipe,
    AtsBasedTextPipe,
    HasPermissionPipe,
  ],
  exports: [
    TranslateWithParamsPipe,
    AppFirstLetterUppercasePipe,
    FormatDatePipe,
    TimeagoPipe,
    AtsBasedTextPipe,
    HasPermissionPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
