import { Component, OnInit } from '@angular/core';
import { sideNavigationConfig } from '@config/side-navigation.config';
import { Observable } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { ATSConfigService } from '@shared/services/ats-config.service';
import packageJson from '../../../../../package.json';
import { UserStateService } from '@pages/users/services/base/user-state.service';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';

@UntilDestroy()
@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  sidenavItems = sideNavigationConfig.filter((sideNavItem) => sideNavItem.showItem);
  appVersion = packageJson.version;
  me$: Observable<User>;
  isLaborHireATS = false;
  navigateToValues: { [key: string]: string } = {};

  constructor(private userState: UserStateService, private atsConfigService: ATSConfigService) {}

  ngOnInit() {
    this.me$ = this.userState.select('me');
    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;

    this.getNavigateToValues();
  }

  private getNavigateToValues() {
    this.atsConfigService
      .getBackendConfig(AppConstants.powerBiReportUrl)
      .pipe(untilDestroyed(this))
      .subscribe((response: { value: string }) => {
        this.navigateToValues[
          AppConstants.powerBiReportUrl
        ] = `${environment.powerBiBaseUrl}${response.value}`;
      });
  }

  navigateTo(event: Event, navigateToValue: string): void {
    if (!this.navigateToValues[navigateToValue]) {
      event.preventDefault();
    } else {
      event.preventDefault();
      window.open(this.navigateToValues[navigateToValue], '_blank');
    }
  }
}
