import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { TableFilterState } from '@shared/classes/table-filter/TableFilterState';
import { TableFilter } from '@shared/classes/table-filter/TableFilter';
import { PartnerOrProject } from '@config/app.constant';

export interface PartnerState extends TableFilterState {
  partnerDetail: PartnerDetail;
  partnerDetailLoaded: boolean;

  projectDetail: ProjectDetail;
  projectDetailLoaded: boolean;

  headerText: string;
  type: PartnerOrProject;
}

export const partnerInitialState: PartnerState = {
  filtersLoaded: false,
  filters: new TableFilter(),

  partnerDetail: undefined,
  partnerDetailLoaded: false,

  projectDetail: undefined,
  projectDetailLoaded: false,

  headerText: '',
  type: undefined,
};
