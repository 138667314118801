import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  candidateInitialState,
  CandidateState,
} from '@shared/modules/state-manager/state/candidates/candidate.state';

@Injectable({
  providedIn: 'root',
})
export class CandidateStateService extends StateService<CandidateState> {
  constructor() {
    super(candidateInitialState);
  }
}
