<div class="modal-title d-flex">
    <div class="modal-title-section d-flex align-items-center" mat-dialog-title>
        <span *ngIf="iconUrl"
            class="section-icon d-flex justify-content-center align-items-center mr-10px"
            [inlineSVG]="iconUrl">
        </span>

        <span class="pt-1">{{title | translate | appFirstLetterUppercase}}</span>

        <app-icon-button
            *ngIf="!!syncControl"
            class="ml-3"
            [hintText]="syncOn ? 'common.sync_on':'common.sync_off'"
            [isGrey]="!syncOn"
            [isBlue]="syncOn"
            [isPulseEffect]="syncOn"
            [hintPosition]="'right'"
            [iconUrl]="syncOn ? 'assets/image/sync-on-icon.svg' : 'assets/image/sync-off-icon.svg'"
            (buttonClick)="toggleSyncState()">
        </app-icon-button>
    </div>
</div>
