import { RouterModule } from '@angular/router';
import { AppRoutes } from '@shared/classes/common/AppRoute';

const routes: AppRoutes = [
  {
    path: '',
    redirectTo: 'partners',
    pathMatch: 'full',
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./pages/partners/partners.module').then((mod) => mod.PartnersModule),
  },
  {
    path: 'positions',
    loadChildren: () =>
      import('./pages/positions/positions.module').then((mod) => mod.PositionsModule),
  },
  {
    path: 'candidates',
    loadChildren: () =>
      import('./pages/candidates/candidates.module').then((mod) => mod.CandidatesModule),
  },
  {
    path: 'base-data',
    loadChildren: () =>
      import('@pages/base-data/base-data.module').then((mod) => mod.BaseDataModule),
  },
  {
    path: 'position-applications',
    loadChildren: () =>
      import('@pages/applications/position-applications.module').then(
        (mod) => mod.PositionApplicationsModule
      ),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('@pages/phone-contacts/phone-contacts.module').then((mod) => mod.PhoneContactsModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then((mod) => mod.UsersModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notifications/notification.module').then((mod) => mod.NotificationModule),
  },
  {
    path: 'auth-user',
    loadChildren: () =>
      import('./pages/auth-user/auth-user.module').then((mod) => mod.AuthUserModule),
  },
  {
    path: '**',
    redirectTo: 'candidates',
  },
];

/** Main routing module */
export const AppRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
