import { Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@shared/classes/KeyValue';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleCheckResponse } from '@pages/users/classes/RoleCheckResponse';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { FormControl } from '@angular/forms';
import { UserApiService } from '@pages/users/services/base/user-api.service';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { environment } from '@environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-user-modal-role-change',
  templateUrl: './user-modal-role-change.component.html',
  styleUrls: ['./user-modal-role-change.component.scss'],
})
export class UserModalRoleChangeComponent implements OnInit {
  @Input() user: UserListItem;
  @Input() isEditMode: boolean;
  @Input() roles: KeyValue<UserRole>[];
  @Input() projectSpecializations: Specialization[];
  @Input() roleNameControl: FormControl;
  @Input() positionControl: FormControl;
  @Input() projectSpecializationControl: FormControl;

  isRoleChangeCheckLoading = false;
  isShowAdditionalFieldsForRole = false;

  constructor(private userApiService: UserApiService) {}

  ngOnInit() {
    this.isShowAdditionalFieldsForRole = this.shouldShowAdditionalFields(
      this.roleNameControl.value as UserRole
    );

    this.listenRoleFieldChange();
  }

  onRoleChange(role: KeyValue<UserRole>): void {
    this.isRoleChangeCheckLoading = true;
    this.userApiService
      .checkRoleChange(this.user.id, role.key)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: RoleCheckResponse) => {
          this.roleNameControl.setErrors(null);

          if (response.hasWarnings) {
            this.roleNameControl.setErrors({
              roleChangeWarning: true,
            });
          }

          this.isRoleChangeCheckLoading = false;
        },
        () => (this.isRoleChangeCheckLoading = false)
      );
  }

  private listenRoleFieldChange() {
    this.roleNameControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isShowAdditionalFieldsForRole = this.shouldShowAdditionalFields(value as UserRole);
    });
  }

  private shouldShowAdditionalFields(value: UserRole): boolean {
    return (
      [UserRole.WhcContact, UserRole.Partner].includes(value) &&
      environment.atsType !== 'labor-hire'
    );
  }
}
