import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { UserStateService } from '@pages/users/services/base/user-state.service';

@Component({
  selector: 'app-user-profile-header',
  templateUrl: './user-profile-header.component.html',
  styleUrls: ['./user-profile-header.component.scss'],
})
export class UserProfileHeaderComponent implements OnInit {
  me$: Observable<User>;
  manageUserRoles = [UserRole.SuperAdmin, UserRole.Admin];

  constructor(private userState: UserStateService) {}

  ngOnInit(): void {
    this.me$ = this.userState.select('me');
  }
}
