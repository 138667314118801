import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { AdvertisementProviderTypes } from '@pages/positions/classes/advertisements/AdvertisementProviderTypes';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { sortBy } from 'lodash-es';

export const providerTypesTranslationMap: { [key: string]: string } = {
  [AdvertisementProviderTypes.Automatic]: 'positions.advertisements.provider_automatic',
  [AdvertisementProviderTypes.Manual]: 'positions.advertisements.provider_manual',
};

export function mapProvidersToCardTable(
  providers: AdvertisementProvider[]
): AdvertisementProviderCardTableRow[] {
  const getProviderTypeTranslated = (type: string): string => {
    if (!providerTypesTranslationMap[type]) {
      return TranslateInstance.instant('common.unknown');
    }

    return TranslateInstance.instant(providerTypesTranslationMap[type]);
  };

  const mapped = providers.map((provider) => ({
    ...provider,
    providerTypeName: getProviderTypeTranslated(provider?.providerType?.type),
    startDate: provider.startDate ?? '',
    endDate: provider.endDate ?? '',
  }));

  return sortBy(mapped, ['status', 'providerTypeName']);
}
