import { Component, Input, OnInit } from '@angular/core';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';
import { Observable, throwError } from 'rxjs';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { catchError, take } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';

@Component({
  selector: 'app-document-position-selection',
  templateUrl: './document-position-selection.component.html',
  styleUrls: ['./document-position-selection.component.scss'],
})
export class DocumentPositionSelectionComponent implements OnInit {
  @Input() group: FormGroup;
  positions$: Observable<CandidatePositionMinimal[]>;
  isPositionListLoading$: Observable<boolean>;
  isPositionListLoadErrorOccurred = false;

  constructor(
    private candidateState: CandidateStateService,
    private candidateService: CandidatesService
  ) {}

  ngOnInit(): void {
    this.isPositionListLoading$ = this.candidateState.select(
      'candidatePositionsForDocumentUploadLoading'
    );
    this.positions$ = this.candidateState.select('candidatePositionsForDocumentUpload');
    this.getPositionsForDropdown();
  }

  private getPositionsForDropdown(): void {
    const { candidatePositionsForDocumentUploadLoaded } = this.candidateState.getStateSnapshot();
    const { id } = this.candidateState.getStateSnapshot().candidateDetail;

    if (!candidatePositionsForDocumentUploadLoaded) {
      this.candidateService
        .getCandidateAttachedPositions(id)
        .pipe(
          take(1),
          catchError((err) => {
            this.isPositionListLoadErrorOccurred = true;
            return throwError(err);
          })
        )
        .subscribe((positions: CandidatePositionMinimal[]) => {
          this.isPositionListLoadErrorOccurred = false;
          this.candidateState.setState({
            candidatePositionsForDocumentUploadLoaded: true,
            candidatePositionsForDocumentUpload: positions,
          });
        });
    }
  }
}
