<app-icon-button
    *appUserHasRole="DELETE_ROLES"
    class="delete-button"
    [isErrorLike]="true"
    [hintText]="hintText"
    [hintPosition]="'left'"
    [disabled]="disabled"
    [iconUrl]="ICON_URL"
    (buttonClick)="callDeleteWithConfirm()">
</app-icon-button>
