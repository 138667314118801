import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';
import { MenuService } from '@shared/services/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle-cell',
  templateUrl: './toggle-cell.component.html',
  styleUrls: ['./toggle-cell.component.scss'],
})
export class ToggleCellComponent extends CardTableCustomCellBaseComponent {
  @Input() isImageShown = true;
  @Input() isActive: boolean;
  @Input() imageKey: string;
  @Input() isToggleDisabled = false;

  @Output() toggleChange = new EventEmitter<unknown>();

  statuses = ProjectStatus;
  beforeChange$: Observable<boolean>;

  constructor(private menuService: MenuService) {
    super();

    this.beforeChange$ = menuService.toggleSubject$.asObservable();
  }
}
