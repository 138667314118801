<div class="filter-header d-flex align-items-center">
    <app-rounded-button
        type="button"
        [text]="'common.all' | translate"
        (buttonClick)="onButtonClick(null)"
        [isActive]="!selectedLetter">
    </app-rounded-button>

    <div *ngFor="let firstLetter of firstLetters">
        <app-rounded-button
            type="button"
            [text]="firstLetter"
            [isActive]="firstLetter === selectedLetter"
            [buttonStyle]="'circle'"
            (buttonClick)="onButtonClick(firstLetter)">
        </app-rounded-button>
    </div>
</div>
