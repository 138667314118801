import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { ListData, ListTotalCount } from '@shared/classes/ListData';
import { PhoneContactListItem } from '@pages/phone-contacts/classes/PhoneContactListItem';
import { EndpointsConfig } from '@config/endpoints.config';
import { HttpResponse } from '@angular/common/http';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateApiService } from '@pages/candidates/services/base/candidate-api.service';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { FilterDropdown } from '@shared/modules/custom-selectors/custom-filter-selector/classes/FilterDropdown';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';

@Injectable({
  providedIn: 'root',
})
export class PhoneContactApiService {
  constructor(private http: HttpService, private candidateApiService: CandidateApiService) {}

  getPhoneContacts(
    page: string,
    perPage: string,
    columnIds: string[],
    filter?: string
  ): Observable<ListData<PhoneContactListItem>> {
    const params: { [k: string]: string } = {
      page,
      perPage,
    };

    if (filter) {
      params.filter = filter;
    }

    return this.http.post(EndpointsConfig.phoneContactsList, { columnIds }, params);
  }

  getPhoneContactsFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.phoneContactsFirstLetters);
  }

  exportPhoneContactTable(filter: string, columns: TableColumn[]): Observable<HttpResponse<Blob>> {
    const params = filter ? { filter } : null;

    return this.http.postFile(EndpointsConfig.phoneContactsExport, columns, params);
  }

  exportPhoneContactTableEmail(filter: string, columns: TableColumn[]): Observable<void> {
    const params = filter ? { filter } : null;

    return this.http.post(EndpointsConfig.phoneContactsExportEmail, { columns }, params);
  }

  createPhoneContact(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.post(
      EndpointsConfig.phoneContacts,
      this.candidateApiService.normalizeCandidateDto(candidateDto)
    );
  }

  updatePhoneContact(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.put(
      EndpointsConfig.phoneContactById(candidateDto.id),
      this.candidateApiService.normalizeCandidateDto(candidateDto)
    );
  }

  getPhoneContactFilters(filter: string): Observable<{ [key: string]: CardFilterInterface }> {
    const params: { [k: string]: string } = {};

    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.phoneContactsFilters, params);
  }

  getFilterDropdown(): Observable<FilterDropdown[]> {
    return this.http.get(EndpointsConfig.phoneContactsFiltersDropdown);
  }

  getPhoneContactTotalCount(filter?: string): Observable<ListTotalCount> {
    const params: { [k: string]: string } = {};

    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.phoneContactsTotalCount, params);
  }
}
