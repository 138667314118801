import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineEditCellComponent } from './components/text-inline-edit-cell/inline-edit-cell.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { InlineEditCellContainerComponent } from './components/inline-edit-cell-container/inline-edit-cell-container.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { InlineEditCellValuePipe } from '@shared/modules/data-table-inline-edit/pipes/inline-edit-cell-value.pipe';
import { InlineEditCellDataPipe } from './pipes/inline-edit-cell-data.pipe';
import { HintModule } from '@shared/modules/hint/hint.module';

@NgModule({
  declarations: [
    InlineEditCellComponent,
    InlineEditCellContainerComponent,
    InlineEditCellValuePipe,
    InlineEditCellDataPipe,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormComponentsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    LoaderModule,
    DirectivesModule,
    NgSelectModule,
    FilteringModule,
    TranslateModule,
    PipesModule,
    HintModule,
  ],
  exports: [InlineEditCellComponent, InlineEditCellContainerComponent, InlineEditCellDataPipe],
})
export class DataTableInlineEditModule {}
