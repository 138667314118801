import values from 'lodash-es/values';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { environment } from '@environments/environment';
import { ATSType } from '@shared/classes/ATSType';
import { UserRole } from './UserRole';

function applyAllPermissions() {
  return values(UserPermission);
}

function addPermissionByATSType(
  atsTypes: ATSType[],
  permissionsToAdd: UserPermission[]
): UserPermission[] {
  if (atsTypes.includes(environment.atsType)) {
    return permissionsToAdd;
  }

  return [];
}

export const UserRolePermission: { [key: string]: UserPermission[] } = {};
UserRolePermission[UserRole.SuperAdmin] = applyAllPermissions();
UserRolePermission[UserRole.Admin] = applyAllPermissions();
UserRolePermission[UserRole.FieldManager] = [
  UserPermission.CreatePartner,
  UserPermission.UpdatePartner,
  UserPermission.ReadPartner,
  UserPermission.ManageHistory,
  UserPermission.ReadProject,
  UserPermission.ManageProject,
  UserPermission.ManageAdvertisement,
  UserPermission.ManagePositionCandidates,
  UserPermission.ReadPosition,
  UserPermission.CreatePosition,
  UserPermission.ExportTable,
  UserPermission.ManageFilters,
  UserPermission.BulkEdit,
  UserPermission.CreateCandidate,
  UserPermission.DetachCandidatePosition,
  UserPermission.CreatePhoneContact,
  UserPermission.ManageTableViews,
];
UserRolePermission[UserRole.ProjectManager] = [
  UserPermission.ReadPartner,
  UserPermission.CreatePosition,
  UserPermission.UpdatePartner,
  UserPermission.ManageHistory,
  UserPermission.ReadProject,
  UserPermission.ManageAdvertisement,
  UserPermission.ManagePositionCandidates,
  UserPermission.ReadPosition,
  UserPermission.ExportTable,
  UserPermission.ManageFilters,
  UserPermission.BulkEdit,
  UserPermission.DetachCandidatePosition,
  UserPermission.CreateCandidate,
  UserPermission.CreatePhoneContact,
  UserPermission.ManageTableViews,
  ...addPermissionByATSType(
    ['labor-hire'],
    [UserPermission.CreatePartner, UserPermission.ManageProject]
  ),
];
UserRolePermission[UserRole.WhcContact] = [];
UserRolePermission[UserRole.Technical] = [];
UserRolePermission[UserRole.Partner] = [];
UserRolePermission[UserRole.Candidate] = [];
