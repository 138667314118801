<div *ngFor="let formGroup of formArray.controls, let i = index;" [class.top-separator]="i > 0">
    <div class="position-relative">
        <app-custom-field-selection
                class="custom-listen-form-array"
                [customFieldTypes]="customFields"
                [typeControl]="getFormControl(formGroup, 'fieldTypeId')"
                [valueControl]="getFormControl(formGroup, 'fieldTypeValue')">
        </app-custom-field-selection>

        <div class="position-form-remove-icon">
            <app-icon-button
                    (buttonClick)="removeForm(i)"
                    class="d-block"
                    textAlign="right"
                    hintText="common.delete"
                    iconUrl="assets/image/close-icon.svg">
            </app-icon-button>
        </div>
    </div>
</div>

<div class="my-20px d-flex flex-column">
    <button
            (click)="addNewForm()"
            [disabled]="disabled"
            type="button"
            class="add-more-item text-left p-0 m-0">
        {{ 'candidates.attach_custom_fields' | attachNewFormFieldsButton }}
    </button>
</div>
