import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import {
  InlineEditCellId,
  InlineEditCellInputStatus,
  InlineEditCellSaveStatus,
  InlineEditData,
  InlineEditDataList,
  InlineEditTriggerCellRefresh,
} from '@shared/modules/data-table-inline-edit/classes/inline-edit-data-list';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { TableService } from '@shared/services/table.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  InlineEditColumnData,
  InlineEditColumnDataList,
  InlineEditColumnType,
} from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { InlineEditRequest } from '@shared/modules/data-table-inline-edit/classes/inline-edit-request';
import { CustomCell } from '@shared/modules/data-table/classes/CustomCell';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { InlineEditCellComponent } from '@shared/modules/data-table-inline-edit/components/text-inline-edit-cell/inline-edit-cell.component';
import { CandidateCustomFieldType } from '@pages/candidates/classes/CandidateCustomFieldType';
import { AppConstants } from '@config/app.constant';
import { TableViewColumnOption } from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableView';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { InlineEditResponse } from '@shared/modules/data-table-inline-edit/classes/inline-edit-response';
import { UnknownErrorResponse } from '@shared/classes/common/HttpErrorResponse';

/**
 * To add inline edit to table:
 * - set inlineEditMode variable to true in *-list component
 * - set [inlineEditMode]=inlineEditMode to data-table in input
 * - set [primaryKey] and [secondaryKey] to data-table in input
 * - add custom column configs to base columns if necessary
 * - make sure on backend, custom columns values is responded in object
 * - in tableRow-s all select type inline edit columns must object type
 */
@Injectable({
  providedIn: 'root',
})
export class DataTableInlineEditService {
  private cellEditingData: InlineEditDataList = {};
  private columnData: InlineEditColumnDataList = {};

  triggerCellRefresh: InlineEditTriggerCellRefresh = {};

  primaryKey: string;
  secondaryKey: string;

  private currentCellEditingId: InlineEditCellId;

  constructor(
    private httpService: HttpService,
    private tableService: TableService,
    private toastService: ToastService
  ) {}

  initColumnData(columnProp: string | number) {
    this.columnData[columnProp.toString()] = {
      params: { type: InlineEditColumnType.NotSet },
      loading: false,
    };
  }

  initCellEditData(rowIndex: number, columnProp: string | number) {
    const id = this.getCellIdentifier(rowIndex, columnProp);

    this.cellEditingData[id] = {
      rowIndex,
      property: columnProp,
      isEditing: false,
      isLoading: false,
      isSelected: false,
      saveStatus: InlineEditCellSaveStatus.Unchanged,
      inputStatus: InlineEditCellInputStatus.Valid,
      inputGroup: new FormGroup({ value: new FormControl() }),
      active: false,
      errorMessage: null,
    };

    this.triggerCellRefresh[id] = 0;
  }

  getCellIdentifier(rowIndex: number, columnProp: string | number) {
    return `${rowIndex}-${columnProp}`;
  }

  getCellEditingData(rowIndex: number, columnProp: string | number): Readonly<InlineEditData> {
    return this.cellEditingData[this.getCellIdentifier(rowIndex, columnProp)];
  }

  getColumnData(columnProp: string | number): Readonly<InlineEditColumnData> {
    return this.columnData[columnProp.toString()];
  }

  setCellEditingData(
    rowIndex: number,
    column: TableColumn,
    data: Partial<Omit<InlineEditData, 'isEditing'>>
  ) {
    const id = this.getCellIdentifier(rowIndex, column.prop);
    this.cellEditingData[id] = { ...this.cellEditingData[id], ...data };

    this.triggerRefreshCell(rowIndex, column.prop);
  }

  setColumnData(
    columnProp: string | number,
    data: Partial<InlineEditColumnData>,
    rowIndex?: number
  ) {
    const id = columnProp.toString();

    if (!this.columnData[id]) {
      this.initColumnData(id);
    }

    this.columnData[id] = { ...this.columnData[id], ...data };

    if (rowIndex !== undefined) {
      this.triggerRefreshCell(rowIndex, columnProp);
    }
  }

  startCellEditing(rowIndex: number, column: TableColumn) {
    this.endCurrentCellEditing();

    const id = this.getCellIdentifier(rowIndex, column.prop);
    this.cellEditingData[id].isEditing = true;
    this.cellEditingData[id].inputStatus = InlineEditCellInputStatus.Valid;

    this.currentCellEditingId = { rowIndex, column };

    this.triggerRefreshCell(rowIndex, column.prop);
  }

  endCurrentCellEditing() {
    if (this.currentCellEditingId) {
      const id = this.getCellIdentifier(
        this.currentCellEditingId.rowIndex,
        this.currentCellEditingId.column.prop
      );

      this.cellEditingData[id].isEditing = false;

      this.triggerRefreshCell(
        this.currentCellEditingId.rowIndex,
        this.currentCellEditingId.column.prop
      );

      this.currentCellEditingId = null;
    }
  }

  saveModifiedCellData(
    rowIndex: number,
    column: TableColumn,
    row: DataTableRow,
    value: unknown,
    originalValue: unknown,
    convertValueToArray: boolean
  ) {
    this.setCellEditingData(rowIndex, column, {
      isLoading: true,
    });

    const data: InlineEditRequest = {
      rowIds: [
        {
          primaryKey: row[this.primaryKey as keyof DataTableRow],
          secondaryKey: row[this.secondaryKey as keyof DataTableRow],
        },
      ],
      property: column.prop as string,
      value: convertValueToArray ? [value] : value,
    };

    this.httpService.post<InlineEditResponse>('inline-edit', data).subscribe({
      next: (response: InlineEditResponse) => {
        const isSuccess = response[0].success;

        this.tableService.updateItemValue({
          rowIndex,
          property: column.prop,
          value: isSuccess ? value : originalValue,
        });

        this.setCellEditingData(rowIndex, column, {
          saveStatus: isSuccess ? InlineEditCellSaveStatus.Updated : InlineEditCellSaveStatus.Error,
          isLoading: false,
          errorMessage: response[0].error,
        });

        if (!isSuccess) {
          this.toastService.showError('common.save_element_error');
        }
      },
      error: (error: UnknownErrorResponse) => {
        this.setCellEditingData(rowIndex, column, {
          saveStatus: InlineEditCellSaveStatus.Error,
          isLoading: false,
          errorMessage: typeof error.error?.errors === 'string' ? error.error.errors : 'unknown',
        });

        this.toastService.showError('common.save_element_error');
      },
    });
  }

  getInlineEditCustomCellConfig(
    customCellName: string,
    tableViewColumn: TableViewColumnOption
  ): CustomCell<ICustomCellComponent> {
    const customCellConfig: CustomCell<ICustomCellComponent> = {
      name: customCellName,
      component: InlineEditCellComponent,
    };

    switch (tableViewColumn.type) {
      case CandidateCustomFieldType.String:
        customCellConfig.componentParams = {
          type: InlineEditColumnType.Text,
          validators: {
            required: true,
          },
        };
        break;
      case CandidateCustomFieldType.Number:
        customCellConfig.componentParams = {
          type: InlineEditColumnType.Text,
          placeholder: '1234',
          validators: {
            required: true,
            pattern: AppConstants.numberRegex,
          },
        };
        break;
      case CandidateCustomFieldType.Date:
        customCellConfig.componentParams = {
          type: InlineEditColumnType.Date,
          placeholder: '1900-01-01',
          validators: {
            required: true,
          },
        };
        break;
      case CandidateCustomFieldType.Select:
        customCellConfig.componentParams = {
          type: InlineEditColumnType.Select,
          options: {
            items: tableViewColumn.options,
            bindLabel: 'name',
          },
          bindValue: 'name',
        };
        break;
      case CandidateCustomFieldType.Multiselect:
        customCellConfig.componentParams = {
          type: InlineEditColumnType.MultiSelect,
          options: {
            items: tableViewColumn.options,
            bindLabel: 'name',
          },
          bindValue: 'name',
        };
        break;
    }

    return customCellConfig;
  }

  triggerRefreshCell(rowIndex: number, columnProp: string | number) {
    const id = this.getCellIdentifier(rowIndex, columnProp);

    this.triggerCellRefresh[id] = (this.triggerCellRefresh[id] + 1) % 10;
  }

  resetAllInlineEditData() {
    this.cellEditingData = {};
    this.columnData = {};

    this.triggerCellRefresh = {};
    this.currentCellEditingId = null;

    this.primaryKey = null;
    this.secondaryKey = null;
  }
}
