<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'base_data.sources.delete_source_title'"
        [iconUrl]="'assets/image/common/base-data.svg'">
    </app-modal-header>

    <div *ngIf="!loading" content class="delete-source-modal">
        <h1 class="message warning-message mb-0">
            {{'base_data.sources.messages.warning' | translate:{ value: data?.source.name } }}
        </h1>
        <h1 class="message helper-message mb-4">
            {{'base_data.sources.messages.supporting' | translate: { value: data?.source.name } }}
        </h1>
        <form [formGroup]="form" class="modal-base h-auto">
            <app-form-field>
                <app-select
                    [label]="'base_data.sources.source_name'"
                    [bindLabel]="'name'"
                    [bindValue]="'id'"
                    [bindValueByPropertyNameToControl]="'id'"
                    [placeholder]="'common.please_select'"
                    [searchable]="true"
                    [options]=existingSources
                    formControlName="newPropertyTypeId">
                </app-select>
            </app-form-field>
        </form>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [primaryButtonColor]="'warn'"
        [primaryButtonText]="'common.delete_confirm'"
        (primaryButtonClicked)="saveSource()">
    </app-modal-footer>
</app-modal-frame>
