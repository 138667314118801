import { Injectable } from '@angular/core';
import { userInitialState, UserState } from '@shared/modules/state-manager/state/users/user.state';
import { Observable, of, throwError } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { UserApiService } from '@pages/users/services/base/user-api.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { AvatarUploadResponse } from '@pages/users/classes/AvatarUploadResponse';
import { UserDto } from '@pages/users/classes/UserDto';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { omit } from 'lodash-es';
import { RelationCheckResponse } from '@pages/users/classes/RelationCheckResponse';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';
import {
  getUserRoleTranslationKey,
  translateRoleName,
} from '@pages/users/utils/role-to-translation-key.util';
import { UserTableRow } from '@pages/users/classes/UserTableRow';
import { HttpErrorResponse } from '@angular/common/http';
import { UserStateService } from '@pages/users/services/base/user-state.service';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private userApiService: UserApiService,
    private userState: UserStateService,
    private modalService: MatModalService,
    private toast: ToastService
  ) {}

  private getStateSnapshot() {
    return this.userState.getStateSnapshot();
  }

  private setState(state: Partial<UserState>) {
    return this.userState.setState(state);
  }

  getMe(): Observable<User | HttpErrorResponse> {
    return this.userApiService.getMe().pipe(
      tap((me: User) => {
        this.setState({ me });
      }),
      catchError((err: HttpErrorResponse) => of(err))
    );
  }

  callUploadAvatar(avatarBase64: string): Observable<AvatarUploadResponse> {
    return this.userApiService
      .uploadUserAvatar({
        avatar: avatarBase64,
      })
      .pipe(
        tap((response: AvatarUploadResponse) => {
          this.refreshAvatarState(response?.url);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  }

  callCreateUser(dto: UserDto): Observable<User | HttpErrorResponse> {
    return this.userApiService
      .createUser(dto)
      .pipe(this.handleUserManageSideEffects('users.created'));
  }

  callUpdateUser(dto: UserDto): Observable<User | HttpErrorResponse> {
    return this.userApiService
      .updateUser(dto)
      .pipe(this.handleUserManageSideEffects('users.modified'));
  }

  refreshUserList(): void {
    const roles = this.getStateSnapshot().currentUserRoles;

    this.userApiService
      .getUsersByRole(roles)
      .pipe(catchError((err) => throwError(err)))
      .subscribe((users) => {
        this.setState({ users: this.mapUsersToTableRow(users) });
      });
  }

  checkUserRelations(user: UserListItem) {
    return this.userApiService.checkHasRelations(user.id).pipe(
      concatMap((payload: RelationCheckResponse) => {
        if (payload.isExists) {
          return this.modalService.openConfirmModal({
            data: {
              secondaryButtonText: 'common.cancel',
              primaryButtonText: 'common.continue',
              modalText: 'users.user_has_relation',
              confirmObservable: this.callUserDelete(user),
            },
          }) as Observable<User | false | HttpErrorResponse>;
        }

        return this.callUserDelete(user);
      })
    );
  }

  private callUserDelete(user: UserListItem): Observable<User | HttpErrorResponse> {
    return this.userApiService
      .deleteUser(user)
      .pipe(this.handleUserManageSideEffects('users.deleted'));
  }

  getTranslatedRoles(mapAllRoles = false): KeyValue<string>[] {
    const roles = mapAllRoles
      ? AppConstants.availableRoles
      : this.getStateSnapshot().currentUserRoles;

    return roles.map((role) => {
      return {
        key: role,
        value: translateRoleName(getUserRoleTranslationKey(role), true),
      };
    });
  }

  mapUsersToTableRow(userList: UserListItem[]): UserTableRow[] {
    return userList.map((item) => {
      return {
        ...item,
        status: item?.status
          ? TranslateInstance.instant(`common.${item.status.toLowerCase()}`)
          : '',
      };
    });
  }

  resetUserState(): void {
    this.setState(omit<UserState>(userInitialState, 'me'));
  }

  private handleUserManageSideEffects(
    toastText: string
  ): (source: Observable<User>) => Observable<User | HttpErrorResponse> {
    return (source: Observable<User>): Observable<User | HttpErrorResponse> => {
      return source.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
          this.refreshUserList();
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        })
      );
    };
  }

  private refreshAvatarState(newAvatarUrl: string): void {
    if (newAvatarUrl) {
      const { me } = this.getStateSnapshot();
      this.setState({ me: { ...me, avatarThumb: newAvatarUrl } });
    }
  }
}
