import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInputBaseComponent } from '../base/form-input-base.component';

export interface RadioGroupOption {
  value: number | string;
  label: string;
}

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent extends FormInputBaseComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() options: RadioGroupOption[];
  @Input() isVerticalLayout = false;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }
}
