import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  partnerInitialState,
  PartnerState,
} from '@shared/modules/state-manager/state/partners/partner.state';

@Injectable({
  providedIn: 'root',
})
export class PartnerStateService extends StateService<PartnerState> {
  constructor() {
    super(partnerInitialState);
  }
}
