<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'positions.advertisements.edit'">
    </app-modal-header>

    <ng-container content>
        <form *ngIf="!loading"
              [formGroup]="form"
              class="modal-base edit-advertisement-container h-auto">
            <div class="detail-grid">
                <div class="left-side">
                    <app-modal-section-header
                        sectionTitle="positions.advertisements.details">
                    </app-modal-section-header>

                    <app-form-field>
                        <app-tags-autocomplete
                            [searchAble]="true"
                            [isFieldRequired]="true"
                            [selectedTagsFormArray]="tagArray"
                            [allTags]="existingTags">
                        </app-tags-autocomplete>
                    </app-form-field>

                    <app-form-field>
                        <app-select
                            label="positions.advertisements.experience"
                            [options]="workExperiences"
                            bindValue="id"
                            bindLabel="name"
                            bindValueByPropertyNameToControl="id"
                            [isHintShown]="true"
                            formControlName="workExperienceId">
                        </app-select>
                    </app-form-field>

                    <app-spoken-language-selection
                        [languageArray]="languageArray"
                        [languages]="languages"
                        [languageLevels]="languageLevels">
                    </app-spoken-language-selection>

                    <app-form-field>
                        <app-select
                            label="positions.advertisements.required_school"
                            [options]="educationTypes"
                            bindValue="id"
                            bindLabel="name"
                            bindValueByPropertyNameToControl="id"
                            [isHintShown]="true"
                            formControlName="educationTypeId">
                        </app-select>
                    </app-form-field>

                    <app-form-field>
                        <app-text-field
                            label="positions.advertisements.work_place"
                            formControlName="local">
                        </app-text-field>
                    </app-form-field>
                </div>
                <div class="right-side">
                    <app-modal-section-header
                        sectionTitle="positions.advertisements.work_place_in_advert">
                    </app-modal-section-header>

                    <app-form-field>
                        <app-text-field
                            label="positions.advertisements.address"
                            formControlName="locality">
                        </app-text-field>
                    </app-form-field>
                </div>
            </div>

            <div>
                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.summary"
                        formControlName="summary"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill"
                        [required]="true">
                    </app-text-editor>
                </app-form-field>

                <app-form-field>
                    <app-text-area
                        label="positions.advertisements.attractive"
                        formControlName="attractive">
                    </app-text-area>
                </app-form-field>

                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.header"
                        formControlName="header"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill"
                        [required]="true">
                    </app-text-editor>
                </app-form-field>

                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.tasks"
                        formControlName="tasks"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill"
                        [required]="true">
                    </app-text-editor>
                </app-form-field>

                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.offers"
                        formControlName="offers"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill"
                        [required]="true">
                    </app-text-editor>
                </app-form-field>

                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.requirements"
                        formControlName="requirements"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill"
                        [required]="true">
                    </app-text-editor>
                </app-form-field>

                <app-form-field>
                    <app-text-editor
                        label="positions.advertisements.benefits"
                        formControlName="benefits"
                        [minLength]="minLengthForQuill"
                        [maxLength]="maxLengthForQuill">
                    </app-text-editor>
                </app-form-field>
            </div>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="saveAdvertisement()">
    </app-modal-footer>

</app-modal-frame>
