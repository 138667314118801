import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonBaseComponent } from '@shared/modules/form-components/button/button-base.components';
import { PrimaryButtonComponent } from '@shared/modules/form-components/button/primary-button/primary-button.component';
import { FabButtonComponent } from '@shared/modules/form-components/button/fab-button/fab-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { SimpleButtonComponent } from '@shared/modules/form-components/button/simple-button/simple-button.component';
import { MatIconModule } from '@angular/material/icon';
import { FilterMenuButtonComponent } from './filter-menu-button/filter-menu-button.component';
import { RoundedButtonComponent } from './rounded-button/rounded-button.component';
import { BackArrowButtonComponent } from './back-arrow-button/back-arrow-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { LoadMoreButtonComponent } from './load-more-button/load-more-button.component';
import { DeleteButtonComponent } from './delete-icon-button/delete-button.component';
import { AuthModule } from '@shared/modules/auth/auth.module';

@NgModule({
  declarations: [
    ButtonBaseComponent,
    PrimaryButtonComponent,
    FabButtonComponent,
    FilterMenuButtonComponent,
    RoundedButtonComponent,
    BackArrowButtonComponent,
    IconButtonComponent,
    LoadMoreButtonComponent,
    SimpleButtonComponent,
    DeleteButtonComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    PipesModule,
    TippyModule,
    MatIconModule,
    AuthModule,
  ],
  exports: [
    PrimaryButtonComponent,
    FabButtonComponent,
    FilterMenuButtonComponent,
    RoundedButtonComponent,
    BackArrowButtonComponent,
    IconButtonComponent,
    LoadMoreButtonComponent,
    SimpleButtonComponent,
    DeleteButtonComponent,
  ],
})
export class ButtonModule {}
