<ng-container>
    <app-form-field>
        <app-select
          [label]="partnerLabel"
          [options]="partners"
          (selectionChange)="onPartnerChange($event); partnerChange.emit($event)"
          bindLabel="name"
          bindValue=""
          [searchable]="true"
          [clearable]="true"
          [formControl]="partnerControl"
          [virtualScroll]="true">
        </app-select>
    </app-form-field>

    <app-form-field *ngIf="partnerControl.value">
        <app-select
          [label]="projectLabel | appATSBasedText"
          [options]="projects"
          bindLabel="name"
          bindValue=""
          [clearable]="true"
          (selectionChange)="onProjectChange($event); projectChange.emit($event)"
          [formControl]="projectControl"
          [virtualScroll]="true">
        </app-select>
    </app-form-field>

    <app-form-field *ngIf="projectControl.value">
        <app-select
          [label]="positionLabel"
          [options]="positions"
          bindLabel="name"
          bindValue=""
          (selectionChange)="positionChange.emit($event)"
          [formControl]="positionsControl"
          [virtualScroll]="true">
        </app-select>
    </app-form-field>
</ng-container>

