import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import { appInitialState, AppState } from '@shared/modules/state-manager/state/app/app.state';
import { Observable, of } from 'rxjs';
import { Office } from '@pages/partners/classes/Office';
import { map, tap } from 'rxjs/operators';
import { EndpointsConfig } from '@config/endpoints.config';
import { HttpService } from '@shared/modules/http/http.service';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { City, CityMinimal } from '@shared/classes/City';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { Country } from '@shared/classes/Country';
import { EducationType } from '@pages/positions/classes/EducationType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';
import { AppConfig } from '@config/app.config';
import { Contact } from '@pages/partners/classes/Contact';
import { IdNameFilterOption } from '@shared/modules/filter-components/components/truefalse-filtering/classes/IdNameFilterOption';
import { TranslateService } from '@ngx-translate/core';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@Injectable({
  providedIn: 'root',
})
export class AppStateService extends StateService<AppState> {
  constructor(private http: HttpService, private translateService: TranslateService) {
    super(appInitialState);
  }

  getOffices(): Observable<Office[]> {
    return this.getEntities<Office>('officesLoaded', 'offices', EndpointsConfig.offices);
  }

  getCountries(): Observable<Country[]> {
    return this.getEntities<Country>('countriesLoaded', 'countries', EndpointsConfig.countries);
  }

  getSources(forceRefresh = false): Observable<Source[]> {
    return this.getEntities<Source>(
      'sourcesLoaded',
      'sources',
      EndpointsConfig.providerTypes,
      forceRefresh
    );
  }

  getJobTypes(): Observable<JobType[]> {
    return this.getEntities<JobType>('jobTypesLoaded', 'jobTypes', EndpointsConfig.jobTypes);
  }

  getSpecializations(): Observable<Specialization[]> {
    return this.getEntities<Specialization>(
      'specializationsLoaded',
      'specializations',
      EndpointsConfig.specializations
    );
  }

  getProjectSpecializations(): Observable<Specialization[]> {
    return this.getEntities<Specialization>(
      'specializationsLoaded',
      'specializations',
      EndpointsConfig.projectSpecializations
    );
  }

  getLanguages(): Observable<Language[]> {
    return this.getEntities<Language>('languagesLoaded', 'languages', EndpointsConfig.languages);
  }

  getPreferredLanguages(): Observable<Language[]> {
    return this.getEntities<Language>(
      'languagesLoaded',
      'languages',
      EndpointsConfig.languages
    ).pipe(
      map((languages) => {
        return languages.filter((language) => language.isPreferable);
      })
    );
  }

  getLanguageLevels(): Observable<LanguageLevel[]> {
    return this.getEntities<LanguageLevel>(
      'languageLevelsLoaded',
      'languageLevels',
      EndpointsConfig.languageLevels
    );
  }

  getDocumentTypes(): Observable<CandidateDocumentType[]> {
    return this.getEntities<CandidateDocumentType>(
      'documentTypesLoaded',
      'documentTypes',
      EndpointsConfig.documentTypes
    );
  }

  getEmploymentTypes(): Observable<EmploymentType[]> {
    return this.getEntities<EmploymentType>(
      'employmentTypesLoaded',
      'employmentTypes',
      EndpointsConfig.employmentTypes
    );
  }

  getEducationTypes(): Observable<EducationType[]> {
    return this.getEntities<EducationType>(
      'educationTypesLoaded',
      'educationTypes',
      EndpointsConfig.educationTypes
    );
  }

  getWorkExperiences(): Observable<WorkExperience[]> {
    return this.getEntities<WorkExperience>(
      'workExperiencesLoaded',
      'workExperiences',
      EndpointsConfig.workExperiences
    );
  }

  getCityByName(countryId: number, cityName: string): Observable<City[]> {
    return this.http.get(EndpointsConfig.citySearch(countryId), { name: cityName });
  }

  getProjectManagers(): Observable<Contact[]> {
    return this.getEntities(
      'projectManagersLoaded',
      'projectManagers',
      EndpointsConfig.projectManagers
    );
  }

  getAllCities(): Observable<CityMinimal[]> {
    return this.getEntities<CityMinimal>(
      'citiesLoaded',
      'cities',
      EndpointsConfig.cities(AppConfig.defaultLanguage)
    );
  }

  getYesNoOptions(): Observable<IdNameFilterOption[]> {
    return of(this.getYesNoOptionsPure());
  }

  getYesNoOptionsPure(): IdNameFilterOption[] {
    return [
      {
        id: true,
        name: this.translateService.instant('common.yes') as string,
      },
      {
        id: false,
        name: this.translateService.instant('common.no') as string,
      },
    ];
  }

  getSyncFeedbackMessage<T extends PartnerDetail | ProjectDetail | CandidateDetail['profile']>(
    detail: T
  ): string[] {
    const feedbackMessages: string[] = [];

    if (!detail) {
      return null;
    }

    feedbackMessages.push(
      detail?.synchronizedAt
        ? moment(detail?.synchronizedAt).format(AppConstants.dateFormat)
        : TranslateInstance.instant('common.sync_pending')
    );

    if (detail.syncErrorMessage) {
      feedbackMessages.push(detail.syncErrorMessage);
    }

    return this.processSyncHint(feedbackMessages);
  }

  private processSyncHint(messages: string[]): string[] {
    if (Array.isArray(messages)) {
      const syncDateStr = messages.shift();

      if (moment(syncDateStr).isValid()) {
        const prefix = TranslateInstance.instant('common.sync_start_date');
        const syncStartDate = `${prefix}: ${syncDateStr}`;

        if (messages.length) {
          messages = this.getErrorMessages(messages);
        }

        messages.unshift(syncStartDate);
      } else {
        messages.unshift(syncDateStr);
      }
    }

    return messages;
  }

  private getErrorMessages(syncErrorResponse: string[]): string[] {
    let errorObject: string[] = [];

    if (Array.isArray(syncErrorResponse)) {
      try {
        for (const item of syncErrorResponse) {
          errorObject = JSON.parse(item) as string[];
        }
      } catch (error) {
        console.error('JSON parse error:', error);
      }
    }

    const errorMessages: string[] = [];

    if (errorObject && typeof errorObject === 'object') {
      for (const key in errorObject) {
        if (Object.prototype.hasOwnProperty.call(errorObject, key)) {
          const value = errorObject[key];
          if (Array.isArray(value)) {
            for (const item of value as string[]) {
              let message = '';

              if (item && typeof item === 'object' && 'PropertyName' in item) {
                message += `${item['PropertyName'] as string}: `;
              }

              if (item && typeof item === 'object' && 'ErrorMessage' in item) {
                message += item['ErrorMessage'];
              }

              if (message !== '') {
                errorMessages.push(message);
              }
            }
          }
        }
      }
    }

    return errorMessages.length ? errorMessages : syncErrorResponse;
  }

  private getEntities<T>(
    listLoadedStateKey: keyof AppState,
    entitiesStoreKey: keyof AppState,
    endpointUrl: string,
    forceRefresh = false
  ): Observable<T[]> {
    const isLoaded = this.getStateSnapshot()[listLoadedStateKey];

    return !forceRefresh && isLoaded
      ? <Observable<T[]>>(<unknown>this.select(entitiesStoreKey))
      : this.http.get<T[]>(endpointUrl).pipe(
          tap((entities: T[]) =>
            this.setState({
              [listLoadedStateKey]: true,
              [entitiesStoreKey]: entities,
            })
          )
        );
  }
}
