export function sortByLocale<T extends object>(
  array: T[],
  sortByKey: string, // ex: user.name.firstName
  direction: 'asc' | 'desc' = 'asc'
): T[] {
  if (!Array.isArray(array)) {
    console.warn('Non array type provided!');
    return [];
  }

  const getNestedObjectValue = (currentItem: T, properties: string[]): string => {
    let nestedValue: object | string = currentItem;

    properties.forEach((prop) => {
      nestedValue = nestedValue[prop as keyof typeof nestedValue];
    });

    if (typeof nestedValue !== 'string') {
      nestedValue = nestedValue.toString();
    }

    return nestedValue;
  };

  const compareFn = (currentItem: T, nextItem: T): number => {
    const properties = sortByKey.split('.');

    const currentItemNestedValue = getNestedObjectValue(currentItem, properties);
    const nextItemNestedValue = getNestedObjectValue(nextItem, properties);

    if (currentItemNestedValue === undefined || nextItemNestedValue === undefined) {
      console.warn('Undefined value provided');
      return 0;
    }

    return direction === 'asc'
      ? currentItemNestedValue.localeCompare(nextItemNestedValue)
      : nextItemNestedValue.localeCompare(currentItemNestedValue);
  };

  return array.sort(compareFn);
}
