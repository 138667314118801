<div class="modal-frame"
     [ngClass]="{
        'full-height-modal-container': variant === modalTypes.FullHeight,
        'without-header': hideHeader
    }">

    <ng-container *ngIf="!loading">
        <ng-content select="[header]"></ng-content>
    </ng-container>

    <div class="modal-content mat-typography" mat-dialog-content>
        <ng-content select="[content]"></ng-content>
    </div>
    <div *ngIf="!loading" class="modal-actions justify-content-end" mat-dialog-actions>
        <ng-content select="[footer]"></ng-content>
    </div>

    <div *ngIf="loading" class="loader d-flex justify-content-center align-items-center h-100 w-100">
        <app-loading class="d-block"></app-loading>
    </div>
</div>
