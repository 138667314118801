import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderResolver } from '@shared/modules/header/resolvers/header.resolver';
import { InlineSVGModule } from 'ng-inline-svg';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { HintModule } from '@shared/modules/hint/hint.module';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../shared.module';
import { InjectHeaderDirective } from './directives/inject-header.directive';
import { MainPageHeaderComponent } from './components/main-page-header/main-page-header.component';
import { PartnerDetailHeaderComponent } from './components/partner-detail-header/partner-detail-header.component';
import { CandidateDetailHeaderComponent } from './components/candidate-detail-header/candidate-detail-header.component';
import { PositionDetailHeaderComponent } from './components/position-detail-header/position-detail-header.component';
import { UserProfileHeaderComponent } from './components/user-profile-header/user-profile-header.component';
import { NotificationHeaderComponent } from './components/notification-header/notification-header.component';
import { BaseDataHeaderComponent } from '@shared/modules/header/components/base-data-header/base-data-header.component';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';
import { OverflowFilterSelectMenuModule } from '@shared/modules/overflow-filter-menu/overflow-filter-select-menu.module';
import { LoaderModule } from '@shared/modules/loader/loader.module';

@NgModule({
  declarations: [
    HeaderComponent,
    InjectHeaderDirective,
    MainPageHeaderComponent,
    PartnerDetailHeaderComponent,
    CandidateDetailHeaderComponent,
    PositionDetailHeaderComponent,
    UserProfileHeaderComponent,
    NotificationHeaderComponent,
    BaseDataHeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InlineSVGModule,
    DirectivesModule,
    PipesModule,
    TippyModule,
    HintModule,
    FormComponentsModule,
    MatSelectModule,
    NgSelectModule,
    FilteringModule,
    OverflowFilterSelectMenuModule,
    LoaderModule,
  ],
  exports: [HeaderComponent, BaseDataHeaderComponent],
  providers: [HeaderResolver],
})
export class HeaderModule {}
