import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { HintComponent } from './hint.component';
import { HintErrorListComponent } from './components/hint-error-list/hint-error-list.component';
import { ImageHintComponent } from './components/image-hint/image-hint.component';
import { SvgHintComponent } from './components/svg-hint/svg-hint.component';
import { HintBaseComponent } from './components/hint-base/hint-base.component';

@NgModule({
  declarations: [
    HintComponent,
    HintErrorListComponent,
    ImageHintComponent,
    SvgHintComponent,
    HintBaseComponent,
  ],
  exports: [HintComponent, HintErrorListComponent],
  imports: [CommonModule, InlineSVGModule, TippyModule, TranslateModule, PipesModule],
})
export class HintModule {}
