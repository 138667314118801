import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { candidateFilterKeys, partnerFiltersKeys } from '@config/filter.config';
import { AppConstants } from '@config/app.constant';
import { CardFilteringBaseComponent } from '@shared/modules/filter-components/components/card-filtering-base/card-filtering-base.component';
import { TranslateService } from '@ngx-translate/core';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@UntilDestroy()
@Component({
  selector: 'app-text-number-filtering',
  templateUrl: './text-number-filtering.component.html',
  styleUrls: ['./text-number-filtering.component.scss'],
})
export class TextNumberFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() savedFilter$: Observable<Object>;

  hintTypes = HintType;
  search = new FormControl();
  hintText: string;

  constructor(
    readonly eRef: ElementRef<HTMLElement>,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService
  ) {
    super(eRef, filteringService, translate);
  }

  ngOnInit() {
    this.setValidatorsAndHintsForGivenFilterKeys();
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls(false);
      });
    return super.ngOnInit();
  }

  ngAfterViewInit() {
    this.listenSearchFieldChange();
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((savedFilterName: unknown) => {
        this.setValueForControls(savedFilterName as string);
      });
  }

  ngOnDestroy() {
    this.resetFormControls(true);
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;

    if (isChecked) {
      this.filterValueChange.emit({ [this.filterKey]: this.search.value as string });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  private setValidatorsAndHintsForGivenFilterKeys(): void {
    const keysToValidate = [
      partnerFiltersKeys.taxNumber,
      candidateFilterKeys.cvContent,
      candidateFilterKeys.messageContent,
    ];

    if (keysToValidate.includes(this.filterKey)) {
      this.search.setValidators(Validators.minLength(AppConstants.minInputLength));
      this.hintText = TranslateInstance.instant('hints.min_length', {
        char: AppConstants.minInputLength.toString(),
      });
    }
  }

  private listenSearchFieldChange(): void {
    this.search.valueChanges
      .pipe(
        filter(() => this.search.valid),
        debounceTime(600),
        tap((value: string) => {
          this.search.patchValue(value, { emitEvent: false });
          this.emitForFilterValueChange(value);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setValueForControls(valueOfControl: string): void {
    if (typeof valueOfControl !== 'undefined') {
      this.search.setValue(valueOfControl, { emitEvent: false });
      this.emitForFilterValueChange(valueOfControl);
    } else {
      this.resetFormControls(false);
    }
  }

  private emitForFilterValueChange(value: string) {
    if (this.search.valid && !(value === null || value === '')) {
      this.isDisabled = false;
      this.isChecked = true;
      this.filterValueChange.emit({ [this.filterKey]: value });
      this.isOpen = true;
    } else {
      this.isDisabled = true;
      this.isChecked = false;
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  private resetFormControls(emitEvent: boolean): void {
    this.search.patchValue('', { emitEvent });
    this.emitForFilterValueChange('');
  }
}
