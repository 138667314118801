import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidateAttachPositionModalConfig } from '@shared/modules/modals/candidate-modals/candidate-attach-position-modal/classes/candidate-attach-position-modal-config';
import { CandidateAttachPositionModalComponent } from '@shared/modules/modals/candidate-modals/candidate-attach-position-modal/candidate-attach-position-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { CandidatePosition } from '@pages/candidates/classes/CandidatePosition';
import { OutsourcedModalConfig } from '@shared/modules/modals/position-modals/outsourced-modal/classes/OutsourcedModalConfig';
import { OutsourcedModalComponent } from '@shared/modules/modals/position-modals/outsourced-modal/outsourced-modal.component';
import { RejectReasonModalConfig } from '@shared/modules/modals/position-modals/reject-reason-candidate-modal/classes/RejectReasonModalConfig';
import { RejectReasonCandidateModalComponent } from '@shared/modules/modals/position-modals/reject-reason-candidate-modal/reject-reason-candidate-modal.component';
import { ManageCandidateModalComponent } from '@shared/modules/modals/candidate-modals/manage-candidate-modal/manage-candidate-modal.component';
import { AppConstants } from '@config/app.constant';
import { BulkEditCandidateModalComponent } from '@shared/modules/modals/candidate-modals/bulk-edit-candidate-modal/bulk-edit-candidate-modal.component';
import { DocumentUploadModalConfig } from '@shared/modules/modals/candidate-modals/document-upload-modal/classes/DocumentUploadModalConfig';
import { DocumentUploadModalComponent } from '@shared/modules/modals/candidate-modals/document-upload-modal/document-upload-modal.component';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { ManageCandidateModalConfig } from '@shared/modules/modals/candidate-modals/manage-candidate-modal/config/ManageCandidateModalConfig';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { ModalType } from '@shared/classes/ModalType';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

@Injectable({
  providedIn: 'root',
})
export class CandidateModalService {
  constructor(
    private candidateState: CandidateStateService,
    private candidateService: CandidatesService,
    private modalService: MatModalService
  ) {}

  openBulkEditCandidateModal(): Observable<unknown> {
    return this.modalService.openDialog(BulkEditCandidateModalComponent, {
      width: AppConstants.fullHeightModalNarrowWidth,
    });
  }

  openManageCandidateModal(type?: ModalType): Observable<unknown> {
    this.candidateState.setState({ candidateModalOpenedFromPosition: false });

    return this.modalService.openDialog<ManageCandidateModalConfig>(ManageCandidateModalComponent, {
      width: AppConstants.threeColumnModalWidth,
      data: {
        type: type,
      },
    });
  }

  openConvertCandidateModal(candidateId: number): Observable<boolean> {
    return this.modalService.openConfirmModal({
      data: {
        modalText: 'candidates.convert_confirm_message',
        primaryButtonText: 'common.yes',
        primaryButtonColor: 'basic',
        secondaryButtonText: 'common.no',
        confirmObservable: this.candidateService.callConvertCandidate(candidateId),
      },
    }) as Observable<boolean>;
  }

  openDocumentUploadModal(candidateId: number): Observable<unknown> {
    return this.modalService.openDialog<DocumentUploadModalConfig>(DocumentUploadModalComponent, {
      width: '460px',
      data: {
        variant: ModalTypes.DynamicHeight,
        candidateId,
      },
    });
  }

  openAttachPositionModal(candidateId: number): Observable<unknown> {
    return this.modalService.openDialog<CandidateAttachPositionModalConfig>(
      CandidateAttachPositionModalComponent,
      {
        width: '460px',
        data: {
          variant: ModalTypes.DynamicHeight,
          candidateId,
        },
      }
    );
  }

  openDetachPositionModal(candidateId: number, payload: CandidatePosition): Observable<boolean> {
    return this.modalService.openConfirmModal({
      data: {
        primaryButtonText: 'candidates.position_detach_confirm_button',
        primaryButtonColor: 'warn',
        modalText: 'candidates.position_detach_confirm',
        confirmObservable: this.candidateService.callDetachPosition(candidateId, payload.id),
      },
    }) as Observable<boolean>;
  }

  openOutsourcedModal(
    positionId: number,
    positionType: PositionTypes,
    candidateId?: number,
    userRoles?: MultiUserRole[]
  ): Observable<BoardCandidate> {
    return this.modalService.openDialog<OutsourcedModalConfig>(OutsourcedModalComponent, {
      width: '460px',
      data: {
        positionId,
        variant: ModalTypes.DynamicHeight,
        confirmFunction: this.candidateService.callUpdateCandidatePosition.bind(
          this.candidateService
        ),
        candidateId,
        userRoles,
        positionType,
      },
    }) as Observable<BoardCandidate>;
  }

  openRejectReasonModal(
    positionId: number,
    candidateId?: number,
    userRoles?: MultiUserRole[]
  ): Observable<BoardCandidate> {
    return this.modalService.openDialog<RejectReasonModalConfig>(
      RejectReasonCandidateModalComponent,
      {
        width: '460px',
        data: {
          positionId,
          variant: ModalTypes.DynamicHeight,
          confirmFunction: this.candidateService.callUpdateCandidatePosition.bind(
            this.candidateService
          ),
          candidateId,
          userRoles,
        },
      }
    ) as Observable<BoardCandidate>;
  }
}
