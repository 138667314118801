import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CountrySettlementSelectionComponent } from './components/country-settlement-selection/country-settlement-selection.component';

@NgModule({
  declarations: [CountrySettlementSelectionComponent],
  imports: [CommonModule, FormComponentsModule, ReactiveFormsModule],
  exports: [CountrySettlementSelectionComponent],
})
export class CountrySettlementSelectionModule {}
