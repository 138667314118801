import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@shared/utils/format-date.util';

@Pipe({
  name: 'appFormatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string): string {
    return formatDate(value);
  }
}
