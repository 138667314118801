import { Observable } from 'rxjs';
import { omit, values } from 'lodash-es';
import { AbstractControl } from '@angular/forms';

export function valueChangesExcept<T>(
  controls: { [key: string]: AbstractControl },
  controlNamesToOmit: (keyof T)[]
): Observable<unknown>[] {
  return values<AbstractControl>(omit(controls, controlNamesToOmit)).map(
    (control) => control.valueChanges
  );
}
