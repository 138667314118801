import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-result-modal',
  templateUrl: './bulk-edit-result-modal.component.html',
  styleUrls: ['./bulk-edit-result-modal.component.scss'],
})
export class BulkEditResultModalComponent
  extends MatModalBaseComponent
  implements OnInit, OnDestroy {
  content: { type: string; successful: number; unsuccessful: number } = {
    type: '',
    successful: 0,
    unsuccessful: 0,
  };
  private bulkEditService: BulkEditService;

  constructor(protected injector: Injector) {
    super(injector);

    this.bulkEditService = this.injector.get<BulkEditService>(BulkEditService);
  }

  ngOnInit(): void {
    this.loading = false;
    this.initContent();
  }

  private initContent(): void {
    const { listType, error, selectedItems } = this.bulkEditService.getStateSnapshot();
    const bulkEditStateKey = this.bulkEditService.getBulkEditStateKey(listType);

    this.bulkEditService
      .select(bulkEditStateKey)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        let successful: number;
        let unsuccessful: number;

        if (typeof error === 'undefined' || !Array.isArray(error)) {
          successful = 0;
          unsuccessful = selectedItems;
        } else {
          successful = selectedItems - error.length;
          unsuccessful = error.length;
        }

        this.content = {
          type: `common.${listType.toLocaleLowerCase()}`,
          successful,
          unsuccessful,
        };
      });
  }

  refreshDataAndClose(): void {
    this.bulkEditService.triggerRefreshList();
    this.modalService.closeAllDialog();
  }

  ngOnDestroy() {
    this.bulkEditService.resetBulkEditState();

    return super.ngOnDestroy();
  }
}
