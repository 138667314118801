import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { getStringId } from '@shared/utils/get-string-id.util';
import { SelectComponent } from '@shared/modules/form-components/select/select.component';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormInputComponent } from '@shared/modules/form-components/base/form-input.component';

@UntilDestroy()
@Component({
  selector: 'app-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss'],
})
export class TagsAutocompleteComponent extends FormInputComponent implements OnInit {
  @ViewChild(SelectComponent) select: SelectComponent;

  @Input() mode: 'formArray' | 'event' = 'formArray';
  @Input() label = 'positions.advertisements.tags';
  @Input() isFieldRequired: boolean = false;
  @Input() immediateValidation: boolean = false;
  @Input() searchAble: boolean;

  @Input() selectedTagsFormArray: FormArray;

  @Input() isHintShown = true;
  @Input() hintText: string;

  @Input() allTags: TagDto[];
  @Input() selectedTags: TagDto[];
  @Output() selectedTagsChange = new EventEmitter<TagDto[]>();

  control = new FormControl();

  selectedIndicatorOption: TagDto[] = [{ id: 0, name: '' }];
  dropdownPanelClass: string;

  ngOnInit() {
    if (this.mode === 'event') {
      this.initFormTagsArrayFromDto();
      this.setIndicatorOption();
    }

    this.disableExistingTags();

    const validator = this.selectedTagsFormArray.validator;
    if (validator) {
      if (validator({} as AbstractControl)?.required) {
        this.listenSelectedTagChange();
        setTimeout(() => {
          this.selectedTagsFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }, 100);
      }
    }

    if (this.isFieldRequired) {
      if (this.immediateValidation) {
        this.control.markAllAsTouched();
      }

      this.control.setValidators(Validators.required);
      this.listenSelectedTagChange();
    }

    this.subscribeToTagArrayChange();

    return super.ngOnInit();
  }

  private initFormTagsArrayFromDto() {
    this.selectedTagsFormArray = new FormArray([]);

    if (this.selectedTags) {
      this.selectedTags.forEach((tag) => {
        this.selectedTagsFormArray.push(
          new FormGroup({
            id: new FormControl(tag.id || getStringId()),
            name: new FormControl(tag.name),
          })
        );
      });
    }
  }

  private setIndicatorOption() {
    this.control.patchValue(
      this.selectedTagsFormArray.length ? this.selectedIndicatorOption : null
    );
  }

  onRemoveTag(index: number): void {
    this.selectedTagsFormArray.removeAt(index);
    this.selectedTagsChange.emit(this.selectedTagsFormArray.getRawValue() as TagDto[]);

    this.updateSelectionWithIndicator();
  }

  onTagSelected(tag: TagDto) {
    this.onAddTag(tag.name, tag.id as number);
    this.updateSelectionWithIndicator();
  }

  onAddTag(tagName: string, id?: number): void {
    this.selectedTagsFormArray.push(
      new FormGroup({
        id: new FormControl(id || getStringId()),
        name: new FormControl(tagName),
      })
    );

    this.selectedTagsChange.emit(this.selectedTagsFormArray.getRawValue() as TagDto[]);

    this.updateSelectionWithIndicator();
  }

  subscribeToTagArrayChange() {
    this.selectedTagsFormArray.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.disableExistingTags();
    });
  }

  updateDropdownPanelClass() {
    this.dropdownPanelClass =
      this.selectedTagsFormArray.length === this.allTags.length ? 'no-display-element' : '';
  }

  compareSelectedOptions(first: TagDto, second: TagDto): boolean {
    return first && second ? first.id === second.id : first === second;
  }

  private disableExistingTags() {
    const selectedTags: { [key: string]: boolean } = {};
    for (const tag of this.selectedTagsFormArray.value as TagDto[]) {
      selectedTags[tag.name] = true;
    }

    this.allTags = this.allTags?.map((tag) => ({
      ...tag,
      disabled: selectedTags[tag.name],
    }));

    this.updateDropdownPanelClass();
  }

  private updateSelectionWithIndicator(): void {
    setTimeout(() => {
      this.setIndicatorOption();

      this.control.updateValueAndValidity();
      this.select.selectComponent.close();
      this.select.selectComponent.detectChanges();
    });
  }

  private listenSelectedTagChange(): void {
    this.selectedTagsFormArray.valueChanges
      .pipe(
        tap((tag: FormArray) => {
          this.control.setErrors(tag.length <= 0 ? { required: true } : null);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
