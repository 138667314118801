import { Contact } from '@pages/partners/classes/Contact';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants, PartnerOrProject } from '@config/app.constant';
import { environment } from '@environments/environment';

export function getContactKeyValueData(
  contacts: Contact[],
  type?: PartnerOrProject
): KeyValue<string>[] {
  const data: { key: string; value: string }[] = [];

  (contacts || []).forEach((contact, index: number) => {
    data.push(
      {
        key: 'common.name',
        value: contact?.name ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'common.email',
        value: contact?.email ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'common.phone_number',
        value: contact?.phone ?? AppConstants.noDataTranslationKey,
      }
    );

    if (type) {
      if (environment.atsType !== 'labor-hire') {
        if (type === 'partner') {
          data.push(
            {
              key: 'common.is_contractual_contact',
              value: contact?.isContractualContact
                ? 'common.yes'
                : 'common.no' ?? AppConstants.noDataTranslationKey,
            },
            {
              key: 'common.has_authorized_signatory',
              value: contact?.hasAuthorizedSignatory
                ? 'common.yes'
                : 'common.no' ?? AppConstants.noDataTranslationKey,
            }
          );
        } else {
          data.push(
            {
              key: 'common.position',
              value: contact?.position ?? AppConstants.noDataTranslationKey,
            },
            {
              key: 'common.project_specialization',
              value: contact?.projectSpecialization?.name ?? AppConstants.noDataTranslationKey,
            }
          );
        }
      }
    }

    if (contacts?.length - 1 !== index) {
      data.push({
        key: '',
        value: 'line-break',
      });
    }
  });

  return data;
}
