import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { getErrorTranslationKey, hasCommonError } from '@shared/utils/error-keys.util';
import { ToastService } from '@shared/modules/toast/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class BaseDataBaseService {
  refreshList$: Subject<void> = new Subject<void>();

  constructor(private toast: ToastService) {}

  onRefreshList(): Observable<void> {
    return this.refreshList$.asObservable();
  }

  handleSaveSuccess<T>(toastText: string) {
    return (listItem: Observable<T>): Observable<T> =>
      listItem.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
        })
      );
  }

  handleSaveError<T>(toastText?: string, triggerRefresh = true) {
    return (listItem: Observable<T>): Observable<T | HttpErrorResponse> =>
      listItem.pipe(
        catchError((err: MessageErrorResponse) => {
          if (!hasCommonError(err?.error?.errors)) {
            this.toast.showError(
              getErrorTranslationKey(err?.error?.errors, getGeneralMessage(toastText, false))
            );
          }
          return of(err);
        }),
        finalize(() => {
          this.triggerRefreshList(triggerRefresh);
        })
      );
  }

  handleDeleteItemResponse<T>(triggerRefresh = true) {
    return (listItem: Observable<T>): Observable<T | HttpErrorResponse> =>
      listItem.pipe(
        catchError((err: MessageErrorResponse) => {
          if (!hasCommonError(err?.error?.errors)) {
            this.toast.showError(getErrorTranslationKey(err?.error?.errors));
          }
          return of(err);
        }),
        finalize(() => {
          this.triggerRefreshList(triggerRefresh);
        })
      );
  }

  private triggerRefreshList(triggerRefresh: boolean) {
    return triggerRefresh ? this.refreshList$.next() : null;
  }
}
