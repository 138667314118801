import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HistoryMessageType } from '@shared/modules/history-message/classes/HistoryMessageType';

export interface HistoryMessageState {
  saveButtonDisabled: boolean;

  selectedHistoryMessage: HistoryMessage;

  messages: HistoryMessage[];
  messagesTotalCount: number;
  currentPage: number;

  messageType: HistoryMessageType;
  historyLoaded: boolean;
}

export const historyMessageInitialState: HistoryMessageState = {
  saveButtonDisabled: false,

  selectedHistoryMessage: null,

  messages: [],
  messagesTotalCount: 0,
  currentPage: 1,

  messageType: HistoryMessageType.Customer,
  historyLoaded: false,
};
