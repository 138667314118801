<label>{{label}}</label>
<mat-radio-group
    class="radio-group"
    [ngClass]="isVerticalLayout ? 'vertical' : 'horizontal'"
    [disabled]="disabled"
    [(ngModel)]="value"
    [color]="'primary'"
    (change)="onChange($event.value)"
    (focus)="onTouched()">
    <mat-radio-button *ngFor="let option of options" [value]="option.value">
        {{option.label}}
    </mat-radio-button>
</mat-radio-group>

<div class="error-message">
    {{getErrorMessage() | translate}}
</div>
