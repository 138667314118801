<label [ngClass]="['checkbox-label', checkboxColor || '', label ? 'm-0' : '']">
    <input type="checkbox"
           #input
           [disabled]="disabled"
           [checked]="checked"
           (change)="onCheckboxChange($event)">
    <span class="checkbox-custom"></span>
</label>

<div *ngIf="label"
     [ngClass]="{'hide-label': hideLabel}"
     class="cursor-pointer ml-2"
     [tippy]="hideLabel ? (label | translate) : null"
     variation="overflowMenuItemTooltip"
     (click)="input.click()">
    {{label | translate}}
</div>
