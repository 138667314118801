import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';

@Component({
  selector: 'app-small-image-table-cell',
  templateUrl: './small-image-table-cell.component.html',
  styleUrls: ['./small-image-table-cell.component.scss'],
})
export class SmallImageTableCellComponent extends CardTableCustomCellBaseComponent {
  @ViewChild('customCell', { static: true, read: TemplateRef }) templateRef: TemplateRef<unknown>;

  @Input() imageUrl: string;
  @Input() value: string;
  @Input() isImageShown = true;
  @Input() imageKey: string;
  @Input() valueKey: string;
  @Input() placeholderPath: string;
  @Input() imageBorderRadius: '50%' | '2px' = '50%';
}
