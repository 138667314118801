<span [tippy]="hintText | translate"
      [placement]="hintPosition"
      [isEnabled]="!!hintText"
      variation="hint">
    <button
        [ngClass]="{
            'text-center': textAlign === 'center',
            'text-left': textAlign === 'left',
            'text-right': textAlign === 'right',
            'white': isWhite,
            'error-like': isErrorLike,
            'blue': isBlue,
            'grey': isGrey,
            'pulse': isPulseEffect
        }"
        type="button"
        class="close-button"
        (click)="buttonClick.emit()"
        [disabled]="disabled"
        [inlineSVG]="iconUrl">
    </button>
</span>
