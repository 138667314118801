import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
} from '@angular/core';
import { CardTableColumn } from '@pages/partners/classes/CardTableColumn';
import { AppConstants } from '@config/app.constant';
import { CardTableService } from '@shared/modules/card-table/services/card-table.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { CardTableItem } from '@shared/modules/card-table/classes/CardTableItem';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';

@UntilDestroy()
@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
})
export class CardTableComponent implements AfterViewInit, OnChanges {
  @ContentChildren('tableCell') cells: QueryList<CardTableCustomCellBaseComponent>;

  @Input() columns: CardTableColumn[] = [];
  @Input() data: CardTableItem[] = [];
  @Input() total: number;
  @Input() customTableClass = '';
  @Input() currentPage = 1;
  @Input() perPage = AppConstants.cardTableRowsPerPage;
  @Input() navigateToPrefix: string;
  @Input() showPointerCursor = true;
  @Input() highlightPredicate: (item: CardTableItem) => boolean;

  @Output() loadMore = new EventEmitter<void>();
  @Output() navigate = new EventEmitter<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private cardTableService: CardTableService,
    private router: Router
  ) {}

  ngOnChanges() {
    if (Array.isArray(this.columns) && Array.isArray(this.cells?.toArray())) {
      this.updateTable();
    }
  }

  ngAfterViewInit() {
    this.updateTable();
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  onNavigate(id: number): void {
    if (this.navigateToPrefix && id > 0) {
      this.router.navigateByUrl(`${this.navigateToPrefix}/${id}`);
      this.navigate.emit();
    }
  }

  private updateTable() {
    this.columns = this.columns.map((column) => {
      const foundColumn = this.cells.find((item) => item.columnKey === column.columnKey);
      if (foundColumn) {
        column.templateRef = foundColumn.templateRef;
      }
      return column;
    });

    this.cdr.detectChanges();
  }
}
