import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { DeleteSourceModalData } from '@shared/modules/modals/base-data-modals/sources/manage-source-modal/classes/DeleteSourceModalConfig';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SourceService } from '@pages/base-data/services/source/source.service';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SourcesStateService } from '@pages/base-data/services/source/sources-state.service';
import { merge } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { SourceType } from '@pages/base-data/classes/sources/SourceType';
import { KeyValue } from '@shared/classes/KeyValue';

@UntilDestroy()
@Component({
  selector: 'app-manage-source-modal',
  templateUrl: './manage-source-modal.component.html',
  styleUrls: ['./manage-source-modal.component.scss'],
})
export class ManageSourceModalComponent
  extends MatModalBaseComponent<DeleteSourceModalData>
  implements OnInit, OnDestroy {
  form: FormGroup;
  selectedSource: SourceListItem;
  options: KeyValue<SourceType>[] = [];
  isEditMode: boolean = false;
  modalTitle: string;

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private sourceService: SourceService,
    private baseDataSourcesState: SourcesStateService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = false;

    this.initSourceTypeOptions();
    this.initModalBaseData();
    this.initSourceForm();
    this.listenFormChanges();
  }

  private initSourceTypeOptions(): void {
    this.options = [
      {
        key: TranslateInstance.instant('base_data.sources.types.source'),
        value: SourceType.Source,
      },
      {
        key: TranslateInstance.instant('base_data.sources.types.manual'),
        value: SourceType.Manual,
      },
    ];
  }

  private initModalBaseData(): void {
    const { selectedSource } = this.baseDataSourcesState.getStateSnapshot();
    this.isEditMode = !!selectedSource;

    this.selectedSource = selectedSource;

    this.modalTitle = this.isEditMode
      ? 'base_data.sources.edit_source_title'
      : 'base_data.sources.new_source_title';
  }

  private initSourceForm(): void {
    const detail = this.selectedSource;

    this.form = this.formBuilder.group({
      id: [detail?.id || null],
      name: [detail?.name || null, [Validators.required]],
      sourceType: [
        {
          value: detail?.type
            ? TranslateInstance.instant(`base_data.sources.types.${detail?.type.toLowerCase()}`)
            : '',
          disabled: true,
        },
      ],
      type: [detail?.type || null, [Validators.required]],
      createdAt: [{ value: detail?.createdAt || null, disabled: true }],
      archive: [
        {
          value: detail?.archive
            ? TranslateInstance.instant('common.yes')
            : TranslateInstance.instant('common.no'),
          disabled: true,
        },
      ],
    });

    this.primaryButtonDisabled = this.form.invalid;
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), debounceTime(300), untilDestroyed(this))
      .subscribe(() => {
        this.primaryButtonDisabled = this.form.invalid;
      });
  }

  saveSource() {
    this.primaryButtonDisabled = true;

    if (this.isEditMode) {
      this.sourceService
        .callUpdateSource(this.form.getRawValue() as SourceListItem)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    } else {
      this.sourceService
        .callCreateSource(this.form.getRawValue() as SourceListItem)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    }
  }

  ngOnDestroy() {
    this.baseDataSourcesState.setState({ selectedSource: null });
    return super.ngOnDestroy();
  }
}
