import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attachNewFormFieldsButton',
})
export class AttachNewFormFieldsButtonPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(title: string): string {
    const translatedTitle = this.translate.instant(title) as string;

    return `+ ${translatedTitle} `;
  }
}
