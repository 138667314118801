import { BoardSortMode } from '@shared/modules/header/classes/BoardSortMode';

export enum BoardSortModeKey {
  Name = 'user.name',
  ValidFrom = 'validFrom',
}

export const kanbanBoardSortListConfig: BoardSortMode[] = [
  {
    key: BoardSortModeKey.Name,
    label: 'positions.sort_modes.asc_by_name',
    sortBy: 'asc',
  },
  {
    key: BoardSortModeKey.Name,
    label: 'positions.sort_modes.desc_by_name',
    sortBy: 'desc',
  },
  {
    key: BoardSortModeKey.ValidFrom,
    label: 'positions.sort_modes.desc_by_from_valid',
    sortBy: 'desc',
  },
  {
    key: BoardSortModeKey.ValidFrom,
    label: 'positions.sort_modes.asc_by_from_valid',
    sortBy: 'asc',
  },
];
