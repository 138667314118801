import { Component, Input } from '@angular/core';

import { TippyPlacement } from '@shared/classes/TippyPlacement';
import { ButtonBaseComponent } from '@shared/modules/form-components/button/button-base.components';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends ButtonBaseComponent {
  @Input() isWhite = false;
  @Input() isErrorLike = false;
  @Input() isBlue = false;
  @Input() isGrey = false;
  @Input() isPulseEffect = false;
  @Input() textAlign: 'center' | 'right' | 'left' = 'center';
  @Input() hintPosition: TippyPlacement = 'bottom';
  @Input() hintText: string;
  @Input() iconUrl: string;
  @Input() disabled: boolean;
}
