import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { FilterContainerBaseComponent } from '@shared/modules/filter-components/components/base/filter-container-base.component';

@Directive({
  selector: '[appInjectFilter]',
})
export class InjectFilterDirective implements OnDestroy {
  @Input()
  set appInjectFilter(filterComponent: ComponentType<FilterContainerBaseComponent>) {
    this.injectFilterComponent(filterComponent);
  }

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.clearView();
  }

  private injectFilterComponent(
    filterComponent: ComponentType<FilterContainerBaseComponent>
  ): void {
    this.clearView();
    if (filterComponent) {
      this.viewContainer.createComponent(filterComponent);
    }
  }

  private clearView(): void {
    this.viewContainer.clear();
  }
}
