import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { AppHeaderState } from '@shared/modules/state-manager/state/app-header/app-header.state';
import { AppStateService } from '@shared/services/app-state.service';
import { AppState } from '@shared/modules/state-manager/state/app/app.state';
import { AppRouteData } from '@shared/classes/common/AppRouteData';

@Injectable()
export class HeaderResolver implements Resolve<void> {
  constructor(private headerService: HeaderService, private appService: AppStateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let newHeaderState: Partial<AppHeaderState> = { filterTotalCount: 0 };
    let newAppState: Partial<AppState> = { currentUrl: state.url };

    const routeData = route.data as AppRouteData;

    const {
      headerComponent: currentHeader,
      pageTitle: title,
      headerCreateButtonActionPermissions: permissions,
    } = routeData;

    if (currentHeader) {
      newAppState = { ...newAppState, currentHeader };
    }

    if (title) {
      newHeaderState = { ...newHeaderState, title };
    }

    if (permissions) {
      newHeaderState = {
        ...newHeaderState,
        createEntityButtonPermissions: Array.isArray(permissions) ? permissions : [],
      };
    }

    this.headerService.setState(newHeaderState);
    this.appService.setState(newAppState);
  }
}
