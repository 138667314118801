import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { FormControl, NgControl } from '@angular/forms';
import { AppConstants } from '@config/app.constant';
import { ToastService } from '@shared/modules/toast/services/toast.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends FormInputBaseComponent<File> implements OnInit {
  @Input() placeholder = 'common.select_file';
  fileNameControl = new FormControl();
  supportedFiles: string;

  constructor(@Self() @Optional() private ngControl: NgControl, private toast: ToastService) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    this.supportedFiles = this.getSupportedFileExtensions();
    return superCalled;
  }

  onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (target?.files?.length > 0) {
      const file: File = target.files[0];

      if (!this.isExtensionValid(file)) {
        this.onClearFile();
        this.toast.showError('validation_errors.invalid_file_extension');
        return;
      }

      this.control.setValue(file);
      this.fileNameControl.setValue(file.name);
    }
  }

  onClearFile() {
    this.control.setValue(null);
    this.fileNameControl.setValue(null);
  }

  private getSupportedFileExtensions(): string {
    return AppConstants.supportedDocumentExtensions.map((extension) => `.${extension}`).join(',');
  }

  private isExtensionValid(file: File): boolean {
    const explodedName = (file?.name || '').split('.');

    return (
      explodedName.length >= 1 &&
      AppConstants.supportedDocumentExtensions.indexOf(
        explodedName[explodedName.length - 1].toLowerCase()
      ) > -1
    );
  }
}
