<app-modal-frame [data]="data" [dialogRef]="dialogRef" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'candidates.attach_title'"
        [iconUrl]="'assets/image/helmet.svg'">
    </app-modal-header>

    <div content class="attach-position-modal">
        <form *ngIf="!loading" [formGroup]="form" class="modal-base h-100">
            <app-partner-project-position-selection
                [partners]="partners"
                [partnerIdControl]="getFormControl(form,'partnerId')"
                [projectIdControl]="getFormControl(form,'projectId')"
                [positionIdControl]="getFormControl(form,'positionId')">
            </app-partner-project-position-selection>
        </form>
    </div>

    <app-modal-footer
        footer
        (primaryButtonClicked)="attachToPosition()"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [dialogRef]="dialogRef">
    </app-modal-footer>
</app-modal-frame>
