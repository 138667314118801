<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="modalTitle"
        [iconUrl]="'assets/image/common/base-data.svg'">
    </app-modal-header>

    <div content class="manage-modal-content">
        <form *ngIf="!loading" [formGroup]="form" class="modal-base h-auto">
            <app-form-field>
                <app-text-field
                    label="base_data.sources.source_name"
                    placeholder="base_data.sources.source_name"
                    formControlName="name">
                </app-text-field>
            </app-form-field>

            <div *ngIf="!isEditMode">
                <app-form-field>
                    <app-select
                        label="base_data.sources.type"
                        [options]="options"
                        bindValue="value"
                        bindLabel="key"
                        bindValueByPropertyNameToControl="value"
                        formControlName="type">
                    </app-select>
                </app-form-field>
            </div>

            <div *ngIf="isEditMode">
                <app-form-field>
                    <app-text-field
                        label="base_data.sources.source_type"
                        placeholder="base_data.sources.source_type"
                        formControlName="sourceType">
                    </app-text-field>
                </app-form-field>
                <app-form-field>
                    <app-text-field
                        label="base_data.sources.source_creationDate"
                        placeholder="base_data.sources.source_creationDate"
                        formControlName="createdAt">
                    </app-text-field>
                </app-form-field>
                <app-form-field>
                    <app-text-field
                        label="base_data.sources.source_archive"
                        placeholder="base_data.sources.source_archive"
                        formControlName="archive">
                    </app-text-field>
                </app-form-field>
            </div>
        </form>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="saveSource()">
    </app-modal-footer>
</app-modal-frame>
