import { TableFilter } from '@shared/classes/table-filter/TableFilter';
import { TableDynamicFilterState } from '@shared/classes/table-filter/TableDynamicFilterState';

export interface PhoneContactState extends TableDynamicFilterState {
  providerTypeId: number;
}

export const phoneContactInitialState: PhoneContactState = {
  filtersLoaded: false,
  filters: new TableFilter(),
  selectedFilters: undefined,

  providerTypeId: null,
};
