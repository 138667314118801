import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdvertisementService } from '@pages/positions/services/advertisement/advertisement.service';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { UploadAdvertisementImageModalData } from '@shared/modules/modals/position-modals/upload-advertisement-image-modal/classes/uploadAdvertisementImageModalConfig';

@UntilDestroy()
@Component({
  selector: 'app-upload-advertisement-image-modal',
  templateUrl: './upload-advertisement-image-modal.component.html',
  styleUrls: ['./upload-advertisement-image-modal.component.scss'],
})
export class UploadAdvertisementImageModalComponent
  extends MatModalBaseComponent<UploadAdvertisementImageModalData>
  implements OnInit {
  form: FormGroup;

  constructor(private advertisementService: AdvertisementService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      image: new FormControl(null, [Validators.required]),
    });

    this.listenFormValueChanges();
  }

  private listenFormValueChanges(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.form.invalid;
    });
  }

  uploadImage(): void {
    this.primaryButtonDisabled = true;

    const { image } = this.form.getRawValue() as { image: File };
    this.advertisementService
      .callUploadAdvertisementImage(this.data.positionId, this.data.advertisementId, image)
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
