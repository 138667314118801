import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagePositionModalComponent } from '@shared/modules/modals/position-modals/manage-position-modal/manage-position-modal.component';
import { AppConstants } from '@config/app.constant';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { KanbanBoardAddCandidateModalConfig } from '@shared/modules/modals/position-modals/kanban-board-add-candidate-modal/classes/KanbanBoardAddCandidateModalConfig';
import { KanbanBoardAddCandidateModalComponent } from '@shared/modules/modals/position-modals/kanban-board-add-candidate-modal/kanban-board-add-candidate-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';
import { PositionsService } from '@pages/positions/services/details/positions.service';
import { BulkEditPositionModalComponent } from '@shared/modules/modals/position-modals/bulk-edit-position-modal/bulk-edit-position-modal.component';
import { OutsourcedModalConfig } from '@shared/modules/modals/position-modals/outsourced-modal/classes/OutsourcedModalConfig';
import { OutsourcedModalComponent } from '@shared/modules/modals/position-modals/outsourced-modal/outsourced-modal.component';
import { RejectReasonModalConfig } from '@shared/modules/modals/position-modals/reject-reason-candidate-modal/classes/RejectReasonModalConfig';
import { RejectReasonCandidateModalComponent } from '@shared/modules/modals/position-modals/reject-reason-candidate-modal/reject-reason-candidate-modal.component';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { PositionKanbanBoardService } from '@pages/positions/services/candidates/position-kanban-board.service';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

@Injectable({
  providedIn: 'root',
})
export class PositionModalService {
  constructor(
    private modalService: MatModalService,
    private positionService: PositionsService,
    private candidatesKanbanBoardService: PositionKanbanBoardService
  ) {}

  openBulkEditPositionsModal(): Observable<unknown> {
    return this.modalService.openDialog(BulkEditPositionModalComponent, {
      width: AppConstants.fullHeightModalNarrowWidth,
    });
  }

  openManagePositionModal(): Observable<PositionDetail | HttpErrorResponse> {
    return this.modalService.openDialog(ManagePositionModalComponent, {
      width: AppConstants.fullHeightModalWidth,
    }) as Observable<PositionDetail | HttpErrorResponse>;
  }

  openAddCandidateToPositionModal(
    positionId: number,
    positionType: PositionTypes
  ): Observable<unknown> {
    return this.modalService.openDialog<KanbanBoardAddCandidateModalConfig>(
      KanbanBoardAddCandidateModalComponent,
      {
        width: '560px',
        data: {
          variant: ModalTypes.DynamicHeight,
          positionId,
          positionType,
        },
      }
    );
  }

  openTogglePositionStatusConfirmModal(value: TogglePositionStatus): void {
    const translateKey = value.isActive
      ? 'positions.position_status_to_inactive_confirm'
      : 'positions.position_status_to_active_confirm';
    const modalText = TranslateInstance.instant(translateKey, {
      positionName: value.positionName,
    });

    this.modalService.openConfirmModal({
      data: {
        modalText,
        primaryButtonText: 'common.yes',
        confirmObservable: this.positionService.callTogglePositionStatus(value),
      },
    });
  }

  openOutsourcedModal(
    positionId: number,
    positionType: PositionTypes,
    candidateId?: number
  ): Observable<unknown> {
    return this.modalService.openDialog<OutsourcedModalConfig>(OutsourcedModalComponent, {
      width: '460px',
      data: {
        positionId,
        variant: ModalTypes.DynamicHeight,
        confirmFunction: this.candidatesKanbanBoardService.callUpdateCandidatePosition.bind(
          this.candidatesKanbanBoardService
        ),
        candidateId,
        positionType,
      },
    });
  }

  openRejectReasonModal(positionId: number, candidateId?: number): Observable<unknown> {
    return this.modalService.openDialog<RejectReasonModalConfig>(
      RejectReasonCandidateModalComponent,
      {
        width: '460px',
        data: {
          positionId,
          variant: ModalTypes.DynamicHeight,
          confirmFunction: this.candidatesKanbanBoardService.callUpdateCandidatePosition.bind(
            this.candidatesKanbanBoardService
          ),
          candidateId,
        },
      }
    );
  }
}
