<app-form-field>
    <app-select
        customClass="candidate-custom-field-selection"
        label="candidates.custom_fields_type"
        bindLabel="name"
        bindValue="id"
        [bindValueByPropertyNameToControl]="'id'"
        [options]="customFieldTypes"
        [hideSelected]="true"
        [searchable]="true"
        (selectionChange)="onCustomFieldTypeChange()"
        [formControl]="typeControl">
    </app-select>
</app-form-field>

<div [ngSwitch]="selectedFieldType?.type">
    <app-form-field *ngSwitchCase="DROPDOWN_TYPE.Multiselect">
        <app-tags-autocomplete
            [label]="'candidates.custom_fields_value'"
            [mode]="'event'"
            [isHintShown]="true"
            [hintText]="selectedFieldType.hint"
            [isFieldRequired]="true"
            [immediateValidation]="true"
            [searchAble]="false"
            [selectedTags]="selectedTags"
            (selectedTagsChange)="setValueFromTagSelect($event)"
            [allTags]="customFieldTypeOptions">
        </app-tags-autocomplete>
    </app-form-field>

    <app-form-field *ngSwitchCase="DROPDOWN_TYPE.Number">
        <app-text-field
            type="number"
            [isHintShown]="true"
            [hintText]="selectedFieldType.hint"
            [placeholder]="'candidates.custom_fields_value'"
            [label]="'candidates.custom_fields_value'"
            [formControl]="valueControl">
        </app-text-field>
    </app-form-field>

    <app-form-field *ngSwitchCase="DROPDOWN_TYPE.String">
        <app-text-field
            type="text"
            [isHintShown]="true"
            [hintText]="selectedFieldType.hint"
            [label]="'candidates.custom_fields_value'"
            [placeholder]="'candidates.custom_fields_value'"
            [formControl]="valueControl">
        </app-text-field>
    </app-form-field>

    <app-form-field *ngSwitchCase="DROPDOWN_TYPE.Date">
        <app-datepicker
            [isHintShown]="true"
            [hintText]="selectedFieldType.hint"
            [clearable]="true"
            [label]="'candidates.custom_fields_value'"
            [placeholder]="'candidates.custom_fields_value'"
            [formControl]="valueControl">
        </app-datepicker>
    </app-form-field>

    <app-form-field *ngSwitchCase="DROPDOWN_TYPE.Select">
        <app-select
            bindLabel="name"
            bindValue="id"
            customClass="custom-ng-select"
            label="candidates.custom_fields_value"
            [multiple]="false"
            [required]="true"
            [isHintShown]="true"
            [hintText]="selectedFieldType.hint"
            [ngModel]="valueControl.value"
            (ngModelChange)="setValueFromSelect($event)"
            [options]="customFieldTypeOptions">
        </app-select>
    </app-form-field>
</div>
