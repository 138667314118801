<app-modal-frame [data]="data" [dialogRef]="dialogRef" [hideHeader]="true">

    <div content class="finalized-bulk-edit-content">
        <div class="mb-30px">
            <p class="basic-black-normal-text mb-0">
                {{'common.successfully_modified' | translate}}
            </p>
            <p class="basic-green-normal-text">
                {{content.successful}} {{content.type | translate}}
            </p>
            <p class="basic-black-normal-text mb-0">{{'common.unsuccessfully_modified' | translate}}</p>
            <p class="basic-red-normal-text">
                {{content.unsuccessful}} {{content.type | translate}}
            </p>
        </div>
        <hr>
    </div>

    <app-modal-footer
        footer
        [hideSecondaryButton]="true"
        [primaryButtonText]="'common.finish'"
        (primaryButtonClicked)="refreshDataAndClose()"
        [dialogRef]="dialogRef">
    </app-modal-footer>

</app-modal-frame>
