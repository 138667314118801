import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { forkJoin, merge, Observable } from 'rxjs';
import { ListTypeName } from '@config/app.constant';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { PartnerDropdownProject } from '@pages/partners/classes/PartnerDropdownProject';
import { BoardColumnOption } from '@pages/positions/classes/board/BoardColumnOption';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { debounceTime, map, startWith, take } from 'rxjs/operators';
import { PositionKanbanBoardApiService } from '@pages/positions/services/candidates/base/position-kanban-board-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CandidateFormService } from '@pages/candidates/services/base/candidate-form.service';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { PositionDropdownItem } from '@pages/positions/classes/PositionDropdownItem';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { keys } from 'lodash-es';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ProjectMinimal } from '@pages/positions/classes/ProjectMinimal';
import { BoardColumnSlugs } from '@pages/positions/classes/BoardColumnSlugs';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-candidate-modal',
  templateUrl: './bulk-edit-candidate-modal.component.html',
  styleUrls: ['./bulk-edit-candidate-modal.component.scss'],
})
export class BulkEditCandidateModalComponent extends MatModalBaseComponent implements OnInit {
  form: FormGroup;
  partners: PartnerDropdownItem[] = [];
  partnerControl = new FormControl();
  subPartnerControl = new FormControl();
  projectControl = new FormControl();
  subProjectControl = new FormControl();
  statuses: BoardColumn[] = [];
  availableStatuses: BoardColumn[] = [];
  statusOption: BoardColumnOption[] = [];
  statusControl: FormControl;
  statusOptionControl: FormControl;
  projects: PartnerDropdownProject[] = [];
  subProjects: PartnerDropdownProject[] = [];
  positions: PositionDropdownItem[] = [];
  subPositions: PositionDropdownItem[];
  positionsControl: FormControl;
  subPositionControl: FormControl;
  membershipMinDate = new Date();
  membershipMaxDate = new Date();
  startDateControl: FormControl;
  feorControl: FormControl;
  guaranteeControl: FormControl;

  isLaborHireATS = false;
  isOutsourced = false;
  selectedStatus: BoardColumn;
  selectedPartner: PartnerDropdownItem;
  positionType: string;

  readonly POSITION_TYPE = PositionTypes;

  selectedItems$: Observable<number>;

  feorValidator = [Validators.required, FormCustomValidators.feorNumberValidator()];

  constructor(
    private bulkEditService: BulkEditService,
    private positionApiService: PositionsApiService,
    private candidateBoardApiService: PositionKanbanBoardApiService,
    private candidateFormService: CandidateFormService,
    private atsConfigService: ATSConfigService,
    protected injector: Injector
  ) {
    super(injector);

    this.loading = true;
    this.primaryButtonDisabled = true;

    this.selectedItems$ = bulkEditService.select('selectedItems');
    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;
  }

  ngOnInit(): void {
    this.bulkEditService.setState({ listType: ListTypeName.Candidate });

    this.loadDependencies();
  }

  private loadDependencies() {
    const isEditMode = this.bulkEditService.getStateSnapshot().isEditMode;
    let candidateBulkUpdate = this.bulkEditService.getStateSnapshot().candidateBulkUpdate;

    if (!isEditMode) {
      candidateBulkUpdate = undefined;
    }

    const dependencies: {
      status: Observable<BoardColumn[]>;
      partners: Observable<PartnerDropdownItem[]>;
      positions?: Observable<PositionDropdownItem[]>;
      subPositions?: Observable<PositionDropdownItem[]>;
    } = {
      status: this.candidateBoardApiService.getColumns().pipe(take(1)),
      partners: this.positionApiService.getPartnersForDropdown().pipe(take(1)),
    };

    if (candidateBulkUpdate?.positionId?.id) {
      dependencies.positions = this.positionApiService
        .getPositionsForDropdown(candidateBulkUpdate?.projectId?.id)
        .pipe(take(1));
    }

    if (candidateBulkUpdate?.subPositionId?.id) {
      dependencies.subPositions = this.positionApiService
        .getPositionsForDropdown(candidateBulkUpdate?.subProject?.id)
        .pipe(take(1));
    }

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(({ status, partners, positions, subPositions }) => {
        this.form = this.isLaborHireATS
          ? this.candidateFormService.initBulkEditCandidateFormForLaborHire(candidateBulkUpdate)
          : this.candidateFormService.initBulkEditCandidateForm(candidateBulkUpdate);
        this.statusControl = this.form.get('statusId') as FormControl;
        this.statusOptionControl = this.form.get('statusOptionId') as FormControl;
        this.positionsControl = this.form.get('positionId') as FormControl;
        this.partnerControl = new FormControl(candidateBulkUpdate?.partner || null);
        this.projectControl = new FormControl(candidateBulkUpdate?.projectId || null);
        if (this.isLaborHireATS) {
          this.renderLaborHireForm(candidateBulkUpdate, subPositions);
        }
        this.statuses = status;
        this.partners = partners;
        this.positions = positions;
        this.projects = candidateBulkUpdate?.partner?.projects || [];
        this.isOutsourced = candidateBulkUpdate?.statusId?.slug === BoardColumnSlugs.Outsourced;
        this.setStatusOptionControl(candidateBulkUpdate?.statusId);

        if (this.positionType === PositionTypes.Outsourced) {
          this.form.removeControl('guarantee');
        }
        this.listenFormChanges();
        this.listenPositionControlChanges();

        this.loading = false;
      });
  }

  onStatusChange(status: BoardColumn) {
    this.isOutsourced = status?.slug === BoardColumnSlugs.Outsourced;
    this.selectedStatus = status;
    this.statusOptionControl.setValue(null);
    this.statusOptionControl.setValidators([]);

    if (this.isLaborHireATS) {
      const controls = [
        this.subPartnerControl,
        this.subProjectControl,
        this.subPositionControl,
        this.startDateControl,
      ];

      controls.forEach((control: FormControl) => {
        control.setValue(null);
        control.setValidators(this.isOutsourced ? [Validators.required] : []);
        control.updateValueAndValidity();
      });

      this.setControlProperties(this.feorControl, this.isOutsourced ? this.feorValidator : []);
      if (status.positionType === PositionTypes.Outsourced) {
        this.setControlProperties(
          this.guaranteeControl,
          this.isOutsourced ? [Validators.required] : []
        );
      }
    } else {
      this.updateFormValidation();
    }

    this.setStatusOptionControl(status);
  }

  resetStatusWithOptions(): void {
    const controls = [this.statusControl, this.statusOptionControl];

    controls.forEach((control: FormControl) => {
      control.setValue(null);
      control.updateValueAndValidity();
    });
    this.statusOption = [];
    this.isOutsourced = false;
  }

  onPartnerChange(partner: PartnerDropdownItem): void {
    this.selectedPartner = partner;
    this.resetStatusWithOptions();
    if (!this.isLaborHireATS) {
      this.updateFormValidation();
    }
  }

  private setStatusOptionControl(status: BoardColumn): void {
    this.statusOption = [];

    if (status?.options) {
      if (status?.options?.length > 0) {
        this.statusOption = status.options.filter((statusOption: BoardColumnOption) => {
          return (
            statusOption.positionType === null || this.positionType === statusOption.positionType
          );
        });
        this.statusOptionControl.setValidators([Validators.required]);
      }
    }
    this.statusOptionControl.updateValueAndValidity();
  }

  private listenPositionControlChanges() {
    this.positionsControl.valueChanges
      .pipe(startWith(this.positionsControl.value), untilDestroyed(this))
      .subscribe((position: PositionDropdownItem) => {
        this.positionType = position?.type;
        this.availableStatuses = this.statuses.filter((status) => {
          return status.positionType === null || status.positionType === position?.type;
        });
      });
  }

  private setControlProperties(control: FormControl, validators: ValidatorFn[] | null): void {
    control.setValue(null);
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  private updateFormValidation() {
    const controls = [
      this.statusControl,
      this.partnerControl,
      this.projectControl,
      this.positionsControl,
    ];
    const validators = this.selectedPartner || this.selectedStatus ? [Validators.required] : [];

    controls.forEach((control: FormControl) => {
      control.setValidators(validators);
      control.updateValueAndValidity();
    });
  }

  private renderLaborHireForm(
    candidateBulkUpdate: CandidateBulkUpdateState,
    subPositions: PositionDropdownItem[]
  ): void {
    this.feorControl = this.form.get('feorNumber') as FormControl;
    this.guaranteeControl =
      this.positionType !== PositionTypes.Outsourced
        ? (this.form.get('guarantee') as FormControl)
        : null;
    this.startDateControl = this.form.get('membershipStartDate') as FormControl;
    this.subPositionControl = this.form.get('subPositionId') as FormControl;
    this.subPartnerControl = new FormControl(
      candidateBulkUpdate?.subPartner || null,
      candidateBulkUpdate?.subPartner ? [Validators.required] : null
    );
    this.subProjectControl = new FormControl(
      candidateBulkUpdate?.subProject || null,
      candidateBulkUpdate?.subProject ? [Validators.required] : null
    );
    this.subPositions = subPositions;
    this.partnerControl.setValidators([Validators.required]);
    this.projectControl.setValidators([Validators.required]);
    this.subProjects = candidateBulkUpdate?.subPartner?.projects || [];
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(
        startWith(this.form.getRawValue()),
        debounceTime(300),
        map(() => {
          const formRawValue = this.form.value as Partial<CandidateBulkUpdateState> & {
            feorNumber: string;
            membershipStartDate: string;
            membershipEndDate: string;
            guarantee: boolean;
          };

          const candidateSate: Partial<CandidateBulkUpdateState> = {
            partner: this.partnerControl.value as PartnerDropdownItem,
            projectId: this.projectControl.value as ProjectMinimal,
            subPartner: this.subPartnerControl.value as PartnerDropdownItem,
            subProject: this.subProjectControl.value as ProjectMinimal,
            ...formRawValue,
            feorNumber: formRawValue.feorNumber ? { name: formRawValue.feorNumber } : null,
            membershipEndDate: formRawValue.membershipEndDate
              ? { name: formRawValue.membershipEndDate }
              : null,
            membershipStartDate: formRawValue.membershipStartDate
              ? { name: formRawValue.membershipStartDate }
              : null,
            guarantee: formRawValue.guarantee === null ? null : { name: formRawValue.guarantee },
          };
          this.bulkEditService.setCandidateBulkUpdateState(candidateSate);

          if (keys(removeBlankAttributes(candidateSate)).length <= 0) {
            return true;
          }

          return this.form.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.primaryButtonDisabled = isSaveButtonDisabled;
      });
  }

  openConfirmBulkEditModal() {
    this.bulkEditService.openBulkEditConfirmModal();
  }
}
