<div class="cursor-pointer"
     [ngClass]="{'overflow-menu-trigger': isUseInSideMenu}"
     [matMenuTriggerFor]="menu"
     [class.opened]="menuOpened"
     (menuOpened)="setMenuOpened(true)"
     (menuClosed)="setMenuOpened(false)">

    <div class="d-flex align-items-center justify-content-between">
        {{label | translate}}
        <div *ngIf="useIconLabel"
             [inlineSVG]="'assets/image/custom-column-selector.svg'">
        </div>

        <div *ngIf="showArrow" class="d-flex">
            <mat-icon *ngIf="!menuOpened" class="d-flex">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="menuOpened" class="d-flex">arrow_drop_up</mat-icon>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" class="overflow-menu-container">
    <div [style.max-width]="maxWidthValue">
        <div (click)="$event.stopPropagation()" class="d-flex flex-column p-2">
            <div *ngIf="!hideSearch" class="pt-2">
                <app-text-field
                    [placeholder]="placeHolder"
                    [ngModel]="searchText | async"
                    (ngModelChange)="search($event)">
                </app-text-field>
                <hr>
            </div>

        <div class="overflow-menu-items-container pb-1">

            <ng-container *ngIf="!singleSelect">
                <div *ngFor="let option of searchedOptions; let i = index;">
                    <ng-container *ngIf="option.separatorText">
                        <hr *ngIf="i > fixedColumns.length" class="separator-hr" />
                        <div class="pb-2 separator-text">
                            {{option.separatorText | translate}}
                        </div>
                    </ng-container>

                    <app-checkbox *ngIf="!option.hidden"
                        class="overflow-select-menu-item"
                        [hideLabel]="isHideLabel"
                        [class.disabled]="option.disabled"
                        [label]="option.label ?? option.translateKey| translateWithPrefix: translatePrefix"
                        [checked]="optionIsSelected(option)"
                        [disabled]="option.disabled"
                        (isChecked)="toggleItem(option)">
                    </app-checkbox>
                </div>
            </ng-container>

                <ng-container *ngIf="singleSelect">
                    <app-radio-group
                        [isVerticalLayout]="true"
                        [ngModel]="selectedOptionsUnsaved[0]?.value"
                        (ngModelChange)="setRadioSelectedOption($event)"
                        [options]="options">
                    </app-radio-group>
                </ng-container>
            </div>

            <app-overflow-filter-footer
                [showSecondaryButton]="selectedOptionsUnsaved.length > 0"
                [applyButtonText]="applyButtonText"
                [buttonSize]="buttonSize"
                [secondaryButtonText]="'common.overflow_menu.clear_selection'"
                (secondaryButtonClicked)="clearSelectedOptions()"
                (applyClicked)="applySelectedOptions()">
            </app-overflow-filter-footer>
        </div>
    </div>
</mat-menu>
