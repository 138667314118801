import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@shared/modules/auth/auth.module';
import { SideNavigationComponent } from './side-navigation.component';

@NgModule({
  declarations: [SideNavigationComponent],
  exports: [SideNavigationComponent],
  imports: [CommonModule, InlineSVGModule, RouterModule, AuthModule],
})
export class SideNavigationModule {}
