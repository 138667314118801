<span class="d-flex align-items-center">
    <app-primary-button
        *ngIf="!hideSecondaryButton"
        (buttonClick)="onSecondaryClicked()"
        [text]="secondaryButtonText | translate"
        class="action-button mr-10px"
        buttonStyle="outline">
    </app-primary-button>
    <app-primary-button
        (buttonClick)="onPrimaryClicked()"
        [text]="primaryButtonText | translate"
        [disabled]="primaryButtonDisabled"
        [color]="primaryButtonColor"
        class="action-button">
    </app-primary-button>
</span>
