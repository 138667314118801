import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CustomModalConfig } from '@shared/modules/mat-modal/classes/CustomModalConfig';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ConfirmModalComponent } from '@shared/modules/modals/base-modals/confirm-modal/confirm-modal.component';
import { take, tap } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/overlay';
import { ConfirmModalConfig } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';

@Injectable({
  providedIn: 'root',
})
export class MatModalService {
  private openedModalList: Array<MatDialogRef<MatModalBaseComponent>> = [];

  constructor(public dialog: MatDialog) {}

  get isModalOpened(): boolean {
    return this.openedModalList.length > 0;
  }

  openDialog<ModalConfigType = CustomModalConfig>(
    modalComponent: ComponentType<MatModalBaseComponent>,
    customModalConfig: ModalConfigType = null
  ): Observable<unknown> {
    const modalConfig = this.getConfig(modalComponent, customModalConfig);
    const dialogRef = this.dialog.open(modalComponent, modalConfig);

    this.openedModalList = [...this.openedModalList, dialogRef];

    return dialogRef.afterClosed().pipe(
      take(1),
      tap(() => this.removeDialog(dialogRef))
    );
  }

  openConfirmModal(modalConfig: ConfirmModalConfig): Observable<boolean | unknown> {
    const defaultConfig = {
      width: '460px',
      data: { variant: ModalTypes.DynamicHeight },
    };

    const mergedData = { ...defaultConfig.data, ...modalConfig.data };
    const confirmModalConfig = { ...defaultConfig, ...modalConfig };
    confirmModalConfig.data = mergedData;

    return this.openDialog(ConfirmModalComponent, confirmModalConfig);
  }

  private getConfig(
    modalComponent: ComponentType<MatModalBaseComponent>,
    customModalConfig: CustomModalConfig
  ): MatDialogConfig {
    // @ts-ignore
    let curConfig = (modalComponent['modalConfig'] as MatDialogConfig) || {};

    const panelClasses = ['modal-reset'];

    if (customModalConfig) {
      if (customModalConfig.data?.variant !== ModalTypes.DynamicHeight) {
        panelClasses.push('reset-modal-border');
      }

      curConfig = {
        ...curConfig,
        ...customModalConfig,
        data: { ...curConfig.data, ...customModalConfig.data } as unknown,
        panelClass: panelClasses,
      };
    }

    return curConfig;
  }

  removeDialog(dialogRef: MatDialogRef<MatModalBaseComponent>) {
    this.openedModalList = this.openedModalList.filter((currentRef) => {
      return currentRef !== dialogRef;
    });
  }

  closeAllDialog() {
    this.openedModalList.forEach((dialogRef) => {
      this.closeDialog(dialogRef);
    });
  }

  closeDialogByName(componentConstructorName: string) {
    this.openedModalList.forEach((dialogRef: MatDialogRef<MatModalBaseComponent>) => {
      if (dialogRef && dialogRef.componentInstance.constructor.name === componentConstructorName) {
        this.closeDialog(dialogRef);
      }
    });
  }

  private closeDialog(dialogRef: MatDialogRef<MatModalBaseComponent>) {
    try {
      if (dialogRef) {
        dialogRef.close();
      }
    } catch (e) {
      console.warn('tried to close invalid dialogRef');
    }
  }
}
