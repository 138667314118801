import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CandidatePositionFormDto } from '@pages/candidates/classes/CandidatePositionFormDto';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';

@UntilDestroy()
@Component({
  selector: 'app-partner-project-position-form-selection',
  templateUrl: './partner-project-position-form-selection.component.html',
  styleUrls: ['./partner-project-position-form-selection.component.scss'],
})
export class PartnerProjectPositionFormSelectionComponent implements OnInit {
  @Input() createdAtPositions = false;
  @Input() partners: PartnerDropdownItem[];
  @Input() positionsControl: FormControl;
  @Input() disabled = false;

  detail: PositionDetail;

  formGroups: FormArray = new FormArray([]);

  constructor(private formBuilder: FormBuilder, private positionState: PositionStateService) {}

  ngOnInit() {
    this.positionState
      .select('positionDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        if (detail) {
          this.detail = detail;
          this.addNewForm();
          this.positionsControl.setValue([{ id: this.detail.id }]);
        }
      });

    this.listenFormArrayChanges();
  }

  addNewForm(): void {
    const newGroup = this.generateNewForm();
    newGroup.markAllAsTouched();
    this.formGroups.push(newGroup);
  }

  removeForm(index: number): void {
    this.formGroups.removeAt(index);
  }

  getFormControl(formGroup: AbstractControl, name: string): FormControl {
    return formGroup.get(name) as FormControl;
  }

  private listenFormArrayChanges(): void {
    this.formGroups.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updatePositionsControlValueAndValidity();
    });
  }

  private generateNewForm(): FormGroup {
    return this.formBuilder.group({
      partnerId: [
        { value: this.detail?.partner.id || null, disabled: this.createdAtPositions },
        Validators.required,
      ],
      projectId: [
        { value: this.detail?.project.id || null, disabled: this.createdAtPositions },
        Validators.required,
      ],
      positionId: [
        { value: this.detail?.id || null, disabled: this.createdAtPositions },
        Validators.required,
      ],
    });
  }

  private updatePositionsControlValueAndValidity(): void {
    const selectedPositions: CandidatePositionFormDto[] = [];
    let invalidCount = 0;

    this.formGroups.controls.forEach((formGroup: AbstractControl) => {
      const partnerId = formGroup.get('partnerId').value as number;
      const projectId = formGroup.get('projectId').value as number;
      const positionId = formGroup.get('positionId').value as number;

      if (partnerId && projectId && positionId) {
        selectedPositions.push({ id: positionId });
      } else {
        invalidCount++;
      }
    });

    this.positionsControl.setValue(selectedPositions);

    if (invalidCount > 0) {
      this.positionsControl.setErrors({ unfilled: true });
    } else {
      this.positionsControl.setErrors(null);
    }
  }
}
