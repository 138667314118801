export enum BulkEditTranslateHelper {
  Partner = 'common.partner',
  OfficeId = 'partners.cost_center',
  FieldManagerId = 'partners.field_manager',
  ProjectId = 'positions.projects',
  Status = 'positions.status',
  JobTypeId = 'candidates.work_type',
  JobSubTypeId = 'candidates.physical_work_type',
  EmploymentTypeId = 'positions.employment_type',
  Specializations = 'positions.advertisements.categories',
  MembershipEndDate = 'candidates.membership_end',
  StatusId = 'common.status',
  StatusOptionId = 'positions.board.reason',
  PositionId = 'common.position',
  SubPositionId = 'common.sub_position',
  MembershipStartDate = 'candidates.membership_start',
  FeorNumber = 'positions.feor_number',
  SubPartner = 'positions.sub_partners',
  SubProject = 'common.sub_site',
  Guarantee = 'candidates.guarantee',
}
