<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="modalTitle"
        [iconUrl]="'assets/image/common/applicant-icon.svg'">
    </app-modal-header>

    <ng-container content>
        <form *ngIf="!loading" [formGroup]="form" class="h-100 modal-base">
            <app-modal-grid>
                <div left-column>
                    <app-modal-section-header sectionTitle="positions.detail"></app-modal-section-header>

                    <app-form-field>
                        <app-text-field
                            label="positions.name"
                            formControlName="name">
                        </app-text-field>
                    </app-form-field>

                    <app-form-field *ngIf="isEditMode">
                        <app-text-field
                            label="positions.code"
                            formControlName="code">
                        </app-text-field>
                    </app-form-field>

                    <ng-container *ngIf="!isEditMode">
                        <app-partner-project-position-selection
                            [partners]="partners"
                            [preFillData]="true"
                            [isProjectDropdownAlwaysShown]="true"
                            [isPositionControlShown]="false"
                            [useVirtualScrollInDropdowns]="true"
                            [partnerIdControl]="partnerControl"
                            [projectIdControl]="projectIdControl">
                        </app-partner-project-position-selection>
                    </ng-container>

                    <ng-container *ngIf="isEditMode">
                        <app-form-field>
                            <app-text-field
                                label="positions.partners"
                                [formControl]="partnerNameControl">
                            </app-text-field>
                        </app-form-field>

                        <app-form-field>
                            <app-text-field
                                [label]="
                          {
                              student: 'common.project',
                              pensioner: 'common.project',
                              laborHire: 'common.site'
                          } | appATSBasedText"
                                [formControl]="projectNameControl">
                            </app-text-field>
                        </app-form-field>
                    </ng-container>

                    <ng-container *ngIf="isLaborHireATS">
                        <app-form-field>
                            <app-select
                                label="positions.types.type"
                                [options]="positionTypes"
                                bindValue="id"
                                bindLabel="name"
                                bindValueByPropertyNameToControl="id"
                                formControlName="type">
                            </app-select>
                        </app-form-field>
                    </ng-container>
                </div>
                <div middle-column>
                    <app-modal-section-header
                        sectionTitle="partners.whc_project_managers">
                    </app-modal-section-header>

                    <app-multiple-selection
                        [optionsArray]="projectManagerArray"
                        [options]="projectManagers">
                    </app-multiple-selection>

                    <app-form-field>
                        <app-select
                            label="positions.status"
                            [options]="statuses"
                            bindLabel="key"
                            bindValue="value"
                            bindValueByPropertyNameToControl="value"
                            formControlName="status">
                        </app-select>
                    </app-form-field>

                    <app-job-type-selection
                        specLabel="positions.advertisements.categories"
                        [specializations]="specializations"
                        [specializationsGroup]="specializationGroup"
                        [jobTypeGroup]="jobTypeGroup"
                        [jobTypes]="jobTypes">
                    </app-job-type-selection>

                    <app-form-field>
                        <app-select
                            label="positions.employment_type"
                            [options]="employmentTypes"
                            bindLabel="name"
                            bindValue="id"
                            bindValueByPropertyNameToControl="id"
                            formControlName="employmentTypeId">
                        </app-select>
                    </app-form-field>
                </div>
            </app-modal-grid>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [primaryButtonText]="'common.save'"
        [secondaryButtonText]="'common.dismiss'"
        (primaryButtonClicked)="savePosition()">
    </app-modal-footer>

</app-modal-frame>
