<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'positions.board.add_candidate'"
        [iconUrl]="'assets/image/common/applicant-icon.svg'">
    </app-modal-header>

    <div content>
        <form *ngIf="!loading" [formGroup]="form" class="modal-base h-auto">
            <app-form-field>
                <app-select
                    label="common.name_and_email"
                    bindValue="id"
                    bindValueByPropertyNameToControl="id"
                    bindLabel="name"
                    [searchable]="true"
                    [useTypeahead]="true"
                    [isHintShown]="true"
                    [hintText]="'common.search_limit_fifty'"
                    (typeaheadChange)="onSearchInputChanges($event)"
                    [options]="options"
                    formControlName="id">
                    <app-candidate-search-option-template #optionsTemplate></app-candidate-search-option-template>
                </app-select>
            </app-form-field>

            <ng-container *ngIf="isLaborHireATS && isOutsourcedColumn">
                <app-form-field>
                    <app-text-field
                        label="positions.feor_number"
                        formControlName="feorNumber">
                    </app-text-field>
                </app-form-field>

                <ng-container *ngIf="data.positionType === POSITION_TYPE.Outsourced">
                    <app-form-field>
                        <app-yes-no-select
                                label="candidates.guarantee"
                                formControlName="guarantee">
                        </app-yes-no-select>
                    </app-form-field>
                </ng-container>


                <app-form-field>
                    <app-datepicker
                        [maxDate]="maxDate"
                        [isHintShown]="true"
                        label="candidates.labor_start"
                        formControlName="membershipStartDate">
                    </app-datepicker>
                </app-form-field>

                <app-partner-project-position-selection
                    [partnerIdControl]="partnerIdControl"
                    [projectIdControl]="projectIdControl"
                    [positionIdControl]="form.get('subPositionId')">
                </app-partner-project-position-selection>
            </ng-container>
        </form>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="addCandidate()">
    </app-modal-footer>

</app-modal-frame>
