import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsightsService } from '@shared/services/application-insights.service';

@Injectable()
export class ApplicationInsightsErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error): void {
    this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
  }
}
