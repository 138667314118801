<div class="d-flex align-items-center">
    <div [tippy]="status.finalDate" class="status-color-{{status.status.toLowerCase()}}">
        {{('sms.status.' + status.status) | translate}}
    </div>

    <app-hint
        *ngIf="status.errorMessage"
        class="ml-1"
        [hintText]="status.errorMessage"
        [placement]="'top-start'"
        [hintTypes]=[HINT_TYPE.Error]>
    </app-hint>
</div>
