import { Component, Input } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { TermsAndConditionsTypes } from '@pages/candidates/classes/CandidateDetail';

@Component({
  selector: 'app-status-selection-cell',
  templateUrl: './status-selection-cell.component.html',
  styleUrls: ['./status-selection-cell.component.scss'],
})
export class StatusSelectionCellComponent extends CardTableCustomCellBaseComponent {
  @Input() statuses: BoardColumn[];
  @Input() isEnabled = true;
  @Input() userRoles: MultiUserRole[];
  @Input() hasTermsAndConditions: TermsAndConditionsTypes;
}
