import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export class NgVarContext<T = unknown> {
  $implicit: T = null!;
  appNgVar: T = null!;
}

@Directive({
  selector: '[appNgVar]',
})
export class NgVarDirective<T = unknown> {
  private _context: NgVarContext<T> = new NgVarContext<T>();
  private _viewRef: EmbeddedViewRef<NgVarContext<T>> | null = null;

  @Input()
  set appNgVar(context: T) {
    this._context.$implicit = this._context.appNgVar = context;

    if (!this._viewRef) {
      this._viewRef = this._viewContainer.createEmbeddedView(this._templateRef, this._context);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngTemplateGuard_appNgVar: 'binding';

  static ngTemplateContextGuard<T>(dir: NgVarDirective<T>, ctx: unknown): ctx is NgVarContext<T> {
    return true;
  }

  constructor(
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<NgVarContext<T>>
  ) {}
}
