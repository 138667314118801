import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';

export enum SumIconType {
  Ok = 'ok',
  Error = 'error',
  Clock = 'clock',
  RedSquare = 'red_square',
  GreenSquare = 'green_square',
  YellowSquare = 'yellow_square',
  GraySquare = 'gray_square',
}

export interface SumIcon {
  type: SumIconType;
  messages: string[];
}

export interface BoardCandidate {
  user: {
    id: number;
    avatarThumb: string;
    avatarName: string;
    name: string;
    email: string;
    birthDate: string;
    icons?: SumIcon[];
    isRead: boolean;
    messages: string[];
    hasCustomMessage: boolean;
  };
  editable: boolean;
  statusId: number;
  optionId: number;
  validFrom: string;
  roles: MultiUserRole[];
}
