<div class="header h-100 d-flex flex-grow-1 align-items-center justify-content-between">
    <div class=" d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <app-filter-menu-button
                    *ngIf="isTableView"
                    class="mr-3">
            </app-filter-menu-button>

            <app-back-arrow-button
                    class="back-button d-block"
                    (buttonClick)="onNavigation()">
            </app-back-arrow-button>

            <ng-container *ngIf="isDetailLoaded$ | async">
                <div class="logo mr-10px">
                    <img
                        class="logo-img w-100 h-100"
                        [src]="logo"
                        [appImgFallback]="placeholder"
                        alt="Partner Logo">
                </div>

                <div class="name">{{positionDetail?.name}}</div>

                <div class="pages">
                    <button *ngFor="let page of pages" type="button" (click)="onInnerNavigation()"
                            [class.active-page]="page.isActive" [routerLink]="page.routerLink">
                        <span>{{page.text | translate}}</span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="isDetailLoaded$ | async">
        <div *ngIf="showAddNewCandidateButton(); else deleteButton" class="d-flex">
            <app-select
                    *ngIf="isKanbanView"
                    class="pr-3"
                    (selectionChange)="setSortMode($event)"
                    [placeholder]="'positions.header.select_sort_mode'"
                    [options]="sortModes"
                    bindValue="key"
                    bindLabel="label"
                    [customClass]="'sort-ng-select'"
                    [formControl]="sortForm">
            </app-select>

            <ng-container *ngIf="!isKanbanView">
                <app-fab-button
                        *appUserHasPermission="exportPermissions"
                        class="button-export mr-20px"
                        iconUrl="assets/image/arrow-to-bottom.svg"
                        isTransparent="true"
                        (buttonClick)="triggerExportClick()">
                </app-fab-button>
            </ng-container>

            <app-fab-button
                    (buttonClick)="toggleView()"
                    [isTransparent]="true"
                    class="view-switcher-button icon pr-3"
                    [iconUrl]="isKanbanView ? 'assets/image/table-icon.svg' : 'assets/image/kanban-icon.svg'">
            </app-fab-button>

            <app-primary-button
                    type="submit"
                    [style]="'filled'"
                    [text]="'page_header.header_button_title' | appTranslateWithParams: {entity: 'common.candidate'} | async"
                    color="basic"
                    [disabled]="isPositionInactive"
                    [buttonClass]="'header-button'"
                    (buttonClick)="createCandidate()">
            </app-primary-button>
        </div>
    </ng-container>
</div>

<ng-template #deleteButton>
    <app-delete-button *ngIf="isDetailView"
        [modalConfig]="deleteModalConfig"
        [disabled]="!isPositionInactive"
        [hintText]="!isPositionInactive ? 'positions.position_could_not_delete': 'partners.delete_position'"
        (deleteSuccess)="onNavigation()"
    ></app-delete-button>
</ng-template>
