import { camelCase, includes, isPlainObject, keys, uniq } from 'lodash-es';

export const commonErrors: { [key: string]: string } = {
  parentIsInactive: 'validation_errors.parent_is_inactive_error',
  projectIsInactive: 'validation_errors.parent_is_inactive_error',
  incorrectDate: 'validation_errors.incorrect_date',
  imageSizeInvalid: 'validation_errors.image_size_invalid',
  couldNotDeleteBecauseHasPosition: 'validation_errors.could_not_delete_because_has_position',
  providerTypeAutomataExists: 'validation_errors.provider_type_automata_exists',
  positionInactive: 'validation_errors.position_inactive',
  cantParseDocument: 'validation_errors.cant_parse_document',
  cantSaveParsedDocument: 'validation_errors.cant_save_parsed_document',
  filterInputInvalid: 'validation_errors.filter_input_invalid',
  filterCvInputInvalid: 'validation_errors.filter_cv_input_invalid',
  baseTermExists: 'validation_errors.base_term_exists',
  advertisementPictureMissing: 'validation_errors.advertisement_picture_missing',
  advertisementHasActiveProviders: 'validation_errors.advertisement_has_active_provider',
  missingFieldsForCandidateRole: 'validation_errors.missing_fields_for_candidate_role',
  missingFieldsForCandidateRoleWithBirthdate:
    'validation_errors.missing_fields_for_candidate_role_with_birthdate',
  customFieldTypeInUse: 'base_data.custom_fields.delete_fail',
  optionInUse: 'base_data.custom_fields.delete_option_fail',
  failedToLoadUserAndGroup: 'validation_errors.failed_to_load_user_and_group',
  socialPermissionDenied: 'validation_errors.social_permission_denied',
  socialPermissionMismatch: 'validation_errors.social_permission_mismatch',
  invalidCredentials: 'validation_errors.invalid_credentials',
  invalidState: 'validation_errors.invalid_state',
  socialUserMismatch: 'validation_errors.social_user_mismatch',
  socialUserMissingData: 'validation_errors.social_user_missing_data',
  positionStatusIncorrect: 'validation_errors.position_status_incorrect',
  tagDeleteExists: 'validation_errors.tag_delete_exists',
  privacyDocumentEmailRequired: 'validation_errors.privacy_document_email_required',
  privacyDocumentUserContactDisabled: 'validation_errors.privacy_document_user_contact_disabled',
};

function traverseObject(object: Object, accumulatedKeys: string[]): void {
  (keys(object) as Array<keyof Object>).forEach((k) => {
    accumulatedKeys.push(k);
    if (typeof object[k] === 'object') {
      traverseObject(object[k], accumulatedKeys);
    }
  });
}

export function getErrorTranslationKey(
  errors: unknown,
  fallbackErrorMessage = 'validation_errors.general_error'
): string | undefined {
  let errorsArray: string[];

  const accumulatedKeys: string[] = [];
  if (typeof errors === 'string') {
    errorsArray = [errors];
  } else if (isPlainObject(errors)) {
    traverseObject(errors, accumulatedKeys);
    errorsArray = accumulatedKeys;
  } else if (Array.isArray(errors)) {
    errorsArray = errors as string[];
  } else {
    errorsArray = [];
  }

  errorsArray = uniq(errorsArray).map((key) => camelCase(key));

  const foundError = keys(commonErrors).find((key) => includes(errorsArray, key));

  if (!foundError && typeof fallbackErrorMessage === 'string') {
    return fallbackErrorMessage;
  }

  return commonErrors[foundError];
}

export function hasCommonError(errorKeys: string[] | string) {
  const errors: string[] = (Array.isArray(errorKeys) ? errorKeys : [errorKeys])
    .map((error: string) => camelCase(error))
    .filter((key: string) => typeof key === 'string');

  return keys(commonErrors).some((key: string) => includes(errors, key));
}
