import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, Validators } from '@angular/forms';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CandidateFormService } from '@pages/candidates/services/base/candidate-form.service';
import differenceBy from 'lodash-es/differenceBy';

@UntilDestroy()
@Component({
  selector: 'app-spoken-language-selection',
  templateUrl: './spoken-language-selection.component.html',
  styleUrls: ['./spoken-language-selection.component.scss'],
})
export class SpokenLanguageSelectionComponent implements OnInit {
  @Input() languageArray: FormArray;
  @Input() languages: Language[];
  @Input() languageLevels: LanguageLevel[];
  availableLanguages: Language[] = [];

  constructor(private candidateFormService: CandidateFormService) {}

  ngOnInit(): void {
    this.availableLanguages = this.languages;

    setTimeout(() => {
      this.availableLanguages = this.getAvailableLanguages();
      this.languageArray.controls.forEach((languageGroup: AbstractControl) => {
        const languageLevelControl = languageGroup.get('languageLevelId');
        if (languageLevelControl?.value > 0) {
          languageLevelControl.setValidators([Validators.required]);
          languageLevelControl.updateValueAndValidity();
        }
      });
    }, 100);
  }

  onSelectionChange(language: Language, languageLevelControl: AbstractControl): void {
    if (language) {
      languageLevelControl.markAsTouched();
    }

    const validators = language ? [Validators.required] : [];
    languageLevelControl.setValue(null);
    languageLevelControl.setValidators(validators);
    languageLevelControl.updateValueAndValidity();

    this.availableLanguages = this.getAvailableLanguages();
  }

  onAddLanguageGroup(): void {
    this.languageArray.push(this.candidateFormService.getLanguagesGroup(null, false));
    this.availableLanguages = this.getAvailableLanguages();
  }

  onRemoveElement(index: number): void {
    this.languageArray.removeAt(index);
    this.availableLanguages = this.getAvailableLanguages();
  }

  private getAvailableLanguages(): Language[] {
    const currentlySelectedLanguages = this.languageArray.controls
      .map((group: AbstractControl) => ({ id: group.get('languageId').value as number }))
      .filter((value) => value.id !== null);
    return differenceBy(this.languages, currentlySelectedLanguages, 'id');
  }
}
