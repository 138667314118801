<ng-template let-item>
    <div class="provider-type-cell d-flex align-items-center">
        <span class="text-value mr-5px">{{item.providerTypeName}}</span>
        <button
            *ngIf="item.providerTypeName === manualType"
            type="button"
            (click)="onCopyUrlToClipboard(item)"
            inlineSVG="assets/image/copy.svg">
        </button>
    </div>
</ng-template>
