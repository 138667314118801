import { Pipe, PipeTransform } from '@angular/core';
import { snakeCase } from 'lodash-es';

@Pipe({
  name: 'documentTypeName',
})
export class DocumentTypeNamePipe implements PipeTransform {
  transform(name: string): string {
    return `candidates.${snakeCase(name)}`;
  }
}
