import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() id: number;
  @Input() value: string;
  @Output() deleteItem: EventEmitter<number> = new EventEmitter<number>();

  delete(id: number) {
    this.deleteItem.emit(id);
  }
}
