<app-modal-frame [dialogRef]="dialogRef" [data]="data" [hideHeader]="true">
    <div content class="confirm-header">
        {{modalText | translate}}
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [primaryButtonText]="data.primaryButtonText"
        [primaryButtonColor]="data.primaryButtonColor"
        [secondaryButtonText]="data.secondaryButtonText"

        (secondaryButtonClicked)="cancel()"
        (primaryButtonClicked)="confirm()">
    </app-modal-footer>
</app-modal-frame>
