<app-form-field>
    <app-select
        label="candidates.partner_name"
        [isHintShown]="true"
        [virtualScroll]="useVirtualScrollInDropdowns"
        [options]="partners"
        (selectionChange)="onPartnerSelected($event)"
        [searchable]="true"
        bindValue="id"
        bindLabel="name"
        bindValueByPropertyNameToControl="id"
        [formControl]="partnerIdControl">
    </app-select>
</app-form-field>

<app-form-field *ngIf="isProjectDropdownAlwaysShown || partnerIdControl.value > 0">
    <app-select
        [label]="
          {
            student: 'candidates.project_name',
            pensioner: 'candidates.project_name',
            laborHire: 'partners.site_name'
          } | appATSBasedText"
        [isHintShown]="true"
        [virtualScroll]="useVirtualScrollInDropdowns"
        [options]="projects"
        bindValue="id"
        bindLabel="name"
        bindValueByPropertyNameToControl="id"
        [formControl]="projectIdControl">
    </app-select>
</app-form-field>

<app-form-field *ngIf="isPositionControlShown && projectIdControl.value > 0">
    <app-select
        label="candidates.position_name"
        [isHintShown]="true"
        [virtualScroll]="useVirtualScrollInDropdowns"
        [options]="positions"
        bindValue="id"
        bindLabel="name"
        bindValueByPropertyNameToControl="id"
        [formControl]="positionIdControl">
    </app-select>
</app-form-field>
