<div class="whc-form-field" [class.field-disabled]="control.disabled">
    <input
      #input
        appNumberInput
        [minValue]="minValue"
        [maxValue]="maxValue"
        [id]="textFieldId"
        [type]="type"
        [placeholder]="placeholder || '' | translate | appFirstLetterUppercase"
        [value]="value"
        [disabled]="disabled"
        (keyup)="keyUp.emit()"
        (input)="onChange($event.target['value'])"
        (blur)="handleBlur()"
    />
    <ng-container *ngIf="suffixTemplate && control?.enabled">
        <ng-container *ngTemplateOutlet="suffixTemplate"></ng-container>
    </ng-container>
</div>
