import { HintType } from '@shared/modules/hint/classes/HintType';

export const hintComponentKeyMap: { [key: string]: string } = {
  [HintType.Error]: 'isErrorHintShown',
  [HintType.Info]: 'isInfoHintShown',
  [HintType.Help]: 'isHelpHintShown',
  [HintType.Success]: 'isSuccessHintShown',
  [HintType.Clock]: 'isClockHintShown',
  [HintType.RedSquare]: 'isNoDataHintShown',
  [HintType.YellowSquare]: 'isLimitedDataHintShown',
  [HintType.GreenSquare]: 'isTwoYearDataHintShown',
  [HintType.GraySquare]: 'isDeleteInProgressHintShown',
  [HintType.Sync]: 'isSyncHintShow',
};

export const hintComponentCurrentlyShownMap: { [key: string]: boolean } = {
  isErrorHintShown: false,
  isInfoHintShown: false,
  isHelpHintShown: false,
  isSuccessHintShown: false,
  isClockHintShown: false,
  isNoDataHintShown: false,
  isLimitedDataHintShown: false,
  isTwoYearDataHintShown: false,
  isDeleteInProgressHintShown: false,
  isSyncHintShow: false,
};
