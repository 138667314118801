import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { FormControl, NgControl } from '@angular/forms';
import { datePickerProviders } from '@config/date-picker.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';
import {
  DatepickerDropdownPositionX,
  DatepickerDropdownPositionY,
} from '@angular/material/datepicker/datepicker-base';

@UntilDestroy()
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: datePickerProviders,
})
export class DatepickerComponent extends FormInputBaseComponent<Date> implements OnInit {
  @Input() yPosition: DatepickerDropdownPositionY = 'above';
  @Input() xPosition: DatepickerDropdownPositionX = 'start';
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() clearable = false;
  @Input() placeholder = 'common.please_select';
  @Input() smallSize = false;

  @Output() dateChanged = new EventEmitter<void>();

  control: FormControl;
  hidePlaceholder: boolean;

  constructor(@Self() @Optional() private ngControl: NgControl) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control as FormControl;
    this.hidePlaceholder = this.control.disabled;

    this.listenControlValueChange();
    this.listenControlStatusChange();

    return superCalled;
  }

  private listenControlValueChange() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value: moment.Moment) => {
      if (moment.isMoment(value)) {
        this.control.patchValue(value.format(AppConstants.backendDateFormat), { emitEvent: false });

        this.dateChanged.emit();
      }
    });
  }

  private listenControlStatusChange() {
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.hidePlaceholder = this.control.disabled;
    });
  }

  onCalendarOpened() {
    this.control.markAsTouched();
  }

  onResetDate() {
    this.control.setValue(null);
  }
}
