import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  cardTableInitialState,
  CardTableState,
} from '@shared/modules/state-manager/state/card-table/card-table.state';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ListData } from '@shared/classes/ListData';
import { uniqBy } from 'lodash-es';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { ClassProperties } from '@shared/types/class-properties.type';

@Injectable({
  providedIn: 'root',
})
export class CardTableService extends StateService<CardTableState> {
  constructor(private toast: ToastService) {
    super(cardTableInitialState);
  }

  callLoadMoreItems<ResponseType, MappedType>(
    parentId: number,
    listName: keyof ClassProperties<CardTableState, MappedType[]>,
    endpointToGetItems: (parentId: number, page: number) => Observable<ListData<ResponseType>>,
    dataMapper: (data: ResponseType[]) => MappedType[],
    successToastText?: string
  ): Observable<ListData<ResponseType>> {
    const currentRows = this.getStateSnapshot()[listName];
    const currentPage = this.getStateSnapshot().currentPage;

    return endpointToGetItems(parentId, currentPage + 1).pipe(
      take(1),
      tap((listData: ListData<ResponseType>) => {
        this.setState({
          total: listData.total,
          currentPage: currentPage + 1,
          [listName]: uniqBy([...currentRows, ...dataMapper(listData.data)], 'id'),
        });

        this.toast.showSuccess(successToastText || 'common.more_items_loaded');
      })
    );
  }

  callGetItems<ResponseType, MappedType>(
    listName: keyof ClassProperties<CardTableState, MappedType[]>,
    endpointToGetItems: Observable<ListData<ResponseType>>,
    dataMapper: (data: ResponseType[]) => MappedType[]
  ): Observable<ListData<ResponseType>> {
    return endpointToGetItems.pipe(
      tap((listData: ListData<ResponseType>) => {
        this.setState({
          total: listData.total,
          [listName]: dataMapper(listData.data),
        });
      })
    );
  }

  resetState(): void {
    this.setState({ ...cardTableInitialState });
  }
}
