<ng-template #dotMenuTemplate let-hide>
    <div class="dot-menu">
        <ng-container *ngFor="let item of menuItems">
            <button (click)="onMenuItemClicked(item); hide()"
                    *appUserHasPermission="item.permissions"
                    type="button"
                    class="dot-menu menu-item">
                {{item.textValue | translate | appFirstLetterUppercase}}
            </button>
        </ng-container>
    </div>
</ng-template>

<ng-container *ngIf="isEnabled">
    <button type="button" class="dot-menu-trigger"
            inlineSVG="assets/image/dot-menu.svg"
            [tippy]="dotMenuTemplate" variation="dotMenu"
            [placement]="placement"
            (click)="onDotMenuClicked($event)"
            [isEnabled]="isEnabled">
    </button>
</ng-container>
