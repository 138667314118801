<div class="filters-loading">
    <div class="loading-info">
        <div class="mb-2">
            {{ 'common.filters_loading' | translate }}
        </div>

        <app-page-loader
            [color]="'white'"
            [inline]="true"
            class="page-loader">
        </app-page-loader>
    </div>
</div>
