import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends StateService<{}> {
  toggleSubject$ = new Subject<boolean>();

  constructor() {
    super({});
  }
}
