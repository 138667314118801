import { HttpClient } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import packageJson from '../../../../package.json';

class HttpLoaderResource implements ITranslationResource {
  suffix = `.json?v=${packageJson.version}`;
  prefix: string;

  constructor(location: string) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

/** get translate files */
export function HttpMultiLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('auth-social-login'),
    new HttpLoaderResource('common'),
    new HttpLoaderResource('not-found'),
    new HttpLoaderResource('browser-not-supported'),
    new HttpLoaderResource('cookie-popup'),
    new HttpLoaderResource('form-components'),
    new HttpLoaderResource('page-header'),
    new HttpLoaderResource('side-filter'),
    new HttpLoaderResource('partners'),
    new HttpLoaderResource('positions'),
    new HttpLoaderResource('validation-errors'),
    new HttpLoaderResource('candidates'),
    new HttpLoaderResource('users'),
    new HttpLoaderResource('page-titles'),
    new HttpLoaderResource('notifications'),
    new HttpLoaderResource('hints'),
    new HttpLoaderResource('history'),
    new HttpLoaderResource('base-data'),
    new HttpLoaderResource('phone-contacts'),
    new HttpLoaderResource('table-view'),
    new HttpLoaderResource('position-applications'),
    new HttpLoaderResource('filters'),
    new HttpLoaderResource('sms'),
  ]);
}
