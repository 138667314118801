import { Component, Injector, OnInit } from '@angular/core';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { SourceService } from '@pages/base-data/services/source/source.service';
import { SourceApiService } from '@pages/base-data/services/source/base/source-api.service';
import { DeleteSourceModalData } from '@shared/modules/modals/base-data-modals/sources/manage-source-modal/classes/DeleteSourceModalConfig';
import { SourceReplacement } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/classes/SourceReplacement';

@UntilDestroy()
@Component({
  selector: 'app-delete-source-modal',
  templateUrl: './delete-source-modal.component.html',
  styleUrls: ['./delete-source-modal.component.scss'],
})
export class DeleteSourceModalComponent
  extends MatModalBaseComponent<DeleteSourceModalData>
  implements OnInit {
  form: FormGroup;
  existingSources: SourceListItem[];

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private sourceService: SourceService,
    private sourceApiService: SourceApiService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;
    this.form = this.initForm();
    this.listenFormChanges();
    this.callExistingSources();
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      newPropertyTypeId: [null, [Validators.required]],
    });
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), debounceTime(300), untilDestroyed(this))
      .subscribe((): void => {
        this.primaryButtonDisabled = this.form.invalid;
      });
  }

  private callExistingSources() {
    const onlyActive: boolean = true;
    this.sourceApiService
      .getSources(onlyActive)
      .pipe(untilDestroyed(this))
      .subscribe((existingSources: SourceListItem[]) => {
        this.existingSources = existingSources.filter(
          (item: SourceListItem): boolean => item.id !== this.data.source.id
        );
        this.loading = false;
      });
  }

  saveSource() {
    this.primaryButtonDisabled = true;
    this.sourceService
      .callDeleteSource(this.data.source.id, this.form.getRawValue() as SourceReplacement)
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
