import { InlineEditColumnParams } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-params';

export interface InlineEditColumnDataList {
  [key: string]: InlineEditColumnData;
}

export interface InlineEditColumnData {
  params: InlineEditColumnParams;
  loading: boolean;

  items?: unknown[];
  bindLabel?: string;
}

export enum InlineEditColumnType {
  NotSet = 'notSet',
  Text = 'text',
  Date = 'date',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Custom = 'custom',
}
