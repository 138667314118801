import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardFilteringBaseComponent } from '@shared/modules/filter-components/components/card-filtering-base/card-filtering-base.component';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, from, merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeAll,
  pairwise,
  tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DateInterval } from '@shared/classes/DateInterval';
import { DateIntervalWithIds } from '@shared/classes/DateIntervalWithIds';
import lodashLast from 'lodash-es/last';
import {
  CityDistanceFilter,
  CityDistanceFilterFormGroupObj,
} from '@shared/modules/filter-components/classes/CityDistanceFilter';
import { CityMinimal } from '@shared/classes/City';
import { FilterDropdownConfig } from '@shared/modules/filter-components/classes/filter-dropdown-config';
import { HttpService } from '@shared/modules/http/http.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import { BaseData } from '@shared/classes/BaseData';
import { ParamArray } from '@shared/modules/http/classes/ParamArray';
import { Country } from '@shared/classes/Country';
import { environment } from '@environments/environment';
import { AppConstants } from '@config/app.constant';
import { DistanceRangeGroupFormValue } from '@shared/modules/filter-components/components/dropdown-filtering/classes/DistanceRangeGroupFormValue';
import { DateIntervalPickerValue } from '@shared/modules/filter-components/components/date-interval-picker/classes/DateIntervalPickerValue';
import {
  DropdownFilteringBaseValue,
  DropdownFilteringValue,
  DropdownFilteringWithDateValue,
  DropdownFilteringWithIntegerValue,
} from '@shared/modules/filter-components/components/dropdown-filtering/classes/DropdownFilteringValue';

interface StatusChangePayload {
  id: number;
  name: string;
}

type IntervalChangePayload = {
  [key in 'start' | 'end']: string;
};

type FilterPayload = StatusChangePayload[] | IntervalChangePayload;

@UntilDestroy()
@Component({
  selector: 'app-dropdown-filtering',
  templateUrl: './dropdown-filtering.component.html',
  styleUrls: ['./dropdown-filtering.component.scss'],
})
export class DropdownFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  @Input() singleDropdown = false;
  @Input() withDate = false;
  @Input() withInteger = false;
  @Input() dropdownConfig: FilterDropdownConfig = null;
  @Input() countries: Country[] = [];
  @Input() withCountry = false;

  selectedItems = new FormControl();
  selected = new BehaviorSubject<BaseData[]>([]);
  idOfSelectedItems: (number | string)[];
  dateIntervalPickerFormGroup: FormGroup;
  withIntegerFormGroup: FormGroup;
  dropDownGroup: FormGroup;
  hintText = '';
  outsideEmitter = new EventEmitter<void>();
  selectedItemWithInteger$: Observable<CityMinimal[]> = of<CityMinimal[]>([]);
  searchTerm$: Subject<string> = new Subject<string>();
  selectedCountry = new FormControl();

  constructor(
    readonly eRef: ElementRef<HTMLElement>,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private http: HttpService
  ) {
    super(eRef, filteringService, translate);

    this.dropDownGroup = this.fb.group({
      selectedItems: [],
      selectedCountry: [],
    });
  }

  get selectedItem(): Observable<BaseData[]> {
    if (this.withInteger) {
      return this.selectedItemWithInteger$;
    }

    return this.selected.asObservable();
  }

  ngOnInit() {
    if (this.withCountry && this.countries?.length) {
      this.selectedCountry.setValue(
        this.countries.find((country) => country.shortName === environment.countryShortName)
      );
    }

    if (this.dropdownConfig?.listType === 'async' && this.dropdownConfig?.listUrl) {
      const urlKey = this.dropdownConfig?.listUrl;

      if (!this.isDropdownDataLoadedByKey(urlKey)) {
        this.setDropdownData(urlKey);
      } else {
        this.data = this.getLoadedDropdownDataByKey(urlKey);
      }
    }

    if (this.dropdownConfig?.listType === 'search' && this.dropdownConfig?.listUrl) {
      const urlKey = this.dropdownConfig?.listUrl;

      if (
        this.isDropdownDataLoadedByKey(urlKey) &&
        this.filteringService.getSavedFilter(this.filterKey)
      ) {
        this.data = this.getLoadedDropdownDataByKey(urlKey);
      }

      this.listenToSyncingDropdownDataChanges(urlKey);
    }

    if (this.withDate) {
      this.dateIntervalPickerFormGroup = new FormGroup({
        start: new FormControl('', [Validators.required]),
        end: new FormControl('', [Validators.required]),
      });
    }

    if (this.withInteger) {
      this.withIntegerFormGroup = new FormGroup({
        id: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        intValue: new FormControl(undefined, [Validators.required, Validators.min(0)]),
      });

      this.selectedItemWithInteger$ = this.getCityRangeValueChanges();
    }

    this.listenToSearchTermChanges();

    this.listenCountryValueChanges();

    this.listenDropDownAndDateChange();

    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });

    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((valueOfControls) => {
        this.setValueForControls(valueOfControls as DropdownFilteringValue);
      });

    this.selected.pipe(delay(200), untilDestroyed(this)).subscribe((values) => {
      this.isOpen = values?.length > 0;
    });

    return super.ngOnInit();
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }

  setDropdownData(
    url: string,
    queryParams?: Record<string, string>,
    isSearchData = false,
    paramArray?: ParamArray[]
  ): void {
    this.filteringService.setDropdownDataLoaded(url, false);

    this.getDropdownData(url, queryParams, paramArray)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.filteringService.setDropdownData(url, data, isSearchData);
        this.filteringService.setDropdownDataLoaded(url, true);
        this.data = data;
      });
  }

  setDropdownDataForSyncing(
    url: string,
    queryParams?: Record<string, string>,
    isSearchData = false,
    paramArray?: ParamArray[]
  ): void {
    this.filteringService.setDropdownDataLoaded(url, false);
    this.getDropdownData(url, queryParams, paramArray)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.filteringService.setDropdownData(url, data, isSearchData);
        this.filteringService.setDropdownDataLoaded(url, true);
      });
  }

  setDropdownDataAsync(url: string, paramArray: ParamArray[]): Observable<BaseData[]> {
    this.filteringService.setDropdownDataLoaded(url, false);

    return this.getDropdownData(url, null, paramArray);
  }

  getDropdownData(
    url: string,
    queryParams?: Record<string, string>,
    paramArray?: ParamArray[]
  ): Observable<BaseData[]> {
    return this.http.get(url, queryParams, paramArray);
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;

    if (isChecked && this.withInteger) {
      this.filterValueChange.emit(this.getFilterStateWithDistanceRange());
    } else if (isChecked) {
      this.filterValueChange.emit(
        this.withDate ? this.getFilterStateWithDate() : { [this.filterKey]: this.idOfSelectedItems }
      );

      if (AppConstants.filterKeysForSave.includes(this.filterKey)) {
        const selectedItemsValue = this.selectedItems.value as BaseData[];
        const selectedIds = selectedItemsValue?.map((selectedItem) => selectedItem.id);

        this.filteringService.setSavedIdsByKey(this.filterKey, selectedIds);
        this.setConnectedDropdowns();
      }
    } else {
      this.filterValueChange.emit({ [this.filterKey]: undefined });

      if (AppConstants.filterKeysForSave.includes(this.filterKey)) {
        this.filteringService.setSavedIdsByKey(this.filterKey, []);
        this.setConnectedDropdowns();
      }
    }
  }

  onResetCity(): void {
    this.withIntegerFormGroup.reset();
    this.filterValueChange.emit({ [this.filterKey]: undefined });
    this.isChecked = false;
    this.isOpen = false;
  }

  customSearchFn(term: string, item: BaseData): boolean {
    const arrayOfTerm = term.toLocaleLowerCase().split(' ');
    const arrayOfItem = item.name.split(' ');
    return (
      arrayOfItem.some((i: string) => {
        return arrayOfTerm.some((t: string) => {
          return i.toLocaleLowerCase().startsWith(t);
        });
      }) || false
    );
  }

  onSearch(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  deleteItem(id: number) {
    const selectedItemsValue = this.selectedItems.value as BaseData[];
    const newSelectedItems = selectedItemsValue.filter((item) => {
      return item.id !== id;
    });
    this.selectedItems.patchValue(this.withInteger ? [] : newSelectedItems);

    if (AppConstants.filterKeysForSave.includes(this.filterKey)) {
      const newSelectedIds = newSelectedItems?.map((value) => value.id) || null;
      this.filteringService.setSavedIdsByKey(this.filterKey, newSelectedIds);
    }

    if (this.withInteger) {
      this.filterValueChange.emit({
        [this.filterKey]: undefined,
      });
    }

    this.isOpen = newSelectedItems.length > 0;
  }

  private listenToSyncingDropdownDataChanges(urlKey: string): void {
    if (AppConstants.checkSavedFilterKeys.includes(this.filterKey)) {
      this.filteringService
        .select('dropdownData')
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.data = data[urlKey] ? data[urlKey] : [];
        });
    }
  }

  private listenToSearchTermChanges(): void {
    this.searchTerm$
      .pipe(
        map((term) => (typeof term === 'string' ? term : '')),
        filter(
          (term: string) =>
            (term.length > 2 && !isNumeric(term)) ||
            !term.length ||
            (term.length >= 4 && isNumeric(term))
        ),
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((term: string) => {
        if (term) {
          const queryParams = { [this.dropdownConfig?.listQueryParamName]: term };
          const searchParamArray: ParamArray[] = this.getParamArrayForSearch();

          const url = this.withCountry
            ? `/countries/${(this.selectedCountry.value as BaseData).id}/${
                this.dropdownConfig?.listUrl
              }`
            : this.dropdownConfig?.listUrl;

          if (AppConstants.checkSavedFilterKeys.includes(this.filterKey)) {
            this.setDropdownDataForSyncing(url, queryParams, false, searchParamArray);
          } else {
            this.setDropdownData(url, queryParams, true, searchParamArray);
          }
        } else {
          this.data = [];
        }
      });
  }

  private getParamArrayForSearch(): ParamArray[] {
    const paramArray: ParamArray[] = [];

    if (
      this.dropdownConfig.additionalIds &&
      AppConstants.checkSavedFilterKeys.includes(this.filterKey)
    ) {
      this.dropdownConfig.additionalIds.forEach((additionalId) => {
        if (AppConstants.filterKeysForSave.includes(additionalId.filterKey)) {
          const savedValue = this.getSavedFilterValuesByKey(additionalId.filterKey);

          if (savedValue && savedValue.length) {
            paramArray.push({
              key: additionalId.paramName,
              value: savedValue,
            });
          }
        }
      });
    }

    return paramArray;
  }

  private isDropdownDataLoadedByKey(key: string): boolean {
    return this.filteringService.getStateSnapshot().dropdownDataLoaded[key];
  }

  private getLoadedDropdownDataByKey(key: string): BaseData[] {
    return this.filteringService.getStateSnapshot().dropdownData[key];
  }

  private getSavedFilterValuesByKey(key: string): number[] {
    return this.filteringService.getStateSnapshot().dropdownSavedIdsByKey[key];
  }

  private listenCountryValueChanges(): void {
    this.selectedCountry.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isOpen = true;
      this.transformedData = [];
    });
  }

  private getCityRangeValueChanges(): Observable<CityMinimal[]> {
    return (this.withIntegerFormGroup.valueChanges as Observable<DistanceRangeGroupFormValue>).pipe(
      pairwise(),
      filter(
        ([prev, current]: [CityDistanceFilterFormGroupObj, CityDistanceFilterFormGroupObj]) =>
          prev?.name !== current?.name
      ),
      tap(() => {
        const lastSelectedCity = lodashLast<BaseData>(this.selectedItems.value as BaseData[]);
        this.selectedItems.patchValue(lastSelectedCity ? [lastSelectedCity] : [], {
          emitEvent: false,
        });
      }),
      map(() => {
        const {
          id,
          name,
        } = this.withIntegerFormGroup.getRawValue() as CityDistanceFilterFormGroupObj;

        return name ? [{ id, name }] : [];
      })
    );
  }

  private listenDropDownAndDateChange(): void {
    const valueChanges: Observable<BaseData[] | void | DateIntervalPickerValue>[] = [
      this.selectedItems.valueChanges as Observable<BaseData[]>,
      this.outsideEmitter.asObservable(),
    ];

    if (this.withDate) {
      valueChanges.push(
        this.dateIntervalPickerFormGroup.valueChanges as Observable<DateIntervalPickerValue>
      );
    }

    merge(from(valueChanges))
      .pipe(
        mergeAll(),
        tap((data: FilterPayload | void) => {
          if (!this.withInteger && (!data || Array.isArray(data))) {
            this.setIntervalControlStates(data as StatusChangePayload[]);
          }

          if (this.withInteger && data) {
            const city = lodashLast(data as StatusChangePayload[]);
            this.setDateRangeCity(city?.id, city?.name);
          }
        }),
        filter((value: FilterPayload | void) => this.checkValueToEmit(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const selectedItemsValue = this.selectedItems.value as BaseData[];

        if (this.withInteger) {
          const intValue = this.withIntegerFormGroup.get('intValue').value as number;
          this.emitIntegerValue({
            id: +this.withIntegerFormGroup.get('id').value,
            intValue,
          });
        } else if (this.withDate) {
          this.emitWithDate(
            selectedItemsValue,
            this.dateIntervalPickerFormGroup.getRawValue() as DateInterval
          );
        } else {
          if (AppConstants.filterKeysForSave.includes(this.filterKey)) {
            const selectedIds = selectedItemsValue?.map((selectedItem) => selectedItem.id);

            this.filteringService.setSavedIdsByKey(this.filterKey, selectedIds);
            this.setConnectedDropdowns();
          }
          this.emitWithoutDate(selectedItemsValue);
        }
      });
  }

  private setConnectedDropdowns() {
    const queryParams = { name: '' };
    const isPartnerSave = this.filterKey === AppConstants.filterKeysForSave[0];

    if (isPartnerSave) {
      const dropdownKeysForPartnerSave = AppConstants.dropdownKeysForPartnerSave;

      dropdownKeysForPartnerSave.forEach((dropdownKey, index) => {
        const searchParamArray: ParamArray[] = this.getParamArrayForLoadingDropdownData(
          isPartnerSave,
          index === 1
        );

        if (searchParamArray.length) {
          this.setDropdownDataForSyncing(dropdownKey, queryParams, false, searchParamArray);
        } else {
          this.filteringService.setDropdownData(dropdownKey, []);
        }
      });
    } else {
      const searchParamArray: ParamArray[] = this.getParamArrayForLoadingDropdownData(false, true);

      if (searchParamArray.length) {
        this.setDropdownDataForSyncing(
          AppConstants.dropdownKeysForProjectSave,
          queryParams,
          false,
          searchParamArray
        );
      } else {
        this.filteringService.setDropdownData(AppConstants.dropdownKeysForProjectSave, []);
      }
    }
  }

  private getParamArrayForLoadingDropdownData(
    isPartnerSave: boolean,
    setPosition: boolean
  ): ParamArray[] {
    const paramArray: ParamArray[] = [];
    let savedValues: number[][] = [];

    if (setPosition) {
      savedValues = [
        this.getSavedFilterValuesByKey(AppConstants.filterKeysForSave[0]),
        this.getSavedFilterValuesByKey(AppConstants.filterKeysForSave[1]),
      ];
    } else if (!setPosition && isPartnerSave) {
      savedValues = [this.getSavedFilterValuesByKey(this.filterKey)];
    }

    savedValues.forEach((savedValue: number[], index) => {
      if (savedValue && savedValue.length) {
        paramArray.push({
          key:
            index === 0
              ? AppConstants.paramArrayKeyPartnerSave
              : AppConstants.paramArrayKeyProjectsSave,
          value: savedValue,
        });
      }
    });

    return paramArray;
  }

  private setDateRangeCity(id: number, name: string): void {
    this.isDisabled = true;
    this.withIntegerFormGroup.reset({ id, name, intValue: null });
  }

  private checkValueToEmit(value: StatusChangePayload[] | IntervalChangePayload | void): boolean {
    if (value && 'start' in value && 'end' in value) {
      if ((value.start && !value.end) || (!value.start && value.end)) {
        this.isOpen = true;
        return false;
      }
    }

    if (this.withInteger) {
      this.isChecked = this.withIntegerFormGroup.valid;
      this.isDisabled = this.withIntegerFormGroup.invalid;

      if (this.withIntegerFormGroup.get('intValue').pristine) {
        return this.withIntegerFormGroup.valid;
      }
    }

    return true;
  }

  private emitIntegerValue(value: CityDistanceFilter): void {
    this.isChecked = this.withIntegerFormGroup.valid;
    this.filterValueChange.emit({
      [this.filterKey]: this.withIntegerFormGroup.valid ? value : undefined,
    });
  }

  private emitWithDate(selectedItems: BaseData[], dateIntervalPicker: DateInterval) {
    this.selected.next(selectedItems);
    this.idOfSelectedItems = this.mapSelectedItemIds(selectedItems);
    let isObjectsValuesAreEmpty = true;

    Object.keys(dateIntervalPicker).forEach((key) => {
      if (dateIntervalPicker[key]) {
        isObjectsValuesAreEmpty = false;
      }
    });
    if ((!selectedItems || selectedItems.length <= 0) && isObjectsValuesAreEmpty) {
      this.isDisabled = true;
      this.isChecked = false;
      this.filterValueChange.emit({ [this.filterKey]: undefined });
    } else {
      this.isDisabled = false;
      this.isChecked = true;
      const values = this.getFilterStateWithDate();
      this.filterValueChange.emit(values);
    }
  }

  private mapSelectedItemIds(selectedItems: BaseData[]): (number | string)[] {
    if (!selectedItems) {
      return [];
    }

    return selectedItems.map((value) => {
      if (Number.isNaN(+value.id)) {
        return value.id;
      }

      return +value.id;
    });
  }

  private emitWithoutDate(selectedItems: BaseData[]) {
    let idOfArray: (number | string)[];

    if (!(selectedItems == null || selectedItems.length <= 0)) {
      this.isDisabled = false;
      this.isChecked = true;
      idOfArray = this.mapSelectedItemIds(selectedItems);
      this.idOfSelectedItems = idOfArray;
    } else {
      this.isDisabled = true;
      this.isChecked = false;
    }
    this.selected.next(selectedItems);
    this.filterValueChange.emit({ [this.filterKey]: idOfArray });
  }

  private getDateInterval(dateIntervalPicker: DateInterval): DateInterval {
    if (dateIntervalPicker.start && dateIntervalPicker.end) {
      return dateIntervalPicker;
    }
    return {
      start: '',
      end: '',
    };
  }

  private getFilterStateWithDate(): Record<string, DateIntervalWithIds> {
    return {
      [this.filterKey]: {
        ...this.getDateInterval(this.dateIntervalPickerFormGroup.getRawValue() as DateInterval),
        id: this.idOfSelectedItems,
      },
    };
  }

  private getFilterStateWithDistanceRange() {
    return {
      [this.filterKey]: {
        id: +this.withIntegerFormGroup.get('id').value,
        intValue: +this.withIntegerFormGroup.get('intValue').value,
      },
    };
  }

  private setValueForControls(valuesOfControls: DropdownFilteringValue): void {
    if (typeof valuesOfControls !== 'undefined') {
      if (!this.withDate && !this.withInteger) {
        this.setBasicValueForControls(valuesOfControls as DropdownFilteringBaseValue);
      } else if (this.withInteger) {
        this.withIntegerFormGroup.patchValue(valuesOfControls as DropdownFilteringWithIntegerValue);
      } else if (this.withDate) {
        this.setWithDateValueOfControls(valuesOfControls as DropdownFilteringWithDateValue);
      }

      this.cdr.detectChanges();
    } else {
      this.resetFormControls();
    }
  }

  private setBasicValueForControls(valuesOfControls: DropdownFilteringBaseValue) {
    if (this.dropdownConfig.listType === 'search') {
      const url = this.dropdownConfig.listUrl;
      const paramArray: ParamArray[] = [{ key: 'id', value: valuesOfControls }];

      this.setDropdownDataAsync(url, paramArray)
        .pipe(untilDestroyed(this))
        .subscribe((data: BaseData[]) => {
          this.filteringService.setDropdownData(url, data);
          this.filteringService.setDropdownDataLoaded(url, true);
          this.data = data;

          this.selectedItems.setValue(this.filterSelected(valuesOfControls));

          this.cdr.detectChanges();
        });
    } else {
      this.selectedItems.setValue(this.filterSelected(valuesOfControls));
    }
  }

  private setWithDateValueOfControls(valuesOfControls: DropdownFilteringWithDateValue) {
    this.setBasicValueForControls(valuesOfControls.id);

    this.dateIntervalPickerFormGroup.patchValue(valuesOfControls);
  }

  private filterSelected(arrayOfIds: DropdownFilteringBaseValue): Object[] {
    let ids = arrayOfIds;

    if (!Array.isArray(arrayOfIds)) {
      ids = [arrayOfIds];
    }

    return (this.transformedData as BaseData[]).filter((value) => ids.includes(value.id));
  }

  private resetFormControls(): void {
    this.selectedItems.reset();
    if (this.withDate) {
      this.dateIntervalPickerFormGroup.reset();
    }
  }

  private setIntervalControlStates(statuses: StatusChangePayload[]) {
    const options = { emitEvent: false, onlySelf: true };

    if (this.withDate && this.dateIntervalPickerFormGroup) {
      const controls = [
        this.dateIntervalPickerFormGroup.get('start'),
        this.dateIntervalPickerFormGroup.get('end'),
      ];

      if (!Array.isArray(statuses) || statuses.length === 0) {
        this.disableAndResetIntervals(controls, options);
      } else {
        this.enableIntervals(controls, options);
      }
    }

    this.cdr.detectChanges();
  }

  private disableAndResetIntervals(
    controls: AbstractControl[],
    options: { emitEvent: boolean; onlySelf: boolean }
  ) {
    controls.forEach((control) => {
      control.disable(options);
      control.reset(null, options);
    });
    this.hintText = 'candidates.status_select_required_hint';
  }

  private enableIntervals(
    controls: AbstractControl[],
    options: { emitEvent: boolean; onlySelf: boolean }
  ) {
    controls.forEach((control) => {
      control.enable(options);
    });
    this.hintText = '';
  }
}
