<div [ngClass]="{'modal-grid-with-three' : isModalWithThreeColumn}" class="modal-grid">
    <div class="left-column">
        <ng-content select="[left-column]"></ng-content>
    </div>

    <div class="middle-column">
        <ng-content select="[middle-column]"></ng-content>
    </div>

    <div *ngIf="isModalWithThreeColumn" class="right-column">
        <ng-content select="[right-column]"></ng-content>
    </div>
</div>
