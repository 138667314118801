import { BaseData } from '@shared/classes/BaseData';

export interface FilterState {
  letter_filter: string;
  first_letters: Array<string>;
  selected_filter: string;
  selectedFirstLetter: string;

  dropdownData: Record<string, BaseData[]>;
  dropdownDataLoaded: Record<string, boolean>;
  dropdownSavedIdsByKey: Record<string, number[]>;
}

export const filterInitialState: FilterState = {
  letter_filter: null,
  first_letters: [],
  selected_filter: '',
  selectedFirstLetter: '',

  dropdownData: {},
  dropdownDataLoaded: {},
  dropdownSavedIdsByKey: {},
};
