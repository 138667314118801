import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AppConstants } from '@config/app.constant';
import { RegexValidator } from '@shared/classes/RegexValidator';
import { UserApiService } from '@pages/users/services/base/user-api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailValidatorResponse } from '@pages/users/classes/EmailValidatorResponse';

export class FormCustomValidators {
  static isSameValidator<Type = string>(compareField: string) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (!control.parent || !control) {
        return null;
      }

      return control.value === control.parent.get(compareField).value
        ? null
        : { isSame: control.value as Type };
    };
  }

  static shouldBeNotSameAsOldValue<Type = string>(compareField: string) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (!control.parent || !control) {
        return null;
      }
      return control.value === control.parent.get(compareField).value
        ? { shouldBeNotSameAsOldValue: control.value as Type }
        : null;
    };
  }

  static emailValidator(isRequired?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.emailRegex,
        control,
        'email',
        'common.email',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static taxNumberValidator() {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.taxNumberRegex,
        control,
        'taxNumber',
        'common.tax_number'
      ).validate();
    };
  }

  static feorNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.feorNumberRegex,
        control,
        'feorNumber',
        'positions.feor_number'
      ).validate();
    };
  }

  static projectCodeValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.projectCode,
        control,
        'identifier',
        'common.project_code',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static zipCodeValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.zipCodeRegex,
        control,
        'zipCode',
        'common.zip_code',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static phoneNumberValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.phoneRegex,
        control,
        'phone',
        'common.phone_number',
        'format',
        isRequired
      ).validate();
    };
  }

  static omIdentifierValidator(isRequired = true) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.omIdentifierRegex,
        control,
        'omIdentifier',
        'common.om_identifier',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static pensionerIdentifierValidator(isRequired = true) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.pensionerIdentifierRegex,
        control,
        'pensionerIdentifier',
        'common.identifier',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static emailExistsAsyncValidator(usersService: UserApiService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control?.value) {
        return of<null>(null);
      }

      return usersService
        .checkEmailExists({
          email: control.value as string,
        })
        .pipe(
          map((result: EmailValidatorResponse) => {
            return result.isExists ? result : null;
          }),
          catchError(() => {
            return of({
              asyncValidationError: true,
            });
          })
        );
    };
  }

  static backAccountNumberValidator(isRequired?: boolean) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.bankAccountNumberRegex,
        control,
        'backAccountNumber',
        'common.back_account_number',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  static companyRegistrationNumberValidator(isRequired?: boolean) {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.companyRegistrationNumberRegex,
        control,
        'companyRegistrationNumber',
        'common.company_registration_number',
        AppConstants.inputFieldFormatKey,
        isRequired
      ).validate();
    };
  }

  // Csak kis és nagybetűket, valamint pont karaktert fogad fogad el
  static nameValidator() {
    return Validators.pattern('^[A-Za-záéiíóöőúüűÁEÉÍÓÖŐÚÜŰ0-9 ./-]{0,50}$');
  }

  static userNameValidator() {
    return Validators.pattern('^[0-9a-zA-Z.@]{6,30}$');
  }

  static numberValidator() {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return new RegexValidator(
        AppConstants.numberRegex,
        control,
        'number',
        'common.numbers',
        AppConstants.inputFieldTypeKey
      ).validate();
    };
  }

  // minden országra rendszám vizsgálat
  static plateNumberValidator() {
    return Validators.pattern('^[a-zA-Z0-9- ]+$');
  }

  static numberPositiveValidator() {
    return Validators.pattern('^[[1-9]{1}[0-9]*$');
  }
}
