import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInOutTransition } from '@app/animations';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { SideFilterService } from './services/side-filter.service';
import { SideFilterMode } from './classes/SideFilterMode';
import { SideFilterToggleEvent } from './classes/SideFilterToggleEvent';
import { BaseComponent } from '../base-component/base.component';
import { FilterContainerBaseComponent } from '@shared/modules/filter-components/components/base/filter-container-base.component';

@UntilDestroy()
@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.scss'],
  animations: [fadeInOutTransition('0.3s')],
})
export class SideFilterComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isFullWidth = false;
  @Input() slideMode = SideFilterMode.PUSH;
  @Output() toggleMenu = new EventEmitter<SideFilterToggleEvent>();

  currentFilter$: Observable<ComponentType<FilterContainerBaseComponent>>;
  isMenuOpen: boolean;

  constructor(public sideMenuService: SideFilterService) {
    super();
  }

  /**
   * Azért van szükség a return-re, mivel az ős osztályban definiáltuk,
   * hogy kötelező visszatérnie egy adott értékkel. Ezt úgy tudod könnyel elérni,
   * ha meghívod az ős lifecycle hook-ját. Ezzel kényszerítjük ki, hogy az ős
   * lifecycle hook-ja mindig meg legyen hívva. Ellenkező esetben hibát fog dobni.
   */
  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.currentFilter$ = this.sideMenuService.select('currentFilter');
    this.handleSideMenuToggle();
    return superCalled;
  }

  private handleSideMenuToggle() {
    this.sideMenuService
      .select('opened')
      .pipe(untilDestroyed(this))
      .subscribe((isOpen) => {
        this.isMenuOpen = isOpen === true || isOpen === false ? isOpen : !this.isMenuOpen;
        if (this.slideMode === SideFilterMode.PUSH) {
          this.toggleMenu.emit({ isOpened: this.isMenuOpen });
        }
      });
  }
}
