import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { getTranslatePathByPageType } from '@shared/modules/header/utils/header-translate-helper.util';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { TranslationParam } from '@shared/classes/TranslationParam';
import { AppStateService } from '@shared/services/app-state.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { AuthService } from '@shared/modules/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-main-page-header',
  templateUrl: './main-page-header.component.html',
  styleUrls: ['./main-page-header.component.scss'],
})
export class MainPageHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  filteredTotalCount$: Observable<number>;
  isFilteredTotalCountLoading$: Observable<boolean>;
  headerTitle$: Observable<string>;
  headerButtonParams$: Observable<TranslationParam>;
  exportPermissions = [UserPermission.ExportTable];
  filterPermissions = [UserPermission.ManageFilters];
  isButtonShow = true;

  constructor(
    private headerService: HeaderService,
    private appService: AppStateService,
    private auth: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.headerButtonParams$ = this.getHeaderButtonParams();
    this.filteredTotalCount$ = this.getFilteredTotalCount();
    this.isFilteredTotalCountLoading$ = this.getFilteredTotalCountLoading();
    this.headerTitle$ = this.getHeaderTitle();

    this.listenHeaderPermissionsChanges();

    return super.ngOnInit();
  }

  triggerExportClick(): void {
    this.headerService.triggerExportButtonClick();
  }

  triggerNewButtonClick(): void {
    this.headerService.triggerNewItemButtonClick();
  }

  private getHeaderTitle(): Observable<string> {
    return this.headerService.select('title');
  }

  private listenHeaderPermissionsChanges() {
    return this.headerService
      .select('createEntityButtonPermissions')
      .pipe(untilDestroyed(this))
      .subscribe((permissions: UserPermission[]) => {
        this.isButtonShow = AuthUtils.isContainUserPermission(
          permissions,
          this.auth.getUserRoles()
        );
      });
  }

  private getFilteredTotalCount(): Observable<number> {
    return this.headerService.select('filterTotalCount');
  }

  private getFilteredTotalCountLoading(): Observable<boolean> {
    return this.headerService.select('isFilterTotalCountLoading');
  }

  private getHeaderButtonParams(): Observable<TranslationParam> {
    return this.appService.select('currentUrl').pipe(
      map((url: string) => ({
        entity: getTranslatePathByPageType(url),
      }))
    );
  }

  ngOnDestroy() {
    this.headerService.setState({ createEntityButtonPermissions: [] });
    return super.ngOnDestroy();
  }
}
