import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { pairwise, startWith } from 'rxjs/operators';
import { DateInterval } from '@shared/classes/DateInterval';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardFilteringBaseComponent } from '@shared/modules/filter-components/components/card-filtering-base/card-filtering-base.component';
import { TranslateService } from '@ngx-translate/core';
import { DateIntervalPickerValue } from '@shared/modules/filter-components/components/date-interval-picker/classes/DateIntervalPickerValue';

@UntilDestroy()
@Component({
  selector: 'app-date-filtering',
  templateUrl: './date-filtering.component.html',
  styleUrls: ['./date-filtering.component.scss'],
})
export class DateFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  dateIntervalPicker = new FormGroup({
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required]),
  });

  constructor(
    readonly eRef: ElementRef<HTMLElement>,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService
  ) {
    super(eRef, filteringService, translate);
  }

  ngOnInit() {
    this.listenDatePickerValueChange();
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: unknown) => {
        this.setValueForControls(items as DateInterval);
      });
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });
    return super.ngOnInit();
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    if (isChecked) {
      this.filterValueChange.emit({
        [this.filterKey]: this.dateIntervalPicker.getRawValue() as DateIntervalPickerValue,
      });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: undefined });
    }
  }

  deleteItem() {
    this.resetFormControls();
  }

  private listenDatePickerValueChange(): void {
    this.dateIntervalPicker.valueChanges
      .pipe(startWith(this.dateIntervalPicker.value), pairwise(), untilDestroyed(this))
      .subscribe(([, next]: [DateInterval, DateInterval]) => {
        if (next.start && next.end) {
          this.isOpen = true;
        }
        if (this.dateIntervalPicker.valid) {
          this.isDisabled = false;
          this.isChecked = true;

          this.filterValueChange.emit({
            [this.filterKey]: this.dateIntervalPicker.getRawValue() as DateIntervalPickerValue,
          });
        } else {
          this.isDisabled = true;
          this.isChecked = false;
          if (!next.start && !next.end) {
            this.filterValueChange.emit({ [this.filterKey]: undefined });
          }
        }
      });
  }

  private setValueForControls(valueOfControl: DateInterval | undefined): void {
    if (typeof valueOfControl !== 'undefined') {
      this.dateIntervalPicker.patchValue(valueOfControl);
    } else {
      this.resetFormControls();
    }
  }

  private resetFormControls(): void {
    this.dateIntervalPicker.reset();
  }
}
