import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CandidateCustomField } from '@pages/candidates/classes/CandidateCustomField';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CandidateCustomFieldForm,
  CandidateCustomFieldFormValue,
} from '@pages/candidates/classes/CandidateCustomFieldForm';
import { CandidateCustomFieldFormGroup } from '@pages/candidates/classes/CandidateCustomFieldFormGroup';
import { CandidateCustomFieldType } from '@pages/candidates/classes/CandidateCustomFieldType';

@UntilDestroy()
@Component({
  selector: 'app-custom-field-selection-form',
  templateUrl: './custom-field-selection-form.component.html',
  styleUrls: ['./custom-field-selection-form.component.scss'],
})
export class CustomFieldSelectionFormComponent implements OnInit {
  @Input() customFields: CandidateCustomField[];
  @Input() customFieldControls: FormControl;
  @Input() disabled = false;

  /* @property FormGroup<CandidateCustomFieldFormGroup>[] */
  formArray: FormArray = new FormArray([]);

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.listenFormArrayChanges();
    this.preFillForm();
  }

  addNewForm(): void {
    const newGroup = this.generateNewForm();

    newGroup.markAllAsTouched();
    this.formArray.push(newGroup);
  }

  removeForm(index: number): void {
    this.formArray.removeAt(index);
  }

  getFormControl(formGroup: AbstractControl, name: string): FormControl {
    return formGroup.get(name) as FormControl;
  }

  private preFillForm() {
    const customFields = this.customFieldControls.value as CandidateCustomField[];

    if (customFields) {
      customFields.forEach((customField) => {
        const selectedValue = this.getSelectedValue(customField);

        const formData: CandidateCustomFieldFormGroup = {
          fieldTypeId: customField.id,
          fieldTypeValue: selectedValue,
        };

        this.formArray.push(this.generateNewForm(formData));
      });
    }
  }

  private getSelectedValue(customField: CandidateCustomField) {
    if (customField.type == CandidateCustomFieldType.Select) {
      return customField.options[0]?.id;
    } else if (customField.type == CandidateCustomFieldType.Multiselect) {
      return customField.options.map((data) => data.id);
    } else {
      return customField.value;
    }
  }

  private listenFormArrayChanges(): void {
    this.formArray.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((formArrayRawValue: CandidateCustomFieldFormGroup[]) => {
        this.updateCustomFieldsDisabled(formArrayRawValue);

        if (this.formArray.valid) {
          this.mapFormArrayToCustomFieldList();
        } else {
          this.customFieldControls?.setErrors({ unfilled: true });
        }
      });
  }

  private updateCustomFieldsDisabled(formArrayRawValue: CandidateCustomFieldFormGroup[]) {
    const selectedFieldTypes: { [key: string]: boolean } = {};

    formArrayRawValue.forEach((formGroup: CandidateCustomFieldFormGroup) => {
      if (formGroup?.fieldTypeId) {
        selectedFieldTypes[formGroup.fieldTypeId] = true;
      }
    });

    this.customFields = this.customFields.map((option) => ({
      ...option,
      disabled: selectedFieldTypes[option.id],
    }));
  }

  private generateNewForm(formGroupData: CandidateCustomFieldFormGroup = null): FormGroup {
    return this.formBuilder.group({
      fieldTypeId: [
        { value: formGroupData?.fieldTypeId ?? null, disabled: false },
        Validators.required,
      ],
      fieldTypeValue: [
        { value: formGroupData?.fieldTypeValue ?? null, disabled: false },
        Validators.required,
      ],
    });
  }

  private mapFormArrayToCustomFieldList(): void {
    const selectedCustomFields: CandidateCustomFieldForm[] = [];

    this.formArray.controls?.forEach((form: AbstractControl) => {
      if (form.get('fieldTypeValue')?.value && form.get('fieldTypeId')?.value) {
        selectedCustomFields.push(this.mapFormGroupToCustomField(form as FormGroup));
      }
    });

    this.customFieldControls?.setValue(selectedCustomFields);
  }

  /**
   * @param form FormGroup<CandidateCustomFieldFormGroup>
   */
  private mapFormGroupToCustomField(form: FormGroup): CandidateCustomFieldForm {
    const formGroupValue = form.getRawValue() as CandidateCustomFieldFormGroup;

    const fieldType = this.customFields.find((field) => field.id === formGroupValue.fieldTypeId);
    const fieldValue = formGroupValue.fieldTypeValue;

    const customFieldType: CandidateCustomFieldForm = {
      customFieldTypeId: fieldType.id,
      value: null,
      optionIds: null,
    };

    if (fieldType.type === CandidateCustomFieldType.Select) {
      customFieldType.optionIds = [fieldValue as number];
    } else if (fieldType.type === CandidateCustomFieldType.Multiselect) {
      customFieldType.optionIds = fieldValue as number[];
    } else {
      customFieldType.value = fieldValue as CandidateCustomFieldFormValue;
    }

    return customFieldType;
  }
}
