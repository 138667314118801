<ng-template let-value='value'>
    <div (click)="$event.stopPropagation()">
        <app-dot-menu
                class="justify-content-center d-flex"
                [menuItems]="getMenuConfig(value)"
                (dotMenuClicked)="checkSource(value);"
                (clicked)="handleSourceTableDotMenuClick($event,value)">
        </app-dot-menu>
    </div>
</ng-template>
