<div class="tags-autocomplete">
    <app-select
        [ngClass]="dropdownPanelClass"
        customClass="candidate-custom-field-selection"
        [compareWith]="compareSelectedOptions"
        [label]="label"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [options]="allTags"
        [isHintShown]="true"
        [closeOnSelect]="false"
        [required]="isFieldRequired"
        [addTagFn]="onAddTag.bind(this)"
        [searchable]="searchAble"
        [formControl]="control"
        (selectionChange)="onTagSelected($event)">
    </app-select>

    <div *ngIf="selectedTagsFormArray?.length > 0" class="tag-list">
        <div class="d-inline-block" *ngFor="let tag of selectedTagsFormArray.controls; let i = index">
            <app-tag
                class="d-inline-block"
                (deleteItem)="onRemoveTag(i)"
                [id]="tag.get('id').value"
                [value]="tag.get('name').value">
            </app-tag>
        </div>
    </div>
</div>
