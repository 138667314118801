<ng-container>
    <div class="d-flex align-items-center">
        <span class="role-name truncate" [ngClass]="{ 'empty-role-name':isEmptyRole }">{{roleText}}</span>
        <app-hint
            *ngIf="isEmptyRole"
            class="d-block"
            [hintText]="hintText"
            variation="boardItemHint"
            [hintTypes]="[hintTypes.Help]">
        </app-hint>
    </div>
</ng-container>

