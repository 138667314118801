import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SharedModule } from '@shared/shared.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { InlineSVGModule } from 'ng-inline-svg';
import { HintModule } from '@shared/modules/hint/hint.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { DataTableComponent } from './data-table.component';
import { SmallImageDatatableCellComponent } from './components/data-table-cells/small-image-table-cell/small-image-datatable-cell.component';
import { CheckboxTableCellComponent } from './components/data-table-cells/checkbox-table-cell/checkbox-table-cell.component';
import { CheckboxTableHeaderComponent } from './components/data-table-headers/checkbox-table-header/checkbox-table-header.component';
import { SwitchTableCellComponent } from './components/data-table-cells/switch-table-cell/switch-table-cell.component';
import { MultiplicityTableCellComponent } from './components/data-table-cells/multiplicity-table-cell/multiplicity-table-cell.component';
import { TooltipTableCellComponent } from './components/data-table-cells/tooltip-table-cell/tooltip-table-cell.component';
import { IconTableCellComponent } from './components/data-table-cells/icon-table-cell/icon-table-cell.component';
import { IconTableCellInsideComponent } from './components/data-table-cells/icon-table-cell/icon-table-cell-inside/icon-table-cell-inside.component';
import { DataTableRowListenerDirective } from './directives/data-table-row-listener.directive';
import { OpenInNewTabDirective } from './directives/open-in-new-tab.directive';
import { DotMenuModule } from '@shared/modules/dot-menu/dot-menu.module';
import { SourceTableMenuCellComponent } from '@pages/base-data/components/base-data-sources/source-table-menu-cell/source-table-menu-cell.component';
import { StatusSelectionTableCellComponent } from './components/data-table-cells/status-selection-table-cell/status-selection-table-cell.component';
import { CardTableModule } from '@shared/modules/card-table/card-table.module';
import { CustomCellTemplateDirective } from './directives/custom-cell-template.directive';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { DataTableInlineEditModule } from '@shared/modules/data-table-inline-edit/data-table-inline-edit.module';

@NgModule({
  declarations: [
    DataTableComponent,
    SmallImageDatatableCellComponent,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    SwitchTableCellComponent,
    MultiplicityTableCellComponent,
    TooltipTableCellComponent,
    IconTableCellComponent,
    IconTableCellInsideComponent,
    DataTableRowListenerDirective,
    OpenInNewTabDirective,
    SourceTableMenuCellComponent,
    StatusSelectionTableCellComponent,
    CustomCellTemplateDirective,
  ],
  imports: [
    FormComponentsModule,
    CommonModule,
    NgxDatatableModule,
    DirectivesModule,
    SharedModule,
    PipesModule,
    TippyModule,
    InlineSVGModule,
    HintModule,
    DotMenuModule,
    CardTableModule,
    LoaderModule,
    DataTableInlineEditModule,
  ],
  exports: [
    DataTableComponent,
    SmallImageDatatableCellComponent,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    SwitchTableCellComponent,
    MultiplicityTableCellComponent,
    TooltipTableCellComponent,
    IconTableCellComponent,
    OpenInNewTabDirective,
    SourceTableMenuCellComponent,
    StatusSelectionTableCellComponent,
  ],
})
export class DataTableModule {}
