import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { getBasicInputLengthValidators } from '@shared/utils/form/form-input-length-validator.util';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { PositionBulkUpdateState } from '@pages/positions/classes/PositionBulkUpdateState';
import { Contact } from '@pages/partners/classes/Contact';

@Injectable({
  providedIn: 'root',
})
export class PositionFormService {
  constructor(
    public readonly atsConfigService: ATSConfigService,
    private formBuilder: FormBuilder
  ) {}

  initForm(detail?: PositionDetail): FormGroup {
    const group = this.formBuilder.group({
      id: [detail?.id || null],
      name: [detail?.name || null, getBasicInputLengthValidators(true)],
      code: [
        {
          value: detail?.code,
          disabled: true,
        },
      ],
      status: [detail?.status || null, [Validators.required]],
      projectId: [
        { value: detail?.project?.id || null, disabled: !!detail },
        [Validators.required],
      ],
      jobType: this.formBuilder.group({
        typeId: [detail?.jobType?.id || null, [Validators.required]],
        subTypeId: [detail?.jobSubType?.id || null],
      }),
      employmentTypeId: [detail?.employmentType?.id || null, [Validators.required]],
      specializations: this.getIdArray(detail?.specializations, true),
      projectManagers: this.getProjectManagerArray(detail?.positionProjectManagers || []),
    });

    if (this.atsConfigService.isLaborHireATS) {
      group.addControl(
        'type',
        new FormControl(
          {
            value: detail?.type || null,
            disabled: !!detail,
          },
          [Validators.required]
        )
      );
    }

    return group;
  }

  initBulkEditForm(bulkUpdateState: PositionBulkUpdateState) {
    let status;

    if (bulkUpdateState?.status) {
      status = { key: bulkUpdateState?.status?.name, value: bulkUpdateState?.status?.id };
    }

    return this.formBuilder.group({
      status: [status || null],
      projectId: [bulkUpdateState?.projectId || null],
      jobType: this.formBuilder.group({
        typeId: [bulkUpdateState?.jobTypeId?.id || null],
        subTypeId: [bulkUpdateState?.jobSubTypeId?.id || null],
      }),
      employmentTypeId: [bulkUpdateState?.employmentTypeId || null],
      specializations: this.getIdArray(bulkUpdateState?.specializations, false),
    });
  }

  private getIdArray(data?: Partial<{ id: number }>[], isRequired: boolean = false) {
    const array = Array.isArray(data) && data.length >= 1 ? data : [null];

    return this.formBuilder.array(
      array.map((item) => {
        return this.formBuilder.group({
          id: [item?.id || null, isRequired ? [Validators.required] : []],
        });
      })
    );
  }

  getProjectManagerArray(contacts?: Contact[]): FormArray {
    if (Array.isArray(contacts) && contacts.length > 0) {
      return this.formBuilder.array(
        contacts.map((contact) => this.getProjectManagerGroup(contact))
      );
    } else {
      return this.formBuilder.array([this.getProjectManagerGroup()]);
    }
  }

  getProjectManagerGroup(projectManager?: Contact): FormGroup {
    return this.formBuilder.group({
      id: [projectManager?.id || null, Validators.required],
    });
  }
}
