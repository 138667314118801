import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { MatModalModule } from '@shared/modules/mat-modal/mat-modal.module';
import { UploadUserAvatarModalComponent } from './upload-user-avatar-modal/upload-user-avatar-modal.component';
import { ManageUserModalComponent } from './manage-user-modal/manage-user-modal.component';
import { UserModalRoleChangeComponent } from './manage-user-modal/user-modal-role-change/user-modal-role-change.component';

@NgModule({
  declarations: [
    UploadUserAvatarModalComponent,
    ManageUserModalComponent,
    UserModalRoleChangeComponent,
  ],
  imports: [CommonModule, FormComponentsModule, ReactiveFormsModule, LoaderModule, MatModalModule],
})
export class UserModalsModule {}
