import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

@Injectable()
export abstract class StateService<T> {
  private state$: BehaviorSubject<T>;

  protected constructor(initialState?: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  getState(): Observable<T> {
    return this.state$.asObservable();
  }

  getStateSnapshot(): T {
    return this.state$.getValue();
  }

  select<K extends keyof T>(key: K): Observable<T[K]> {
    return this.getState().pipe(pluck(key), distinctUntilChanged());
  }

  setState(stateSlice: Partial<T>): void {
    const newState = { ...this.getStateSnapshot(), ...stateSlice };
    this.state$.next(newState);
  }
}
