import { Component, Input } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';

@Component({
  selector: 'app-text-value-cell',
  templateUrl: './text-value-cell.component.html',
  styleUrls: ['./text-value-cell.component.scss'],
})
export class TextValueCellComponent extends CardTableCustomCellBaseComponent {
  @Input() path: string;
}
