import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';
import { Observable } from 'rxjs';
import { CreateTableViewDto } from '@shared/modules/custom-selectors/custom-table-view-selector/classes/CreateTableViewDto';
import {
  TableView,
  TableViewColumn,
} from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableView';
import { TableNames } from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableNames';

@Injectable({
  providedIn: 'root',
})
export class TableViewApiService {
  constructor(private http: HttpService) {}

  getTableViews(table: TableNames): Observable<TableView[]> {
    return this.http.get(EndpointsConfig.tableViewsByTableName(table));
  }

  getTableViewColumns(table: TableNames): Observable<TableViewColumn[]> {
    return this.http.get(EndpointsConfig.tableColumnsByTableName(table));
  }

  createTableView(tableView: CreateTableViewDto): Observable<TableView> {
    return this.http.post(EndpointsConfig.tableViews, tableView);
  }

  editTableView(tableView: CreateTableViewDto, viewId: number): Observable<TableView> {
    return this.http.put(EndpointsConfig.tableViewEditById(viewId), tableView);
  }

  deleteTableView(viewId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.tableViewDeleteById(viewId));
  }

  getDefaultTableViewColumns(table: TableNames): Observable<TableViewColumn[]> {
    return this.http.get(EndpointsConfig.defaultTableColumnsByTableName(table));
  }
}
