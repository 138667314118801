import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  filterInitialState,
  FilterState,
} from '@shared/modules/state-manager/state/filters/filter.state';
import { Observable, Subject } from 'rxjs';
import { map, skip, startWith } from 'rxjs/operators';
import { decodeBase64 } from '@shared/utils/encrypt.util';
import { get } from 'lodash-es';
import { BaseData } from '@shared/classes/BaseData';

@Injectable({
  providedIn: 'root',
})
export class FilteringService extends StateService<FilterState> {
  private resetFilters$: Subject<void> = new Subject<void>();

  constructor() {
    super(filterInitialState);
  }

  triggerResetFilter(): void {
    this.resetFilters$.next();
  }

  onResetFilter(): Observable<void> {
    return this.resetFilters$.asObservable();
  }

  setSelectedFilterState(selectedFilter: string) {
    this.setState({ selected_filter: selectedFilter });
  }

  getSavedFilterAsync(filterKey: string): Observable<unknown> {
    return this.select('selected_filter').pipe(
      skip(1),
      startWith(this.getStateSnapshot().selected_filter),
      map((filter: string) => {
        return this.getDecodedFilter(filter);
      }),
      map((decodedFilter: Object) => {
        return get(decodedFilter, filterKey) as unknown;
      })
    );
  }

  getSavedFilter(filterKey: string): unknown {
    const filter = this.getStateSnapshot().selected_filter;
    const filterObj = this.getDecodedFilter(filter) as unknown;

    return get(filterObj, filterKey);
  }

  setDropdownData(key: string, data: BaseData[], isSearchData?: boolean): void {
    let dropdownData: Record<string, BaseData[]> = {
      ...this.getStateSnapshot().dropdownData,
    };

    if (key in dropdownData) {
      if (isSearchData) {
        const filteredData = data.filter((value) => {
          return !dropdownData[key].find((dD) => dD.id === value.id);
        });
        dropdownData[key] = [...dropdownData[key], ...filteredData];
      } else {
        dropdownData[key] = data;
      }
    } else {
      dropdownData = { ...dropdownData, [key]: data };
    }

    this.setState({ dropdownData });
  }

  setDropdownDataLoaded(key: string, loaded: boolean): void {
    let dataLoaded: Record<string, boolean> = {
      ...this.getStateSnapshot().dropdownDataLoaded,
    };
    if (key in dataLoaded) {
      dataLoaded[key] = loaded;
    } else {
      dataLoaded = { ...dataLoaded, [key]: loaded };
    }

    this.setState({ dropdownDataLoaded: dataLoaded });
  }

  setSavedIdsByKey(key: string, values: number[]) {
    let savedIds: Record<string, number[]> = {
      ...this.getStateSnapshot().dropdownSavedIdsByKey,
    };

    if (key in savedIds) {
      savedIds[key] = values;
    } else {
      savedIds = { ...savedIds, [key]: values };
    }

    this.setState({ dropdownSavedIdsByKey: savedIds });
  }

  getDecodedFilter(filter: string): Object {
    if (filter) {
      const decodedFilter = decodeBase64(filter);

      return JSON.parse(decodedFilter) as Object;
    }
    return null;
  }
}
