import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country } from '@shared/classes/Country';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { City } from '@shared/classes/City';
import { AppStateService } from '@shared/services/app-state.service';
import { CountrySettlementApiService } from '@shared/modules/selections/country-settlement-selection/services/country-settlement-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import compact from 'lodash-es/compact';
import { DropdownPosition } from '@ng-select/ng-select';
import { ModalType } from '@shared/classes/ModalType';

@UntilDestroy()
@Component({
  selector: 'app-country-settlement-selection',
  templateUrl: './country-settlement-selection.component.html',
  styleUrls: ['./country-settlement-selection.component.scss'],
})
export class CountrySettlementSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: FormGroup;
  @Input() existingCity: City;
  @Input() showStreetControl = true;
  @Input() controlNameConfig = {
    cityId: 'cityId',
    street: 'street',
  };
  @Input() dropdownPosition: DropdownPosition;
  @Input() contactType: ModalType;
  @Input() isCountryRequired = true;
  @Input() isCityClearable = false;
  @Input() isZipCodeRequired = false;
  @Input() disabled: boolean;

  countries$: Observable<Country[]>;
  cities: City[] = [];
  cityNameChange$ = new Subject<string>();
  zipCodeControl: FormControl;
  countryControl: FormControl;
  cityControl: FormControl;
  streetControl: FormControl;
  countryIdControl: FormControl;
  citiesHintText = 'common.multiple_settlements_select';
  isCityHintShown = false;

  constructor(
    private appService: AppStateService,
    private settlementService: CountrySettlementApiService,
    private toast: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (changes.disabled?.currentValue) {
        this.countryControl?.disable();
      } else {
        this.countryControl?.enable();
      }
    }

    if (changes.isCountryRequired || changes.isZipCodeRequired) {
      this.updateValidatorsForControls();
    }
  }

  ngOnInit(): void {
    this.zipCodeControl = new FormControl(
      this.existingCity?.zipCode || null,
      this.contactType !== ModalType.UserContact && this.isZipCodeRequired
        ? [Validators.required, FormCustomValidators.zipCodeValidator(false)]
        : [FormCustomValidators.zipCodeValidator(false)]
    );
    this.countryControl = new FormControl(
      {
        value: this.existingCity?.countryId || null,
        disabled: this.disabled,
      },
      this.contactType !== ModalType.UserContact && this.isCountryRequired
        ? [Validators.required]
        : []
    );

    if (this.existingCity) {
      this.cities = [...this.cities, this.existingCity];
    }

    this.countries$ = this.appService.getCountries();
    this.cityControl = this.group.get(this.controlNameConfig.cityId) as FormControl;
    this.streetControl = this.group.get(this.controlNameConfig.street) as FormControl;
    this.countryIdControl = this.group.get('countryId') as FormControl;

    this.countryControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: Country) => {
      if (value && value.id) {
        const { id: countryId } = value;
        const controlsToReset = [this.zipCodeControl, this.cityControl, this.streetControl];

        compact(controlsToReset).forEach((control) => {
          control.setValue(null);
          control.updateValueAndValidity();
          control.markAllAsTouched();
        });
        this.cities = [];

        if (this.countryIdControl) {
          this.countryIdControl.setValue(countryId);
        }
      }
    });

    this.zipCodeControl.valueChanges
      .pipe(
        filter((zipCode: string) => this.isZipCodeValid(zipCode)),
        debounceTime(300),
        tap(() => {
          this.cities = [];
          this.isCityHintShown = false;
          this.cityControl.setValue(null);
        }),
        switchMap((zipCode: string) => {
          const countryId =
            typeof this.countryControl.value === 'number'
              ? this.countryControl.value
              : (this.countryControl.value as Country).id;
          return this.settlementService.getCitiesByZipCode(countryId, zipCode).pipe(
            catchError(() => {
              this.toast.showError('common.connection_failure');
              return of([]);
            })
          );
        }),
        tap((cities: City[]) => {
          if (Array.isArray(cities) && cities.length >= 1) {
            const { id } = cities[0];
            this.cityControl.setValue(id);
            this.isCityHintShown = cities.length > 1;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((cities) => (this.cities = cities));

    this.cityNameChange$
      .asObservable()
      .pipe(
        filter((cityName: string) => this.isCityNameValid(cityName)),
        debounceTime(300),
        tap(() => {
          this.isCityHintShown = false;
        }),
        switchMap((cityName: string) => {
          const countryId =
            typeof this.countryControl.value === 'number'
              ? this.countryControl.value
              : (this.countryControl.value as Country).id;
          return this.settlementService.getCitiesByName(countryId, cityName).pipe(
            catchError(() => {
              this.toast.showError('common.connection_failure');
              return of([]);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((cities) => {
        this.cities = cities;
      });
  }

  private updateValidatorsForControls(): void {
    let validator = Validators.nullValidator;

    if (this.isCountryRequired && this.isZipCodeRequired) {
      validator = Validators.required;
    }

    [(this.countryControl, this.zipCodeControl)].forEach((control) => {
      control?.setValidators(validator);
      control?.updateValueAndValidity();
    });

    this.zipCodeControl?.addValidators(FormCustomValidators.zipCodeValidator(false));
    this.zipCodeControl?.updateValueAndValidity();
  }

  onCitySelectionChange(city: City) {
    if (!city && this.isCityClearable) {
      this.countryControl.setValue(null);
      this.cityControl.setValue(null);
      this.zipCodeControl.setValue(null);
      return;
    }

    this.zipCodeControl.setValue(city.zipCode, { emitEvent: false });
  }

  private isZipCodeValid(zipCode: string) {
    return this.countryControl.value !== null && !!zipCode && this.zipCodeControl.valid;
  }

  private isCityNameValid(cityName: string) {
    return (
      this.countryControl.value !== null &&
      (cityName || '').length >= AppConstants.cityNameMinInputLength
    );
  }

  ngOnDestroy(): void {
    this.cityNameChange$.complete();
  }
}
