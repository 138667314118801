import { Address } from '@shared/classes/Address';

export function getAddressString(address: Address): string {
  if (!address) {
    return '';
  }

  const zipCode = address?.city?.zipCode ?? '';
  const cityName = address?.city?.name ?? '';
  const street = address?.street ?? '';

  return `${zipCode} ${cityName}, ${street}`;
}
