import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { ToastBaseComponent } from './components/toast-base/toast-base.component';
import { SimpleToastMessageComponent } from './components/simple-toast-message/simple-toast-message.component';

@NgModule({
  declarations: [ToastBaseComponent, SimpleToastMessageComponent],
  imports: [CommonModule, InlineSVGModule, TranslateModule],
})
export class ToastModule {}
