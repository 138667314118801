import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsStatusIndicatorComponent } from '@shared/modules/sms-status-indicator/sms-status-indicator.component';
import { HintModule } from '@shared/modules/hint/hint.module';
import { TranslateModule } from '@ngx-translate/core';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
  declarations: [SmsStatusIndicatorComponent],
  imports: [CommonModule, HintModule, TranslateModule, TippyModule],
  exports: [SmsStatusIndicatorComponent],
})
export class SmsStatusIndicatorModule {}
