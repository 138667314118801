import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CandidatePositionStatusOption } from '@pages/candidates/classes/CandidatePositionStatusOption';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { find, flatten, last } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CandidateModalService } from '@pages/candidates/services/candidate-modal.service';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { BoardColumnSlugs } from '@pages/positions/classes/BoardColumnSlugs';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { TermsAndConditionsTypes } from '@pages/candidates/classes/CandidateDetail';

@UntilDestroy()
@Component({
  selector: 'app-status-selection',
  templateUrl: './status-selection.component.html',
  styleUrls: ['./status-selection.component.scss'],
})
export class StatusSelectionComponent implements OnInit, OnChanges {
  @Input() positionId: number;
  @Input() candidateId: number;
  @Input() userRoles: MultiUserRole[];
  @Input() positionType: PositionTypes;
  @Input() editable: boolean;
  @Input() appendTo: string;
  @Input() status: CandidatePositionStatusOption;
  @Input() statusOption: CandidatePositionStatusOption;
  @Input() hasTermsAndConditions: TermsAndConditionsTypes;

  @Input() middleSize: boolean = false;
  @Input() smallSize: boolean = false;

  @Input() statuses: BoardColumn[];

  statusControl: FormControl;
  ngSelectClass: string;
  isLoading = false;
  hintText: string;
  isDisabled: boolean = false;

  statusesSelectable: BoardColumn[] = [];
  private previousStatusId: number;

  appSelectCustomClass: string;
  notEditableLabelClass: string;

  readonly CONTACT_VALID_TERM_STATUSES = [BoardColumnSlugs.Outsourced, BoardColumnSlugs.Admitted];

  constructor(
    private candidateState: CandidateStateService,
    private candidateService: CandidatesService,
    private candidateModalService: CandidateModalService,
    private positionState: PositionStateService
  ) {}

  ngOnInit(): void {
    this.statusControl = new FormControl(this.status?.id);
    this.ngSelectClass = this.getNgSelectClass(this.statusControl?.value as number);
    this.isDisabled = this.positionState.getStateSnapshot().isBoardDisabled;
    this.hintText = this.statusOption?.name;
    this.previousStatusId = this.status?.id;

    this.initLabelClass();
    this.initAppSelectCustomClass();
    this.listenIsLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusOption) {
      this.hintText = this.statusOption?.name;
    }

    if (changes.status) {
      this.statusesSelectable = this.filterStatusesOnConditions(this.statuses);
      this.statusControl?.patchValue(this.status?.id);
      this.ngSelectClass = this.getNgSelectClass(this.statusControl?.value as number);
    }
  }

  private initLabelClass() {
    if (this.middleSize) {
      this.notEditableLabelClass = 'not-editable-placeholder-middle-size';
    } else if (this.smallSize) {
      this.notEditableLabelClass = 'not-editable-placeholder-small-size';
    } else {
      this.notEditableLabelClass = 'not-editable-placeholder-default';
    }
  }

  private initAppSelectCustomClass() {
    if (this.middleSize) {
      this.appSelectCustomClass = 'ng-select-middle-size';
    } else if (this.smallSize) {
      this.appSelectCustomClass = 'ng-select-small-size';
    } else {
      this.appSelectCustomClass = 'ng-select-default-size';
    }
  }

  private filterStatusesOnConditions(statuses: BoardColumn[]): BoardColumn[] {
    const filteredStatuses: BoardColumn[] = [];

    if (!Array.isArray(statuses)) {
      return [];
    }

    for (const status of statuses) {
      const isCurrentStatus = this.status.id === status.id;
      const invalidStatus =
        this.statusInvalidForPositionType(status) || this.statusInvalidForContact(status);

      if (!invalidStatus || isCurrentStatus) {
        filteredStatuses.push({
          ...status,
          disabled: invalidStatus,
        });
      }
    }

    return filteredStatuses;
  }

  private statusInvalidForPositionType(status: BoardColumn) {
    return status.positionType !== null && status.positionType !== this.positionType;
  }

  private statusInvalidForContact(status: BoardColumn) {
    const hasContactRole = this.userRoles?.some(
      (role) => role.name === UserRole.UserContact || role.name === UserRole.PhoneContact
    );

    return (
      this.hasTermsAndConditions === TermsAndConditionsTypes.PrivacyEmpty &&
      hasContactRole &&
      this.CONTACT_VALID_TERM_STATUSES.includes(status.slug)
    );
  }

  handlePositionStatusChange(selectedItem: Object) {
    const status = selectedItem as BoardColumn;

    if (!this.editable || this.isDisabled || !find(this.statusesSelectable, { id: status.id })) {
      return;
    }

    this.candidateState.setState({
      positionSelectionLoading: { positionId: this.positionId, candidateId: this.candidateId },
    });
    this.positionState.setState({
      selectedKanbanBoardColumn: status,
    });

    if (status.slug === BoardColumnSlugs.Outsourced) {
      this.candidateModalService
        .openOutsourcedModal(this.positionId, status.positionType, this.candidateId, this.userRoles)
        .subscribe((result) => this.handleUpdateResult(result));
    } else if (status.slug === BoardColumnSlugs.Denied) {
      this.positionState.setState({
        selectedPositionType: this.positionType,
      });

      this.candidateModalService
        .openRejectReasonModal(this.positionId, this.candidateId, this.userRoles)
        .subscribe((result) => this.handleUpdateResult(result));
    } else {
      this.candidateService
        .callUpdateCandidatePosition(
          this.positionId,
          {
            statusId: status.id,
            statusOptionId: null,
          },
          this.candidateId,
          this.userRoles
        )
        .subscribe((result) => this.handleUpdateResult(result));
    }
  }

  private handleUpdateResult(result: BoardCandidate | HttpErrorResponse): void {
    if (!result || result instanceof HttpErrorResponse) {
      this.rollbackStatus();
    } else {
      this.updateSelectedOption(result);
    }
    this.candidateState.setState({ positionSelectionLoading: null });
  }

  private listenIsLoading(): void {
    this.candidateState
      .select('positionSelectionLoading')
      .pipe(
        map((position) => {
          return (
            position?.positionId === this.positionId && position?.candidateId === this.candidateId
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
  }

  private updateSelectedOption(newPosition: BoardCandidate): void {
    const options = flatten(this.statusesSelectable.map((status) => status.options));
    const foundOption = find(options, { id: newPosition.optionId });
    this.hintText = foundOption?.name || '';
    this.ngSelectClass = this.getNgSelectClass(this.statusControl.value as number);
    this.previousStatusId = newPosition.statusId;
  }

  private rollbackStatus(): void {
    this.statusControl.setValue(this.previousStatusId);
    this.ngSelectClass = this.getNgSelectClass(this.statusControl.value as number);
  }

  private getNgSelectClass(statusId: number): string {
    if (Array.isArray(this.statusesSelectable)) {
      const hasCandidateRole = this.userRoles?.some((role) => role.name === UserRole.Candidate);

      if (statusId === last(this.statusesSelectable)?.id) {
        return 'rejected';
      }

      if (hasCandidateRole) {
        const penultimateStatus = this.statusesSelectable[this.statusesSelectable.length - 2];

        if (
          statusId === penultimateStatus?.id &&
          this.CONTACT_VALID_TERM_STATUSES.includes(penultimateStatus?.slug)
        ) {
          return 'approved';
        }
      }
    }

    return '';
  }
}
