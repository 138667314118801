import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DataTableInlineEditCellBaseComponent } from '@shared/modules/data-table-inline-edit/components/data-table-inline-edit-cell-base.component';

@Component({
  selector: 'app-inline-edit-cell',
  templateUrl: './inline-edit-cell.component.html',
  styleUrls: ['./inline-edit-cell.component.scss'],
})
export class InlineEditCellComponent extends DataTableInlineEditCellBaseComponent {
  @ViewChild('dataDiv') dataDiv: ElementRef<HTMLDivElement>;

  @Output() opened = new EventEmitter<void>();

  appendTo: string = 'body';

  constructor(protected injector: Injector, private renderer2: Renderer2) {
    super(injector);
  }

  onDropdownOpen() {
    this.opened.emit();

    // when using appendTo, we need to add our class manually to "ng-dropdown-panel"
    // because the "ng-dropdown-panel" won't be in the ng-select element anymore,
    //    (that originally has the class we can reference)
    // so this is the only way to style the dropdown element
    setTimeout(() => {
      if (this.appendTo) {
        this.renderer2.addClass(document.querySelector('ng-dropdown-panel'), 'base-dropdown-panel');
      }
    });
  }
}
