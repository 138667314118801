import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appImgFallback]',
})
export class ImgFallbackDirective {
  @Input() appImgFallback: string;

  constructor(private eRef: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  loadFallbackOnError() {
    this.eRef.nativeElement.src = this.appImgFallback;
  }
}
