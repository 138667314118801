<app-modal-frame [data]="data" [dialogRef]="dialogRef" [hideHeader]="true">

    <ng-container content>
        <div class="confirm-header">
            {{ confirmHeaderText }}
        </div>
        <div *ngFor="let item of updatableItems">
            <p class="basic-black-normal-text mb-0">
                {{'common.new' | translate | appFirstLetterUppercase}}
                {{getTranslateKey(item.name) | translate | appFirstLetterUppercase}}
            </p>
            <p class="basic-blue-normal-text white-space-text">{{item.selected}}</p>
        </div>
    </ng-container>

    <app-modal-footer
        footer
        [primaryButtonDisabled]="isProcessing"
        (primaryButtonClicked)="saveBulkEditData()"
        [dialogRef]="dialogRef">
    </app-modal-footer>

</app-modal-frame>
