import { Component, Injector, OnInit } from '@angular/core';
import { PositionFormService } from '@pages/positions/services/details/base/position-form.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, merge } from 'rxjs';
import { KeyValue } from '@shared/classes/KeyValue';
import { entries } from 'lodash-es';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { AppStateService } from '@shared/services/app-state.service';
import { startWith, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { PositionsService } from '@pages/positions/services/details/positions.service';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { JobType } from '@pages/candidates/classes/JobType';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { PartnerStateService } from '@pages/partners/services/base/partner-state.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { Contact } from '@pages/partners/classes/Contact';
import { UserType } from '@pages/users/classes/UserType';
import { UserApiService } from '@pages/users/services/base/user-api.service';

@UntilDestroy()
@Component({
  selector: 'app-manage-position',
  templateUrl: './manage-position-modal.component.html',
  styleUrls: ['./manage-position-modal.component.scss'],
})
export class ManagePositionModalComponent extends MatModalBaseComponent implements OnInit {
  form: FormGroup;
  statuses: KeyValue<string>[];
  jobTypes: JobType[] = [];
  specializations: Specialization[] = [];
  specializationGroup: FormArray;
  employmentTypes: EmploymentType[] = [];
  partners: PartnerDropdownItem[] = [];
  partnerControl = new FormControl(null, [Validators.required]);
  projectNameControl: FormControl;
  partnerNameControl: FormControl;
  projectIdControl: FormControl;
  jobTypeGroup: FormGroup;
  isEditMode: boolean;
  isLaborHireATS = false;
  positionTypes: KeyValue<string>[];
  projectManagers: Contact[] = [];
  projectManagerArray: FormArray;

  modalTitle: string;

  constructor(
    private positionFormService: PositionFormService,
    private positionApiService: PositionsApiService,
    private appService: AppStateService,
    private positionService: PositionsService,
    private positionState: PositionStateService,
    private partnerState: PartnerStateService,
    protected injector: Injector,
    protected usersApiService: UserApiService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loading = true;
    this.isLaborHireATS = this.positionFormService.atsConfigService.isLaborHireATS;

    this.loadDependencies();
  }

  private loadDependencies() {
    const dependencies = {
      partners: this.positionApiService.getPartnersForDropdown().pipe(take(1)),
      employmentTypes: this.appService.getEmploymentTypes().pipe(take(1)),
      specializations: this.appService.getSpecializations().pipe(take(1)),
      jobTypes: this.appService.getJobTypes().pipe(take(1)),
      projectManagers: this.usersApiService.getUsersByType(UserType.ProjectManager).pipe(take(1)),
    };

    if (this.isLaborHireATS) {
      Object.assign(dependencies, {
        positionTypes: this.positionApiService.getPositionTypes().pipe(take(1)),
      });
    }

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(
        ({
          partners,
          employmentTypes,
          specializations,
          jobTypes,
          positionTypes,
          projectManagers,
        }) => {
          const { positionDetail } = this.positionState.getStateSnapshot();
          this.isEditMode = !!positionDetail;
          this.modalTitle = this.isEditMode ? 'positions.edit' : 'positions.new';
          this.form = this.positionFormService.initForm(positionDetail);
          this.specializationGroup = this.form.get('specializations') as FormArray;
          this.projectManagerArray = this.form.get('projectManagers') as FormArray;

          this.projectIdControl = this.form.get('projectId') as FormControl;
          this.jobTypeGroup = this.form.get('jobType') as FormGroup;
          this.statuses = entries(PositionStatus).map(([key, value]) => ({
            key: this.translateService.instant(`positions.statuses.${key.toLowerCase()}`) as string,
            value,
          }));

          this.employmentTypes = employmentTypes;
          this.specializations = specializations;
          this.jobTypes = jobTypes;
          this.partners = partners;
          this.positionTypes = positionTypes || [];
          this.projectManagers = projectManagers || [];

          const { partnerDetail, projectDetail } = this.partnerState.getStateSnapshot();
          const partnerId = this.findPartnerId(positionDetail, partnerDetail);
          this.partnerControl = new FormControl(
            {
              value: partnerId || null,
              disabled: !!positionDetail,
            },
            [Validators.required]
          );

          if (projectDetail) {
            const projectIdControl = this.form.get('projectId');
            projectIdControl.setValue(projectDetail.id);
            projectIdControl.disable();
            this.partnerControl.disable();
          }

          this.setPartnerProjectReadOnlyFields(positionDetail);
          this.listenFormChanges();

          this.primaryButtonDisabled = this.form.invalid;
          this.loading = false;
        }
      );
  }

  private setPartnerProjectReadOnlyFields(positionDetail: PositionDetail) {
    if (positionDetail) {
      this.partnerNameControl = new FormControl({
        value: positionDetail.partner.name,
        disabled: true,
      });
      this.projectNameControl = new FormControl({
        value: positionDetail.project.name,
        disabled: true,
      });
    }
  }

  private findPartnerId(positionDetail: PositionDetail, partnerDetail: PartnerDetail): number {
    return positionDetail?.partner?.id || partnerDetail?.id || null;
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), untilDestroyed(this))
      .subscribe(() => {
        this.primaryButtonDisabled = this.form.invalid;
      });
  }

  private extractIdsFromControls(controls: { id: number }[]): number[] {
    return (controls || []).filter((control) => control.id).map((control) => control.id);
  }

  savePosition() {
    this.primaryButtonDisabled = true;
    const positionDto = this.form.getRawValue() as PositionDto;

    const projectManagerControls = this.form.get('projectManagers')?.value as { id: number }[];
    positionDto.projectManagers = this.extractIdsFromControls(projectManagerControls);

    if (this.isEditMode) {
      this.positionService
        .callUpdatePosition(positionDto)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    } else {
      this.positionService
        .callCreatePosition(positionDto)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    }
  }
}
