<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'positions.board.reason_title'"
        [iconUrl]="'assets/image/reject.svg'">
    </app-modal-header>

    <div content>
        <div *ngIf="!loading" class="modal-base h-auto">
            <app-form-field>
                <app-select
                    label="positions.board.reason"
                    bindValue="id"
                    bindValueByPropertyNameToControl="id"
                    bindLabel="name"
                    [options]="selectedBoardColumnOptions$ | async"
                    [formControl]="optionControl">
                    <app-reject-candidate-selection-option-template #optionsTemplate>
                    </app-reject-candidate-selection-option-template>
                </app-select>
            </app-form-field>
        </div>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (secondaryButtonClicked)="cancel()"
        (primaryButtonClicked)="confirm()">
    </app-modal-footer>

</app-modal-frame>
