import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { EndpointsConfig } from '@config/endpoints.config';
import { AdvertisementDto } from '@pages/positions/classes/advertisements/AdvertisementDto';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';
import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { AppConstants } from '@config/app.constant';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { map } from 'rxjs/operators';
import { mapProvidersToCardTable } from '@pages/positions/utils/map-provider.util';
import { AdvertisementProviderStatus } from '@pages/positions/classes/advertisements/AdvertisementProviderStatus';
import { AdvertisementProviderType } from '@pages/positions/classes/advertisements/AdvertisementProviderType';
import { AdvertisementProviderHelperService } from '@pages/positions/services/advertisement/advertisement-provider-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementApiService {
  constructor(
    private http: HttpService,
    private providerHelper: AdvertisementProviderHelperService
  ) {}

  getAdvertisementByPositionId(positionId: number): Observable<AdvertisementDetail> {
    return this.http.get(EndpointsConfig.advertisement(positionId));
  }

  updateAdvertisement(
    positionId: number,
    advertisementId: number,
    advertisementDto: AdvertisementDto
  ): Observable<AdvertisementDetail> {
    return this.http.put(
      EndpointsConfig.advertisementById(positionId, advertisementId),
      this.normalizeAdvertisementDto(advertisementDto)
    );
  }

  getProviders(advertisementId: number): Observable<AdvertisementProvider[]> {
    return this.http.get(EndpointsConfig.providers(advertisementId), {
      page: '1',
      perPage: AppConstants.advertisementProvidersPerPage.toString(),
    });
  }

  getTags(): Observable<TagDto[]> {
    return this.http.get(EndpointsConfig.tags);
  }

  getProviderTypes(): Observable<AdvertisementProviderType[]> {
    return this.http.get(EndpointsConfig.providerTypesManual);
  }

  getMappedProviders(
    advertisementId: number
  ): Observable<{
    providers: AdvertisementProvider[];
    mappedProviders: AdvertisementProviderCardTableRow[];
  }> {
    return this.getProviders(advertisementId).pipe(
      map((providers) => {
        return {
          providers,
          mappedProviders: mapProvidersToCardTable(providers),
        };
      })
    );
  }

  createProvider(
    advertisementId: number,
    provider: AdvertisementProvider
  ): Observable<AdvertisementProvider> {
    return this.http.post(
      EndpointsConfig.providers(advertisementId),
      this.normalizeProvider(provider)
    );
  }

  updateProvider(
    advertisementId: number,
    provider: AdvertisementProvider
  ): Observable<AdvertisementProvider> {
    return this.http.put(
      EndpointsConfig.providerById(advertisementId, provider.id),
      this.normalizeProvider(provider)
    );
  }

  deleteProvider(advertisementId: number, providerId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.providerById(advertisementId, providerId));
  }

  toggleProviderStatus(
    advertisementId: number,
    providerId: number,
    currentStatus: AdvertisementProviderStatus
  ): Observable<AdvertisementProvider> {
    return this.http.put(EndpointsConfig.providerStatus(advertisementId, providerId), {
      status:
        currentStatus === AdvertisementProviderStatus.Active
          ? AdvertisementProviderStatus.Inactive
          : AdvertisementProviderStatus.Active,
    });
  }

  uploadAdvertisementImage(advertisementId: number, imageFile: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', imageFile);
    return this.http.post(EndpointsConfig.advertisementImage(advertisementId), formData);
  }

  deleteAdvertisementImage(advertisementId: number, imageId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.advertisementImageById(advertisementId, imageId));
  }

  private normalizeProvider(dto: AdvertisementProvider): AdvertisementProvider {
    const normalized = { ...dto };

    if (typeof normalized.providerType.id === 'string') {
      normalized.providerType.id = null;
    }

    if (
      this.providerHelper.isProfessionAndNotStudent(normalized.providerType.name) ||
      this.providerHelper.isWhc(normalized.providerType.name)
    ) {
      normalized.endDate = null;
    }

    return normalized;
  }

  private normalizeAdvertisementDto(advertisementDto: AdvertisementDto): AdvertisementDto {
    const tags = advertisementDto.tags.map((tag: TagDto) => {
      const id = typeof tag.id === 'number' ? tag.id : null;
      return {
        id,
        name: tag.name,
      };
    });

    const languages = advertisementDto.languages.filter((language) => !!language.languageId);

    return { ...advertisementDto, tags, languages };
  }
}
