import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkEditTranslateHelper } from '@shared/modules/bulk-edit-bar/classes/BulkEditTranslateHelper';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { ListTypeName } from '@config/app.constant';
import { finalize, map, tap } from 'rxjs/operators';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { PositionBulkUpdateState } from '@pages/positions/classes/PositionBulkUpdateState';
import { BulkEditConfirmModalContent } from '@shared/modules/bulk-edit-bar/classes/BulkEditConfirmModalContent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { BulkEditUpdatedData } from '@shared/modules/bulk-edit-bar/classes/BulkEditUpdatedData';
import { upperFirst } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-confirm-modal',
  templateUrl: './bulk-edit-confirm-modal.component.html',
  styleUrls: ['./bulk-edit-confirm-modal.component.scss'],
})
export class BulkEditConfirmModalComponent extends MatModalBaseComponent implements OnInit {
  confirmHeaderText: string;
  translateHelper = BulkEditTranslateHelper;
  listType: ListTypeName;
  isLaborHireATS = false;
  updatableItems: BulkEditUpdatedData[] = [];
  isProcessing: boolean = false;

  constructor(
    protected injector: Injector,
    private bulkEditService: BulkEditService,
    private atsConfigService: ATSConfigService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = false;

    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;
    this.listType = this.bulkEditService.getStateSnapshot().listType;

    this.setContent(this.listType).pipe(untilDestroyed(this)).subscribe();
  }

  private setContent(type: ListTypeName): Observable<BulkEditConfirmModalContent> {
    const bulkEditStateKey = this.bulkEditService.getBulkEditStateKey(type);
    return this.bulkEditService.select(bulkEditStateKey).pipe(
      map((value: CandidateBulkUpdateState | PositionBulkUpdateState) => {
        return this.bulkEditStateConverter(type, value);
      }),
      tap((convertedState: BulkEditConfirmModalContent) => {
        const typeTranslate = TranslateInstance.instant(`common.${type.toLocaleLowerCase()}`);
        this.confirmHeaderText = TranslateInstance.instant('common.bulk_edit_confirm_title', {
          number: convertedState.selectedItemsLength.toString(),
          type: typeTranslate,
        });
        this.convertItemsSelectedValueToText(convertedState.data);

        this.updatableItems = convertedState.data;
      })
    );
  }

  getTranslateKey(key: string): string {
    const capitalized = upperFirst(key) as keyof typeof BulkEditTranslateHelper;

    if (this.isLaborHireATS) {
      switch (capitalized) {
        case 'ProjectId':
          return 'common.site';
        case 'SubProject':
          return 'common.sub_site';
        default:
          return this.translateHelper[capitalized] as string;
      }
    } else {
      return this.translateHelper[capitalized] as string;
    }
  }

  private bulkEditStateConverter(
    type: ListTypeName,
    state: CandidateBulkUpdateState | PositionBulkUpdateState
  ): BulkEditConfirmModalContent {
    let selectedItemsLength: number;
    const newState = { ...state };

    switch (type) {
      case ListTypeName.Position:
        if ('positionIds' in newState) {
          selectedItemsLength = newState.positionIds.length;
          delete newState.positionIds;
        }
        break;
      case ListTypeName.Candidate:
        if ('candidateIds' in newState) {
          selectedItemsLength = newState.candidateIds.length;
          delete newState.candidateIds;
        }
        break;
      default:
        break;
    }

    return {
      selectedItemsLength,
      data: Object.keys(newState)
        .filter((key) => {
          return state[key];
        })
        .map((key) => {
          const selected: string = Array.isArray(state[key])
            ? ((state[key] as unknown) as Array<{ name: string }>)
                .map((value) => value?.name)
                .join('\n')
            : (state[key] as { name: string }).name;

          return {
            name: key,
            selected,
          };
        }),
    };
  }

  private convertItemsSelectedValueToText(data: BulkEditUpdatedData[]) {
    for (const item of data) {
      if (typeof item.selected == 'boolean') {
        item.selected = TranslateInstance.instant(item.selected ? 'common.yes' : 'common.no');
      }
    }
  }

  saveBulkEditData() {
    this.isProcessing = true;

    this.bulkEditService
      .bulkUpdate()
      .pipe(
        finalize(() => {
          this.isProcessing = false;
        })
      )
      .subscribe(() => {
        this.bulkEditService.openBulkEditResultModal();
        this.closeModal();
      });
  }
}
