import { ValidatorFn, Validators } from '@angular/forms';
import { ModalType } from '@shared/classes/ModalType';

interface ValidationConfig {
  type: ModalType | ModalType[] | null;
  validator: ValidatorFn | ValidatorFn[];
}

const commonValidators: Record<string, ValidationConfig> = {
  email: {
    type: ModalType.Candidate,
    validator: Validators.required,
  },
  needSendTermsNotification: {
    type: ModalType.Candidate,
    validator: Validators.required,
  },
  explorerUserId: {
    type: [ModalType.UserContact, ModalType.PhoneContact],
    validator: Validators.required,
  },
  exploredDate: {
    type: [ModalType.UserContact, ModalType.PhoneContact],
    validator: [Validators.required],
  },
  phone: {
    type: [ModalType.PhoneContact, ModalType.Candidate],
    validator: Validators.required,
  },
  preferredLanguageId: {
    type: [ModalType.PhoneContact, ModalType.Candidate],
    validator: Validators.required,
  },
  newsletter: {
    type: [ModalType.PhoneContact, ModalType.Candidate],
    validator: Validators.required,
  },
  cityId: {
    type: [ModalType.PhoneContact, ModalType.Candidate],
    validator: Validators.required,
  },
  contactType: {
    type: [ModalType.UserContact, ModalType.PhoneContact],
    validator: Validators.required,
  },
  firstName: {
    type: [ModalType.UserContact, ModalType.PhoneContact],
    validator: Validators.required,
  },
  lastName: {
    type: [ModalType.UserContact, ModalType.PhoneContact],
    validator: Validators.required,
  },
};

export const validationLaborHireAtsConfigs: Record<string, ValidationConfig> = {
  ...commonValidators,
};

export const validationStudentAtsConfigs: Record<string, ValidationConfig> = {
  ...commonValidators,
  birthDate: {
    type: [ModalType.Candidate, ModalType.PhoneContact],
    validator: Validators.required,
  },
  parentFirstName: {
    type: [ModalType.Candidate, ModalType.PhoneContact],
    validator: Validators.required,
  },
  parentLastName: {
    type: [ModalType.Candidate, ModalType.PhoneContact],
    validator: Validators.required,
  },
  parentEmail: {
    type: [ModalType.Candidate, ModalType.PhoneContact],
    validator: Validators.required,
  },
};
