import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { PartnerDropdownProject } from '@pages/partners/classes/PartnerDropdownProject';
import { PositionDropdownItem } from '@pages/positions/classes/PositionDropdownItem';
import { PositionsService } from '@pages/positions/services/details/positions.service';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-partner-project-position-selection',
  templateUrl: './partner-project-position-selection.component.html',
  styleUrls: ['./partner-project-position-selection.component.scss'],
})
export class PartnerProjectPositionSelectionComponent implements OnInit {
  @Input() partnerIdControl: FormControl;
  @Input() projectIdControl: FormControl;
  @Input() positionIdControl = new FormControl();

  @Input() isPositionControlShown = true;
  @Input() isProjectDropdownAlwaysShown = false;
  @Input() useVirtualScrollInDropdowns = true;
  @Input() partners: PartnerDropdownItem[];
  @Input() preFillData = false;

  projects: PartnerDropdownProject[] = [];
  positions: PositionDropdownItem[] = [];

  constructor(
    private positionService: PositionsService,
    private positionApiService: PositionsApiService
  ) {}

  ngOnInit(): void {
    const dependencies = {
      // use partners from outside (via Input prop) if available
      partners: Array.isArray(this.partners)
        ? of(this.partners)
        : this.positionApiService.getPartnersForDropdown().pipe(take(1)),
    };

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(({ partners }) => {
        this.partners = partners;

        if (this.preFillData) {
          this.preFillFormData();
        }

        this.listenProjectChanges();
      });
  }

  preFillFormData(): void {
    const selectedPartner = this.partners.find(
      (partner) => partner.id === this.partnerIdControl?.value
    );
    if (selectedPartner) {
      this.projects = selectedPartner.projects;
      if (this.projectIdControl?.value) {
        this.positionApiService
          .getPositionsForDropdown(this.projectIdControl.value as number)
          .pipe(untilDestroyed(this))
          .subscribe((positions) => {
            this.positions = positions;
          });
      }
    }
  }

  onPartnerSelected(item: PartnerDropdownItem): void {
    if (Array.isArray(item?.projects)) {
      this.projectIdControl.reset(null);
      this.positionIdControl.reset(null);
      this.positions = [];
      this.projects = item.projects;

      if (this.projects.length === 0) {
        this.projectIdControl.setErrors({
          emptyProjectList: true,
        });
      }

      this.projectIdControl.markAsTouched();
      this.positionIdControl.markAsTouched();
    }
  }

  private listenProjectChanges(): void {
    this.projectIdControl.valueChanges
      .pipe(
        filter((projectId: number) => this.isPositionControlShown && projectId > 0),
        switchMap((projectId: number) => {
          return this.positionApiService.getPositionsForDropdown(projectId).pipe(
            tap((positions: PositionDropdownItem[]) => {
              this.positions = positions;
              this.positionIdControl.setValue(null);

              if (this.positions.length === 0) {
                this.positionIdControl.setErrors({
                  emptyPositionList: true,
                });
              }
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
