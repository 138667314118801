import { CityDistanceFilter } from '@shared/modules/filter-components/classes/CityDistanceFilter';
import { TableSortByFilter } from '@shared/classes/table-filter/TableSortByFilter';

export class TableFilter {
  letter_filter?: string;
  sortBy?: TableSortByFilter[];
  city_distance?: CityDistanceFilter;

  constructor() {
    this.letter_filter = '';
    this.sortBy = [];
    this.city_distance = undefined;
  }
}
