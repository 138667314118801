import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import { userInitialState, UserState } from '@shared/modules/state-manager/state/users/user.state';

@Injectable({
  providedIn: 'root',
})
export class UserStateService extends StateService<UserState> {
  constructor() {
    super(userInitialState);
  }
}
