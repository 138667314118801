<app-form-field>
    <app-select
      [label]="label"
      [isHintShown]="true"
      [hideSelected]="true"
      [options]="options"
      bindLabel="name"
      bindValue="id"
      bindValueByPropertyNameToControl="id"
      [formControl]="control">
    </app-select>
</app-form-field>
