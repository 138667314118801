import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PartnersService } from '@pages/partners/services/partners.service';
import { Observable, Subscription } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { AppStateService } from '@shared/services/app-state.service';
import { PartnerStateService } from '@pages/partners/services/base/partner-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { PartnerOrProject } from '@config/app.constant';
import { getSyncIconPathUtil } from '@shared/utils/get-sync-icon-path-util';
import { ConfirmModalConfig } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { ATSConfigService } from '@shared/services/ats-config.service';

@UntilDestroy()
@Component({
  selector: 'app-partner-detail-header',
  templateUrl: './partner-detail-header.component.html',
  styleUrls: ['./partner-detail-header.component.scss'],
})
export class PartnerDetailHeaderComponent implements OnInit {
  partnerName$: Observable<string>;
  partnerLogoUrl$: Observable<string>;

  detailSub: Subscription;
  deleteModalConfig: ConfirmModalConfig;

  placeholder = 'assets/image/partner-placeholder.svg';

  isPartnerImageShown = true;
  isSyncOn: boolean;
  isDeletable: boolean;

  syncHintText: string[];
  deleteHintText: string;
  syncIconPath: string;

  readonly HINT_TYPE = HintType;

  constructor(
    protected partnerState: PartnerStateService,
    private partnerService: PartnersService,
    private appService: AppStateService,
    private cdr: ChangeDetectorRef,
    private atsConfigService: ATSConfigService
  ) {}

  ngOnInit(): void {
    this.partnerState
      .select('type')
      .pipe(untilDestroyed(this))
      .subscribe((type) => {
        this.handlePartnerDetailChange(type);
      });

    this.partnerName$ = this.partnerState.select('headerText').pipe(
      tap(() => {
        const { currentUrl } = this.appService.getStateSnapshot();
        this.isPartnerImageShown = currentUrl.indexOf('projects') === -1;
        this.cdr.detectChanges();
      })
    );

    this.partnerLogoUrl$ = this.partnerState.select('partnerDetail').pipe(pluck('logoThumb'));
  }

  private handlePartnerDetailChange(type: PartnerOrProject) {
    if (this.detailSub) {
      this.detailSub.unsubscribe();
    }

    this.detailSub = this.partnerState
      .select(type === 'partner' ? 'partnerDetail' : 'projectDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        this.syncIconPath = getSyncIconPathUtil(detail?.synchronizedAt, detail?.isSynchronized);
        this.syncHintText = this.appService.getSyncFeedbackMessage(detail);
        this.isSyncOn = detail?.isSynchronizable;
        this.isDeletable = detail?.isDeletable;

        this.deleteHintText = this.getTooltipTextForDeleteAction(type);

        const partnerId =
          type === 'project' ? this.partnerState.getStateSnapshot().partnerDetail?.id : detail?.id;

        this.deleteModalConfig = this.getDeletePartnerProjectModalConfig(
          type,
          partnerId,
          detail as ProjectDetail
        );
      });
  }

  onNavigation(): void {
    this.partnerService.triggerNavigateBack();
  }

  private getDeletePartnerProjectModalConfig(
    type: PartnerOrProject,
    partnerOrProjectId: number,
    project?: ProjectDetail
  ): ConfirmModalConfig {
    const deleteObservable =
      type === 'partner'
        ? this.partnerService.callDeletePartner(partnerOrProjectId)
        : this.partnerService.callDeleteProject(partnerOrProjectId, project);

    return {
      data: {
        modalText: TranslateInstance.instant(
          'common.delete_confirm_message',
          { message: type === 'partner' ? 'common.partner' : 'common.project' },
          true
        ),
        confirmObservable: deleteObservable,
      },
    };
  }

  private getTooltipTextForDeleteAction(type: PartnerOrProject) {
    const interpolateParam = this.atsConfigService.isLaborHireATS
      ? 'positions.projects'
      : 'partners.partner_address';

    return this.isDeletable
      ? `partners.${type}_delete`
      : TranslateInstance.instant(`partners.${type}_could_not_delete`, {
          project: TranslateInstance.instant(interpolateParam),
        });
  }
}
