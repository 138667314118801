<ng-template
  let-column="column"
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
    <div class="d-flex justify-content-center">
        <app-checkbox
            checkboxColor="blue"
            [formControl]="checkBox"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)">
        </app-checkbox>
    </div>
</ng-template>
