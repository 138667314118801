import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import differenceBy from 'lodash-es/differenceBy';

@Component({
  selector: 'app-multiple-selection',
  templateUrl: './multiple-selection.component.html',
  styleUrls: ['./multiple-selection.component.scss'],
})
export class MultipleSelectionComponent {
  @Input() set options(items: Partial<{ id: number; name: string }>[]) {
    if (Array.isArray(items)) {
      this.allOptions = items;
      this.availableOptions = this.allOptions;
    }
  }

  @Input() optionsArray: FormArray;
  @Input() labelText = 'common.name';
  @Input() addMoreText = 'partners.add_project_manager';

  availableOptions: Partial<{ id: number; name: string }>[] = [];
  private allOptions: Partial<{ id: number; name: string }>[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  get hasMore(): boolean {
    return (
      this.getAvailableOptions().length > 0 &&
      this.optionsArray.length > 0 &&
      this.optionsArray.at(this.optionsArray.length - 1).valid
    );
  }

  onSelectionOpened(): void {
    this.availableOptions = this.getAvailableOptions();
    this.cdr.detectChanges();
  }

  getAvailableOptions() {
    const currentIds = this.optionsArray.controls.map((group: AbstractControl) => {
      return (group as FormGroup).getRawValue() as unknown;
    });
    return differenceBy(this.allOptions, currentIds, 'id');
  }

  onItemSelected() {
    this.availableOptions = this.getAvailableOptions();
  }

  onAddItem() {
    this.optionsArray.push(
      new FormGroup({
        id: new FormControl(null, [Validators.required]),
      })
    );
  }

  onRemoveItem(index: number) {
    this.optionsArray.removeAt(index);
    this.availableOptions = this.getAvailableOptions();
  }
}
