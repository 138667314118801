import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ButtonBaseComponent } from '@shared/modules/form-components/button/button-base.components';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { ConfirmModalConfig } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';

@Component({
  selector: 'app-delete-button',
  templateUrl: 'delete-button.component.html',
  styleUrls: ['delete-button.component.scss'],
})
export class DeleteButtonComponent extends ButtonBaseComponent {
  @Input() modalConfig: ConfirmModalConfig;
  @Input() hintText: string;
  @Input() disabled: boolean;

  @Output() deleteSuccess = new EventEmitter<void>();

  readonly ICON_URL: string = 'assets/image/delete-icon.svg';
  readonly DELETE_ROLES = [UserRole.SuperAdmin, UserRole.Admin];

  constructor(private modalService: MatModalService) {
    super();
  }

  callDeleteWithConfirm() {
    this.modalService
      .openConfirmModal({
        data: {
          modalText: this.modalConfig.data.modalText,
          primaryButtonText: 'common.yes',
          primaryButtonColor: 'warn',
          secondaryButtonText: 'common.no',
          confirmObservable: this.modalConfig.data.confirmObservable,
        },
      })
      .subscribe((result) => {
        if (result) {
          this.deleteSuccess.emit();
        }
      });
  }
}
