<div *ngIf="candidateName" class="candidate-detail-header h-100 d-flex align-items-center"
    [class.marked-for-delete]="!isAnonymized && hasDeleteSystemTag">

    <app-back-arrow-button
        (buttonClick)="onNavigation()"
        class="back-button d-block">
    </app-back-arrow-button>

    <div *ngIf="isDetailLoaded$ | async" class="d-flex flex-grow-1 justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
            <div class="profile-image mr-10px"
                 [appSetBackgroundImage]="profileImage$ | async"
                 [tippy]="convertButtonTooltipText | translate"
                 [delay]="100"
                 variation="hint"
                 placement="bottom">
            </div>

            <div class="mr-5px">{{candidateName}} <span *ngIf="age > 0">({{age}})</span></div>

            <ng-container *ngIf="!isAnonymized">
                <app-hint
                    *ngFor="let icon of icons; let i = index"
                    [ngClass]="{ 'ml-2': i > 0, 'ml-3': i === 0 }"
                    placement="bottom"
                    [hintTypes]="icon.hintType"
                    [hintText]="icon.tooltipTexts"
                    variation="hint">
                </app-hint>
            </ng-container>

            <div class="ml-2">
                <app-hint *ngIf="isSyncOn"
                    [placement]="'right'"
                    [filePath]="syncIconPath"
                    [hintTypes]=[HINT_TYPE.Sync]
                    [hintText]="syncHintText"
                    [variation]="'syncMessageTooltip'">
                </app-hint>
            </div>
        </div>

        <ng-container *ngIf="!isAnonymized && !hasDeleteSystemTag">
            <div class="d-flex">
                <app-icon-button
                    *ngIf="showConvertButton"
                    class="mr-3 convert-candidate"
                    [isBlue]="true"
                    [hintText]="'candidates.convert_to_candidate'"
                    [hintPosition]="'left'"
                    [iconUrl]="'assets/image/change-role-icon.svg'"
                    (buttonClick)="openConvertCandidateModal()">
                </app-icon-button>

                <app-delete-button
                    [modalConfig]="deleteModalConfig"
                    [hintText]="'candidates.delete_candidate'"
                    (deleteSuccess)="refreshCandidatePositions()"
                ></app-delete-button>
            </div>
        </ng-container>

        <app-hint
            *ngIf="!isAnonymized && hasDeleteSystemTag"
            class="marked-for-delete-icon"
            [placement]="'left'"
            [hintTypes]="[HINT_TYPE.Error]"
            [hintText]="'candidates.candidate_has_delete_system_tag'">
        </app-hint>
    </div>
</div>
