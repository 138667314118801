import { Component, Input, OnInit } from '@angular/core';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@shared/modules/mat-modal/classes/ModalData';

@UntilDestroy()
@Component({
  selector: 'app-modal-frame',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss'],
})
export class ModalFrameComponent implements OnInit {
  @Input() hideHeader: boolean = false;
  @Input() dialogRef: MatDialogRef<MatModalBaseComponent>;
  @Input() data: ModalData;
  @Input() loading: boolean;

  modalTypes = ModalTypes;
  variant: ModalTypes;

  ngOnInit(): void {
    this.listenBackdropClick();

    if (this.data) {
      this.variant = this.data.variant;
    }
  }

  private listenBackdropClick(): void {
    this.dialogRef
      .backdropClick()
      .pipe(
        filter(() => !this.dialogRef.disableClose),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
