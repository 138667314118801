<div
    *ngIf="cellData && columnData"
    (click)="setFillHandleActiveAndSelected(rowIndex, column, row, value)"
    (dblclick)="startInlineEditOnDblClick(rowIndex, column, value)"
    (mouseover)="toggleFillHandleSelected(rowIndex, column, row)"
    (mouseup)="endFillHandle($event)"
    [class.cell-error]="cellData.saveStatus === SAVE_STATUS.Error"
    [class.cell-updated]="cellData.saveStatus === SAVE_STATUS.Updated"
    [class.fill-handle-active]="cellData.active"
    [class.fill-handle-selected]="!cellData.isEditing && cellData.isSelected"
    class="edit-cell-container pointer-events-none-all position-relative d-flex align-items-center h-100"
>
    <div class="w-100">
        <div *ngIf="!cellData.isEditing">
            <div #cellValue>
                <ng-content select="[cell-value]"></ng-content>
            </div>

            <div *ngIf="!cellValue?.hasChildNodes()" data-id="'cell-value'" class="d-flex inline-edit-cell">
                {{ value | inlineEditCellValue:column }}
            </div>
        </div>

        <div *ngIf="cellData.isEditing" class="pointer-events-auto">
            <ng-content select="[cell-inline-input]"></ng-content>
        </div>

        <app-hint
            *ngIf="cellData.errorMessage"
            [hintTypes]="[HINT_TYPE.Error]"
            [hintText]="'validation_errors.' + cellData.errorMessage"
            class="error-icon pointer-events-auto">
        </app-hint>

        <div *ngIf="cellData.active"
             (mousedown)="startFillHandle(row, rowIndex, column, value)"
             class="fill-handle-icon pointer-events-auto">
            +
        </div>

        <div *ngIf="cellData.isLoading" class="cell-loading-icon">
            <app-loading [size]="15"></app-loading>
        </div>
    </div>
</div>
