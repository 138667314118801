/** Main constants should store here */
import { UserRole } from '@shared/modules/auth/classes/UserRole';

export class AppConstants {
  static test = 'test';
  static lightBlueBackgroundColor = '#F7F9FB';
  static overflowMenuWidth = '230px';
  static defaultBackgroundColor = '#fff';
  static dotMenuWidth = 210; // px
  static inputFieldFormatKey = 'format';
  static inputFieldTypeKey = 'type';
  static backendDateFormat = 'YYYY-MM-DD';
  static dateFormat = 'YYYY.MM.DD.';
  static dateTimeFormat = 'YYYY.MM.DD. HH:mm';
  static minInputLength = 3;
  static maxInputLength = 255;
  static cityNameMinInputLength = 2;
  static fullHeightModalWidth = '760px';
  static fullHeightModalNarrowWidth = '340px';
  static threeColumnModalWidth = '1380px';
  static historyMessagesPerPage = 5;
  static cardTableRowsPerPage = 4;
  static tableRowsPerPage = 25;
  static tableHeaderHeight = 34;
  static tableRowHeight = 34;
  static historyMessageMaxLength = 500;
  static historyMessageMinLength = 1;
  static employeeNeedsMaxLength = 6;
  static bulkEditMinLength = 1;
  static maxFileSizeBytes = 5 * 1000 * 1000; // ~5MB
  static ageLimit = 18;
  static missingData = ' - ';
  static supportedImageExtensions = ['jpg', 'jpeg', 'pjpeg'];
  static supportedDocumentExtensions = [
    'doc',
    'docx',
    'pdf',
    'gif',
    'rtf',
    'msg',
    ...AppConstants.supportedImageExtensions,
  ];
  static documentTypesWithExpiryDate = ['medicalCertificate', 'lungFilter'];
  static advertisementFreeTextMaxLength = 65535;
  static advertisementProvidersPerPage = 1000;
  static dragTableBorderColors = {
    default: '#058BDC',
    approved: '#22BC44',
    rejected: '#D41F26',
  };
  static availableRoles = [
    UserRole.Admin,
    UserRole.FieldManager,
    UserRole.ProjectManager,
    UserRole.Partner,
    UserRole.WhcContact,
  ];
  static historyUserMessageKey = 'user_message';
  static historyEmailMessageKey = 'common.system_email';
  static historySMSMessageKey = 'common.system_sms';
  static noDataTranslationKey = 'common.no_data';
  static professionProviderName = 'profession';
  static professionAdvertisementDays = 28;
  static whcProviderName = 'whc.hu';
  static whcAdvertisementMonths = 3;
  static cvDocumentKey = 'curriculumVitae';
  static parentStatementDocumentKey = 'parentStatement';
  static privacyAppStatementDocumentKey = 'privacyAppStatement';
  static documentTypesWithPositionSelection = [
    AppConstants.parentStatementDocumentKey,
    AppConstants.privacyAppStatementDocumentKey,
  ];
  static placeholderImageUrl = 'assets/image/person-placeholder.svg';
  static candidateImageUrl = 'assets/image/candidate-placeholder.svg';
  static phoneContactImageUrl = 'assets/image/phone-contact-placeholder.svg';
  static userContactImageUrl = 'assets/image/user-contact-placeholder.svg';
  static azureLoginIconUrl = 'assets/image/common/azure_social_icon.svg';
  // filter keys where values should be saved
  static filterKeysForSave = ['partner_id', 'project_id'];
  // filter keys where saved filter values should be checked
  static checkSavedFilterKeys = ['project_id', 'position_id'];
  static dropdownKeysForPartnerSave = ['projects/search', 'positions/search'];
  static dropdownKeysForProjectSave = 'positions/search';
  static paramArrayKeyPartnerSave = 'partnerId';
  static paramArrayKeyProjectsSave = 'projectId';
  static powerBiReportUrl = 'POWER_BI_REPORT_URL';

  // regex
  static emailRegex = /^(?!.*[-+._]{2})\w+[a-z0-9-+._]+@\w*([\w-]+\.)+\w{2,6}$/;
  static taxNumberHuRegex = /^(\d{8}-\d-\d{2})$/s;
  static taxNumberRsRegex = /^(\d{9})$/s;
  static taxNumberAtRegex = /^(ATU\d{8})$/s;
  static taxNumberUaRegex = /^(\d{12})$/s;
  static taxNumberSkRegex = /^(SK(\d{9,10})$)/s;
  static taxNumberRegex = new RegExp(
    // eslint-disable-next-line max-len
    `${AppConstants.taxNumberHuRegex.source}|${AppConstants.taxNumberRsRegex.source}|${AppConstants.taxNumberAtRegex.source}|${AppConstants.taxNumberUaRegex.source}|${AppConstants.taxNumberSkRegex.source}`
  );
  static zipCodeRegex = /^(\d{4,5})$/;
  static phoneRegex = /^[+]\d{1,3} \d{6,50}$/;
  static phoneRegexStartsWith = /^[+]\d{1,3}/;
  static projectCode = /^[A-Z0-9]{1,5}$/;
  static omIdentifierRegex = /^7\d{10}$/;
  static pensionerIdentifierRegex = /^\d{9}$/;
  static feorNumberRegex = /^(\d{4})$/;
  static numberRegex = /^[0-9]*$/;

  static bankAccountNumberRegex = /^(([0-9]{8})-([0-9]{8})|([0-9]{8})-([0-9]{8})-([0-9]{8}))$/;
  static companyRegistrationNumberRegex = /^([0-9]{2})-([0-9]{2})-([0-9]{6})$/;
  static customColumnIdPrefix = 'custom_column__';
}

export enum ListTypeName {
  Partner = 'PARTNER',
  Candidate = 'CANDIDATE',
  Position = 'POSITION',
  PositionCandidate = 'POSITION_CANDIDATES',
  PhoneContact = 'PHONE_CONTACTS',
  PositionApplications = 'POSITION_APPLICATIONS',
}

export type PartnerOrProject = 'partner' | 'project';
