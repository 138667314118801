import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { LoadingComponent } from './components/loading/loading.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoadingComponent, PageLoaderComponent],
  exports: [LoadingComponent, PageLoaderComponent],
  imports: [CommonModule, InlineSVGModule, TranslateModule],
})
export class LoaderModule {}
