import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  positionInitialState,
  PositionState,
} from '@shared/modules/state-manager/state/positions/position.state';

@Injectable({
  providedIn: 'root',
})
export class PositionStateService extends StateService<PositionState> {
  constructor() {
    super(positionInitialState);
  }
}
