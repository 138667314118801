import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { TableFilterState } from '@shared/classes/table-filter/TableFilterState';
import { TableFilter } from '@shared/classes/table-filter/TableFilter';

export interface CandidateState extends TableFilterState {
  candidateDetailLoaded: boolean;
  candidateDetail: CandidateDetail;
  candidateDocuments: CandidateDocument[];

  positionSelectionLoading: { positionId: number; candidateId: number };

  candidatePositionsForDocumentUploadLoading: boolean;
  candidatePositionsForDocumentUploadLoaded: boolean;
  candidatePositionsForDocumentUpload: CandidatePositionMinimal[];

  candidateModalOpenedFromPosition: boolean;

  isEditMode: boolean;
}

export const candidateInitialState: CandidateState = {
  filtersLoaded: false,
  filters: new TableFilter(),

  candidateDetail: undefined,
  candidateDetailLoaded: false,
  candidateDocuments: [],

  positionSelectionLoading: null,

  candidatePositionsForDocumentUploadLoading: false,
  candidatePositionsForDocumentUploadLoaded: false,
  candidatePositionsForDocumentUpload: [],

  candidateModalOpenedFromPosition: false,

  isEditMode: false,
};
