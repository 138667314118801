import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { CandidateState } from '@shared/modules/state-manager/state/candidates/candidate.state';
import {
  CityDistanceFilter,
  CityDistanceFilterFormGroupObj,
} from '@shared/modules/filter-components/classes/CityDistanceFilter';
import { FilterDropdownConfig } from '@shared/modules/filter-components/classes/filter-dropdown-config';
import { ParamArray } from '@shared/modules/http/classes/ParamArray';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/modules/http/http.service';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { take } from 'rxjs/operators';
import { BaseData } from '@shared/classes/BaseData';

@UntilDestroy()
@Component({
  selector: 'app-distance-range-filter',
  templateUrl: './distance-range-filter.component.html',
  styleUrls: ['./distance-range-filter.component.scss'],
})
export class DistanceRangeFilterComponent implements OnInit {
  @Input() distanceRangeGroup: FormGroup;
  @Input() isOpen: boolean;
  @Input() valueEmitter: EventEmitter<void>;
  @Input() filterKey: string;
  @Input() data: unknown[];
  @Input() dropdownConfig: FilterDropdownConfig;

  @Output() resetCity = new EventEmitter<void>();
  @Output() isChecked = new EventEmitter<boolean>();

  kmValueTagText: string;

  constructor(
    private filterService: FilteringService,
    private candidateState: CandidateStateService,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    const {
      intValue,
      id,
    } = this.distanceRangeGroup.getRawValue() as CityDistanceFilterFormGroupObj;
    this.kmValueTagText = this.getTagText(intValue);
    this.handleKMRangeInputStates(id);

    this.distanceRangeGroup
      .get('id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((cityId: number) => {
        this.distanceRangeGroup.get('intValue').setValue('');
        this.handleKMRangeInputStates(cityId);
      });

    this.distanceRangeGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: CityDistanceFilterFormGroupObj) => {
        this.kmValueTagText = this.getTagText(value.intValue);
        this.valueEmitter.emit();
      });

    this.filterService
      .getSavedFilterAsync(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((cityDistanceObj: unknown) => {
        if (typeof cityDistanceObj !== 'undefined') {
          const cityDistance = cityDistanceObj as CityDistanceFilter;

          const paramArray: ParamArray[] = [
            {
              key: 'id',
              value: [cityDistance.id],
            },
          ];

          this.getDropdownData(this.dropdownConfig.listUrl, paramArray)
            .pipe(take(1))
            .subscribe((data: BaseData[]) => {
              this.data = data;
              const cityName = data.find((city) => city.id === cityDistance.id)?.name;
              const cityId = cityDistance?.id;
              const kmValue = cityDistance?.intValue;
              this.isChecked.emit(cityId > 0 && kmValue > 0);
              this.distanceRangeGroup.setValue({ name: cityName, id: cityId, intValue: kmValue });
              this.distanceRangeGroup.get('intValue').markAsDirty();
              this.candidateState.setState({
                ...this.getStateWithCityDistanceObj(cityDistance),
              });
            });
        }
      });

    this.filterService
      .onResetFilter()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.candidateState.setState({
          ...this.getStateWithCityDistanceObj(undefined),
        });
        this.distanceRangeGroup.reset({ name: null, id: null, intValue: null });
        this.isChecked.emit(false);
      });
  }

  getDropdownData(url: string, paramArray: ParamArray[]): Observable<BaseData[]> {
    return this.http.get(url, null, paramArray);
  }

  onResetKMValue(): void {
    this.distanceRangeGroup.get('intValue').setValue('');
  }

  private getTagText(intValue: string | number): string {
    return intValue ? `${intValue} km` : '';
  }

  private getStateWithCityDistanceObj(
    cityDistanceObj: CityDistanceFilter
  ): Partial<CandidateState> {
    const currentState = this.candidateState.getStateSnapshot().filters;

    return {
      ...this.candidateState.getStateSnapshot(),
      filters: { ...currentState, city_distance: cityDistanceObj },
    };
  }

  private handleKMRangeInputStates(cityId: number): void {
    const intValueControl = this.distanceRangeGroup.get('intValue');

    if (cityId) {
      intValueControl.enable();
      return;
    }

    intValueControl.disable();
  }
}
