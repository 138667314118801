import { UserPermission } from '@shared/modules/auth/classes/UserPermission';

export interface AppHeaderState {
  filterTotalCount: number;
  isFilterTotalCountLoading: boolean;
  title: string;
  createEntityButtonPermissions: UserPermission[];
}

export const headerInitialState: AppHeaderState = {
  filterTotalCount: 0,
  isFilterTotalCountLoading: false,
  title: '',
  createEntityButtonPermissions: [],
};
