import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { debounceTime, mergeAll } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';
import { TableService } from '@shared/services/table.service';
import { TableRowClickEvent } from '@shared/modules/data-table/classes/TableRowClickEvent';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { DataTableActivateEvent } from '@shared/modules/data-table/classes/DataTableActivateEvent';
import { DataTableShortEvent } from '@shared/modules/data-table/classes/DataTableShortEvent';
import { DataTableReorderEvent } from '@shared/modules/data-table/classes/DataTableReorderEvent';
import { SortPropDir } from '@swimlane/ngx-datatable/lib/types/sort-prop-dir.type';
import {
  CustomCellConfig,
  CustomCellType,
} from '@shared/modules/data-table/classes/CustomCellConfig';
import { DataTableInlineEditService } from '@shared/modules/data-table-inline-edit/services/data-table-inline-edit.service';
import { UpdateTableItemEvent } from '@shared/modules/data-table/classes/UpdateTableItemEvent';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { ICustomHeaderComponent } from '@shared/modules/data-table/classes/ICustomHeaderComponent';
import { CustomCellTemplateDirective } from '@shared/modules/data-table/directives/custom-cell-template.directive';
import { InlineEditColumnType } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';

const RECALCULATE_EVENT_DELAY = 300;

@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(CustomCellTemplateDirective) customCellTemplates: CustomCellTemplateDirective;
  @ViewChild(DatatableComponent, { static: true }) dataTable: DatatableComponent;

  @Input() columnConfig: TableColumn[] = [];
  @Input() data: DataTableRow[] = [];
  @Input() unClickableColumns: string[] = [];
  @Input() hideCheckboxes = false;
  @Input() showPointerCursor: boolean = true;
  @Input() externalSorting: boolean = true;
  @Input() noCheckboxColumn: boolean = false;
  @Input() sorts: SortPropDir[];
  @Input() inlineEditMode = false;
  @Input() primaryKey: string;
  @Input() secondaryKey: string;

  @Input() customCells: CustomCellConfig<ICustomCellComponent>;
  @Input() customHeaders: CustomCellConfig<ICustomHeaderComponent>;

  @Output() rowClick = new EventEmitter<TableRowClickEvent>();
  @Output() loadMore = new EventEmitter<boolean>();
  @Output() sortBy = new EventEmitter<DataTableShortEvent>();
  @Output() reorder = new EventEmitter<DataTableReorderEvent>();
  @Output() selectChange = new EventEmitter<DataTableRow[]>();

  columnConfigWithCustomCells: TableColumn[];
  columnMode = ColumnMode;
  selectionType = SelectionType;
  messages = {
    emptyMessage: TranslateInstance.instant('common.not_found_datatable'),
  };
  selected: DataTableRow[] = [];
  dataChange$ = new EventEmitter<void>();

  headerHeight = AppConstants.tableHeaderHeight;
  rowHeight = AppConstants.tableRowHeight;

  constructor(
    private cdr: ChangeDetectorRef,
    private sideFilterService: SideFilterService,
    private tableService: TableService,
    private inlineEditService: DataTableInlineEditService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.listenResetSelectedItemsAction();
    this.checkAndLoadMoreAfterDataChange();

    if (this.inlineEditMode) {
      this.inlineEditService.primaryKey = this.primaryKey;
      this.inlineEditService.secondaryKey = this.secondaryKey;

      this.listenForInlineEditRowUpdates();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      if (this.inlineEditMode) {
        this.initEditCellDataList();
      }
    }

    setTimeout(() => {
      if (changes.columnConfig?.currentValue) {
        this.setColumnConfig();
      }

      if (changes.data?.currentValue) {
        this.updateTableData();
        this.dataChange$.emit();
      }
    });
  }

  ngAfterViewInit(): void {
    this.setColumnConfig();

    this.listenEventsToRecalculate();
  }

  onSelect(event: { selected: DataTableRow[] }) {
    if (event.selected) {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...event.selected);
      this.selectChange.emit(this.selected);
    }
  }

  onActivate(event: DataTableActivateEvent) {
    if (
      !this.inlineEditMode &&
      event.type === 'click' &&
      !this.unClickableColumns.includes(event?.column?.prop as string)
    ) {
      this.rowClick.emit({ id: event.row.id, openInNewTab: false });
    } else {
      event.event.stopPropagation();
    }
  }

  onMouseWheelPushed(event: { rowData: DataTableRow; rowElement: HTMLElement }): void {
    this.rowClick.emit({ id: event.rowData.id, openInNewTab: true });
  }

  onScroll(offsetY: number) {
    let offset = offsetY;
    const viewHeight =
      (this.el.nativeElement as HTMLElement).getBoundingClientRect().height - this.headerHeight;
    if (offsetY === 0) {
      offset = undefined;
    }
    if (offset + viewHeight >= (this.data || []).length * this.rowHeight) {
      this.loadMore.emit();
    }
  }

  onResize() {
    this.checkAndLoadMoreIfNeeded();
  }

  // on dataChange, call checkAndLoadMoreIfNeeded
  private checkAndLoadMoreAfterDataChange(): void {
    this.dataChange$.pipe(untilDestroyed(this)).subscribe(() => this.checkAndLoadMoreIfNeeded());
  }

  // trigger load more data if scrollbar not visible
  private checkAndLoadMoreIfNeeded() {
    const viewHeight = (this.el.nativeElement as HTMLElement).clientHeight - this.headerHeight;
    const tableHeight = (this.data || []).length * this.rowHeight;

    if (viewHeight >= tableHeight) {
      this.loadMore.emit(true);
    }
  }

  private setColumnConfig(): void {
    if (this.customCellTemplates && this.columnConfig) {
      if (this.customCells) {
        this.addCustomCellTemplatesToColumnConfig();
      }

      if (this.customHeaders) {
        this.addCustomHeaderTemplatesToColumnConfig();
      }
    }

    if (this.columnConfig) {
      this.columnConfigWithCustomCells = this.copyColumnConfig();
    }

    this.cdr.detectChanges();
  }

  private addCustomCellTemplatesToColumnConfig() {
    const customCellsByKey = this.getCustomTemplatesByKeys<ICustomCellComponent>(
      this.customCells,
      (item) => item.cellTemplate
    );

    this.columnConfig?.forEach((column) => {
      if (customCellsByKey[column.prop]) {
        column.cellTemplate = customCellsByKey[column.prop];
      } else if (customCellsByKey[CustomCellType.Default]) {
        column.cellTemplate = customCellsByKey[CustomCellType.Default];
      }
    });
  }

  private addCustomHeaderTemplatesToColumnConfig() {
    const customHeadersByKey = this.getCustomTemplatesByKeys<ICustomHeaderComponent>(
      this.customHeaders,
      (item) => item.headerTemplate
    );

    this.columnConfig.forEach((column) => {
      if (customHeadersByKey[column.prop]) {
        column.headerTemplate = customHeadersByKey[column.prop];
      }
    });
  }

  getCustomTemplatesByKeys<T extends ICustomCellComponent | ICustomHeaderComponent>(
    customItems: CustomCellConfig<T>,
    extractTemplate: (item: T) => TemplateRef<unknown>
  ): { [key: string]: TemplateRef<unknown> } {
    const vcr = this.customCellTemplates.viewContainerRef;
    const customItemsByKey: { [key: string]: TemplateRef<unknown> } = {};

    for (const key in customItems) {
      const customItemConfig = customItems[key];

      this.inlineEditService.setColumnData(customItemConfig.name, {
        params: customItemConfig.componentParams ?? { type: InlineEditColumnType.NotSet },
        bindLabel: customItemConfig.componentParams?.options?.bindLabel,
      });

      const component = vcr.createComponent(customItemConfig.component);

      customItemsByKey[customItemConfig.name] = extractTemplate(component.instance);
    }

    return customItemsByKey;
  }

  private copyColumnConfig() {
    const copiedArray: TableColumn[] = [];

    for (const column of this.columnConfig) {
      copiedArray.push({ ...column });
    }

    return copiedArray;
  }

  private updateTableData(): void {
    if (this.data) {
      this.data = [...this.data];
    }
    this.cdr.detectChanges();
  }

  private listenEventsToRecalculate(): void {
    merge(
      of(
        this.dataTable.resize.asObservable(),
        this.sideFilterService.select('opened'),
        this.dataTable.select.asObservable()
      )
    )
      .pipe(mergeAll(), debounceTime(RECALCULATE_EVENT_DELAY), untilDestroyed(this))
      .subscribe(() => {
        this.dataTable.recalculate();
        this.cdr.detectChanges();
      });
  }

  private listenResetSelectedItemsAction(): void {
    this.tableService
      .onResetSelectedItems()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dataTable.selected = [];
        this.dataTable.select.emit({ selected: [] });
      });
  }

  private initEditCellDataList() {
    if (this.data?.length) {
      const rowKeys = Object.keys(this.data[0]);
      const customColumnKeys = this.data[0]['customColumns']
        ? (Object.keys(this.data[0]['customColumns']) as string[])
        : [];

      for (let rowIndex = 0; rowIndex < this.data.length; rowIndex++) {
        for (const rowKey of rowKeys) {
          if (rowKey !== 'customColumns') {
            this.initEditCellData(rowIndex, rowKey);
          }

          for (const customColumnKey of customColumnKeys) {
            this.initEditCellData(rowIndex, customColumnKey);
          }
        }
      }
    }
  }

  private initEditCellData(rowIndex: number, rowKey: string) {
    this.inlineEditService.initCellEditData(rowIndex, rowKey);
  }

  private listenForInlineEditRowUpdates() {
    this.tableService.onUpdateItem$
      .pipe(untilDestroyed(this))
      .subscribe((event: UpdateTableItemEvent) => {
        // @ts-ignore
        this.data[event.rowIndex][event.property] = event.value;
        this.updateTableData();
      });
  }
}
