import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import {
  HistoryCreateMessageData,
  HistoryEditMessageData,
} from '@shared/modules/history-message/classes/HistoryEditMessageData';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PositionHistoryService {
  constructor(
    private historyMessageService: HistoryMessageService,
    private positionApiService: PositionsApiService
  ) {}

  getFirstHistoryPage(
    candidateId: number
  ): Observable<ListData<HistoryMessage> | HttpErrorResponse> {
    return this.historyMessageService
      .getFirstHistoryPage(
        candidateId,
        this.positionApiService.getHistory.bind(this.positionApiService)
      )
      .pipe(catchError((err: HttpErrorResponse) => of(err)));
  }

  callLoadMoreHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.callLoadMoreHistory(
      positionId,
      this.positionApiService.getHistory.bind(this.positionApiService)
    );
  }

  callCreateHistory(
    positionId: number,
    data: HistoryCreateMessageData
  ): Observable<HistoryMessage | HttpErrorResponse> {
    return this.historyMessageService.callCreateHistory(
      positionId,
      data,
      this.positionApiService.createHistoryMessage.bind(this.positionApiService)
    );
  }

  callUpdateHistory(
    positionId: number,
    messageData: HistoryEditMessageData
  ): Observable<HistoryMessage | HttpErrorResponse> {
    return this.historyMessageService.callUpdateHistory(
      positionId,
      messageData,
      this.positionApiService.updateHistoryMessage.bind(this.positionApiService)
    );
  }

  openDeleteHistoryConfirmModal(positionId: number, historyMessage: HistoryMessage): void {
    this.historyMessageService.openDeleteHistoryConfirmModal(
      positionId,
      historyMessage,
      this.positionApiService.deleteHistoryMessage.bind(this.positionApiService)
    );
  }
}
