import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobType } from '@pages/candidates/classes/JobType';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { Specialization } from '@pages/candidates/classes/Specialization';
import differenceBy from 'lodash-es/differenceBy';

@UntilDestroy()
@Component({
  selector: 'app-job-type-selection',
  templateUrl: './job-type-selection.component.html',
  styleUrls: ['./job-type-selection.component.scss'],
})
export class JobTypeSelectionComponent implements OnInit {
  @Input() jobTypeGroup: FormGroup;
  @Input() jobTypes: JobType[] = [];
  @Input() specializations: Specialization[] = [];
  @Input() specializationsGroup: FormArray;
  @Input() specLabel = 'candidates.what_to_work';
  @Input() alwaysHideSpecializations = false;
  @Input() clearable = false;
  @Input() requiredSpec = true;
  subJobTypes: JobType[] = [];
  availableSpecializations: Specialization[] = [];
  isSpecializationShown = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.availableSpecializations = [...this.specializations];

    this.subJobTypes =
      this.jobTypes.find((type) => type.id === this.jobTypeGroup.get('typeId').value)?.subTypes ||
      [];

    this.jobTypeGroup.valueChanges
      .pipe(startWith(this.jobTypeGroup.getRawValue()), untilDestroyed(this))
      .subscribe(({ typeId, subTypeId }: { typeId: number; subTypeId: number }) => {
        this.isSpecializationShown = typeId > 0;
        const subTypeControl = this.jobTypeGroup.get('subTypeId');
        if (subTypeId > 0 && subTypeControl) {
          subTypeControl.setValidators([Validators.required]);
          subTypeControl.updateValueAndValidity({ onlySelf: true });
        }
      });

    this.jobTypeGroup
      .get('typeId')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((typeId: number) => {
        const jobType = this.jobTypes.find((type) => type.id === typeId);
        const subJobTypeControl = this.jobTypeGroup.get('subTypeId');

        if (jobType) {
          this.subJobTypes = jobType.subTypes || [];

          if (this.subJobTypes.length === 0) {
            subJobTypeControl.setValue(null);
            subJobTypeControl.setValidators([]);
          } else {
            subJobTypeControl.setValidators([Validators.required]);
          }

          subJobTypeControl.markAsTouched();
        } else {
          this.subJobTypes = [];
          this.isSpecializationShown = false;
          subJobTypeControl.setValue(null);
          subJobTypeControl.setValidators([]);
        }

        subJobTypeControl.updateValueAndValidity();
      });

    if (!this.alwaysHideSpecializations) {
      setTimeout(() => {
        this.availableSpecializations = this.getAvailableSpecialization();
      }, 100);
    }
  }

  onAddSpecialization() {
    if (this.specializationsGroup) {
      const group = this.formBuilder.group({
        id: [null, this.requiredSpec ? [Validators.required] : []],
      });
      group.markAllAsTouched();
      this.specializationsGroup.push(group);
    }
  }

  onRemoveElement(index: number) {
    this.specializationsGroup.removeAt(index);
    this.availableSpecializations = this.getAvailableSpecialization();
  }

  onSelectionChange(): void {
    this.availableSpecializations = this.getAvailableSpecialization();
  }

  getAvailableSpecialization() {
    const currentIds = this.specializationsGroup.controls.map(
      (group: AbstractControl) => (group as FormGroup).getRawValue() as unknown
    );

    return differenceBy(this.specializations, currentIds, 'id');
  }
}
