import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';

export function checkOlderOrEqualThan18(date: string): boolean {
  if (!moment(date).isValid()) {
    return true;
  }

  return moment().diff(new Date(date), 'years') >= AppConstants.ageLimit;
}
