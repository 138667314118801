import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  bulkEditInitiateState,
  BulkEditState,
} from '@shared/modules/state-manager/state/bulk-edit/bulk-edit.state';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ListTypeName } from '@config/app.constant';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { CandidateApiService } from '@pages/candidates/services/base/candidate-api.service';
import { PositionBulkUpdateState } from '@pages/positions/classes/PositionBulkUpdateState';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { BulkEditResultModalComponent } from '@shared/modules/modals/base-modals/bulk-edit-result-modal/bulk-edit-result-modal.component';
import { BulkEditConfirmModalComponent } from '@shared/modules/modals/base-modals/bulk-edit-confirm-modal/bulk-edit-confirm-modal.component';
import { BulkEditResponse } from '@shared/classes/BulkEditResponse';
import { ClassProperties } from '@shared/types/class-properties.type';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';

@Injectable({
  providedIn: 'root',
})
export class BulkEditService extends StateService<BulkEditState> {
  private refreshList$: Subject<void> = new Subject<void>();

  constructor(
    private modalService: MatModalService,
    private positionApiService: PositionsApiService,
    private candidateApiService: CandidateApiService
  ) {
    super(bulkEditInitiateState);
  }

  triggerRefreshList(): void {
    this.refreshList$.next();
  }

  onRefreshList(): Observable<void> {
    return this.refreshList$.asObservable();
  }

  setPositionBulkUpdateState(filterObj: Partial<PositionBulkUpdateState>) {
    const newState = {
      ...this.getStateSnapshot(),
      positionBulkUpdate: { ...this.getStateSnapshot().positionBulkUpdate, ...filterObj },
    };
    this.setState(newState);
  }

  setCandidateBulkUpdateState(filterObj: Partial<CandidateBulkUpdateState>) {
    const newState = {
      ...this.getStateSnapshot(),
      candidateBulkUpdate: { ...this.getStateSnapshot().candidateBulkUpdate, ...filterObj },
    };
    this.setState(newState);
  }

  openBulkEditConfirmModal() {
    return this.modalService.openDialog(BulkEditConfirmModalComponent, {
      width: '460px',
      disableClose: true,
    });
  }

  bulkUpdate(): Observable<BulkEditResponse | MessageErrorResponse> {
    const state = this.getStateSnapshot();

    let endpoint: Observable<BulkEditResponse>;
    switch (state.listType) {
      case ListTypeName.Position:
        endpoint = this.positionApiService.bulkUpdatePosition(state.positionBulkUpdate);
        break;
      case ListTypeName.Candidate:
        endpoint = this.candidateApiService.bulkUpdateCandidate(state.candidateBulkUpdate);
        break;
      default:
        break;
    }

    return endpoint.pipe(
      tap((resp: BulkEditResponse) => {
        this.setState({ error: resp.errors });
      }),
      catchError((err: MessageErrorResponse) => {
        this.setState({ error: err.error.errors });
        return of(err);
      })
    );
  }

  openBulkEditResultModal(): void {
    this.modalService.openDialog(BulkEditResultModalComponent, {
      width: '460px',
      disableClose: true,
    });
  }

  getBulkEditStateKey(
    type: ListTypeName
  ): keyof ClassProperties<BulkEditState, CandidateBulkUpdateState | PositionBulkUpdateState> {
    if (type === ListTypeName.Position) {
      return 'positionBulkUpdate';
    } else {
      return 'candidateBulkUpdate';
    }
  }

  resetBulkEditState(): void {
    this.setState({ ...bulkEditInitiateState });
  }
}
