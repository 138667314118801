import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import { DotMenuClickEvent } from '@shared/modules/dot-menu/classes/DotMenuClickEvent';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';

@Component({
  selector: 'app-menu-cell',
  templateUrl: './menu-cell.component.html',
  styleUrls: ['./menu-cell.component.scss'],
})
export class MenuCellComponent extends CardTableCustomCellBaseComponent {
  @Input() isEnabledPredicate: (item?: DataTableRow) => boolean;
  @Input() menuConfig: DotMenuItem[] = [];

  @Output() menuClicked = new EventEmitter<DotMenuClickEvent<unknown>>();

  onMenuClick(menu: DotMenuItem, item: DataTableRow): void {
    this.menuClicked.emit({ menu, item });
  }
}
