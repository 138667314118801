import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-grid',
  templateUrl: './modal-grid.component.html',
  styleUrls: ['./modal-grid.component.scss'],
})
export class ModalGridComponent {
  @Input() isModalWithThreeColumn: boolean = false;
}
