<app-modal-frame [data]="data" [dialogRef]="dialogRef" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="modalTitle"
        [syncControl]="getFormControl(form,'profile.isSynchronizable')"
        [iconUrl]="'assets/image/common/applicant-icon.svg'">
    </app-modal-header>

    <ng-container content>
        <form *ngIf="!loading" [formGroup]="form" class="modal-base">
            <app-modal-grid
                [isModalWithThreeColumn]="true">
                <div left-column>
                    <ng-container *ngIf="isCreateMode && modalType !== MODAL_TYPES.Candidate">
                        <app-modal-section-header sectionTitle="candidates.contact-type"></app-modal-section-header>

                        <app-form-field>
                            <app-select
                                [options]="CONTACT_TYPES"
                                bindValueByPropertyNameToControl="value"
                                bindLabel="name"
                                bindValue="value"
                                label="candidates.type"
                                formControlName="contactType">
                            </app-select>
                        </app-form-field>
                    </ng-container>

                    <app-modal-section-header sectionTitle="candidates.basic_data"></app-modal-section-header>

                    <ng-container *ngIf="!isLaborHireATS">
                        <app-form-field>
                            <app-select
                                [options]="KPR_PROCESS_LANGUAGES"
                                bindValueByPropertyNameToControl="value"
                                bindLabel="name"
                                bindValue="value"
                                label="candidates.kpr_process_language"
                                [formControl]="form.get('profile.kprProcessLang')">
                            </app-select>
                        </app-form-field>
                    </ng-container>

                    <app-form-field>
                        <app-text-field
                            label="common.last_name"
                            formControlName="lastName">
                        </app-text-field>
                    </app-form-field>

                    <app-form-field>
                        <app-text-field
                            label="common.first_name"
                            formControlName="firstName">
                        </app-text-field>
                    </app-form-field>

                    <app-form-field>
                        <app-datepicker
                            [formControl]="form.get('profile.birthDate')"
                            label="common.birth_date"
                            placeholder="common.please_select"
                            [clearable]="!isStudentATS"
                            [maxDate]="birthDateMaxDate"
                            [isHintShown]="true">
                        </app-datepicker>
                    </app-form-field>

                    <app-form-field>
                        <app-text-field
                            label="common.email"
                            formControlName="email">
                        </app-text-field>
                    </app-form-field>

                    <div class="w-50 mb-2">
                        <app-simple-button
                            *ngIf="candidateEmailAlreadyExistsResponse?.isExists"
                            [text]="'candidates.go_to_candidate'"
                            [isSmallButton]="true"
                            (click)="goToCandidate()">
                        </app-simple-button>
                    </div>

                    <ng-container *ngIf="isPensionerATS" formGroupName="profile">
                        <app-yes-no-select
                            label="filters.is_pensioner"
                            formControlName="isPensioner">
                        </app-yes-no-select>
                    </ng-container>

                    <app-form-field>
                        <app-text-field
                            placeholder="+36 301234567"
                            label="common.phone_number"
                            formControlName="phone">
                        </app-text-field>
                    </app-form-field>

                    <app-form-field>
                        <app-text-field
                            placeholder="+36 301234567"
                            label="common.phone_two"
                            formControlName="phoneTwo">
                        </app-text-field>
                    </app-form-field>

                    <app-form-field>
                        <app-select
                            [options]="sources"
                            (selectionChange)="onProviderSelected($event)"
                            bindValueByPropertyNameToControl="id"
                            bindLabel="name"
                            bindValue="id"
                            label="candidates.source"
                            [searchable]="true"
                            [formControl]="form.get('profile.providerType.id')">
                        </app-select>
                    </app-form-field>

                    <app-modal-section-header
                        *ngIf="isStudentATS && parentsArray.length > 0"
                        sectionTitle="candidates.parent_details">
                    </app-modal-section-header>

                    <ng-container *ngIf="isStudentATS">
                        <ng-container *ngFor="let parentGroup of parentsArray.controls">
                            <app-form-field>
                                <app-text-field
                                    label="common.last_name"
                                    [formControl]="parentGroup.get('parentLastName')">
                                </app-text-field>
                            </app-form-field>

                            <app-form-field>
                                <app-text-field
                                    label="common.first_name"
                                    [formControl]="parentGroup.get('parentFirstName')">
                                </app-text-field>
                            </app-form-field>

                            <app-form-field>
                                <app-text-field
                                    label="common.email"
                                    [formControl]="parentGroup.get('parentEmail')">
                                </app-text-field>
                            </app-form-field>
                        </ng-container>
                    </ng-container>

                    <app-modal-section-header sectionTitle="candidates.address"></app-modal-section-header>

                    <app-country-settlement-selection
                        class="d-block w-100"
                        [group]="addressGroup"
                        dropdownPosition="bottom"
                        [contactType]="modalType"
                        [disabled]="isCreateMode && !isContactTypeControlValid && modalType !== MODAL_TYPES.Candidate"
                        [isCountryRequired]="modalType !== MODAL_TYPES.UserContact"
                        [isZipCodeRequired]="modalType !== MODAL_TYPES.UserContact"
                        [existingCity]="livingCity">
                    </app-country-settlement-selection>

                    <ng-container *ngIf="isStudentATS">
                        <app-modal-section-header sectionTitle="candidates.where_to_work"></app-modal-section-header>

                        <app-country-settlement-selection
                            [existingCity]="preferredCity"
                            [controlNameConfig]="{cityId: 'id', street: null}"
                            dropdownPosition="bottom"
                            [disabled]="isCreateMode && !isContactTypeControlValid && modalType !== MODAL_TYPES.Candidate"
                            [isCountryRequired]="false"
                            [showStreetControl]="false"
                            [isCityClearable]="true"
                            [group]="cityPreferenceGroup">
                        </app-country-settlement-selection>
                    </ng-container>
                </div>
                <div middle-column>
                    <app-modal-section-header [sectionTitle]="
                    {
                        student: 'candidates.membership_details',
                        pensioner: 'candidates.membership_details',
                        laborHire: 'candidates.labor_relation'
                    } | appATSBasedText">
                    </app-modal-section-header>

                    <app-start-end-date-picker
                        [minDateControl]="form.get('profile.membershipStartDate')"
                        [maxDateControl]="form.get('profile.membershipEndDate')"
                        [minDate]="membershipMinDate"
                        [maxDate]="membershipMaxDate"
                        [clearable]="!isCreateMode"
                        [disabled]="!isContactTypeControlValid"
                        [maxDateLabel]="{
                            student: 'candidates.membership_end',
                            pensioner: 'candidates.membership_end',
                            laborHire: 'candidates.labor_end'
                          } | appATSBasedText"
                        [minDateLabel]="{
                            student: 'candidates.membership_start',
                            pensioner: 'candidates.membership_start',
                            laborHire: 'candidates.labor_start'
                          } | appATSBasedText">
                    </app-start-end-date-picker>

                    <ng-container *ngIf="!isLaborHireATS">
                        <app-form-field>
                            <app-text-field
                                [label]="
                          {
                            student: 'candidates.om_identifier',
                            pensioner: 'candidates.pensioner_identifier'
                          } | appATSBasedText"
                                placeholder="common.om_identifier_placeholder"
                                [formControl]="form.get('profile.identifier')">
                            </app-text-field>
                        </app-form-field>
                    </ng-container>

                    <app-modal-section-header sectionTitle="candidates.newsletter"></app-modal-section-header>

                    <ng-container formGroupName="profile">
                        <app-yes-no-select
                            label="candidates.newsletter_subscribed"
                            formControlName="newsletter">
                        </app-yes-no-select>
                    </ng-container>

                    <app-modal-section-header sectionTitle="candidates.preferred_language"></app-modal-section-header>

                    <app-form-field>
                        <app-select
                            [options]="preferredLanguages"
                            bindValueByPropertyNameToControl="id"
                            bindLabel="name"
                            bindValue="id"
                            label="common.language"
                            formControlName="preferredLanguageId">
                        </app-select>
                    </app-form-field>

                    <app-modal-section-header
                        *ngIf="!isLaborHireATS"
                        sectionTitle="candidates.language_skills">
                    </app-modal-section-header>

                    <app-spoken-language-selection
                        *ngIf="!isLaborHireATS"
                        [languageArray]="languagesArray"
                        [languages]="languages"
                        [languageLevels]="languageLevels">
                    </app-spoken-language-selection>

                    <ng-container *ngIf="isLaborHireATS">
                        <app-modal-section-header sectionTitle="candidates.other_details"></app-modal-section-header>

                        <ng-container *ngIf="!isLaborHireATS" formGroupName="profile">
                            <app-form-field>
                                <app-select
                                    [options]="educationTypes"
                                    bindValue="id"
                                    bindLabel="name"
                                    bindValueByPropertyNameToControl="id"
                                    label="candidates.education_type"
                                    formControlName="educationTypeId">
                                </app-select>
                            </app-form-field>
                        </ng-container>

                        <ng-container formGroupName="profile">
                            <app-form-field>
                                <app-select
                                    [options]="projectManagers"
                                    bindValueByPropertyNameToControl="id"
                                    bindLabel="name"
                                    bindValue="id"
                                    label="filters.contact_candidate"
                                    formControlName="contactUserId">
                                </app-select>
                            </app-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isCreateMode && modalType === MODAL_TYPES.Candidate">
                        <app-modal-section-header
                            sectionTitle="candidates.need_send_terms_notification">
                        </app-modal-section-header>

                        <app-yes-no-select
                            label="candidates.need_send_terms_notification"
                            formControlName="needSendTermsNotification">
                        </app-yes-no-select>
                    </ng-container>

                    <ng-container *ngIf="createdAtPositions || isCreateMode">
                        <app-modal-section-header
                            sectionTitle="candidates.attach_title">
                        </app-modal-section-header>

                        <app-partner-project-position-form-selection
                            [disabled]="!isContactTypeControlValid && modalType !== MODAL_TYPES.Candidate"
                            [createdAtPositions]="createdAtPositions"
                            [partners]="partners"
                            [positionsControl]="form.get('positions')">
                        </app-partner-project-position-form-selection>
                    </ng-container>
                </div>

                <div right-column>
                    <ng-container *ngIf="modalType !== MODAL_TYPES.Candidate">
                        <app-modal-section-header sectionTitle="candidates.explorer_user"></app-modal-section-header>

                        <ng-container formGroupName="profile">
                            <app-form-field>
                                <app-select
                                    [options]="projectManagers"
                                    bindValueByPropertyNameToControl="id"
                                    bindLabel="name"
                                    bindValue="id"
                                    label="filters.explorer_user"
                                    formControlName="explorerUserId">
                                </app-select>
                            </app-form-field>

                            <app-form-field>
                                <app-datepicker
                                    [isHintShown]="true"
                                    [clearable]="true"
                                    formControlName="exploredDate"
                                    label="common.explored_date"
                                    placeholder="common.please_select">
                                </app-datepicker>
                            </app-form-field>
                        </ng-container>
                    </ng-container>

                    <app-modal-section-header
                        sectionTitle="candidates.custom_fields">
                    </app-modal-section-header>

                    <app-custom-field-selection-form
                        [disabled]="isCreateMode && !isContactTypeControlValid && modalType !== MODAL_TYPES.Candidate"
                        [customFieldControls]="form.get('profile.customFields')"
                        [customFields]="customFields">
                    </app-custom-field-selection-form>
                </div>
            </app-modal-grid>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        (primaryButtonClicked)="updateCandidate()"
        [primaryButtonText]="'common.save'"
        [secondaryButtonText]="'common.dismiss'"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [dialogRef]="dialogRef">
    </app-modal-footer>
</app-modal-frame>
