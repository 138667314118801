import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';
import { SideFilterComponent } from './side-filter.component';
import { InjectFilterDirective } from './directives/inject-filter.directive';

@NgModule({
  declarations: [SideFilterComponent, InjectFilterDirective],
  imports: [CommonModule, FilteringModule],
  exports: [SideFilterComponent],
})
export class SideFilterModule {}
