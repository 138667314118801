import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import values from 'lodash-es/values';
import { SuperRequired } from '@shared/modules/base-component/base.component';
import { BaseData } from '@shared/classes/BaseData';

@UntilDestroy()
@Component({
  template: '',
})
export class CardFilteringBaseComponent implements OnInit, OnDestroy {
  @ViewChild('dropDown', { static: false }) dropDown: HTMLElement;
  @ViewChild('filterTag', { static: false }) filterTag: HTMLElement;

  @Input() title: string;
  @Input() filterKey: string;

  transformedData: BaseData[] | string;

  @Input() set data(data: BaseData[] | string) {
    this.transformedData = data;
  }

  @Output() filterValueChange = new EventEmitter<Record<string, unknown>>();

  checkBox = new FormControl();
  isOpen = false;
  isChecked = false;
  isDisabled = true;

  targetClassList = [
    'mat-calendar-body-selected',
    'mat-calendar-spacer',
    'mat-focus-indicator',
    'mat-calendar-arrow',
    'mat-button-wrapper',
    'mat-calendar-body-label',
    'mat-calendar',
    'mat-calendar-header',
    'mat-calendar-content',
    'mat-calendar-table',
    'mat-calendar',
  ];

  constructor(
    readonly eRef: ElementRef<HTMLElement>,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService
  ) {}

  ngOnInit(skip?: boolean): SuperRequired.NgOnInit {
    if (!skip) {
      // common onInit logic
    }
    return SuperRequired.NgOnInit;
  }

  ngOnDestroy(skip?: boolean): SuperRequired.NgOnDestroy {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.NgOnDestroy;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    const eventTarget = event.target as HTMLElement;

    if (values(eventTarget.classList).length > 0) {
      if (
        typeof this.dropDown === 'undefined' &&
        !values(eventTarget.classList).some((c: string) => this.targetClassList.includes(c))
      ) {
        this.isOpen = !!this.eRef.nativeElement.contains(eventTarget);
      } else if (this.eRef.nativeElement.contains(eventTarget)) {
        this.isOpen = true;
      } else if (eventTarget.classList.contains('ng-option')) {
        this.isOpen = this.isOpen ?? this.isOpen;
      } else if (
        values(eventTarget.classList).some((c: string) => this.targetClassList.includes(c))
      ) {
        this.isOpen = this.isOpen ?? this.isOpen;
      } else {
        this.isOpen = false;
      }
    } else {
      this.isOpen = this.isOpen ?? this.isOpen;
    }
  }

  listenFilterResetAction(): Observable<void> {
    return this.filteringService.onResetFilter().pipe(untilDestroyed(this));
  }

  listenSavedFilterChange(filterKey: string): Observable<unknown> {
    return this.filteringService.getSavedFilterAsync(filterKey).pipe(untilDestroyed(this));
  }
}
