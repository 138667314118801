import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AdvertisementProviderType } from '@pages/positions/classes/advertisements/AdvertisementProviderType';
import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { AdvertisementProviderTypes } from '@pages/positions/classes/advertisements/AdvertisementProviderTypes';
import { getStringId } from '@shared/utils/get-string-id.util';
import { debounceTime, map, mergeAll, startWith } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { AdvertisementProviderHelperService } from '@pages/positions/services/advertisement/advertisement-provider-helper.service';
import { valueChangesExcept } from '@shared/utils/form/value-changes-except.util';
import { AddProviderModalData } from '@shared/modules/modals/position-modals/manage-provider-modal/classes/AddProviderModalConfig';
import { AdvertisementService } from '@pages/positions/services/advertisement/advertisement.service';
import { AdvertisementApiService } from '@pages/positions/services/advertisement/base/advertisement-api.service';
import { AdvertisementFormService } from '@pages/positions/services/advertisement/base/advertisement-form.service';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { AppConstants } from '@config/app.constant';
import { DurationInputArg2 } from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-manage-provider-modal',
  templateUrl: './manage-provider-modal.component.html',
  styleUrls: ['./manage-provider-modal.component.scss'],
})
export class ManageProviderModalComponent
  extends MatModalBaseComponent<AddProviderModalData>
  implements OnInit, OnDestroy {
  isEditMode = false;
  today = new Date();
  urlControl: FormControl;
  existingProviders: AdvertisementProviderType[] = [];
  selectedProvider: AdvertisementProviderCardTableRow;
  providerTypes = AdvertisementProviderTypes;
  form: FormGroup;

  modalTitle: string;
  advertisementId: number;

  constructor(
    private positionState: PositionStateService,
    private advertisementService: AdvertisementService,
    private advertisementApiService: AdvertisementApiService,
    private formService: AdvertisementFormService,
    private providerHelper: AdvertisementProviderHelperService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;
    this.advertisementId = this.data.advertisementId;

    this.advertisementApiService
      .getProviderTypes()
      .pipe(untilDestroyed(this))
      .subscribe((existingProviders: AdvertisementProviderType[]) => {
        const { selectedProvider } = this.positionState.getStateSnapshot();

        this.isEditMode = !!selectedProvider;
        this.selectedProvider = selectedProvider;

        this.form = this.formService.initProviderForm(selectedProvider);
        this.urlControl = new FormControl({ value: selectedProvider?.url, disabled: true });
        this.existingProviders = existingProviders;

        this.modalTitle = this.isEditMode
          ? 'positions.advertisements.edit_provider_title'
          : 'positions.advertisements.new_provider_title';

        this.primaryButtonDisabled = this.form.invalid;
        this.loading = false;

        this.listenProviderTypeChanges();
        this.listenFormChanges();
      });
  }

  onAddProvider(provider: string | AdvertisementProviderType) {
    const providerFormGroup = this.form.get('providerType') as FormGroup;

    let providerGroup = {};

    if (typeof provider === 'string') {
      providerGroup = {
        id: getStringId(),
        name: provider,
      };
      providerFormGroup.get('name').setValue(provider);
    } else {
      providerGroup = {
        id: provider.id,
        name: provider.name,
      };
      providerFormGroup.get('name').setValue(provider.name);
    }

    return providerGroup;
  }

  getEndDateMinimum(): Date {
    const minDate = (this.form.get('startDate').value as Date) ?? this.today;

    return moment(minDate).add(1, 'd').toDate();
  }

  getStartDateMaximum(): Date {
    const maxDate = (this.form.get('endDate').value as Date) ?? null;
    const providerName = this.form.get('providerType.name').value as string;

    const isProfessionAndNotStudent = this.providerHelper.isProfessionAndNotStudent(providerName);
    const isWhc = this.providerHelper.isWhc(providerName);

    const shouldNotRestrictStartDateMaxDate =
      maxDate === null || isProfessionAndNotStudent || isWhc;

    if (shouldNotRestrictStartDateMaxDate) {
      return null;
    }

    return moment(maxDate).subtract(1, 'd').toDate();
  }

  private listenProviderTypeChanges(): void {
    const controlsToListen = valueChangesExcept<AdvertisementProvider>(this.form.controls, [
      'endDate',
    ]);

    merge(of(...controlsToListen))
      .pipe(
        mergeAll(),
        startWith(this.form.getRawValue()),
        map(() => this.form.getRawValue() as AdvertisementProvider),
        untilDestroyed(this)
      )
      .subscribe((formData) => {
        const providerName = formData.providerType?.name;

        if (this.providerHelper.isProfessionAndNotStudent(providerName)) {
          this.updateAndDisableEndDate(formData, AppConstants.professionAdvertisementDays, 'days');
        } else if (this.providerHelper.isWhc(providerName)) {
          this.updateAndDisableEndDate(formData, AppConstants.whcAdvertisementMonths, 'months');
        } else {
          this.form.get('endDate').enable();
        }
      });
  }

  private updateAndDisableEndDate(
    formData: AdvertisementProvider,
    value: number,
    unit: DurationInputArg2
  ) {
    const endDateControl = this.form.get('endDate');

    endDateControl.setValue(this.providerHelper.addIntervalToDate(formData.startDate, value, unit));
    endDateControl.disable();
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), debounceTime(300), untilDestroyed(this))
      .subscribe(() => {
        this.primaryButtonDisabled = this.form.invalid;
      });
  }

  saveProvider() {
    this.primaryButtonDisabled = true;

    if (this.isEditMode) {
      this.advertisementService
        .callUpdateProvider(this.advertisementId, this.form.getRawValue() as AdvertisementProvider)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    } else {
      this.advertisementService
        .callCreateProvider(this.advertisementId, this.form.getRawValue() as AdvertisementProvider)
        .subscribe(this.handleConfirmActionResponse.bind(this));
    }
  }

  ngOnDestroy() {
    this.positionState.setState({ selectedProvider: null });
    return super.ngOnDestroy();
  }
}
