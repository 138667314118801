import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetBackgroundImage]',
})
export class SetBackgroundImageDirective {
  @Input() set appSetBackgroundImage(imageUrl: string) {
    this.setImage(imageUrl);
  }
  @Input() backgroundSize = 'cover';

  constructor(private element: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  private setImage(imageUrl: string): void {
    this.setBackgroundUrl(imageUrl);
    this.setBackgroundSize();
    this.setBackgroundRepeat();
  }

  private setBackgroundUrl(imageUrl: string) {
    if (this.element && imageUrl) {
      this.renderer.setStyle(this.element.nativeElement, 'background-image', `url('${imageUrl}')`);
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'background-image', `none`);
    }
  }

  private setBackgroundSize(): void {
    if (typeof this.backgroundSize === 'string') {
      this.renderer.setStyle(this.element.nativeElement, 'background-size', this.backgroundSize);
    } else {
      this.renderer.removeStyle(this.element.nativeElement, 'background-size');
    }
  }

  private setBackgroundRepeat() {
    this.renderer.setStyle(this.element.nativeElement, 'background-repeat', 'no-repeat');
  }
}
