import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ModalPrimaryButtonColor } from '@shared/modules/mat-modal/classes/ModalPrimaryButtonColor';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent {
  @Input() dialogRef: MatDialogRef<MatModalBaseComponent>;

  @Input() primaryButtonText: string = 'common.yes';
  @Input() primaryButtonColor: ModalPrimaryButtonColor = 'basic';
  @Input() primaryButtonDisabled: boolean = false;
  @Input() hidePrimaryButton: boolean = false;

  @Input() secondaryButtonText: string = 'common.cancel';
  @Input() secondaryButtonDisabled: boolean = false;
  @Input() hideSecondaryButton: boolean = false;

  @Output() primaryButtonClicked = new EventEmitter();
  @Output() secondaryButtonClicked = new EventEmitter();

  onPrimaryClicked() {
    if (this.primaryButtonClicked) {
      this.primaryButtonClicked.emit();
    }
  }

  onSecondaryClicked() {
    if (this.secondaryButtonClicked) {
      this.secondaryButtonClicked.emit();
    }

    if (this.dialogRef) {
      setTimeout(() => {
        this.dialogRef.close();
      }, 50);
    }
  }
}
