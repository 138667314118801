<span class="icon-wrapper d-flex align-items-center">
    <img
        [src]="filePath"
        [isEnabled]="!!hintText"
        [tippy]="hintText"
        [variation]="variation"
        [placement]="placement"
        [lazy]="true"
        alt="Data icon"
    />
</span>
