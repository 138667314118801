import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { upperFirst } from 'lodash-es';

const roleMap: { [key: string]: string } = {
  [UserRole.SuperAdmin]: 'users.roles.super_admin',
  [UserRole.Admin]: 'users.roles.admin',
  [UserRole.FieldManager]: 'users.roles.field_manager',
  [UserRole.ProjectManager]: 'users.roles.project_manager',
  [UserRole.Technical]: 'users.roles.technical',
  [UserRole.Candidate]: 'users.roles.candidate',
  [UserRole.WhcContact]: 'users.roles.whc_contact',
  [UserRole.Partner]: 'users.roles.partner',
  [UserRole.Empty]: 'users.roles.empty',
};

export function getUserRoleTranslationKey(role: string): string {
  return roleMap[role] || '';
}

export function translateRoleName(role: string, toUpperInitialLetter = false): string {
  const translated = TranslateInstance.instant(role);
  return toUpperInitialLetter ? upperFirst(translated) : translated;
}
