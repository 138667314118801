import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { AdvertisementApiService } from '@pages/positions/services/advertisement/base/advertisement-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { AdvertisementImage } from '@pages/positions/classes/advertisements/AdvertisementImage';
import { getErrorTranslationKey, hasCommonError } from '@shared/utils/error-keys.util';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementService {
  constructor(
    private positionState: PositionStateService,
    private modalService: MatModalService,
    private advertisementApiService: AdvertisementApiService,
    private toast: ToastService
  ) {}

  callCreateProvider(
    advertisementId: number,
    provider: AdvertisementProvider
  ): Observable<AdvertisementProvider | HttpErrorResponse> {
    return this.advertisementApiService
      .createProvider(advertisementId, provider)
      .pipe(
        this.handleProviderSideEffects('positions.advertisements.provider_create', advertisementId)
      );
  }

  callUpdateProvider(
    advertisementId: number,
    provider: AdvertisementProvider
  ): Observable<AdvertisementProvider | HttpErrorResponse> {
    return this.advertisementApiService
      .updateProvider(advertisementId, provider)
      .pipe(
        this.handleProviderSideEffects('positions.advertisements.provider_modify', advertisementId)
      );
  }

  callDeleteProvider(
    advertisementId: number,
    provider: AdvertisementProvider
  ): Observable<void | HttpErrorResponse> {
    return this.advertisementApiService.deleteProvider(advertisementId, provider.id).pipe(
      tap(() => {
        this.refreshProviders(advertisementId);
      }),
      this.handleModalCloseAndToast('positions.advertisements.provider_delete_message'),
      this.handleSaveErrorSideEffects('positions.advertisements.provider_delete_message')
    );
  }

  callToggleProviderStatus(
    advertisementId: number,
    provider: AdvertisementProviderCardTableRow
  ): Observable<AdvertisementProvider | HttpErrorResponse> {
    return this.advertisementApiService
      .toggleProviderStatus(advertisementId, provider.id, provider.status)
      .pipe(
        tap(() => {
          this.refreshProviders(advertisementId);
        }),
        this.handleModalCloseAndToast('positions.advertisements.provider_status_modify'),
        this.handleSaveErrorSideEffects('positions.advertisements.provider_status_modify')
      );
  }

  callUploadAdvertisementImage(
    positionId: number,
    advertisementId: number,
    imageFile: File
  ): Observable<AdvertisementDetail | HttpErrorResponse> {
    return this.advertisementApiService.uploadAdvertisementImage(advertisementId, imageFile).pipe(
      concatMap(() => {
        return this.refreshAdvertisementDetail(positionId);
      }),
      this.handleModalCloseAndToast('positions.advertisements.image_upload')
    );
  }

  callDeleteImage(
    advertisementId: number,
    positionId: number,
    image: AdvertisementImage
  ): Observable<AdvertisementDetail | HttpErrorResponse> {
    return this.advertisementApiService.deleteAdvertisementImage(advertisementId, image.id).pipe(
      concatMap(() => {
        return this.refreshAdvertisementDetail(positionId);
      }),
      this.handleModalCloseAndToast('positions.advertisements.image_delete'),
      this.handleSaveErrorSideEffects('positions.advertisements.image_delete')
    );
  }

  refreshProviders(advertisementId: number): void {
    this.advertisementApiService.getMappedProviders(advertisementId).subscribe((data) => {
      this.positionState.setState({ providers: data.mappedProviders });
    });
  }

  handleModalCloseAndToast<T>(toastText: string) {
    return (source: Observable<T>): Observable<T> =>
      source.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
        })
      );
  }

  handleSaveErrorSideEffects<T>(toastText: string) {
    return (source: Observable<T>): Observable<T | HttpErrorResponse> =>
      source.pipe(
        catchError((err: MessageErrorResponse) => {
          if (!hasCommonError(err?.error?.errors)) {
            this.toast.showError(
              getErrorTranslationKey(err?.error?.errors, getGeneralMessage(toastText, false))
            );
          }
          return of(err);
        })
      );
  }

  private handleProviderSideEffects(toastText: string, advertisementId: number) {
    return (
      source: Observable<AdvertisementProvider>
    ): Observable<AdvertisementProvider | HttpErrorResponse> =>
      source.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
          this.refreshProviders(advertisementId);
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        })
      );
  }

  private refreshAdvertisementDetail(positionId: number): Observable<AdvertisementDetail> {
    return this.advertisementApiService.getAdvertisementByPositionId(positionId).pipe(
      tap((advertisementDetail: AdvertisementDetail) => {
        this.positionState.setState({ advertisementDetail });
      })
    );
  }
}
