import { Component, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-input',
  template: '',
})
export abstract class FormInputComponent extends BaseComponent {
  control: AbstractControl;
  isHintShown = true;
  hintText: string;
  label: string;
  textFieldId: string;

  changeSubject$: Subject<SimpleChanges>;
}
