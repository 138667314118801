import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbcFilteringComponent } from '@shared/modules/filter-components/components/abc-filtering/abc-filtering.component';
import { SharedModule } from '@shared/shared.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { CardFilteringBaseComponent } from '@shared/modules/filter-components/components/card-filtering-base/card-filtering-base.component';
import { FilterLabelTemplateComponent } from '@shared/modules/filter-components/components/filter-label-template/filter-label-template.component';
import { FilterOptionTemplateComponent } from '@shared/modules/filter-components/components/filter-option-template/filter-option-template.component';
import { DotMenuModule } from '@shared/modules/dot-menu/dot-menu.module';
import { TippyModule } from '@ngneat/helipopper';
import { HintModule } from '@shared/modules/hint/hint.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TextNumberFilteringComponent } from './components/card-filtering/text-number-filtering.component';
import { CardFilteringLabelComponent } from './components/card-filtering-label/card-filtering-label.component';
import { CardFilteringOptionComponent } from './components/card-filtering-option/card-filtering-option.component';
import { FilterTagComponent } from './components/filter-tag/filter-tag.component';
import { DateFilteringComponent } from './components/date-filtering/date-filtering.component';
import { TruefalseFilteringComponent } from './components/truefalse-filtering/truefalse-filtering.component';
import { DropdownFilteringComponent } from './components/dropdown-filtering/dropdown-filtering.component';
import { FilterContainerHeaderComponent } from './components/filter-container-header/filter-container-header.component';
import { FilterContainerContentComponent } from './components/filter-container-content/filter-container-content.component';
import { DateIntervalPickerComponent } from './components/date-interval-picker/date-interval-picker.component';
import { DistanceRangeFilterComponent } from './components/distance-range-filter/distance-range-filter.component';
import { FilterContainerLoadingComponent } from './components/filter-container-loading/filter-container-loading.component';
import { LoaderModule } from '@shared/modules/loader/loader.module';

@NgModule({
  declarations: [
    AbcFilteringComponent,
    TextNumberFilteringComponent,
    CardFilteringLabelComponent,
    CardFilteringOptionComponent,
    FilterTagComponent,
    DateFilteringComponent,
    TruefalseFilteringComponent,
    CardFilteringBaseComponent,
    DropdownFilteringComponent,
    FilterContainerHeaderComponent,
    FilterLabelTemplateComponent,
    FilterOptionTemplateComponent,
    FilterContainerContentComponent,
    DateIntervalPickerComponent,
    DistanceRangeFilterComponent,
    FilterContainerLoadingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormComponentsModule,
    InlineSVGModule,
    DotMenuModule,
    TippyModule,
    HintModule,
    PipesModule,
    LoaderModule,
  ],
  exports: [
    AbcFilteringComponent,
    TextNumberFilteringComponent,
    DateFilteringComponent,
    CardFilteringBaseComponent,
    TruefalseFilteringComponent,
    DropdownFilteringComponent,
    FilterTagComponent,
    FilterLabelTemplateComponent,
    FilterOptionTemplateComponent,
    FilterContainerHeaderComponent,
    FilterContainerContentComponent,
    FilterContainerLoadingComponent,
  ],
})
export class FilteringModule {}
