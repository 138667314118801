import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { ToastModule } from '@shared/modules/toast/toast.module';
import { AuthModule } from './modules/auth/auth.module';
import { SmsStatusIndicatorModule } from '@shared/modules/sms-status-indicator/sms-status-indicator.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    ButtonModule,
    ToastModule,
    SmsStatusIndicatorModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthModule,
    ButtonModule,
  ],
  providers: [],
  declarations: [],
})
export class SharedModule {}
