export enum HintType {
  NoHint,
  Error,
  Info,
  Success,
  Clock,
  RedSquare,
  YellowSquare,
  GreenSquare,
  GraySquare,
  Help,
  Sync,
}
