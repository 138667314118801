<ng-template
  let-column="column"
  let-rowIndex="rowIndex"
  let-value="value"
  let-row="row"
>
    <ng-container
        *appNgVar="inlineEditService.triggerCellRefresh[rowIndex + '-' + column.prop] | inlineEditCellData:rowIndex:column as data"
    >
        <app-inline-edit-cell-container
            [rowIndex]="rowIndex"
            [row]="row"
            [column]="column"
            [value]="value"
            [cellData]="data.cellData"
            [columnData]="data.columnData"
            [inlineEditDisabled]="true"
            [disableFillHandleSelect]="!value?.userPositions"
        >
            <div cell-value class="status-selection-table-cell-container">
                <app-status-selection
                    *ngIf="value?.userPositions?.status?.id && positionStatuses"
                    class="d-block w-100 pointer-events-auto"
                    (mouseover)="$event.stopPropagation()"
                    [smallSize]="true"
                    [editable]="value?.userPositions?.editable"
                    [appendTo]="'body'"
                    [candidateId]="value?.id"
                    [userRoles]="value?.roles"
                    [positionId]="value?.position?.id"
                    [positionType]="value?.position?.type"
                    [status]="value?.userPositions?.status"
                    [statusOption]="value?.userPositions?.statusOption"
                    [hasTermsAndConditions]="value?.hasTermsAndConditions"
                    [statuses]="positionStatuses">
                </app-status-selection>

                <div *ngIf="value?.userPositions?.status?.id && !positionStatuses"
                     class="w-100 d-flex justify-content-center">
                    <app-loading [size]="20"></app-loading>
                </div>

                <p *ngIf="!value?.userPositions?.status?.id"
                   class="none-position-status d-flex justify-content-center align-items-center">
                    {{"table_view.none-position-status" | translate}}
                </p>
            </div>
        </app-inline-edit-cell-container>
    </ng-container>
</ng-template>

