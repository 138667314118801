<button
  class="filter-menu-button"
  type="button"
  (click)="toggleSideFilter()"
  [disabled]="disabled">
    <div class="filter-menu-icon d-flex">
        <div [ngClass]="{'open': isSideFilterOpen, 'd-flex align-items-center': true}" aria-label="Arrow Icon"
             [inlineSVG]="'assets/image/arrow.svg'"></div>
        <div class="d-flex align-items-center" aria-label="Menu Icon" [inlineSVG]="'assets/image/sliders-h.svg'"></div>
    </div>
</button>
