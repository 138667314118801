import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  historyMessageInitialState,
  HistoryMessageState,
} from '@shared/modules/state-manager/state/history-message/history-message.state';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { Observable, of, Subject } from 'rxjs';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { ListData } from '@shared/classes/ListData';
import { uniqBy } from 'lodash-es';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { HistoryEditMessageData } from '@shared/modules/history-message/classes/HistoryEditMessageData';
import { HttpErrorResponse } from '@angular/common/http';
import {
  HistoryMessageInputTypes,
  HistoryMessageType,
} from '@shared/modules/history-message/classes/HistoryMessageType';

@Injectable({
  providedIn: 'root',
})
export class HistoryMessageService extends StateService<HistoryMessageState> {
  private clearMessageInput: Subject<void> = new Subject<void>();

  constructor(private modalService: MatModalService, private toast: ToastService) {
    super(historyMessageInitialState);
  }

  triggerClearMessageInput(): void {
    this.clearMessageInput.next();
  }

  onClearMessageInput(): Observable<void> {
    return this.clearMessageInput.asObservable();
  }

  resetHistoryMessageState(): void {
    this.setState({ ...historyMessageInitialState });
  }

  getFirstHistoryPage(
    parentId: number,
    endpoint: (
      id: number,
      page: number,
      type: HistoryMessageType
    ) => Observable<ListData<HistoryMessage>>
  ): Observable<ListData<HistoryMessage>> {
    const { messageType } = this.getStateSnapshot();

    return endpoint(parentId, 1, messageType).pipe(
      take(1),
      tap((history: ListData<HistoryMessage>) => {
        this.setState({
          messages: history.data,
          messagesTotalCount: history.total,
          currentPage: 1,
        });
      })
    );
  }

  callLoadMoreHistory(
    parentId: number,
    endpointToGetPage: (
      parentId: number,
      currentPage: number,
      type?: HistoryMessageType
    ) => Observable<ListData<HistoryMessage>>
  ): Observable<ListData<HistoryMessage>> {
    const currentPage = this.getStateSnapshot().currentPage;

    return endpointToGetPage(parentId, currentPage + 1, this.getStateSnapshot().messageType).pipe(
      take(1),
      tap((history: ListData<HistoryMessage>) => {
        const { messages } = this.getStateSnapshot();
        this.toast.showSuccess('partners.load_more_history_success');
        this.setState({
          currentPage: currentPage + 1,
          messagesTotalCount: history.total,
          messages: uniqBy([...messages, ...history.data], 'id'),
        });
      })
    );
  }

  callCreateHistory(
    id: number,
    data: { message: string; type?: HistoryMessageInputTypes },
    endpointToCreate: (
      id: number,
      data: { message: string; type?: HistoryMessageInputTypes }
    ) => Observable<HistoryMessage>
  ): Observable<HistoryMessage | HttpErrorResponse> {
    this.setState({ saveButtonDisabled: true });

    return endpointToCreate(id, data).pipe(
      take(1),
      tap((newMessage: HistoryMessage) => {
        const { messages, messagesTotalCount } = this.getStateSnapshot();

        this.setState({
          messages: [newMessage, ...messages],
          messagesTotalCount: messagesTotalCount + 1,
        });

        this.toast.showSuccess(getGeneralMessage('candidates.create_message', true));
        this.triggerClearMessageInput();
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('candidates.create_message', false));
        return of(err);
      }),
      finalize(() => {
        this.setState({ saveButtonDisabled: false });
      })
    );
  }

  callUpdateHistory(
    id: number,
    messageData: HistoryEditMessageData,
    endpointToUpdate: (id: number, historyId: number, message: string) => Observable<HistoryMessage>
  ): Observable<HistoryMessage | HttpErrorResponse> {
    this.setState({ saveButtonDisabled: true });

    return endpointToUpdate(id, messageData.historyId, messageData.message).pipe(
      take(1),
      tap((updatedMessage: HistoryMessage) => {
        const { messages } = this.getStateSnapshot();
        const index = messages.findIndex(
          (existingMessage) => existingMessage.id === messageData.historyId
        );
        if (index > -1) {
          messages[index] = updatedMessage;
        }
        this.setState({ messages: uniqBy([...messages], 'id') });
        this.triggerClearMessageInput();
        this.toast.showSuccess(getGeneralMessage('candidates.modify_message', true));
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('candidates.modify_message', false));
        return of(err);
      }),
      finalize(() => {
        this.setState({ saveButtonDisabled: false });
      })
    );
  }

  openDeleteHistoryConfirmModal(
    parentId: number,
    historyMessage: HistoryMessage,
    endpointToDeleteMessage: (entityId: number, historyId: number) => Observable<unknown>
  ): void {
    this.modalService.openConfirmModal({
      data: {
        modalText: 'partners.message_delete_confirm',
        primaryButtonText: 'common.delete_confirm',
        primaryButtonColor: 'warn',
        confirmObservable: this.callDeleteHistory(
          parentId,
          historyMessage,
          endpointToDeleteMessage
        ),
      },
    });
  }

  private callDeleteHistory(
    parentId: number,
    historyMessage: HistoryMessage,
    endpointToDeleteMessage: (entityId: number, historyId: number) => Observable<unknown>
  ) {
    return endpointToDeleteMessage(parentId, +historyMessage.id).pipe(
      take(1),
      tap(() => {
        const { messages, messagesTotalCount } = this.getStateSnapshot();
        this.setState({
          messages: messages.filter((history) => history.id !== historyMessage.id),
          messagesTotalCount: messagesTotalCount - 1,
        });
        this.toast.showSuccess('partners.message_deleted_success');
      }),
      catchError((err) => {
        this.toast.showError('partners.message_deleted_error');
        return of(err);
      })
    );
  }
}
