<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="modalTitle">
    </app-modal-header>

    <div content *ngIf="!loading" class="manage-user-form">
        <form [formGroup]="form" class="modal-base h-auto">
            <app-form-field>
                <app-text-field
                    label="common.last_name"
                    formControlName="lastName">
                </app-text-field>
            </app-form-field>

            <app-form-field>
                <app-text-field
                    label="common.first_name"
                    formControlName="firstName">
                </app-text-field>
            </app-form-field>

            <app-form-field>
                <app-text-field
                    label="common.email"
                    formControlName="email">
                </app-text-field>
            </app-form-field>

            <app-form-field>
                <app-text-field
                    placeholder="+36 301234567"
                    label="common.phone_number"
                    formControlName="phone">
                </app-text-field>
            </app-form-field>

            <app-form-field>
                <app-select
                    label="partners.cost_center"
                    [options]="offices"
                    bindValue="id"
                    bindLabel="name"
                    bindValueByPropertyNameToControl="id"
                    formControlName="officeId">
                </app-select>
            </app-form-field>

            <app-user-modal-role-change
                *ngIf="menuType === userMenuTypes.PartnerUsers"
                [roles]="roles"
                [projectSpecializations]="projectSpecialization"
                [isEditMode]="isEditMode"
                [user]="selectedUser"
                [positionControl]="form.get('position')"
                [projectSpecializationControl]="form.get('projectSpecializationId')"
                [roleNameControl]="form.get('roleName')">
            </app-user-modal-role-change>
        </form>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [secondaryButtonText]="'common.dismiss'"
        [primaryButtonText]="'common.save'"
        (primaryButtonClicked)="saveUser()">
    </app-modal-footer>

</app-modal-frame>
