import { AppConstants } from '@config/app.constant';
import { ProjectCardTableRow } from '@pages/partners/classes/ProjectCardTableRow';
import { PositionCardTableRow } from '@pages/partners/classes/PositionCardTableRow';
import { CandidatePositionCardTableRow } from '@pages/candidates/classes/CandidatePositionCardTableRow';

export interface CardTableState {
  selectedProject: ProjectCardTableRow;

  projectRows: ProjectCardTableRow[];
  positionRows: PositionCardTableRow[];
  candidatePositionRows: CandidatePositionCardTableRow[];

  total: number;
  currentPage: number;
  perPage: number;
}

export const cardTableInitialState: CardTableState = {
  selectedProject: undefined,

  projectRows: [],
  positionRows: [],
  candidatePositionRows: [],

  total: 0,
  currentPage: 1,
  perPage: AppConstants.cardTableRowsPerPage,
};
