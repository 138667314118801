import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { PositionDropdownItem } from '@pages/positions/classes/PositionDropdownItem';
import { PartnerDropdownProject } from '@pages/partners/classes/PartnerDropdownProject';
import { catchError, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { ATSConfigService } from '@shared/services/ats-config.service';

@UntilDestroy()
@Component({
  selector: 'app-position-selector',
  templateUrl: './position-selector.component.html',
  styleUrls: ['./position-selector.component.scss'],
})
export class PositionSelectorComponent {
  @Input() partnerControl: FormControl;
  @Input() projectControl: FormControl;
  @Input() positionsControl: FormControl;
  @Input() partners: PartnerDropdownItem[] = [];
  @Input() projects: PartnerDropdownProject[] = [];
  @Input() positions: PositionDropdownItem[] = [];
  @Input() partnerLabel = 'positions.partners';
  @Input() projectLabel = {
    student: 'common.project',
    pensioner: 'common.project',
    laborHire: 'common.site',
  };
  @Input() positionLabel = 'common.position';

  @Output() partnerChange = new EventEmitter<PartnerDropdownItem>();
  @Output() projectChange = new EventEmitter<PartnerDropdownProject>();
  @Output() positionChange = new EventEmitter<PositionDropdownItem>();

  isLaborHireATS = false;

  constructor(
    private positionApiService: PositionsApiService,
    private atsConfigService: ATSConfigService
  ) {
    this.isLaborHireATS = atsConfigService.isLaborHireATS;
  }

  onPartnerChange(partner: PartnerDropdownItem): void {
    this.resetProjectControl();
    this.resetPositionControl();
    this.positionsControl.setValue(null);
    this.handleProjectOnPartnerChange(partner, this.projectControl, this.positionsControl);
  }

  onProjectChange(project: PartnerDropdownProject): void {
    this.resetPositionControl();

    if (project) {
      this.positionApiService
        .getPositionsForDropdown(project.id)
        .pipe(
          take(1),
          tap((positions: PositionDropdownItem[]) => {
            this.positions = positions;
            this.positionsControl.markAsTouched();
            if (this.positions.length === 0) {
              setTimeout(() => {
                this.positionsControl.setErrors({
                  [this.isLaborHireATS ? 'emptyPositionListLaborHire' : 'emptyPositionList']: true,
                });
              });
            } else {
              this.positionsControl.setErrors({ required: true });
              this.positionsControl.updateValueAndValidity();
            }
          }),
          catchError((err) => {
            this.positions = [];
            return of(err);
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  private handleProjectOnPartnerChange(
    partner: PartnerDropdownItem,
    projectControl: FormControl,
    positionControl: FormControl
  ): void {
    if (Array.isArray(partner?.projects)) {
      this.projects = partner.projects;

      if (partner.projects.length === 0) {
        setTimeout(() => {
          projectControl.setErrors({
            [this.isLaborHireATS ? 'emptySiteList' : 'emptyProjectList']: true,
          });
        });
      } else {
        projectControl.setErrors({ required: true });
        positionControl.setErrors({ required: true });
      }
    }
  }

  private resetProjectControl(): void {
    this.projectControl.setValue(null);
    this.projectControl.updateValueAndValidity();
    this.projectControl.markAsTouched();
    this.projects = [];
  }

  private resetPositionControl(): void {
    this.positionsControl.setValue(null);
    this.positionsControl.updateValueAndValidity();
    this.positions = [];
  }
}
