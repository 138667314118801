<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="modalTitle">
    </app-modal-header>

    <ng-container content>
        <form *ngIf="!loading" [formGroup]="form">
            <div class="modal-base h-auto">
                <app-form-field>
                    <app-text-field
                        label="positions.advertisements.provider_name"
                        formControlName="name">
                    </app-text-field>
                </app-form-field>

                <app-form-field>
                    <app-datepicker
                        [isHintShown]="true"
                        [minDate]="today"
                        [maxDate]="getStartDateMaximum()"
                        label="positions.advertisements.provider_start_date"
                        formControlName="startDate">
                    </app-datepicker>
                </app-form-field>

                <app-form-field>
                    <app-datepicker
                        [isHintShown]="true"
                        [minDate]="getEndDateMinimum()"
                        label="positions.advertisements.provider_end_date"
                        formControlName="endDate">
                    </app-datepicker>
                </app-form-field>

                <app-form-field *ngIf="isEditMode">
                    <app-text-field
                        label="positions.advertisements.url"
                        [formControl]="urlControl">
                    </app-text-field>
                </app-form-field>

                <ng-container *ngIf="selectedProvider?.providerType?.type !== providerTypes.Automatic"
                              formGroupName="providerType">
                    <app-form-field>
                        <app-select
                            label="positions.advertisements.provider_platform"
                            bindValue="id"
                            bindLabel="name"
                            bindValueByPropertyNameToControl="id"
                            [searchable]="true"
                            (selectionChange)="onAddProvider($event)"
                            [options]="existingProviders"
                            formControlName="id">
                        </app-select>
                    </app-form-field>
                </ng-container>
            </div>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="saveProvider()">
    </app-modal-footer>

</app-modal-frame>
