import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../../../base-component/base.component';
import { ButtonClass } from './classes/button-class';

@UntilDestroy()
@Component({
  selector: 'app-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss'],
})
export class SimpleButtonComponent extends BaseComponent {
  @Input() text = 'Button';
  @Input() icon = '';
  @Input() disabled: boolean;
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() color: ThemePalette;
  @Input() buttonClass: ButtonClass = ButtonClass.Primary;
  @Input() isSmallButton = false;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
