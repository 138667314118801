<div class="card-filtering-container">
    <div class="d-flex align-items-center">
        <div class="d-inline-flex">
            <app-checkbox
                checkboxColor="red"
                [formControl]="checkBox"
                [checked]="isChecked"
                [isDisabled]="isDisabled"
                (isChecked)="onCheckboxChanged($event)">
            </app-checkbox>
            <p class="card-filter-title">{{title | translate}}</p>
            <app-hint
                *ngIf="hintText"
                class="d-flex ml-5px"
                [hintText]="hintText"
                variation="hint"
                [hintTypes]="[hintTypes.Help]">
            </app-hint>
        </div>
    </div>
    <div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
        <app-form-field class="mb-0">
            <app-text-field
              #textFilterInput
              [placeholder]="title"
              [type]="transformedData === 'number' ? 'number': 'text'"
              [isHintShown]="true"
              hintText="hint"
              [formControl]="search">
            </app-text-field>
        </app-form-field>
    </div>
</div>

