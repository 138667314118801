import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { last } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private allHistory: string[] = [];

  valueSubject = new BehaviorSubject<void>(null);

  constructor(public readonly router: Router, public readonly location: Location) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.valueSubject.next();

        this.allHistory.push(event.urlAfterRedirects);
      }
    });
  }

  get histories(): string[] {
    return this.allHistory;
  }

  popHistory(): void {
    this.allHistory.pop();
  }

  back(fallbackPath: string, forceFallBackPath?: boolean): void {
    this.allHistory.pop();

    if (this.allHistory.length > 0 && !forceFallBackPath) {
      this.router.navigateByUrl(last(this.allHistory));
    } else {
      this.router.navigateByUrl(fallbackPath);
    }
  }

  isCurrentUrl(url: string): boolean {
    return this.location.path().indexOf(url) > -1;
  }
}
