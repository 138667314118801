import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BoardColumnOption } from '@pages/positions/classes/board/BoardColumnOption';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { RejectReasonModalData } from '@shared/modules/modals/position-modals/reject-reason-candidate-modal/classes/RejectReasonModalConfig';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';

@UntilDestroy()
@Component({
  selector: 'app-reject-reason-candidate-modal',
  templateUrl: './reject-reason-candidate-modal.component.html',
  styleUrls: ['./reject-reason-candidate-modal.component.scss'],
})
export class RejectReasonCandidateModalComponent
  extends MatModalBaseComponent<RejectReasonModalData>
  implements OnInit, OnDestroy {
  optionControl: FormControl;
  positionDetail$: Observable<PositionDetail>;
  options: BoardColumnOption[] = [];
  selectedBoardColumnOptions$: Observable<BoardColumnOption[]>;

  constructor(private positionState: PositionStateService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.primaryButtonDisabled = true;
    this.positionDetail$ = this.positionState.select('positionDetail');

    this.selectedBoardColumnOptions$ = this.positionState.select('selectedKanbanBoardColumn').pipe(
      withLatestFrom(this.positionState.select('selectedPositionType')),
      map(([boardColumn, positionType]: [BoardColumn, PositionTypes]) => {
        if (!Array.isArray(boardColumn.options)) {
          return [];
        }

        // on kanban board, no filtering needed
        if (!positionType) {
          return boardColumn.options;
        }

        return boardColumn.options.filter((option: BoardColumnOption) => {
          return option.positionType === null || option.positionType === positionType;
        });
      })
    );

    const { selectedKanbanBoardCandidate } = this.positionState.getStateSnapshot();

    this.optionControl = new FormControl(selectedKanbanBoardCandidate?.optionId || null, [
      Validators.required,
    ]);

    this.optionControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.optionControl.invalid;
    });
  }

  confirm() {
    this.primaryButtonDisabled = true;

    this.data
      .confirmFunction(
        this.data.positionId,
        {
          statusOptionId: this.optionControl.value as number,
        },
        this.data.candidateId,
        this.data.userRoles
      )
      .pipe(take(1))
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }

  cancel() {
    if (this.data.cancelFunction) {
      this.data
        .cancelFunction()
        .pipe(take(1))
        .subscribe(() => {
          this.closeModal();
        });
    } else {
      this.closeModal();
    }
  }

  ngOnDestroy() {
    this.positionState.setState({ selectedKanbanBoardColumn: null });
    return super.ngOnDestroy();
  }
}
