import { Pipe, PipeTransform } from '@angular/core';
import { InlineEditColumnType } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';
import { StandardObject } from '@shared/classes/common/StandardObject';
import { DataTableInlineEditService } from '@shared/modules/data-table-inline-edit/services/data-table-inline-edit.service';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';

@Pipe({
  name: 'inlineEditCellValue',
})
export class InlineEditCellValuePipe implements PipeTransform {
  constructor(private inlineEditService: DataTableInlineEditService) {}

  transform(value: unknown, column: TableColumn): unknown {
    const columnData = this.inlineEditService.getColumnData(column.prop);

    const columnType = columnData.params.type;

    if (columnType === InlineEditColumnType.MultiSelect) {
      const arrayValue = value as Array<StandardObject>;
      const arrayBindValue = columnData.params.bindValue as keyof StandardObject;

      return this.getArrayValueAsString(arrayValue, arrayBindValue);
    } else if (columnType === InlineEditColumnType.Select) {
      const objectValue = value as StandardObject;
      const objectBindValue = columnData.params.bindValue as keyof StandardObject;

      return objectValue[objectBindValue];
    } else {
      return value;
    }
  }

  private getArrayValueAsString(
    arrayValue: Array<StandardObject>,
    arrayBindValue: keyof StandardObject
  ): string | unknown {
    if (arrayValue && arrayValue.length > 0) {
      let stringValue = arrayValue[0][arrayBindValue];

      for (let i = 1; i < arrayValue.length; i++) {
        stringValue += `, ${arrayValue[i][arrayBindValue] as string}`;
      }

      return stringValue;
    } else {
      return arrayValue;
    }
  }
}
