<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'common.bulk_edit_text'"
        [iconUrl]="'assets/image/building.svg'">
    </app-modal-header>

    <ng-container content *ngIf="!loading">
        <form [formGroup]="form" class="modal-base h-auto">
            <p class="basic-blue-normal-text">{{selectedItems$ | async}} {{'common.selected_items' | translate}}</p>
            <app-form-field>
                <app-select
                    label="positions.partners"
                    [options]="partners"
                    (selectionChange)="onPartnerChange($event)"
                    bindLabel="name"
                    bindValue=""
                    [searchable]="true"
                    [clearable]="true"
                    [formControl]="partnerControl"
                    [virtualScroll]="true">
                </app-select>
            </app-form-field>

            <app-form-field *ngIf="partnerControl.value">
                <app-select
                    [label]="
              {
                  student: 'common.project',
                  pensioner: 'common.project',
                  laborHire: 'common.site'
              } | appATSBasedText"
                    [options]="projects"
                    bindLabel="name"
                    bindValue=""
                    [formControl]="projectIdControl"
                    [virtualScroll]="true">
                </app-select>
            </app-form-field>

            <app-form-field>
                <app-select
                    label="positions.status"
                    [options]="statuses"
                    bindLabel="key"
                    bindValue=""
                    formControlName="status"
                    [clearable]="true">
                </app-select>
            </app-form-field>

            <app-job-type-selection
                [specializations]="specializations"
                [specializationsGroup]="specializationArray"
                [jobTypeGroup]="jobTypeGroup"
                [jobTypes]="jobTypes"
                [specLabel]="'positions.advertisements.categories'"
                [requiredSpec]="false"
                [clearable]="true">
            </app-job-type-selection>

            <app-form-field>
                <app-select
                    label="positions.employment_type"
                    [options]="employmentTypes"
                    bindLabel="name"
                    bindValue=""
                    formControlName="employmentTypeId"
                    [clearable]="true">
                </app-select>
            </app-form-field>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="openConfirmBulkEditModal()"
        [dialogRef]="dialogRef">
    </app-modal-footer>

</app-modal-frame>
