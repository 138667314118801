import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserRole } from '@app/shared/modules/auth/classes/UserRole';
import { AppConfig } from '@config/app.config';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { getCandidateAge } from '@pages/candidates/utils/get-candidate-age.util';
import { getCandidateName } from '@pages/candidates/utils/get-candidate-name.util';
import { NavigationService } from '@shared/services/navigation.service';
import { SumIconData } from '@pages/positions/classes/board/SumIconData';
import { getSumIconData } from '@pages/positions/utils/sum-icon.util';
import { CandidateModalService } from '@pages/candidates/services/candidate-modal.service';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { AppStateService } from '@shared/services/app-state.service';
import { getSyncIconPathUtil } from '@shared/utils/get-sync-icon-path-util';
import { SystemTagName } from '@shared/classes/SystemTag';
import { ConfirmModalConfig } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@UntilDestroy()
@Component({
  selector: 'app-candidate-detail-header',
  templateUrl: './candidate-detail-header.component.html',
  styleUrls: ['./candidate-detail-header.component.scss'],
})
export class CandidateDetailHeaderComponent implements OnInit {
  profileImage$: Observable<string>;
  isDetailLoaded$: Observable<boolean>;

  isAnonymized: boolean = false;
  showConvertButton: boolean;
  isSyncOn: boolean;
  hasDeleteSystemTag: boolean;

  age: number;

  syncHintText: string[];
  syncIconPath: string;
  convertButtonTooltipText: string;
  candidateName: string;

  deleteModalConfig: ConfirmModalConfig;
  icons: SumIconData[];

  readonly HINT_TYPE = HintType;

  constructor(
    private candidateState: CandidateStateService,
    private candidateService: CandidatesService,
    private candidateModalService: CandidateModalService,
    private navigationService: NavigationService,
    private appService: AppStateService
  ) {}

  ngOnInit(): void {
    this.isDetailLoaded$ = this.candidateState.select('candidateDetailLoaded');

    this.candidateState
      .select('candidateDetail')
      .pipe(
        untilDestroyed(this),
        filter((detail: CandidateDetail) => !!detail),
        tap((detail: CandidateDetail) => {
          this.age = getCandidateAge(detail.profile.birthDate);
          this.icons = getSumIconData(detail.icons, true);
          this.isAnonymized = !!detail.anonymized;

          this.showConvertButton =
            detail.roles.length === 1 && detail.roles[0].name === UserRole.UserContact;

          this.hasDeleteSystemTag = !!detail.systemTags?.find(
            (item) => item.name === SystemTagName.Delete
          );
        })
      )
      .subscribe((detail) => {
        this.candidateName = getCandidateName(detail.firstName, detail.lastName);
      });

    this.profileImage$ = this.candidateState.select('candidateDetail').pipe(
      map((detail) => {
        const roleImageData = this.candidateService.getRoleImage(detail);

        this.convertButtonTooltipText = roleImageData?.tooltipText;

        return roleImageData?.imageUrl;
      })
    );

    this.handleCandidateDetailChange();
  }

  private handleCandidateDetailChange() {
    this.candidateState
      .select('candidateDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        this.syncIconPath = getSyncIconPathUtil(
          detail?.profile?.synchronizedAt,
          detail?.profile?.isSynchronized
        );

        this.syncHintText = this.appService.getSyncFeedbackMessage(detail?.profile);
        this.isSyncOn = detail?.profile?.isSynchronizable;
        this.deleteModalConfig = this.getDeleteCandidateModalConfig(detail?.id);
      });
  }

  onNavigation(): void {
    this.navigationService.back(AppConfig.candidatesUrl);
  }

  private getDeleteCandidateModalConfig(candidateId: number): ConfirmModalConfig {
    return {
      data: {
        modalText: TranslateInstance.instant(
          'common.delete_confirm_message',
          { message: 'common.candidate' },
          true
        ),
        confirmObservable: this.candidateService.callDeleteCandidate(candidateId),
      },
    };
  }

  refreshCandidatePositions() {
    const candidateId = this.candidateState.getStateSnapshot().candidateDetail?.id;

    this.candidateService.getAllPositionsForCardTable(candidateId).subscribe();
  }

  openConvertCandidateModal(): void {
    const candidateId = this.candidateState.getStateSnapshot().candidateDetail.id;

    this.candidateModalService.openConvertCandidateModal(candidateId).subscribe();
  }
}
