import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverflowFilterSelectEndPoint } from './classes/overflow-filter-select-end-point';
import { MatMenuTrigger } from '@angular/material/menu';
import { OverflowSelectOption } from '@shared/modules/overflow-filter-menu/overflow-filter-select-menu/classes/overflow-select-option';
import { TypedChanges } from '@shared/types/typed-change.type';
import { TranslateWithPrefixPipe } from '@shared/pipes/table-view-selectable-columns.pipe';
import { AppConstants } from '@config/app.constant';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

/**
 * overflow filter select menu component
 * - on label click it will show a modal with selectable elements and an apply button
 * - on apply give back all selected elements
 * - on closing modal, reset any changes done in the component
 *
 * Supports:
 * - live search (always query the searched list from backend)
 * - static list search (query the list one time from backend, then filter it on frontend side)
 *
 * inputs: (that needs some description)
 * - hideSearch: hides search bar
 * - liveSearch: is live search functionality is on
 * - optionsEndpoint: called to get list from backend
 * - staticListLoaded: called when static list is loaded from backend (not in live search mode)
 * - singleSelect: single select mode, if in single select then radio buttons will be shown
 * - showArrow: show up and down arrow on menu open
 * - showSaveSelection: show save selection button
 *
 * Outputs:
 * - selectedOptionsApplied: pass all currently selected items, fired on save selection button click
 * - saveSelectionClicked: pass all currently selected items, fired on apply button click
 * - staticListLoaded: event fired if static list is loaded
 */
@UntilDestroy()
@Component({
  selector: 'app-overflow-filter-select-menu',
  templateUrl: './overflow-filter-select-menu.component.html',
  styleUrls: ['./overflow-filter-select-menu.component.scss'],
  providers: [TranslateWithPrefixPipe],
})
export class OverflowFilterSelectMenuComponent<T> implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Input() selectedOptions: OverflowSelectOption[] = [];
  @Input() valueKey: keyof T;
  @Input() labelKey: keyof T;
  @Input() translateKey: keyof T;
  @Input() hiddenKey: keyof T;
  @Input() typeKey: keyof T;
  @Input() disabledKey: keyof T;
  @Input() separatorTextKey: keyof T;

  @Input() label: string;
  @Input() placeHolder: string;
  @Input() showArrow = false;
  @Input() showSaveSelection = true;
  @Input() useIconLabel = true;

  @Input() liveSearch: boolean;
  @Input() hideSearch = false;
  @Input() singleSelect = false;
  @Input() isHideLabel: boolean = false;
  @Input() isUseInSideMenu: boolean = false;

  @Input() translatePrefix: string = '';
  @Input() applyButtonText: string = 'common.overflow_menu.apply';
  @Input() buttonSize: 'primary-button' | 'secondary-button' = 'primary-button';
  @Input() maxWidthValue: AppConstants | 'none';

  @Input() optionsEndpoint: OverflowFilterSelectEndPoint<T>;

  @Output() staticListLoaded = new EventEmitter<OverflowSelectOption[]>();
  @Output() selectedOptionsApplied = new EventEmitter<OverflowSelectOption[]>();
  @Output() saveSelectionClicked = new EventEmitter<OverflowSelectOption[]>();

  menuOpened = false;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');

  searchedOptions: OverflowSelectOption[];
  selectedOptionsUnsaved: OverflowSelectOption[] = [];
  options: OverflowSelectOption[];
  fixedColumns: OverflowSelectOption[];

  readonly DEBOUNCE_TIME = 300;
  readonly MIN_SEARCH_TEXT = 3;

  ngOnInit() {
    if (this.liveSearch) {
      this.getListFromBackendOnSearchChange();
    } else {
      this.optionsEndpoint()
        .pipe(untilDestroyed(this), map(this.mapToFilterOptions.bind(this)))
        .subscribe((result) => {
          this.fixedColumns = result.filter((option) => option.hidden);
          this.options = result;
          this.searchedOptions = [...this.options];

          this.staticListLoaded.emit(this.options);

          this.filterListOnSearchChange();
        });
    }
  }

  ngOnChanges(changes: TypedChanges<OverflowFilterSelectMenuComponent<T>>) {
    if (changes.selectedOptions) {
      this.resetSelectedOptionsUnsaved();
    }
  }

  toggleItem(selected: OverflowSelectOption) {
    const findIndex = this.selectedOptionsUnsaved.findIndex(
      (option) => selected.value === option.value
    );

    if (findIndex !== -1) {
      this.selectedOptionsUnsaved.splice(findIndex, 1);
    } else {
      this.selectedOptionsUnsaved.push(selected);
    }
  }

  search(searchText: string) {
    this.searchText.next(searchText);
  }

  private getListFromBackendOnSearchChange() {
    this.searchText
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(),
        untilDestroyed(this),
        switchMap((search) => {
          return !search || search.length < this.MIN_SEARCH_TEXT
            ? of([] as OverflowSelectOption[])
            : this.optionsEndpoint({ search }).pipe(map(this.mapToFilterOptions.bind(this)));
        })
      )
      .subscribe((result) => {
        this.searchedOptions = result;
      });
  }

  private filterListOnSearchChange() {
    this.searchText
      .pipe(debounceTime(this.DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((searchText) => {
        if (!searchText || searchText?.length < this.MIN_SEARCH_TEXT) {
          this.searchedOptions = [...this.options];
        } else {
          const searchTextLowerCase = searchText?.toLowerCase();
          this.searchedOptions = this.options.filter((option) => {
            if (option.type === 'base') {
              return TranslateInstance.instant(this.translatePrefix + option?.label)
                .toLowerCase()
                .includes(searchTextLowerCase);
            } else {
              return option.label.toLowerCase().includes(searchTextLowerCase);
            }
          });
        }
      });
  }

  private mapToFilterOptions(result: T[]): OverflowSelectOption[] {
    return result.map((item) => {
      return {
        label: ((item[this.labelKey] ?? item[this.translateKey]) as unknown) as string,
        value: (item[this.valueKey] as unknown) as string,
        hidden: (item[this.hiddenKey] as unknown) as boolean,
        type: (item[this.typeKey] as unknown) as string,
        disabled: (item[this.disabledKey] as unknown) as boolean,
        separatorText: (item[this.separatorTextKey] as unknown) as string,
      };
    });
  }

  applySelectedOptions() {
    this.selectedOptionsApplied.emit(this.selectedOptionsUnsaved);
    this.menuTrigger.closeMenu();
  }

  clearSelectedOptions() {
    if (this.selectedOptionsUnsaved.length) {
      this.selectedOptionsUnsaved = this.selectedOptionsUnsaved.filter(
        (option) => option.hidden ?? option.disabled
      );
    }
  }

  optionIsSelected(option: OverflowSelectOption) {
    return (
      this.selectedOptionsUnsaved.findIndex((selected) => selected.value === option.value) !== -1
    );
  }

  setMenuOpened(opened: boolean) {
    this.menuOpened = opened;

    if (!this.menuOpened) {
      this.resetSelectedOptionsUnsaved();
    }
  }

  resetSelectedOptionsUnsaved() {
    this.selectedOptionsUnsaved = this.selectedOptions ? [...this.selectedOptions] : [];
  }

  setRadioSelectedOption(value: string) {
    this.selectedOptionsUnsaved = [this.searchedOptions.find((item) => item.value === value)];
  }

  emitSaveSelectionClick() {
    this.saveSelectionClicked.emit(this.selectedOptionsUnsaved);
  }
}
