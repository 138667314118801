import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { NgControl } from '@angular/forms';
import { quillModules } from '@config/quill.config';
import { ContentChange } from 'ngx-quill/lib/quill-editor.component';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent extends FormInputBaseComponent<string> implements OnInit {
  @Input() isHintShown = true;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() required = false;

  modules = quillModules;

  constructor(@Self() @Optional() private ngControl: NgControl) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }

  textChanged(event: ContentChange) {
    const length = event.editor.getLength() - 1;

    if (length <= 0) {
      this.control.setErrors(this.required ? { required: true } : null);
      return;
    }

    if (this.minLength && length < this.minLength) {
      this.control.setErrors({ minlength: true });
      return;
    }

    if (this.maxLength && length >= this.maxLength) {
      this.control.setErrors({ maxlength: true });
    }
  }
}
