import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointsConfig } from '@config/endpoints.config';
import { HttpService } from '@shared/modules/http/http.service';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { SourceIsUsed } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/classes/SourceIsUsed';
import { SourceReplacement } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/classes/SourceReplacement';

@Injectable({
  providedIn: 'root',
})
export class SourceApiService {
  constructor(private http: HttpService) {}

  getSources(onlyActive?: boolean): Observable<SourceListItem[]> {
    const params: { [k: string]: string } = {};

    if (onlyActive) {
      params.onlyActive = onlyActive.toString();
    }

    return this.http.get(EndpointsConfig.sources, params);
  }

  createSource(source: SourceListItem): Observable<SourceListItem> {
    return this.http.post(EndpointsConfig.sources, { name: source.name, type: source.type });
  }

  updateSource(sourceId: number, name: string): Observable<SourceListItem> {
    return this.http.put(EndpointsConfig.sourceUpdateById(sourceId), { name });
  }

  deleteSource(sourceId: number, newPropertyType?: SourceReplacement): Observable<void> {
    return this.http.post(EndpointsConfig.sourceDeleteById(sourceId), newPropertyType);
  }

  archiveSource(source: SourceListItem): Observable<void> {
    return this.http.put(EndpointsConfig.sourceArchiveById(source.id), source);
  }

  activateSource(source: SourceListItem): Observable<void> {
    return this.http.put(EndpointsConfig.sourceActivateById(source.id), source);
  }

  checkUsageSource(sourceId: number): Observable<SourceIsUsed> {
    return this.http.get(EndpointsConfig.sourceCheckUsageById(sourceId));
  }
}
