import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SourceApiService } from '@pages/base-data/services/source/base/source-api.service';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { SourcesStateService } from '@pages/base-data/services/source/sources-state.service';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { SourceReplacement } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/classes/SourceReplacement';
import { BaseDataBaseService } from '@pages/base-data/services/base-data-base.service';

@Injectable({
  providedIn: 'root',
})
export class SourceService {
  constructor(
    private sourceApiService: SourceApiService,
    private toast: ToastService,
    private baseDataBaseService: BaseDataBaseService,
    private baseDataSourcesState: SourcesStateService
  ) {}

  callDeleteSource(
    sourceId: number,
    newPropertyType?: SourceReplacement
  ): Observable<void | HttpErrorResponse> {
    return this.sourceApiService.deleteSource(sourceId, newPropertyType).pipe(
      tap(() => {
        this.refreshSource();
      }),
      this.baseDataBaseService.handleSaveSuccess('base_data.sources.source_delete_message'),
      this.baseDataBaseService.handleSaveError('base_data.sources.source_delete_message')
    );
  }

  callArchiveSource(data: SourceListItem): Observable<void | HttpErrorResponse> {
    return this.sourceApiService.archiveSource(data).pipe(
      tap(() => {
        this.refreshSource();
      }),
      this.baseDataBaseService.handleSaveSuccess('base_data.sources.source_archive_message'),
      this.baseDataBaseService.handleSaveError('base_data.sources.source_archive_message')
    );
  }

  callActivateSource(data: SourceListItem): Observable<void | HttpErrorResponse> {
    return this.sourceApiService.activateSource(data).pipe(
      tap(() => {
        this.refreshSource();
      }),
      this.baseDataBaseService.handleSaveSuccess('base_data.sources.source_activate_message'),
      this.baseDataBaseService.handleSaveError('base_data.sources.source_activate_message')
    );
  }

  callUpdateSource(source: SourceListItem): Observable<SourceListItem | HttpErrorResponse> {
    return this.sourceApiService.updateSource(source.id, source.name).pipe(
      tap((sourceListItem: SourceListItem) => {
        this.baseDataSourcesState.setState({ sourceListItem });
      }),
      this.baseDataBaseService.handleSaveSuccess('base_data.sources.source_modify'),
      this.baseDataBaseService.handleSaveError('base_data.sources.source_modify')
    );
  }

  callCreateSource(source: SourceListItem): Observable<SourceListItem | HttpErrorResponse> {
    return this.sourceApiService
      .createSource(source)
      .pipe(
        this.baseDataBaseService.handleSaveSuccess('base_data.sources.source_modify'),
        this.baseDataBaseService.handleSaveError('base_data.sources.source_modify')
      );
  }

  private refreshSource(): Observable<SourceListItem[]> {
    return this.sourceApiService.getSources().pipe(
      tap((sources: SourceListItem[]) => {
        this.baseDataSourcesState.setState({ sources });
      })
    );
  }
}
