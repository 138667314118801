import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { EndpointsConfig } from '@config/endpoints.config';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { NormalizedPositionDto } from '@pages/positions/classes/NormalizedPositionDto';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { ListData, ListTotalCount } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { AppConstants } from '@config/app.constant';
import { PositionListItem } from '@pages/positions/classes/PositionListItem';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { PositionBulkUpdateState } from '@pages/positions/classes/PositionBulkUpdateState';
import { PositionBulkUpdateDto } from '@pages/positions/classes/PositionBulkUpdateDto';
import { BulkEditResponse } from '@shared/classes/BulkEditResponse';
import { PositionDropdownItem } from '@pages/positions/classes/PositionDropdownItem';
import { omit } from 'lodash-es';
import { PositionType } from '@pages/positions/classes/PositionType';
import {
  HistoryMessageInputTypes,
  HistoryMessageType,
} from '@shared/modules/history-message/classes/HistoryMessageType';
import { getPaginatedParams } from '@shared/utils/get-page-per-page.util';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PositionsApiService {
  constructor(protected http: HttpService) {}

  getPositions(
    page: string,
    perPage: string,
    filter?: string
  ): Observable<ListData<PositionListItem>> {
    const params: { [k: string]: string } = {
      page,
      perPage,
    };
    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.positions, params);
  }

  getPartnersForDropdown(): Observable<PartnerDropdownItem[]> {
    return this.http.get(EndpointsConfig.partnersDropdown);
  }

  getPositionById(positionId: number): Observable<PositionDetail> {
    return this.http.get(EndpointsConfig.positionById(positionId));
  }

  getPositionTypes(): Observable<PositionType[]> {
    return this.http.get(EndpointsConfig.positionTypes);
  }

  getPositionTotalCount(filter?: string): Observable<ListTotalCount> {
    const params: { [k: string]: string } = {};

    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.positionsTotalCount, params);
  }

  deletePosition(positionId: number): Observable<boolean> {
    return this.http.delete(EndpointsConfig.positionById(positionId));
  }

  createPosition(positionDto: PositionDto): Observable<PositionDetail> {
    return this.http.post(EndpointsConfig.positions, this.normalizePositionDto(positionDto));
  }

  updatePosition(positionDto: PositionDto): Observable<PositionDetail> {
    return this.http.put(
      EndpointsConfig.positionById(positionDto.id),
      this.normalizePositionDto(positionDto)
    );
  }

  getHistory(
    positionId: number,
    page = 1,
    type?: HistoryMessageType
  ): Observable<ListData<HistoryMessage>> {
    return this.http.get(
      EndpointsConfig.positionHistory(positionId),
      getPaginatedParams(page, AppConstants.historyMessagesPerPage, type)
    );
  }

  createHistoryMessage(
    positionId: number,
    data: { message: string; type?: HistoryMessageInputTypes }
  ): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.positionHistory(positionId), data);
  }

  updateHistoryMessage(
    positionId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.positionHistoryWithId(positionId, historyId), {
      message,
    });
  }

  deleteHistoryMessage(positionId: number, historyId: number): Observable<unknown> {
    return this.http.delete(EndpointsConfig.positionHistoryWithId(positionId, historyId));
  }

  toggleStatus(positionId: number, status: PositionStatus): Observable<PositionDetail> {
    return this.http.put(EndpointsConfig.positionStatus(positionId), {
      status: status === PositionStatus.Active ? PositionStatus.Inactive : PositionStatus.Active,
    });
  }

  getPositionFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.positionsFirstLetters);
  }

  getPositionFilters(): Observable<{ [key: string]: CardFilterInterface }> {
    return this.http.get(EndpointsConfig.positionsFilters);
  }

  bulkUpdatePosition(positionBulkUpdate: PositionBulkUpdateState): Observable<BulkEditResponse> {
    return this.http.put(
      EndpointsConfig.positionsBulkEdit,
      this.normalizePositionBulkEdit(positionBulkUpdate)
    );
  }

  getPositionsForDropdown(projectId: number, userId?: number): Observable<PositionDropdownItem[]> {
    const params = {};
    if (userId > 0) {
      Object.assign(params, { userId });
    }
    return this.http.get(EndpointsConfig.positionsDropdown(projectId), params);
  }

  exportPositionTable(filter: string): Observable<HttpResponse<Blob>> {
    const params = filter ? { filter } : null;

    return this.http.getFile(EndpointsConfig.positionsExport, params);
  }

  exportPositionTableEmail(filter: string): Observable<void> {
    const params = filter ? { filter } : null;

    return this.http.get(EndpointsConfig.positionsExportEmail, params);
  }

  private normalizePositionBulkEdit(
    position: PositionBulkUpdateState
  ): Partial<PositionBulkUpdateDto> {
    return {
      projectId: +position?.projectId?.id || undefined,
      status: position?.status?.id || undefined,
      jobTypeId: +position?.jobTypeId?.id || undefined,
      jobSubTypeId: +position?.jobSubTypeId?.id || undefined,
      employmentTypeId: +position?.employmentTypeId?.id || undefined,
      specializations:
        position?.specializations?.length > 0
          ? position.specializations.map((spec) => spec.id)
          : undefined,
      positionIds: position.positionIds,
    };
  }

  private normalizePositionDto(positionDto: PositionDto): NormalizedPositionDto {
    const dto = { ...positionDto };

    if (!dto.id) {
      delete dto.id;
    }

    delete dto.code;

    const specializations = dto.specializations.map((specialization) => specialization.id);

    return omit<NormalizedPositionDto, ['jobType']>(
      {
        ...dto,
        jobTypeId: dto.jobType.typeId,
        jobSubTypeId: dto.jobType.subTypeId,
        specializations,
      },
      'jobType'
    );
  }
}
