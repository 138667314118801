import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes/pipes.module';
import { PositionSelectorComponent } from './components/position-selector/position-selector.component';

@NgModule({
  declarations: [PositionSelectorComponent],
  imports: [CommonModule, FormComponentsModule, ReactiveFormsModule, PipesModule],
  exports: [PositionSelectorComponent],
})
export class PositionSelectorModule {}
