import { Component, Input, OnInit } from '@angular/core';
import { CandidateSearchItem } from '@pages/positions/classes/board/CandidateSearchItem';
import { SumIconData } from '@pages/positions/classes/board/SumIconData';
import { getSumIconData } from '@pages/positions/utils/sum-icon.util';
import { head } from 'lodash-es';

@Component({
  selector: 'app-candidate-search-option-template-item',
  templateUrl: './candidate-search-option-template-item.component.html',
  styleUrls: ['./candidate-search-option-template-item.component.scss'],
})
export class CandidateSearchOptionTemplateItemComponent implements OnInit {
  @Input() candidateSearchItem: CandidateSearchItem;
  icon: SumIconData;

  ngOnInit(): void {
    this.icon = head<SumIconData>(getSumIconData(this.candidateSearchItem?.icons, true));
  }
}
