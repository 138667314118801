<ng-template let-item="item">
    <div class="d-flex align-items-center px-2">
        <span class="status-name truncate">{{item.name}}</span>
        <app-hint
            *ngIf="hintText"
            class="d-block status-option-hint"
            [hintText]="hintText"
            variation="boardItemHint"
            [hintTypes]="[hintTypes.Help]">
        </app-hint>
    </div>
</ng-template>
