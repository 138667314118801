<div class="d-flex align-items-center">
    <app-simple-button
        [type]="'button'"
        [buttonClass]="BUTTON_CLASS.Icon"
        [icon]="icon"
        [text]="''"
        (buttonClick)="navigate()">
    </app-simple-button>
    <h1 class="ml-4">{{title | translate}}</h1>
</div>
