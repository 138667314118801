import { difference, values } from 'lodash-es';

export enum UserRole {
  SuperAdmin = 'super-admin',
  Admin = 'admin',
  FieldManager = 'field-manager',
  ProjectManager = 'project-manager',
  Partner = 'partner',
  Candidate = 'candidate',
  Technical = 'technical',
  WhcContact = 'whc-contact', // whc kapcsolattartó
  PhoneContact = 'phone-contact', // telefonos megkeresett
  UserContact = 'user-contact', // megkeresett
  UnAuthorized = 'unAuthorized',
  Empty = 'empty',
}

export const getAllRoles: UserRole[] = values(UserRole);

export function getAllRolesExcept(userRoles: UserRole[]): UserRole[] {
  return difference(getAllRoles, userRoles);
}
