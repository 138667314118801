import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';
import { BehaviorSubject } from 'rxjs';
import { TableService } from '@shared/services/table.service';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { PositionModalService } from '@pages/positions/services/details/position-modal.service';
import { SwitchTableCellData } from '@shared/modules/data-table/classes/SwitchTableCellData';

@Component({
  selector: 'app-switch-table-cell',
  templateUrl: './switch-table-cell.component.html',
  styleUrls: ['./switch-table-cell.component.scss'],
})
export class SwitchTableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<unknown>;

  beforeChange$ = new BehaviorSubject<boolean>(false);
  statuses = ProjectStatus;
  bulkEditPermission = [UserPermission.BulkEdit];

  constructor(
    private tableService: TableService,
    private positionModalService: PositionModalService,
    private auth: AuthService
  ) {}

  onToggleChange(value: SwitchTableCellData) {
    if (AuthUtils.isContainUserPermission(this.bulkEditPermission, this.auth.getUserRoles())) {
      const togglePos: TogglePositionStatus = {
        id: value.id,
        positionName: value.positionName,
        isActive: value.status === this.statuses.Active,
      };
      this.positionModalService.openTogglePositionStatusConfirmModal(togglePos);
    }
  }
}
