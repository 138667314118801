import { Injectable } from '@angular/core';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getErrorTranslationKey } from '@shared/utils/error-keys.util';
import { UnknownErrorResponse } from '@shared/classes/common/HttpErrorResponse';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService {
  constructor(private toast: ToastService) {}

  handleError(errorResponse: UnknownErrorResponse): void {
    if (errorResponse.status === 403) {
      this.toast.showError('validation_errors.permission_denied');
      return;
    }

    const errors: unknown = errorResponse?.error?.errors || errorResponse?.error?.error;

    if (!errors) {
      return;
    }

    const firstError = getErrorTranslationKey(errors, null);
    if (!firstError) {
      return;
    }

    this.toast.showError(firstError);
  }
}
