import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { User } from '@shared/modules/auth/classes/User';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { AppConfig } from '@config/app.config';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { ApplicationInsightsService } from '@shared/services/application-insights.service';
import { AppRouteData } from '@shared/classes/common/AppRouteData';
import { RendererInstance } from '@shared/utils/renderer-instance.util';

/** Main component */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  isSideFilterOpened: boolean;
  slideHeader: boolean;

  loggedInUser: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private sideFilterService: SideFilterService,
    private applicationInsightsService: ApplicationInsightsService,
    renderer: Renderer2
  ) {
    super();

    RendererInstance.renderer = renderer;
  }

  ngOnInit() {
    this.initLoggedInUser();
    this.initTranslationConfig();
    this.listenForNavigationEndChanges();
    this.listenSideFilterToggleChanges();

    return super.ngOnInit();
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  private initTranslationConfig(): void {
    this.translate.addLangs(AppConfig.languages);
    this.translate.setDefaultLang(AppConfig.defaultLanguage);
    this.translate.use(AppConfig.defaultLanguage);
    TranslateInstance.translateService = this.translate;
  }

  listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return {
                snapshot: child.snapshot,
                url: event.urlAfterRedirects,
              };
            } else {
              return {
                snapshot: null,
                url: event.urlAfterRedirects,
              };
            }
          }

          return {
            snapshot: null,
            url: event.urlAfterRedirects,
          };
        }),
        tap(({ snapshot, url }: { snapshot: ActivatedRouteSnapshot; url: string }) => {
          const title = this.setTitle(snapshot) || '';
          this.applicationInsightsService.logPageView(title, url);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  listenSideFilterToggleChanges(): void {
    this.sideFilterService
      .select('opened')
      .pipe(untilDestroyed(this))
      .subscribe((isOpened) => (this.isSideFilterOpened = isOpened));
  }

  setTitle(snapshot: ActivatedRouteSnapshot): string {
    const routeData = snapshot?.data as AppRouteData;

    if (!routeData?.title) {
      return null;
    }

    const title = TranslateInstance.instant(routeData.title);
    this.titleService.setTitle(title);
    return title;
  }

  ngOnDestroy() {
    sessionStorage.clear();
    return super.ngOnDestroy();
  }
}
