import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModalModule } from '@shared/modules/mat-modal/mat-modal.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { JobTypeSpecializationSelectionModule } from '@shared/modules/selections/job-type-specialization-selection/job-type-specialization-selection.module';
import { PartnerProjectPositionSelectionModule } from '@shared/modules/selections/partner-project-position-selection/partner-project-position-selection.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { HintModule } from '@shared/modules/hint/hint.module';
import { MatIconModule } from '@angular/material/icon';
import { ManageAdvertisementModalComponent } from './manage-advertisement-modal/manage-advertisement-modal.component';
import { ManagePositionModalComponent } from './manage-position-modal/manage-position-modal.component';
import { KanbanBoardAddCandidateModalComponent } from './kanban-board-add-candidate-modal/kanban-board-add-candidate-modal.component';
import { RejectReasonCandidateModalComponent } from './reject-reason-candidate-modal/reject-reason-candidate-modal.component';
import { ManageProviderModalComponent } from './manage-provider-modal/manage-provider-modal.component';
import { UploadAdvertisementImageModalComponent } from './upload-advertisement-image-modal/upload-advertisement-image-modal.component';
import { BulkEditPositionModalComponent } from './bulk-edit-position-modal/bulk-edit-position-modal.component';
import { OutsourcedModalComponent } from './outsourced-modal/outsourced-modal.component';
import { CandidateSearchOptionTemplateComponent } from '../../form-components/select/templates/candidate-search-option-template/candidate-search-option-template.component';
import { CandidateSearchOptionTemplateItemComponent } from '../../form-components/select/templates/candidate-search-option-template/candidate-search-option-template-item/candidate-search-option-template-item.component';
import { RejectCandidateSelectionOptionTemplateComponent } from '../../form-components/select/templates/reject-candidate-selection-option-template/reject-candidate-selection-option-template.component';
import { MultipleSelectionModule } from '@shared/modules/selections/multiple-selection/multiple-selection.module';

@NgModule({
  declarations: [
    ManagePositionModalComponent,
    KanbanBoardAddCandidateModalComponent,
    RejectReasonCandidateModalComponent,
    ManageAdvertisementModalComponent,
    ManageProviderModalComponent,
    UploadAdvertisementImageModalComponent,
    BulkEditPositionModalComponent,
    OutsourcedModalComponent,
    CandidateSearchOptionTemplateComponent,
    CandidateSearchOptionTemplateItemComponent,
    RejectCandidateSelectionOptionTemplateComponent,
  ],
  imports: [
    CommonModule,
    MatModalModule,
    ReactiveFormsModule,
    FormComponentsModule,
    FilteringModule,
    TranslateModule,
    PipesModule,
    JobTypeSpecializationSelectionModule,
    PartnerProjectPositionSelectionModule,
    DirectivesModule,
    HintModule,
    MatIconModule,
    MultipleSelectionModule,
  ],
})
export class PositionModalsModule {}
