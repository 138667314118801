<div class="partner-detail-header h-100 d-flex flex-grow-1 align-items-center justify-content-between">
    <ng-container *ngIf="partnerName$ | async" >
        <div class="d-flex align-items-center">
            <app-back-arrow-button
                (buttonClick)="onNavigation()"
                class="back-button d-block">
            </app-back-arrow-button>

            <div *ngIf="isPartnerImageShown" class="partner-logo">
                <img class="logo-img"
                    [src]="(partnerLogoUrl$ | async) || placeholder"
                    [appImgFallback]="placeholder"
                    alt="Partner Logo">
            </div>

            <div class="partner-name">{{partnerName$ | async}}</div>

            <div class="ml-3">
                <app-hint *ngIf="isSyncOn"
                    [placement]="'right'"
                    [filePath]="syncIconPath"
                    [hintTypes]=[HINT_TYPE.Sync]
                    [hintText]="syncHintText"
                    [variation]="'syncMessageTooltip'">
                </app-hint>
            </div>
        </div>

        <div class="d-flex">
            <app-delete-button
                [modalConfig]="deleteModalConfig"
                [disabled]="!isDeletable"
                [hintText]="deleteHintText"
                (deleteSuccess)="onNavigation()"
            ></app-delete-button>
        </div>
    </ng-container>
</div>
