import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  filterManageInitialState,
  FilterManageState,
} from '@shared/modules/state-manager/state/filters/filter-manage.state';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { UserFilterApiService } from '@shared/services/user-filter-api.service';
import { GetUserFilter } from '@shared/classes/users/GetUsersFilter';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FilteringManageService extends StateService<FilterManageState> {
  constructor(
    private userFilterApiService: UserFilterApiService,
    private toast: ToastService,
    private modalService: MatModalService
  ) {
    super(filterManageInitialState);
  }

  resetFilterManageState(): void {
    this.setState({
      selectedUserFilter: filterManageInitialState.selectedUserFilter,
    });
  }

  setFilterManageState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      selectedUserFilter: { ...this.getStateSnapshot().selectedUserFilter, ...filterObj },
    };
    this.setState(newState);
  }

  deleteFilter(): Observable<GetUserFilter[] | HttpErrorResponse> {
    const filterId = this.getStateSnapshot().selectedUserFilter.id;

    return this.userFilterApiService.deleteUserFilterById(filterId).pipe(
      tap((filters) => {
        this.setState({ savedUserFilters: filters });
        this.toast.showSuccess(getGeneralMessage('common.delete_filter', true));
      }),
      catchError((res: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('common.delete_filter', true));
        return of(res);
      }),
      finalize(() => {
        this.resetFilterManageState();
      })
    );
  }

  openDeleteFilterModal(): Observable<GetUserFilter[] | false> {
    const filterName = this.getStateSnapshot().selectedUserFilter.name;
    return this.modalService.openConfirmModal({
      data: {
        modalText: TranslateInstance.instant('side_filter.delete_filter_modal_text', {
          filterName,
        }),
        primaryButtonText: 'common.delete_confirm',
        primaryButtonColor: 'warn',
        confirmObservable: this.deleteFilter(),
      },
    }) as Observable<GetUserFilter[] | false>;
  }

  callSaveFilter(isEditMode: boolean) {
    const userFilter = this.getStateSnapshot().selectedUserFilter;
    const endpoint: Observable<GetUserFilter[] | GetUserFilter> = isEditMode
      ? this.userFilterApiService.updateUserFilterById(userFilter.id, userFilter)
      : this.userFilterApiService.saveUserFilter(userFilter);

    return this.handleSaveFilterResult(endpoint, isEditMode);
  }

  private handleSaveFilterResult(
    endpoint: Observable<GetUserFilter[] | GetUserFilter>,
    isEditMode: boolean
  ) {
    const message = !isEditMode ? `common.create_filter` : `common.update_filter`;

    return endpoint.pipe(
      tap((filters: GetUserFilter[] | GetUserFilter) => {
        this.setState({ savedUserFilters: filters as GetUserFilter[] });
        this.toast.showSuccess(getGeneralMessage(message, true));
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      }),
      finalize(() => {
        this.resetFilterManageState();
      })
    );
  }
}
