import { Pipe, PipeTransform } from '@angular/core';
import { ATSBasedText, getATSBasedText } from '@shared/utils/ats-based-text.util';

@Pipe({
  name: 'appATSBasedText',
})
export class AtsBasedTextPipe implements PipeTransform {
  transform(typeBasedText: ATSBasedText): string {
    return getATSBasedText(typeBasedText);
  }
}
