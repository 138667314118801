<label *ngIf="label" [for]="textFieldId">
    <app-text-field-label
        [hintText]="hintText"
        [isHintShown]="isHintShown"
        [control]="control"
        [label]="label">
    </app-text-field-label>
</label>

<ng-container *ngIf="existingImageUrl">
    <div *ngIf="permanentHintText" class="permanent-hint mb-10px">
        {{'Jelenlegi fotó' | translate}}
    </div>
    <img [src]="existingImageUrl" class="existing-photo" alt="Uploaded photo">
</ng-container>

<input
    type="file"
    (change)="onFileChange($event); fileInput.value = null"
    [accept]="supportedFiles"
    [id]="textFieldId"
    hidden
    #fileInput
/>

<div *ngIf="permanentHintText" class="permanent-hint mb-10px">
    <span class="mr-5px">{{permanentHintText | translate}}</span>
    <app-hint
        *ngIf="control.errors"
        class="d-inline-block align-middle"
        [hintTypes]="[hintType.Error]"
        [errors]="control.errors">
    </app-hint>
</div>

<div class="whc-form-field has-icon w-100 d-flex justify-content-center align-items-center cursor-pointer"
     [class.field-disabled]="control.disabled">
    <input
        [class.w-100]="!fileName"
        class="cursor-pointer"
        [placeholder]="!label && placeholder || '' | translate | appFirstLetterUppercase"
        type="text"
        [value]="fileName"
        readonly
        (click)="fileInput.click()"
    />
    <span *ngIf="control.value" class="d-flex justify-content-end align-items-center">
        <app-icon-button
            (buttonClick)="onClearFile()"
            textAlign="right"
            iconUrl="assets/image/close-icon.svg">
        </app-icon-button>
    </span>
</div>
