import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  sideFilterInitialState,
  SideFilterState,
} from '@shared/modules/state-manager/state/side-filter/side-filter.state';

@Injectable({
  providedIn: 'root',
})
export class SideFilterService extends StateService<SideFilterState> {
  constructor() {
    super(sideFilterInitialState);
  }

  toggleSideFilter(isOpen?: boolean) {
    this.setState({ opened: isOpen });
  }
}
