<ng-container *ngIf="total > 0; else noResults">
    <table [ngClass]="[customTableClass]" aria-describedby="card-table-desc">
        <thead>
        <tr>
            <th *ngFor="let column of columns">
                {{column.translationKey | translate | appFirstLetterUppercase}}
            </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let item of data | slice : 0 : perPage * currentPage"
            (click)="onNavigate(item.id)"
            [ngClass]="
                {
                    'highlighted': highlightPredicate && highlightPredicate(item),
                    'cursor-pointer': showPointerCursor
                }">
            <td *ngFor="let column of columns">
                <ng-container *ngIf="column.templateRef">
                    <ng-container
                        [ngTemplateOutlet]="column.templateRef"
                        [ngTemplateOutletContext]="{$implicit: column.onlyPassPropertyValue ? item[column.columnKey] : item}">
                    </ng-container>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>

    <app-load-more-button
        [total]="total"
        [currentPage]="currentPage"
        [data]="data"
        [perPage]="perPage"
        (buttonClick)="onLoadMore()">
    </app-load-more-button>
</ng-container>

<ng-template #noResults>
    <div class="d-flex justify-content-center my-10px">
        {{'common.not_found' | translate}}
    </div>
</ng-template>
