import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isObservable, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { toPairs, values } from 'lodash-es';
import { TranslationParam } from '@shared/classes/TranslationParam';
import { StandardObject } from '@shared/classes/common/StandardObject';

/*
 * Translates inner params as well as main translate key
 */
@Pipe({
  name: 'appTranslateWithParams',
})
export class TranslateWithParamsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    translationKey: string,
    params: TranslationParam | Observable<TranslationParam>
  ): Observable<string> {
    const params$: Observable<TranslationParam> = isObservable(params) ? params : of(params);

    return params$.pipe(
      switchMap((paramsToTranslate: TranslationParam) =>
        this.translate.stream(values(paramsToTranslate)).pipe(
          switchMap((rawTranslatedParams) => {
            const normalizedTranslatedParams: StandardObject = {};

            toPairs(paramsToTranslate).forEach(([key, value]: [string, string]) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              normalizedTranslatedParams[key] = rawTranslatedParams[value];
            });

            return this.translate.get(
              translationKey,
              normalizedTranslatedParams
            ) as Observable<string>;
          })
        )
      )
    );
  }
}
