import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ExportDataFormat } from '@shared/classes/ExportDataFormat';
import { merge } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { environment } from '@environments/environment';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ExportModalData } from '@shared/modules/modals/base-modals/export-modal/classes/ExportModalConfig';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@UntilDestroy()
@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent extends MatModalBaseComponent<ExportModalData> implements OnInit {
  exportTypes: ExportDataFormat[];
  exportTypeControl = new FormControl(null, [Validators.required]);
  exportCount: number;
  exportInfoText: string;
  exportCountLimit = environment.exportCount;

  constructor(private headerService: HeaderService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.exportTypes = [{ id: 1, type: 'xlsx', name: TranslateInstance.instant('common.xlsx') }];
    this.listenControlChange();

    this.exportCount = this.headerService.getStateSnapshot().filterTotalCount;

    if (this.exportCount > this.exportCountLimit) {
      this.setExportInfoText();
    }
  }

  setExportInfoText(): void {
    this.exportInfoText = TranslateInstance.instant('common.export_info', {
      exportNumberLimit: this.exportCountLimit.toString(),
    });
  }

  listenControlChange(): void {
    merge(this.exportTypeControl.valueChanges, this.exportTypeControl.statusChanges)
      .pipe(
        startWith(this.exportTypeControl.value),
        map(() => {
          if (this.exportTypeControl.status === 'PENDING') {
            return true;
          }

          return this.exportTypeControl.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.primaryButtonDisabled = isSaveButtonDisabled;
      });
  }

  export(): void {
    this.primaryButtonDisabled = true;
    this.loading = true;
    this.exportTypeControl.disable();

    this.data
      .exportFunction()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.closeModal();
        },
        error: () => {
          this.primaryButtonDisabled = false;
          this.loading = false;
          this.exportTypeControl.enable();
        },
      });
  }
}
