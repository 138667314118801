<div class="datatable-container custom-table"
     [ngClass]="{
        'hide-checkboxes': hideCheckboxes,
        'show-pointer': showPointerCursor,
        'table-header-without-checkbox-column': noCheckboxColumn
    }">

    <ngx-datatable
        appDataTableRowListener
        [dataChange$]="dataChange$.asObservable()"
        (mouseWheelPushed)="onMouseWheelPushed($event)"
        class="datatable"
        [rows]="data"
        [columns]="columnConfigWithCustomCells"
        [columnMode]="columnMode.force"
        [scrollbarV]="true"
        [scrollbarH]="true"
        [draggable]="false"
        [swapColumns]="false"
        [virtualization]="true"
        [headerHeight]="headerHeight"
        [rowHeight]="rowHeight"
        [messages]="messages"
        [selectAllRowsOnPage]="false"
        [externalSorting]="externalSorting"
        [selectionType]="selectionType.checkbox"
        (scroll)="onScroll($event.offsetY)"
        (activate)="onActivate($event)"
        (select)="onSelect($event)"
        (sort)="sortBy.emit($event)"
        [sorts]="sorts"
        (reorder)="reorder.emit($event)"
        (resize)="onResize()">

        <ng-template *appCustomCellTemplate></ng-template>
    </ngx-datatable>
</div>

<a id="hidden-anchor" href="#" target="_blank" rel="noopener" hidden></a>

