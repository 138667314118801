export enum HistoryMessageType {
  Customer = 'CUSTOMER',
  System = 'SYSTEM',
  EmployeeNeeds = 'EMPLOYEE_NEEDS',
  SystemEmail = 'SYSTEM_EMAIL',
  SystemSms = 'SYSTEM_SMS',
}

export type HistoryMessageInputTypes =
  | HistoryMessageType.Customer
  | HistoryMessageType.EmployeeNeeds;
