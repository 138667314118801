import { Pipe, PipeTransform } from '@angular/core';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';

@Pipe({
  name: 'appHasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(permissions: UserPermission[]): boolean {
    if (this.auth.isSuperAdmin) {
      return true;
    }

    return AuthUtils.isContainUserPermission(permissions, this.auth.getUserRoles());
  }
}
