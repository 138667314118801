<ng-container [formGroup]="group">
    <app-form-field>
        <app-select
          label="common.country"
          [options]="countries$ | async"
          bindLabel="name"
          bindValue="id"
          [dropdownPosition]="dropdownPosition"
          [formControl]="countryControl">
        </app-select>
    </app-form-field>

    <ng-container *ngIf="countryControl.value">
        <div class="settlement d-flex justify-content-between align-items-end">
            <app-form-field>
                <app-text-field
                  label="common.zip_code"
                  [formControl]="zipCodeControl">
                </app-text-field>
            </app-form-field>

            <app-form-field>
                <app-select
                  (typeaheadChange)="cityNameChange$.next($event)"
                  (selectionChange)="onCitySelectionChange($event)"
                  label="common.settlement"
                  [hintText]="isCityHintShown ? citiesHintText : null"
                  [options]="cities"
                  [searchable]="true"
                  [useTypeahead]="true"
                  [clearable]="isCityClearable"
                  [dropdownPosition]="dropdownPosition"
                  bindLabel="name"
                  bindValue="id"
                  bindValueByPropertyNameToControl="id"
                  [formControlName]="controlNameConfig.cityId">
                </app-select>
            </app-form-field>
        </div>

        <ng-container *ngIf="showStreetControl">
            <app-form-field>
                <app-text-field
                  label="common.street_house"
                  [formControlName]="controlNameConfig.street">
                </app-text-field>
            </app-form-field>
        </ng-container>
    </ng-container>
</ng-container>
