import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { NgControl } from '@angular/forms';
import { BlobToBase64Converter } from '@shared/utils/blob-to-base64.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
})
export class PhotoUploadComponent extends FormInputBaseComponent<string> implements OnInit {
  @Input() existingImageUrl: string;
  @Input() permanentHintText: string;
  @Input() storeBinaryFile = false;
  @Output() keyUp = new EventEmitter<void>();
  fileName = '';
  supportedFiles: string;

  constructor(@Self() @Optional() private ngControl: NgControl, private toast: ToastService) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    this.supportedFiles = this.getSupportedFileExtensions();
    this.listenImageSizeError();
    return superCalled;
  }

  onFileChange(event: Event) {
    const files = (event?.target as HTMLInputElement)?.files;

    if (files?.length && files[0]) {
      const photo: File = files[0];

      if (!this.isExtensionValid(photo.name)) {
        this.onClearFile();
        this.toast.showError('validation_errors.invalid_file_extension');
        return;
      }

      if (!this.isSizeValid(photo)) {
        this.onClearFile();
        this.toast.showError('validation_errors.big_file_size');
        return;
      }

      this.fileName = photo.name;

      if (!this.storeBinaryFile) {
        BlobToBase64Converter.convert(photo, true)
          .pipe(untilDestroyed(this))
          .subscribe((base64: string) => {
            this.control.setValue(base64);
          });
      } else {
        this.control.setValue(photo);
      }
    }
  }

  onClearFile() {
    this.fileName = '';
    this.control.setValue(null);
  }

  private getSupportedFileExtensions(): string {
    if (this.deviceService.isFirefox) {
      return AppConstants.supportedImageExtensions.map((extension) => `.${extension}`).join(',');
    }

    return AppConstants.supportedImageExtensions.map((extension) => `image/${extension}`).join(',');
  }

  private listenImageSizeError(): void {
    this.control.statusChanges
      .pipe(
        filter(() => this.control.hasError('image_size_invalid')),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.onClearFile();
      });
  }

  private isSizeValid(photo: File): boolean {
    return photo.size <= AppConstants.maxFileSizeBytes;
  }

  private isExtensionValid(photoName: string): boolean {
    const explodedName = (photoName || '').split('.');

    return (
      explodedName.length >= 1 &&
      AppConstants.supportedImageExtensions.indexOf(
        explodedName[explodedName.length - 1].toLowerCase()
      ) > -1
    );
  }
}
