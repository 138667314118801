<div class="filter-container-header">
    <app-select
        [placeholder]="'side_filter.my_filters_button' | translate"
        [options]="savedFilters"
        bindLabel="name"
        bindValue=""
        [formControl]="filter"
        [customClass]="'filter-ng-select'"
        (selectionChange)="selectedClicked.emit($event)">
        <app-filter-label-template #labelTemplate></app-filter-label-template>
        <app-filter-option-template #optionsTemplate></app-filter-option-template>
    </app-select>
    <app-dot-menu
        [menuItems]="menuConfig"
        (clicked)="dotMenuClicked.emit($event)"
        class="white-dot-button"
        placement="bottom-end">
    </app-dot-menu>
</div>
