<div class="w-100 d-flex align-items-center pt-1 px-1"
     [class.justify-content-between]="showSecondaryButton"
     [class.justify-content-end]="!showSecondaryButton">

    <button *ngIf="showSecondaryButton"
            (click)="emitSecondaryButtonClick($event)"
            class="button-link mr-2">
        {{secondaryButtonText | translate}}
    </button>

    <app-primary-button
        [ngClass]="[buttonSize]"
        color="basic"
        type="submit"
        [style]="'filled'"
        [text]="applyButtonText"
        (buttonClick)="emitApply()">
    </app-primary-button>
</div>
