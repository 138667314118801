import { Injectable } from '@angular/core';
import { AddCandidateToPositionPayload } from '@pages/positions/classes/board/AddCandidateToPositionPayload';
import { cloneDeep, head, omit } from 'lodash-es';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { PositionKanbanBoardApiService } from '@pages/positions/services/candidates/base/position-kanban-board-api.service';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { PositionState } from '@shared/modules/state-manager/state/positions/position.state';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { sortByLocale } from '@shared/utils/sort.util';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { KanbanBoardTableItem } from '@pages/positions/classes/board/table/KanbanBoardTableItem';
import { ListData } from '@shared/classes/ListData';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { environment } from '@environments/environment';
import { TableViewService } from '@shared/modules/custom-selectors/custom-table-view-selector/services/table-view.service';
import { PositionKanbanBoardTableStateService } from '@pages/positions/services/candidates/base/position-kanban-board-table-state.service';

@Injectable({
  providedIn: 'root',
})
export class PositionKanbanBoardService {
  constructor(
    private positionState: PositionStateService,
    private toast: ToastService,
    private candidateBoardApiService: PositionKanbanBoardApiService,
    private headerService: HeaderService,
    private tableViewService: TableViewService,
    private positionKanbanBoardTableState: PositionKanbanBoardTableStateService
  ) {}

  private getStateSnapshot() {
    return this.positionState.getStateSnapshot();
  }

  private setState(state: Partial<PositionState>) {
    return this.positionState.setState(state);
  }

  callAddCandidateToPosition(positionId: number, payload: AddCandidateToPositionPayload) {
    const selectedKanbanBoardColumn = this.getStateSnapshot().selectedKanbanBoardColumn;
    const dto: AddCandidateToPositionPayload = {
      statusId: selectedKanbanBoardColumn.id,
      statusOptionId: null,
      ...payload,
    };

    return this.candidateBoardApiService
      .addCandidateToPosition(
        positionId,
        payload.candidateId,
        omit(dto, 'candidateId') as MoveOrAddPositionDto
      )
      .pipe(
        concatMap((boardCandidate) => {
          this.toast.showSuccess(getGeneralMessage('candidates.candidate_assign', true));
          return this.addCandidateToColumn(boardCandidate);
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(this.getCandidatePositionUpdateErrorMessage(err));
          return of(err);
        })
      );
  }

  callUpdateCandidatePosition(
    positionId: number,
    moveOrAddDto?: Partial<MoveOrAddPositionDto>
  ): Observable<BoardCandidate | HttpErrorResponse> {
    const targetColumn = this.getStateSnapshot().selectedKanbanBoardColumn;
    const candidate = this.getStateSnapshot().selectedKanbanBoardCandidate;

    this.setState({ kanbanBoardLoading: true });

    return this.candidateBoardApiService
      .updateCandidatePosition(positionId, candidate.user.id, {
        ...moveOrAddDto,
        statusId: targetColumn.id,
        statusOptionId: moveOrAddDto?.statusOptionId || null,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(this.getCandidatePositionUpdateErrorMessage(err));
          return of(err);
        }),
        finalize(() => {
          this.setState({ kanbanBoardLoading: false });
        })
      );
  }

  addCandidateToPosition(positionId: number, candidate: CandidateDetail) {
    this.candidateBoardApiService
      .getBoardCandidate(positionId, candidate.id)
      .subscribe((boardCandidate) => {
        this.addCandidateToColumn(boardCandidate).subscribe();
      });
  }

  addCandidateToColumn(boardCandidate: BoardCandidate) {
    this.setState({ kanbanBoardLoading: true });

    const currentCandidatesByColumns = this.getStateSnapshot().candidatesByColumns;

    if (!currentCandidatesByColumns.get(boardCandidate.statusId)) {
      currentCandidatesByColumns.set(boardCandidate.statusId, [boardCandidate]);
    } else {
      currentCandidatesByColumns.get(boardCandidate.statusId).push(boardCandidate);
    }

    this.applySortForColumn(currentCandidatesByColumns, boardCandidate.statusId);

    this.setState({
      candidatesByColumns: currentCandidatesByColumns,
      kanbanBoardLoading: false,
    });

    return of(currentCandidatesByColumns);
  }

  private getCandidatePositionUpdateErrorMessage(err: MessageErrorResponse) {
    let errorMessage = getGeneralMessage('positions.board.modify', false);
    if (head<string>(err?.error?.errors) === 'position_outsourced_exists') {
      errorMessage = 'positions.board.is_outsourced_error';
    }
    return errorMessage;
  }

  getBoardCandidatesByColumn(positionId: number): Observable<Map<number, BoardCandidate[]>> {
    return this.candidateBoardApiService.getBoardCandidates(positionId).pipe(
      map((candidatesByColumn) => {
        return this.mapCandidatesByColumn(candidatesByColumn);
      })
    );
  }

  getBoardCandidatesForTable(
    positionId: number,
    columnIds: string[],
    base64EncodedFilter?: string
  ): Observable<ListData<KanbanBoardTableItem>> {
    return this.candidateBoardApiService
      .getBoardCandidatesForTable(positionId, base64EncodedFilter, columnIds)
      .pipe(
        tap(() => {
          this.positionState.setState({
            positionDetailLoaded: true,
          });
        }),
        map((boardCandidateList) => {
          this.headerService.setState({ filterTotalCount: boardCandidateList.length });
          return { data: boardCandidateList } as ListData<KanbanBoardTableItem>;
        })
      );
  }

  openExportTableModal(): Observable<void> {
    return this.headerService.openExportDataModal(this.callExportTable.bind(this));
  }

  private callExportTable(): Observable<void | HttpResponse<Blob> | HttpErrorResponse> {
    const boardCandidateFilter = this.positionKanbanBoardTableState.getStateSnapshot().filters;
    const sortedFilters = removeBlankAttributes(boardCandidateFilter);
    const selectedColumns = this.tableViewService.getSelectedColumnsFromState();
    const positionId = this.positionState.getStateSnapshot().positionDetail.id;
    let encodedFilters: string | undefined;

    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }

    if (this.headerService.getStateSnapshot().filterTotalCount <= environment.exportCount) {
      return this.headerService.handleExportResponse(
        this.candidateBoardApiService.exportBoardCandidateTable(
          positionId,
          encodedFilters,
          selectedColumns
        )
      );
    }

    return this.headerService.handleEmailExportResponse(
      this.candidateBoardApiService.exportBoardCandidateTableEmail(
        positionId,
        encodedFilters,
        selectedColumns
      )
    );
  }

  mapCandidatesByColumn(boardCandidates: BoardCandidate[]): Map<number, BoardCandidate[]> {
    const candidatesByColumns = new Map<number, BoardCandidate[]>();

    boardCandidates.forEach((candidate: BoardCandidate) => {
      if (!candidatesByColumns.has(candidate.statusId)) {
        candidatesByColumns.set(candidate.statusId, []);
      }

      candidatesByColumns.get(candidate.statusId).push(cloneDeep(candidate));
    });

    return candidatesByColumns;
  }

  applySortMode(candidatesByColumns: Map<number, BoardCandidate[]>) {
    candidatesByColumns.forEach((_, columnId) => {
      this.applySortForColumn(candidatesByColumns, columnId);
    });
  }

  applySortForColumn(candidatesByColumns: Map<number, BoardCandidate[]>, columnId: number) {
    const sortMode = this.positionState.getStateSnapshot().selectedSortMode;
    const candidates = candidatesByColumns.get(columnId);

    if (sortMode) {
      sortByLocale(candidates, sortMode.key, sortMode.sortBy);
      candidatesByColumns.set(columnId, candidates);
    }
  }
}
