import { RoleImageData } from '@shared/modules/data-table/classes/RoleImageData';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { AppConstants } from '@config/app.constant';

export const roleImagesConfig: { [key: string]: RoleImageData } = {
  [UserRole.Candidate]: {
    imageUrl: AppConstants.candidateImageUrl,
    tooltipText: 'candidates.icons.candidate',
  },
  [UserRole.PhoneContact]: {
    imageUrl: AppConstants.phoneContactImageUrl,
    tooltipText: 'candidates.icons.phone_contact',
  },
  [UserRole.UserContact]: {
    imageUrl: AppConstants.userContactImageUrl,
    tooltipText: 'candidates.icons.contact',
  },
};
