import { ValidatorFn, Validators } from '@angular/forms';
import {
  validationLaborHireAtsConfigs,
  validationStudentAtsConfigs,
} from '@shared/modules/modals/candidate-modals/manage-candidate-modal/config/validation.config';
import { ModalType } from '@shared/classes/ModalType';

export function setValidationByModalType(
  formControlName: string,
  modalType: ModalType,
  isStudentAts: boolean = false
): ValidatorFn[] {
  const validationConfig = isStudentAts
    ? validationStudentAtsConfigs[formControlName]
    : validationLaborHireAtsConfigs[formControlName];

  if (
    (Array.isArray(validationConfig?.type) && validationConfig?.type.includes(modalType)) ||
    modalType === validationConfig?.type
  ) {
    return Array.isArray(validationConfig.validator)
      ? [...validationConfig.validator]
      : [validationConfig.validator];
  }

  return [Validators.nullValidator];
}
