import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, merge, Observable } from 'rxjs';
import { KeyValue } from '@shared/classes/KeyValue';
import { JobType } from '@pages/candidates/classes/JobType';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { PartnerDropdownProject } from '@pages/partners/classes/PartnerDropdownProject';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { debounceTime, map, startWith, take } from 'rxjs/operators';
import { AppStateService } from '@shared/services/app-state.service';
import { PositionsService } from '@pages/positions/services/details/positions.service';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { entries, keys } from 'lodash-es';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { PositionFormService } from '@pages/positions/services/details/base/position-form.service';
import { ListTypeName } from '@config/app.constant';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ProjectMinimal } from '@pages/positions/classes/ProjectMinimal';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-position',
  templateUrl: './bulk-edit-position-modal.component.html',
  styleUrls: ['./bulk-edit-position-modal.component.scss'],
})
export class BulkEditPositionModalComponent
  extends MatModalBaseComponent
  implements OnInit, OnDestroy {
  form: FormGroup;
  statuses: KeyValue<string>[];
  jobTypes: JobType[] = [];
  specializations: Specialization[] = [];
  partners: PartnerDropdownItem[] = [];
  partnerControl = new FormControl();
  employmentTypes: EmploymentType[] = [];
  projects: PartnerDropdownProject[] = [];
  projectIdControl: FormControl;
  employmentTypeId: FormControl;
  jobTypeGroup: FormGroup;
  specializationArray: FormArray;
  status: FormControl;

  selectedItems$: Observable<number>;

  constructor(
    private bulkEditService: BulkEditService,
    private appService: AppStateService,
    private positionService: PositionsService,
    private positionApiService: PositionsApiService,
    private positionFormService: PositionFormService,
    protected injector: Injector
  ) {
    super(injector);

    this.loading = true;
    this.primaryButtonDisabled = true;
  }

  ngOnInit(): void {
    this.bulkEditService.setState({ listType: ListTypeName.Position });
    this.loadDependencies();
  }

  private loadDependencies() {
    const isEditMode = this.bulkEditService.getStateSnapshot().isEditMode;
    let positionBulkUpdate = this.bulkEditService.getStateSnapshot().positionBulkUpdate;

    if (!isEditMode) {
      positionBulkUpdate = undefined;
    }

    const dependencies = {
      partners: this.positionApiService.getPartnersForDropdown().pipe(take(1)),
      employmentTypes: this.appService.getEmploymentTypes().pipe(take(1)),
      specializations: this.appService.getSpecializations().pipe(take(1)),
      jobTypes: this.appService.getJobTypes().pipe(take(1)),
    };

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(({ partners, employmentTypes, specializations, jobTypes }) => {
        this.form = this.positionFormService.initBulkEditForm(positionBulkUpdate);
        this.projectIdControl = this.form.get('projectId') as FormControl;
        this.employmentTypeId = this.form.get('employmentTypeId') as FormControl;
        this.jobTypeGroup = this.form.get('jobType') as FormGroup;
        this.specializationArray = this.form.get('specializations') as FormArray;
        this.status = this.form.get('status') as FormControl;
        this.employmentTypes = employmentTypes;
        this.specializations = specializations;
        this.jobTypes = jobTypes;
        this.partners = partners;
        this.statuses = entries(PositionStatus).map(([key, value]) => ({
          key: TranslateInstance.instant(`positions.statuses.${key.toLowerCase()}`),
          value,
        }));

        this.partnerControl = new FormControl(positionBulkUpdate?.partner || null);
        this.projects = positionBulkUpdate?.partner?.projects || [];
        this.listenFormChanges();

        this.loading = false;
      });

    this.selectedItems$ = this.bulkEditService.select('selectedItems');
  }

  onPartnerChange(partner: PartnerDropdownItem): void {
    this.projectIdControl.reset(null);
    this.projectIdControl.setValidators([]);
    this.projectIdControl.updateValueAndValidity();
    this.projectIdControl.markAsTouched();
    this.projects = [];
    if (Array.isArray(partner?.projects)) {
      this.projects = partner.projects;

      if (partner.projects.length === 0) {
        setTimeout(() => {
          this.projectIdControl.setErrors({
            required: true,
            emptyProjectList: true,
          });
        });
      } else {
        this.projectIdControl.setValidators([Validators.required]);
        this.projectIdControl.updateValueAndValidity();
      }
    }
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(
        startWith(this.form.getRawValue()),
        debounceTime(300),
        map(() => {
          const statusValue = this.status.value as { value: string; key: string };
          const jobTypeGroupValue = this.jobTypeGroup.value as {
            typeId: number;
            subTypeId: number;
          };

          const jobTypeId: JobType = this.jobTypes.find(
            (job) => jobTypeGroupValue.typeId === job.id
          );

          let jobSubTypeId: JobType = null;

          if (jobTypeId) {
            jobSubTypeId = jobTypeId.subTypes.find((job) => jobTypeGroupValue.subTypeId === job.id);
          }

          const specialization = this.specializations.filter((spec: Specialization) =>
            this.specializationArray
              .getRawValue()
              .some((selectedSpec: Specialization) => spec.id === selectedSpec.id)
          );

          this.bulkEditService.setPositionBulkUpdateState({
            partner: this.partnerControl.value as PartnerDropdownItem,
            projectId: this.projectIdControl.value as ProjectMinimal,
            employmentTypeId: this.employmentTypeId.value as EmploymentType,
            specializations: specialization.length > 0 ? specialization : undefined,
            jobTypeId,
            jobSubTypeId,
            status: this.status.value ? { id: statusValue?.value, name: statusValue?.key } : null,
          });

          const savedState = { ...this.bulkEditService.getStateSnapshot().positionBulkUpdate };
          delete savedState.positionIds;

          if (keys(removeBlankAttributes(savedState)).length === 0) {
            return true;
          }
          return this.form.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.primaryButtonDisabled = isSaveButtonDisabled;
      });
  }

  openConfirmBulkEditModal() {
    this.bulkEditService.openBulkEditConfirmModal();
  }

  ngOnDestroy() {
    this.form.reset();
    return super.ngOnDestroy();
  }
}
