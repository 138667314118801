import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppStateService } from '@shared/services/app-state.service';
import { debounceTime, startWith, take } from 'rxjs/operators';
import { EducationType } from '@pages/positions/classes/EducationType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';
import { AppConstants } from '@config/app.constant';
import { ManageAdvertisementModalData } from '@shared/modules/modals/position-modals/manage-advertisement-modal/classes/ManageAdvertisementModalConfig';
import { AdvertisementService } from '@pages/positions/services/advertisement/advertisement.service';
import { AdvertisementApiService } from '@pages/positions/services/advertisement/base/advertisement-api.service';
import { AdvertisementFormService } from '@pages/positions/services/advertisement/base/advertisement-form.service';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { AdvertisementModalService } from '@pages/positions/services/advertisement/advertisement-modal.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { AdvertisementDto } from '@pages/positions/classes/advertisements/AdvertisementDto';

@UntilDestroy()
@Component({
  selector: 'app-manage-advertisement',
  templateUrl: './manage-advertisement-modal.component.html',
  styleUrls: ['./manage-advertisement-modal.component.scss'],
})
export class ManageAdvertisementModalComponent
  extends MatModalBaseComponent<ManageAdvertisementModalData>
  implements OnInit {
  control = new FormControl();
  educationTypes: EducationType[] = [];
  workExperiences: WorkExperience[] = [];
  languages: Language[] = [];
  languageLevels: LanguageLevel[] = [];
  languageArray: FormArray;
  tagArray: FormArray;
  existingTags: TagDto[] = [];
  minLengthForQuill = AppConstants.minInputLength;
  maxLengthForQuill = AppConstants.advertisementFreeTextMaxLength;
  form: FormGroup;

  advertisementId: number;
  positionId: number;

  constructor(
    private positionState: PositionStateService,
    private advertisementService: AdvertisementService,
    private advertisementModalService: AdvertisementModalService,
    private advertisementApiService: AdvertisementApiService,
    private formService: AdvertisementFormService,
    private appService: AppStateService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;
    this.advertisementId = this.data.advertisementId;
    this.positionId = this.data.positionId;

    this.loadDependencies();
  }

  private loadDependencies() {
    const dependencies = {
      educationTypes: this.appService.getEducationTypes().pipe(take(1)),
      workExperiences: this.appService.getWorkExperiences().pipe(take(1)),
      languages: this.appService.getLanguages().pipe(take(1)),
      languageLevels: this.appService.getLanguageLevels().pipe(take(1)),
      existingTags: this.advertisementApiService.getTags().pipe(take(1)),
    };

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(
        ({
          educationTypes,
          workExperiences,
          languages,
          languageLevels,
          existingTags,
        }: {
          educationTypes: EducationType[];
          workExperiences: WorkExperience[];
          languages: Language[];
          languageLevels: LanguageLevel[];
          existingTags: TagDto[];
        }) => {
          const { advertisementDetail } = this.positionState.getStateSnapshot();
          this.form = this.formService.initAdvertisementForm(advertisementDetail);

          this.educationTypes = educationTypes;
          this.workExperiences = workExperiences;
          this.languages = languages;
          this.languageLevels = languageLevels;
          this.existingTags = existingTags;
          this.languageArray = this.form.get('languages') as FormArray;
          this.tagArray = this.form.get('tags') as FormArray;

          this.primaryButtonDisabled = this.form.invalid;
          this.loading = false;

          this.listenFormChanges();
        }
      );
  }

  private listenFormChanges(): void {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), debounceTime(300), untilDestroyed(this))
      .subscribe(() => {
        this.primaryButtonDisabled = this.form.invalid;
      });
  }

  saveAdvertisement() {
    this.primaryButtonDisabled = true;

    this.advertisementModalService
      .updateAdvertisement(
        this.positionId,
        this.advertisementId,
        this.form.getRawValue() as AdvertisementDto
      )
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
