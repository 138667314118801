import { isBoolean } from 'lodash-es';
import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateParent } from '@pages/candidates/classes/CandidateParent';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { UserApiService } from '@pages/users/services/base/user-api.service';
import { Address } from '@shared/classes/Address';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { atLeastOneArrayItem } from '@shared/utils/at-least-one-array-item.util';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { getBasicInputLengthValidators } from '@shared/utils/form/form-input-length-validator.util';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { setValidationByModalType } from '@shared/utils/form/set-validation-by-modal.type.util';
import { PhoneContactStateService } from '@pages/phone-contacts/services/base/phone-contact-state.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalType } from '@shared/classes/ModalType';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CandidateFormService {
  modalType: ModalType;
  isCreateMode: boolean;

  constructor(
    public readonly atsConfigService: ATSConfigService,
    private formBuilder: FormBuilder,
    private userService: UserApiService,
    private candidateState: CandidateStateService,
    private phoneContactState: PhoneContactStateService
  ) {}

  initCandidateForm(modalType: ModalType, detail?: CandidateDetail): FormGroup {
    this.isCreateMode = !this.candidateState.getStateSnapshot().isEditMode;
    this.modalType = modalType;

    const emailAsyncValidators = !detail
      ? [FormCustomValidators.emailExistsAsyncValidator(this.userService)]
      : [];

    return this.formBuilder.group({
      id: [detail?.id || null],
      contactType: [
        detail?.contactType || null,
        this.isCreateMode ? [...setValidationByModalType('contactType', this.modalType)] : [],
      ],
      firstName: [
        detail?.firstName || null,
        [
          ...getBasicInputLengthValidators(true),
          ...setValidationByModalType('firstName', this.modalType),
        ],
      ],
      lastName: [
        detail?.lastName || null,
        [
          ...getBasicInputLengthValidators(true),
          ...setValidationByModalType('lastName', this.modalType),
        ],
      ],
      email: [
        {
          value: detail?.email || null,
          disabled: detail?.email && this.modalType === ModalType.Candidate,
        },
        [
          ...setValidationByModalType('email', this.modalType),
          ...getBasicInputLengthValidators(false),
          FormCustomValidators.emailValidator(false),
        ],
        emailAsyncValidators,
      ],
      phone: [
        detail?.phone || null,
        [
          FormCustomValidators.phoneNumberValidator(false),
          ...setValidationByModalType('phone', this.modalType),
        ],
      ],
      phoneTwo: [detail?.phoneTwo || null, [FormCustomValidators.phoneNumberValidator(false)]],
      needSendTermsNotification: [
        {
          value: this.getTermsAndConditionsNotificationValue(detail),
          disabled: !!detail,
        },
        ...setValidationByModalType('needSendTermsNotification', this.modalType),
      ],
      preferredLanguageId: [
        detail?.preferredLanguage?.id || null,
        [...setValidationByModalType('preferredLanguageId', this.modalType)],
      ],
      cityPreferences: this.getIdArray(detail?.cityPreferences, false),
      languages: this.getLanguagesArray(detail?.languages),
      profile: this.getProfileGroup(detail),
      positions: [[]],
    });
  }

  initBulkEditCandidateForm(bulkEditState: CandidateBulkUpdateState): FormGroup {
    return this.formBuilder.group({
      membershipEndDate: [bulkEditState?.membershipEndDate?.name || null],
      statusId: [bulkEditState?.statusId || null, []],
      statusOptionId: [bulkEditState?.statusOptionId || null],
      positionId: [bulkEditState?.positionId || null, []],
    });
  }

  initBulkEditCandidateFormForLaborHire(bulkEditState: CandidateBulkUpdateState): FormGroup {
    return this.formBuilder.group({
      statusId: [bulkEditState?.statusId || null, [Validators.required]],
      statusOptionId: [bulkEditState?.statusOptionId || null],
      positionId: [bulkEditState?.positionId || null, [Validators.required]],
      membershipStartDate: [
        bulkEditState?.membershipStartDate?.name || null,
        bulkEditState?.membershipStartDate?.name ? [Validators.required] : null,
      ],
      feorNumber: [
        bulkEditState?.feorNumber?.name || null,
        bulkEditState?.feorNumber?.name
          ? [Validators.required, FormCustomValidators.feorNumberValidator()]
          : null,
      ],
      guarantee: [
        bulkEditState?.guarantee?.name || null,
        bulkEditState?.guarantee?.name ? [Validators.required] : null,
      ],
      subPositionId: [
        bulkEditState?.subPositionId || null,
        bulkEditState?.subPositionId ? [Validators.required] : null,
      ],
      contactCandidate: [bulkEditState?.contactCandidate.id || null],
    });
  }

  getLanguagesGroup(
    language?: { language: Language; languageLevel: LanguageLevel },
    isRequired = false
  ): FormGroup {
    const validators = isRequired ? [Validators.required] : [];

    return this.formBuilder.group({
      languageId: [language?.language?.id || null, validators],
      languageLevelId: [language?.languageLevel?.id || null, validators],
    });
  }

  getParentGroup(parent?: CandidateParent): FormGroup {
    let baseValidators: ValidatorFn[] = [];
    let emailValidators: ValidatorFn[] = [];

    if (this.atsConfigService.isStudentATS) {
      baseValidators = getBasicInputLengthValidators(true);
      emailValidators = [...baseValidators, FormCustomValidators.emailValidator(true)];
    }

    return this.formBuilder.group({
      parentFirstName: [parent?.firstName || null, baseValidators],
      parentLastName: [parent?.lastName || null, baseValidators],
      parentEmail: [parent?.email || null, emailValidators],
    });
  }

  private getProfileGroup(detail?: CandidateDetail): FormGroup {
    const identifierValidators = [];
    const isPensionerValidators = this.atsConfigService.isPensionerATS ? [Validators.required] : [];

    if (this.atsConfigService.isStudentATS) {
      identifierValidators.push(FormCustomValidators.omIdentifierValidator(false));
    } else if (this.atsConfigService.isPensionerATS) {
      identifierValidators.push(FormCustomValidators.pensionerIdentifierValidator(false));
    }

    const group = this.formBuilder.group({
      id: [detail?.profile?.id || null],
      birthDate: [
        detail?.profile?.birthDate || '',
        [
          ...setValidationByModalType(
            'birthDate',
            this.modalType,
            this.atsConfigService.isStudentATS
          ),
        ],
      ],
      providerType: this.getProviderGroup(detail),
      parents: this.getParentsArray(detail?.profile?.parents),
      address: this.getAddressGroup(detail?.profile?.address),
      identifier: [detail?.profile?.identifier, identifierValidators],
      newsletter: [
        isBoolean(detail?.profile?.newsletter) ? detail.profile.newsletter : null,
        [...setValidationByModalType('newsletter', this.modalType)],
      ],
      membershipStartDate: [
        {
          value: detail?.profile?.membershipStartDate,
          disabled: true,
        },
      ],
      membershipEndDate: [
        {
          value: detail?.profile?.membershipEndDate,
          disabled: true,
        },
      ],
      customFields: [detail?.profile?.customFields],
      explorerUserId: [
        detail?.profile?.explorerUser?.id || null,
        ...setValidationByModalType('explorerUserId', this.modalType),
      ],
      exploredDate: [
        detail?.profile?.exploredDate || null,
        ...setValidationByModalType('exploredDate', this.modalType),
      ],
    });

    if (!this.atsConfigService.isLaborHireATS) {
      group.addControl(
        'isSynchronizable',
        new FormControl(detail?.profile?.isSynchronizable || false)
      );

      group.addControl(
        'kprProcessLang',
        new FormControl(
          detail?.profile?.kprProcessLang,
          detail?.profile?.isSynchronizable ? [Validators.required] : []
        )
      );
    }

    if (this.atsConfigService.isLaborHireATS) {
      [
        { value: detail?.profile?.educationType?.id, controlName: 'educationTypeId' },
        { value: detail?.profile?.contactUser?.id, controlName: 'contactUserId' },
      ].forEach(({ controlName, value }) =>
        group.addControl(controlName, new FormControl(value ?? null))
      );
      group.removeControl('identifier');
    }

    if (this.atsConfigService.isPensionerATS) {
      group.addControl(
        'isPensioner',
        new FormControl(detail?.profile?.isPensioner ?? false, isPensionerValidators)
      );
    }

    return group;
  }

  getProviderGroup(detail: CandidateDetail): FormGroup {
    const group = this.formBuilder.group({
      id: [this.getProviderTypeIdValue(detail), [Validators.required]],
      name: [detail?.profile?.providerType?.name || null, [Validators.required]],
    });

    if (detail || this.modalType === ModalType.PhoneContact) {
      group.disable();
    }

    return group;
  }

  private getProviderTypeIdValue(detail: CandidateDetail) {
    const contactProviderTypeId: number = this.phoneContactState.getStateSnapshot().providerTypeId;

    return this.isCreateMode && this.modalType !== ModalType.Candidate
      ? contactProviderTypeId
      : detail?.profile?.providerType?.id || null;
  }

  private getParentsArray(parents?: CandidateParent[]): FormArray {
    const parentsArray = Array.isArray(parents) && parents.length > 0 ? parents : [null];
    return this.formBuilder.array(
      parentsArray.map((parent) => {
        return this.getParentGroup(parent);
      })
    );
  }

  private getAddressGroup(address?: Address): FormGroup {
    return this.formBuilder.group({
      id: [address?.id || null],
      cityId: [address?.city?.id || null, [...setValidationByModalType('cityId', this.modalType)]],
      street: [address?.street || '', getBasicInputLengthValidators(false)],
    });
  }

  private getIdArray(array: Partial<{ id: number }>[], isRequired = true): FormArray {
    const dataArray = atLeastOneArrayItem<Partial<{ id: number }>>(array);

    const validators = isRequired ? [Validators.required] : [];
    return this.formBuilder.array(
      dataArray.map((data) => {
        return this.formBuilder.group({
          id: [data?.id || null, validators],
        });
      })
    );
  }

  private getLanguagesArray(
    languages: {
      language: Language;
      languageLevel: LanguageLevel;
    }[]
  ): FormArray {
    const languagesArray = atLeastOneArrayItem(languages);

    return this.formBuilder.array(
      languagesArray.map((language) => this.getLanguagesGroup(language))
    );
  }

  private getTermsAndConditionsNotificationValue(detail: CandidateDetail): boolean | null {
    if (!detail) {
      return null;
    }

    return typeof detail.needSendTermsNotification === 'boolean'
      ? detail.needSendTermsNotification
      : false;
  }
}
