<div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
    <form [formGroup]='dateIntervalPicker'>
        <app-form-field class="mb-0">
            <app-datepicker
                formControlName="start"
                placeholder="common.please_select"
                [tippy]="hintText | translate"
                variation="hint"
                [isEnabled]="!!hintText"
                [lazy]="true"
                [isHintShown]="false"
                [maxDate]="dateIntervalPicker.get('end').value">
            </app-datepicker>
        </app-form-field>

        <app-form-field class="mb-0">
            <app-datepicker
                formControlName="end"
                placeholder="common.please_select"
                [isHintShown]="false"
                [tippy]="hintText | translate"
                variation="hint"
                [isEnabled]="!!hintText"
                [lazy]="true"
                [minDate]="dateIntervalPicker.get('start').value">
            </app-datepicker>
        </app-form-field>
    </form>
</div>

<div class="filter-tags-wrapper">
    <app-filter-tag
        *ngIf="dateIntervalPicker.valid"
        [id]="1"
        [value]="tagValue"
        (deleteItem)="deleteItem()">
    </app-filter-tag>
</div>
