import { Language } from '@pages/candidates/classes/Language';
import { City } from '@shared/classes/City';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { Address } from '@shared/classes/Address';
import { Office } from '@pages/partners/classes/Office';
import { CandidateParent } from '@pages/candidates/classes/CandidateParent';
import { EducationType } from '@pages/positions/classes/EducationType';
import { ProviderType } from '@pages/candidates/classes/ProviderType';
import { SumIcon } from '@pages/positions/classes/board/BoardCandidate';
import { CandidateMemberTypes } from '@pages/candidates/classes/CandidateMemberTypes';
import { Contact } from '@pages/partners/classes/Contact';
import { CandidateCustomField } from '@pages/candidates/classes/CandidateCustomField';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { CandidateStudentQualification } from '@pages/candidates/classes/CandidateStudentQualification';
import { CandidateStudentInstitute } from '@pages/candidates/classes/CandidateStudentInstitute';
import { SystemTag } from '@shared/classes/SystemTag';

export interface SubPosition {
  code: string;
  id: number;
  name: string;
}

export interface CandidateOutsourcedTo {
  name: string;
  identifier: string;
}

export enum TermsAndConditionsTypes {
  PrivacyEmpty = 'privacy_empty',
  PrivacyApp = 'privacy_app',
  PrivacyBase = 'privacy_base',
}

export interface CandidateDetail {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneTwo: string;
  avatar: string;
  hasTermsAndConditions: TermsAndConditionsTypes;
  preferredLanguage: Language;
  cityPreferences: City[];
  languages: {
    language: Language;
    languageLevel: LanguageLevel;
  }[];
  needSendTermsNotification: boolean;
  profile: {
    id: number;
    birthDate: string;
    address: Address;
    providerType: ProviderType;
    office: Office;
    isMember: CandidateMemberTypes;
    membershipStartDate: string;
    membershipEndDate: string;
    parents: CandidateParent[];
    identifier: string;
    isPensioner: boolean;
    isRead: boolean;
    newsletter: boolean;
    customFields?: CandidateCustomField[];
    studentInstitute: CandidateStudentInstitute;
    studentQualification: CandidateStudentQualification;
    passThroughRecruitment: boolean;
    syncErrorMessage: string;
    synchronizedAt: string;
    isSynchronizable: boolean;
    isSynchronized: boolean;
    kprProcessLang: string;

    // labor-hire related fields
    educationType: EducationType;
    feorNumber: string;
    contactUser: Contact;
    explorerUser: Contact;
    exploredDate: string;
    guarantee: boolean;
  };
  medicalCertificateEndDate: string;
  lungFilterEndDate: string;
  icons: SumIcon[];
  partner: CandidateOutsourcedTo;
  project: CandidateOutsourcedTo;
  roles: MultiUserRole[];
  contactType: string;
  canEdit: boolean;
  canAddMessage: boolean;
  canEditPositions: boolean;
  anonymized: string;

  systemTags: SystemTag[];

  // labor-hire related fields
  subPositions: SubPosition[];
}
