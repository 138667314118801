import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import { TippyPlacement } from '@shared/classes/TippyPlacement';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import reduce from 'lodash-es/reduce';
import uniq from 'lodash-es/uniq';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';

@Component({
  selector: 'app-dot-menu',
  templateUrl: './dot-menu.component.html',
  styleUrls: ['./dot-menu.component.scss'],
})
export class DotMenuComponent implements OnChanges {
  @Input() menuItems: DotMenuItem[] = [];
  @Input() placement: TippyPlacement = 'bottom-end';
  @Input() isEnabled: boolean = true;

  @Output() clicked = new EventEmitter<DotMenuItem>();
  @Output() dotMenuClicked = new EventEmitter<MouseEvent>();

  constructor(private auth: AuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    let menuItems: DotMenuItem[] = changes.menuItems?.currentValue as DotMenuItem[];
    if (!Array.isArray(menuItems)) {
      menuItems = [];
    }

    const permissions = reduce(
      menuItems,
      (accumulatedPermissions: UserPermission[], item: DotMenuItem) => {
        item.permissions.forEach((itemPermission) => accumulatedPermissions.push(itemPermission));
        return accumulatedPermissions;
      },
      []
    );

    this.isEnabled =
      menuItems.length > 0 &&
      AuthUtils.isContainUserPermission(uniq(permissions), this.auth.getUserRoles());
  }

  onMenuItemClicked(menu: DotMenuItem): void {
    this.clicked.emit(menu);
  }

  onDotMenuClicked(event: MouseEvent): void {
    this.dotMenuClicked.emit(event);
  }
}
