import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateWithPrefix',
})
export class TranslateWithPrefixPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(title: string, prefix: string = ''): string {
    const columnName = `${prefix}${title}`;

    const translatedTitle = this.translate.instant(columnName) as string;

    return translatedTitle === columnName ? title : translatedTitle;
  }
}
