import { Observable, Subject } from 'rxjs';

export class TablePageService {
  protected refreshList$: Subject<void> = new Subject<void>();

  triggerRefreshList(): void {
    this.refreshList$.next();
  }

  // TODO: implement on phone contact!
  onRefreshList(): Observable<void> {
    return this.refreshList$.asObservable();
  }
}
