<div class="w-100" [class.role-change-loading]="isRoleChangeCheckLoading">
    <app-form-field>
        <app-select
          label="users.role"
          (selectionChange)="isEditMode && onRoleChange($event)"
          [options]="roles"
          bindValue="key"
          bindLabel="value"
          bindValueByPropertyNameToControl="key"
          [formControl]="roleNameControl">
        </app-select>
    </app-form-field>

    <div *ngIf="isShowAdditionalFieldsForRole">
        <app-form-field>
            <app-text-field
                label="common.position"
                [formControl]="positionControl">
            </app-text-field>
        </app-form-field>
        <app-form-field>
            <app-select
                label="common.project_specialization"
                [options]="projectSpecializations"
                bindValue="id"
                bindLabel="name"
                bindValueByPropertyNameToControl="id"
                [formControl]="projectSpecializationControl">
            </app-select>
        </app-form-field>
    </div>

    <app-loading *ngIf="isRoleChangeCheckLoading"></app-loading>
</div>
