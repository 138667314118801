import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HistoryEditMessageData } from '@shared/modules/history-message/classes/HistoryEditMessageData';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { CandidateApiService } from '@pages/candidates/services/base/candidate-api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CandidateHistoryService {
  constructor(
    private historyMessageService: HistoryMessageService,
    private candidateApiService: CandidateApiService
  ) {}

  getFirstHistoryPage(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.getFirstHistoryPage(
      candidateId,
      this.candidateApiService.getHistory.bind(this.candidateApiService)
    );
  }

  refreshHistories(candidateId: number) {
    this.getFirstHistoryPage(candidateId).subscribe();
  }

  callLoadMoreHistory(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.callLoadMoreHistory(
      candidateId,
      this.candidateApiService.getHistory.bind(this.candidateApiService)
    );
  }

  callCreateHistory(
    candidateId: number,
    message: string
  ): Observable<HistoryMessage | HttpErrorResponse> {
    return this.historyMessageService.callCreateHistory(
      candidateId,
      { message },
      this.candidateApiService.createHistoryMessage.bind(this.candidateApiService)
    );
  }

  callUpdateHistory(
    candidateId: number,
    messageData: HistoryEditMessageData
  ): Observable<HistoryMessage | HttpErrorResponse> {
    return this.historyMessageService.callUpdateHistory(
      candidateId,
      messageData,
      this.candidateApiService.updateHistoryMessage.bind(this.candidateApiService)
    );
  }

  openDeleteHistoryConfirmModal(candidateId: number, historyMessage: HistoryMessage): void {
    this.historyMessageService.openDeleteHistoryConfirmModal(
      candidateId,
      historyMessage,
      this.candidateApiService.deleteHistoryMessage.bind(this.candidateApiService)
    );
  }
}
