import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { PartnerProjectPositionSelectionComponent } from './components/partner-project-position-selection/partner-project-position-selection.component';
import { PartnerProjectPositionFormSelectionComponent } from './components/partner-project-position-form-selection/partner-project-position-form-selection.component';

@NgModule({
  declarations: [
    PartnerProjectPositionSelectionComponent,
    PartnerProjectPositionFormSelectionComponent,
  ],
  exports: [PartnerProjectPositionSelectionComponent, PartnerProjectPositionFormSelectionComponent],
  imports: [
    CommonModule,
    FormComponentsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule,
    ButtonModule,
  ],
})
export class PartnerProjectPositionSelectionModule {}
