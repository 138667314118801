import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@Pipe({
  name: 'appTimeago',
})
export class TimeagoPipe implements PipeTransform {
  transform(dateTime: string, onlyDays = false): string {
    return this.getValue(dateTime, onlyDays);
  }

  private getValue(dateTime: string, onlyDays: boolean): string {
    const now = moment().utc();
    const createdDate = moment(dateTime).utc(true);
    const duration = moment.duration(now.diff(createdDate));
    const nowString = TranslateInstance.instant('common.now');
    const todayString = TranslateInstance.instant('common.today');
    const textValue = TranslateInstance.instant('common.days_ago');

    if (duration.asSeconds() < 60) {
      return nowString;
    }

    if (onlyDays && duration.asDays() < 1) {
      return todayString;
    }

    const timeBoundaries = [
      {
        duration: duration.asMinutes(),
        boundary: 60,
        textValue: TranslateInstance.instant('common.minute_ago'),
      },
      {
        duration: duration.asHours(),
        boundary: 24,
        textValue: TranslateInstance.instant('common.hours_ago'),
      },
      {
        duration: duration.asDays(),
        boundary: 7,
        textValue: TranslateInstance.instant('common.days_ago'),
      },
    ];

    const foundBoundary = timeBoundaries.find((data) => data.duration < data.boundary);

    if (foundBoundary) {
      return `${Math.floor(foundBoundary.duration)} ${foundBoundary.textValue}`;
    }

    if (onlyDays) {
      return `${Math.floor(duration.asDays())} ${textValue}`;
    }

    return createdDate.format(AppConstants.dateTimeFormat);
  }
}
