import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';
import { Observable } from 'rxjs';
import { City } from '@shared/classes/City';

@Injectable({
  providedIn: 'root',
})
export class CountrySettlementApiService {
  constructor(private http: HttpService) {}

  getCitiesByZipCode(countryId: number, zipCode: string): Observable<City[]> {
    return this.http.get(EndpointsConfig.citySearch(countryId), {
      zipCode,
    });
  }

  getCitiesByName(countryId: number, name: string): Observable<City[]> {
    return this.http.get(EndpointsConfig.citySearch(countryId), {
      name,
    });
  }
}
