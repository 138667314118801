import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AUTH_STORAGE } from '@shared/modules/auth/providers/auth-storage.injection-token';
import { IAuthStorageStrategy } from '@shared/modules/auth/classes/IAuthStorageStrategy';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EndpointsConfig } from '@config/endpoints.config';
import { catchError, tap } from 'rxjs/operators';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { Location } from '@angular/common';
import { includes } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { HttpService } from '../../http/http.service';
import { User } from '../classes/User';
import { UserRole } from '../classes/UserRole';
import { AuthGuardParams } from '../classes/AuthGuardParams';
import { UserStateService } from '@pages/users/services/base/user-state.service';
import { environment } from '@environments/environment';
import { getErrorTranslationKey } from '@shared/utils/error-keys.util';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

/** Write/read data of user's to/from local storage, */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInUser: User;
  loggedInUserChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  previousURL: string;

  authStorage: IAuthStorageStrategy;
  private jwtHelper: JwtHelperService;
  private router: Router;

  constructor(
    private httpService: HttpService,
    private injector: Injector,
    private modalService: MatModalService
  ) {
    this.authStorage = injector.get<IAuthStorageStrategy>(AUTH_STORAGE);
    this.jwtHelper = injector.get<JwtHelperService>(JwtHelperService);
    this.router = injector.get<Router>(Router);
  }

  get isSuperAdmin(): boolean {
    return includes(this.getUserRoles(), UserRole.SuperAdmin);
  }

  redirectToPreviousUrlOrHome() {
    if (this.previousURL) {
      const splitUrl = this.previousURL.split('?');
      const route = splitUrl[0];
      const { queryParams } = this.router.parseUrl(this.previousURL);
      this.router.navigate([route], { queryParams });
      this.previousURL = null;
    } else {
      this.router.navigate([AppConfig.homeUrl]);
    }
  }

  logout(doRedirect?: boolean) {
    this.modalService.closeAllDialog();
    const router = this.injector.get<Router>(Router);

    this.authStorage.removeAll();
    sessionStorage.clear();

    if (this.loggedInUser) {
      this.setUser(null);
    }

    if (doRedirect) {
      router.navigate([AppConfig.loginUrl]);
    }
  }

  azureLogin() {
    const redirectUrl = this.getRedirectUrl();
    window.location.href = `${environment.apiUrl}/v1/${EndpointsConfig.socialLogin}${redirectUrl}`;
  }

  logoutWithApi() {
    this.httpService.post(EndpointsConfig.logout, null).subscribe(() => {
      this.logout(true);
    });
  }

  loadUser(): Observable<User | HttpErrorResponse> {
    const userState = this.injector.get<UserStateService>(UserStateService);
    const toastService = this.injector.get<ToastService>(ToastService);

    return this.httpService.get<User>(EndpointsConfig.me).pipe(
      tap((me: User) => {
        userState.setState({ me });
      }),
      catchError((err: HttpErrorResponse) => {
        toastService.showError(TranslateInstance.instant('validation_errors.load_user_error'));
        this.logout(true);
        return of(err);
      })
    );
  }

  checkAuth(): Promise<boolean> {
    console.log('check auth-user!');

    const csrfToken = this.authStorage.readCSRFToken();

    if (!csrfToken) {
      return Promise.resolve(true);
    }

    return new Promise((resolve) => {
      this.loadUser().subscribe(
        (userData: User | HttpErrorResponse) => {
          if (userData instanceof HttpErrorResponse) {
            resolve(true);
            throw new Error(`Login error occurred: ${userData.message}`);
          }

          const location = this.injector.get(Location);
          this.setUser(userData);
          if (location.path().includes(AppConfig.loginUrl)) {
            this.redirectToPreviousUrlOrHome();
          }
          resolve(true);
        },
        () => {
          this.logout();
          resolve(true);
        }
      );
    });
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    let route: string;
    const userRoles = this.getUserRoles();

    if (authGuardParams.redirectTo) {
      route = authGuardParams.redirectTo;
    } else if (userRoles?.length > 0 && userRoles[0] !== UserRole.UnAuthorized) {
      const roleRouteMap: { [key: string]: string } = {
        [UserRole.SuperAdmin]: AppConfig.homeUrl,
        [UserRole.Admin]: AppConfig.homeUrl,
        [UserRole.FieldManager]: AppConfig.homeUrl,
        [UserRole.ProjectManager]: AppConfig.homeUrl,
        [UserRole.Partner]: AppConfig.positionsUrl,
        [UserRole.Candidate]: AppConfig.positionsUrl,
      };
      route = roleRouteMap[userRoles[0]];
    } else {
      route = AppConfig.loginUrl;
    }

    this.previousURL = previousUrl;
    this.router.navigate([route]);
  }

  private getRedirectUrl(): string {
    const isLocalEnvironment = environment.name === 'LOCAL';

    if (isLocalEnvironment) {
      return `?redirectUrl=${environment.loginRedirectUrl}`;
    } else {
      return '';
    }
  }

  private setUser(userData: User | null) {
    if (userData) {
      this.loggedInUser = userData;
    } else {
      this.loggedInUser = null;
    }
    this.loggedInUserChange.next(this.loggedInUser);
  }

  handleErrorMessage(errorKey: string) {
    const toastService = this.injector.get<ToastService>(ToastService);

    toastService.showError(getErrorTranslationKey(errorKey), {
      autoClose: false,
      duration: 0,
      position: 'top-right',
      className: 'custom-toast-container',
    });
  }

  removeQueryParam(queryParams: Params) {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getLoggedInUser(): User {
    return this.loggedInUser;
  }

  getUserRoles(): UserRole[] {
    let roles = [UserRole.UnAuthorized];

    if (Array.isArray(this.loggedInUser?.roles)) {
      roles = this.loggedInUser?.roles.map((userRole) => userRole.name);
    }

    return roles;
  }
}
