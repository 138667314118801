import { keys } from 'lodash-es';

/* eslint-disable */

export function removeBlankAttributes(object: Object) {
  return (
    keys(object)
      .filter((k) => isValueEmpty(object, k))
      // @ts-ignore
      .reduce((a, k) => ({ ...a, [k]: object[k] }), {})
  );
}

export function removeBlankAttributesFromNestedObj(object: Object) {
  return keys(object)
    .filter((k) => isValueEmpty(object, k))
    .reduce((a, k) => {
      // @ts-ignore
      a[k] =
        // @ts-ignore
        typeof object[k] === 'object' ? removeBlankAttributesFromNestedObj(object[k]) : object[k];
      return a;
    }, {});
}

function isValueEmpty(object: Object, k: string) {
  // @ts-ignore
  return !(object[k] == null || object[k] === '' || object[k].length <= 0);
}
