import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ATSConfigService } from '@shared/services/ats-config.service';

@UntilDestroy()
@Component({
  selector: 'app-start-end-date-picker',
  templateUrl: './start-end-date-picker.component.html',
  styleUrls: ['./start-end-date-picker.component.scss'],
})
export class StartEndDatePickerComponent implements OnInit {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() clearable: boolean = false;
  @Input() minDateControl: FormControl;
  @Input() maxDateControl: FormControl;
  @Input() minDateLabel: string;
  @Input() maxDateLabel: string;
  @Input() disabled: boolean;

  maxDateClearable: boolean;

  constructor(public readonly atsConfigService: ATSConfigService) {}

  ngOnInit(): void {
    this.minDateControl.valueChanges
      .pipe(startWith(this.minDateControl.value), untilDestroyed(this))
      .subscribe(() => {
        this.setMaxDateControlDisabled();
        this.setControlsRequired();

        this.maxDateControl.markAsTouched();
        this.maxDateControl.updateValueAndValidity();
      });
  }

  private setMaxDateControlDisabled() {
    if (this.minDateControl.value && this.atsConfigService.isLaborHireATS) {
      this.maxDateControl.enable();
    } else {
      this.maxDateControl.disable();
      this.maxDateControl.setValidators([]);
    }
  }

  private setControlsRequired() {
    if (!this.clearable) {
      if (this.minDateControl.value) {
        this.minDateControl.setValidators([Validators.required]);
      }

      if (this.maxDateControl.value) {
        this.maxDateControl.setValidators([Validators.required]);
        this.maxDateClearable = false;
      } else {
        this.maxDateClearable = true;
      }
    }
  }
}
