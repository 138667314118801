import { Component, Injector, Input, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { DataTableInlineEditService } from '@shared/modules/data-table-inline-edit/services/data-table-inline-edit.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import {
  InlineEditCellInputStatus,
  InlineEditCellSaveStatus,
} from '@shared/modules/data-table-inline-edit/classes/inline-edit-data-list';
import { DataTableFillHandleService } from '@shared/modules/data-table-inline-edit/services/data-table-fill-handle.service';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { AppStateService } from '@shared/services/app-state.service';
import { Observable } from 'rxjs';
import { InlineEditColumnParams } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-params';
import { InlineEditColumnType } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';

@UntilDestroy()
@Component({
  selector: 'app-data-table-inline-edit-cell-base',
  template: '<ng-content></ng-content>',
})
export abstract class DataTableInlineEditCellBaseComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<unknown>;

  @Input() inlineEditDisabled = false;
  @Input() fillHandleDisabled = false;
  @Input() disableFillHandleSelect = false;

  protected formBuilder: FormBuilder;
  protected renderer: Renderer2;
  protected appStateService: AppStateService;
  protected fillHandleService: DataTableFillHandleService;
  inlineEditService: DataTableInlineEditService;

  readonly SAVE_STATUS = InlineEditCellSaveStatus;
  readonly INPUT_STATUS = InlineEditCellInputStatus;
  readonly COLUMN_TYPE = InlineEditColumnType;

  protected constructor(protected injector: Injector) {
    this.renderer = this.injector.get(Renderer2);
    this.formBuilder = this.injector.get(FormBuilder);
    this.inlineEditService = this.injector.get(DataTableInlineEditService);
    this.fillHandleService = this.injector.get(DataTableFillHandleService);
    this.appStateService = this.injector.get(AppStateService);
  }

  getCellData(rowIndex: number, column: TableColumn) {
    return this.inlineEditService.getCellEditingData(rowIndex, column.prop);
  }

  getColumnData(column: TableColumn) {
    return this.inlineEditService.getColumnData(column.prop);
  }

  startInlineEditOnDblClick(rowIndex: number, column: TableColumn, value: unknown) {
    const cellData = this.getCellData(rowIndex, column);
    const columnData = this.inlineEditService.getColumnData(column.prop);

    if (this.inlineEditDisabled || cellData.isEditing) {
      return;
    }

    if (
      columnData.params.type === InlineEditColumnType.Select ||
      columnData.params.type === InlineEditColumnType.MultiSelect
    ) {
      if (!columnData.items) {
        if (columnData.params.options.items) {
          this.setOptionsFromParams(column, columnData.params, cellData.rowIndex);
        } else if (columnData.params.options.endpoint) {
          this.getOptionFromApi(column, columnData.params, cellData.rowIndex);
        }
      }
    }

    this.updateInputFormGroup(rowIndex, column, value);

    this.fillHandleService.resetFillHandleActive();
    this.inlineEditService.startCellEditing(rowIndex, column);
  }

  endInlineEditAndSave(
    rowIndex: number,
    column: TableColumn,
    row: DataTableRow,
    value: unknown,
    convertValueToArray: boolean = false
  ) {
    const inputGroup = this.getCellData(rowIndex, column).inputGroup;

    if (inputGroup.valid) {
      this.inlineEditService.endCurrentCellEditing();

      const inputGroupValue: unknown = inputGroup.controls.value.value;

      if (inputGroupValue !== value) {
        this.inlineEditService.saveModifiedCellData(
          rowIndex,
          column,
          row,
          inputGroupValue,
          value,
          convertValueToArray
        );
      }
    } else {
      this.inlineEditService.setCellEditingData(rowIndex, column, {
        inputStatus: InlineEditCellInputStatus.Invalid,
      });
    }
  }

  startFillHandle(row: DataTableRow, rowIndex: number, column: TableColumn, value: unknown) {
    this.fillHandleService.startFillHandle(row, rowIndex, column, value);
  }

  endFillHandle(event: Event) {
    // stop propagation to data-table-fill-handle.service.ts -> endFillHandleOnMouseUp function
    event.stopPropagation();

    if (this.fillHandleService.fillHandleEnabled) {
      this.fillHandleService.endFillHandle();
    }
  }

  endCurrentCellEditing() {
    this.inlineEditService.endCurrentCellEditing();
  }

  setFillHandleActiveAndSelected(
    rowIndex: number,
    column: TableColumn,
    row: DataTableRow,
    value: unknown
  ) {
    if (!value || this.disableFillHandleSelect) {
      return;
    }

    const cellData = this.getCellData(rowIndex, column);

    if (!cellData.isEditing) {
      this.inlineEditService.endCurrentCellEditing();
    }

    if (!this.fillHandleDisabled && !cellData.active && !cellData.isEditing) {
      this.fillHandleService.setFillHandleActive(rowIndex, column);
      this.fillHandleService.setFillHandleSelected(rowIndex, column, row);
    }
  }

  toggleFillHandleSelected(rowIndex: number, column: TableColumn, row: DataTableRow) {
    if (!this.fillHandleDisabled && this.fillHandleService.fillHandleEnabled) {
      this.fillHandleService.toggleFillHandleSelected(rowIndex, column, row);
    }
  }

  private updateInputFormGroup(rowIndex: number, column: TableColumn, value: unknown) {
    const inputGroup = this.getCellData(rowIndex, column).inputGroup;
    const valueControl = inputGroup.controls.value;

    valueControl.setValue(value);

    inputGroup.controls.value.setValidators(this.getValidators(column));
    inputGroup.controls.value.updateValueAndValidity();
  }

  private getValidators(column: TableColumn): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    const params = this.getColumnData(column).params;

    if (params.validators) {
      if (params.validators.required) {
        validators.push(Validators.required);
      }

      if (params.validators.pattern) {
        validators.push(Validators.pattern(params.validators.pattern));
      }
    }

    return validators;
  }

  private setOptionsFromParams(
    column: TableColumn,
    params: InlineEditColumnParams,
    rowIndex: number
  ) {
    this.inlineEditService.setColumnData(column.prop, { items: params.options.items }, rowIndex);
  }

  private getOptionFromApi(column: TableColumn, params: InlineEditColumnParams, rowIndex: number) {
    this.inlineEditService.setColumnData(column.prop, { loading: true }, rowIndex);

    if (params.options.items) {
      this.inlineEditService.setColumnData(
        column.prop,
        { loading: false, items: params.options.items },
        rowIndex
      );
    } else if (params.options.endpoint) {
      const endpointObs: Observable<unknown> = this.appStateService[params.options.endpoint]();

      endpointObs.subscribe((items) => {
        setTimeout(() => {
          this.inlineEditService.setColumnData(
            column.prop,
            { loading: false, items: items as unknown[] },
            rowIndex
          );
        }, 100);
      });
    }
  }
}
