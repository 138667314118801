import { environment } from '@environments/environment';

export interface ATSBasedText {
  student: string;
  laborHire?: string;
  pensioner: string;
}

export function getATSBasedText(typeBasedText: ATSBasedText): string {
  const type = environment.atsType;

  switch (type) {
    case 'pensioner':
      return typeBasedText.pensioner;
    case 'labor-hire':
      return typeBasedText.laborHire;
    default:
      return typeBasedText.student;
  }
}
