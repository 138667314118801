import { Component, Input, OnInit } from '@angular/core';
import { get } from 'lodash-es';

@Component({
  selector: 'app-text-value-getter',
  templateUrl: './text-value-getter.component.html',
  styleUrls: ['./text-value-getter.component.scss'],
})
export class TextValueGetterComponent implements OnInit {
  @Input() path: string;
  @Input() item: string;

  parsedText: string;

  ngOnInit(): void {
    if (!this.path) {
      this.parsedText = this.item;
      return;
    }

    this.parsedText = get(this.item, this.path, '') as string;
  }
}
