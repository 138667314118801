import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { createEmptyListData, ListData, ListTotalCount } from '@shared/classes/ListData';
import { catchError, tap } from 'rxjs/operators';
import { PhoneContactApiService } from '@pages/phone-contacts/services/base/phone-contact-api.service';
import { PhoneContactListItem } from '@pages/phone-contacts/classes/PhoneContactListItem';
import { PhoneContactStateService } from '@pages/phone-contacts/services/base/phone-contact-state.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { environment } from '@environments/environment';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { FormGroup } from '@angular/forms';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { TablePageService } from '@shared/classes/table/TablePageService';
import { TableViewService } from '@shared/modules/custom-selectors/custom-table-view-selector/services/table-view.service';

@Injectable({
  providedIn: 'root',
})
export class PhoneContactsService extends TablePageService {
  constructor(
    private phoneContactApiService: PhoneContactApiService,
    private phoneContactState: PhoneContactStateService,
    private headerService: HeaderService,
    private candidateService: CandidatesService,
    private tableViewService: TableViewService
  ) {
    super();
  }

  private getStateSnapshot() {
    return this.phoneContactState.getStateSnapshot();
  }

  getPhoneContacts(
    page: string,
    perPage: string,
    base64EncodedFilter?: string,
    columnIds?: string[]
  ): Observable<ListData<PhoneContactListItem>> {
    return this.phoneContactApiService
      .getPhoneContacts(page, perPage, columnIds, base64EncodedFilter)
      .pipe(
        catchError(() => {
          return of(createEmptyListData<PhoneContactListItem>());
        })
      );
  }

  getTotalCount(base64EncodedFilter?: string): Observable<ListTotalCount> {
    return this.phoneContactApiService.getPhoneContactTotalCount(base64EncodedFilter);
  }

  openExportTableModal(): Observable<void> {
    return this.headerService.openExportDataModal(this.callExportTable.bind(this));
  }

  private callExportTable(): Observable<void | HttpResponse<Blob> | HttpErrorResponse> {
    const phoneContactFilter = this.getStateSnapshot().filters;
    const sortedFilters = removeBlankAttributes(phoneContactFilter);
    const selectedColumns = this.tableViewService.getSelectedColumnsFromState();
    let encodedFilters: string | undefined;

    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }

    if (this.headerService.getStateSnapshot().filterTotalCount <= environment.exportCount) {
      return this.headerService.handleExportResponse(
        this.phoneContactApiService.exportPhoneContactTable(encodedFilters, selectedColumns)
      );
    }

    return this.headerService.handleEmailExportResponse(
      this.phoneContactApiService.exportPhoneContactTableEmail(encodedFilters, selectedColumns)
    );
  }

  callCreatePhoneContact(
    phoneContactDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.phoneContactApiService
      .createPhoneContact(phoneContactDto)
      .pipe(
        this.candidateService.handleCandidateSaveSideEffects(
          form,
          'phone_contacts.create_phone_contact',
          true
        )
      );
  }

  callUpdatePhoneContact(
    phoneContactDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.phoneContactApiService.updatePhoneContact(phoneContactDto).pipe(
      tap((candidateDetail: CandidateDetail) => {
        this.candidateService.refreshCandidateDetail(phoneContactDto.id, candidateDetail);
      }),
      this.candidateService.handleCandidateSaveSideEffects(
        form,
        'phone_contacts.modify_phone_contact'
      )
    );
  }
}
