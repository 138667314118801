<div class="header h-100 d-flex align-items-center justify-content-between">
    <div class=" d-flex align-items-center">
        <div class="logo mr-10px">
            <img class="logo-img"
                 [src]="'assets/image/base-data-placeholder.svg'"
                 alt="Edit-basic-data Logo">
        </div>
        <h1 class="page-header-title">
            {{(headerTitle$ | async) | translate}}
        </h1>
        <div class="pages">
            <button *ngFor="let page of pages" type="button" (click)="removeLastNavigationHistory()"
                    [class.active-page]="page.isActive" [routerLink]="page.routerLink">
                <span>{{page.text | translate}}</span>
            </button>
        </div>
    </div>
</div>
