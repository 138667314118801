import { ListTypeName } from '@config/app.constant';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { PositionBulkUpdateState } from '@pages/positions/classes/PositionBulkUpdateState';

export interface BulkEditState {
  listType: ListTypeName;
  candidateBulkUpdate: CandidateBulkUpdateState;
  positionBulkUpdate: PositionBulkUpdateState;

  selectedItems: number;
  isEditMode: boolean;
  error: unknown;
}

export const bulkEditInitiateState: BulkEditState = {
  listType: undefined,
  candidateBulkUpdate: undefined,
  positionBulkUpdate: undefined,
  selectedItems: null,
  isEditMode: false,
  error: undefined,
};
