import { Component, Injector, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PositionKanbanBoardApiService } from '@pages/positions/services/candidates/base/position-kanban-board-api.service';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';
import { CandidateSearchItem } from '@pages/positions/classes/board/CandidateSearchItem';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { KanbanBoardAddCandidateModalData } from '@shared/modules/modals/position-modals/kanban-board-add-candidate-modal/classes/KanbanBoardAddCandidateModalConfig';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { BoardColumnSlugs } from '@pages/positions/classes/BoardColumnSlugs';
import { PositionKanbanBoardService } from '@pages/positions/services/candidates/position-kanban-board.service';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

@UntilDestroy()
@Component({
  selector: 'app-kanban-board-add-candidate-modal',
  templateUrl: './kanban-board-add-candidate-modal.component.html',
  styleUrls: ['./kanban-board-add-candidate-modal.component.scss'],
})
export class KanbanBoardAddCandidateModalComponent
  extends MatModalBaseComponent<KanbanBoardAddCandidateModalData>
  implements OnInit {
  form: FormGroup;
  options: CandidateSearchItem[] = [];
  isLaborHireATS = false;
  isOutsourcedColumn = false;
  maxDate = new Date();
  partnerIdControl = new FormControl(null, [Validators.required]);
  projectIdControl = new FormControl(null, [Validators.required]);

  positionId: number;
  readonly POSITION_TYPE = PositionTypes;

  constructor(
    private candidateBoardApiService: PositionKanbanBoardApiService,
    private positionState: PositionStateService,
    private atsConfigService: ATSConfigService,
    private formBuilder: FormBuilder,
    protected injector: Injector,
    private candidatesKanbanBoardService: PositionKanbanBoardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = false;
    this.primaryButtonDisabled = true;
    this.positionId = this.data.positionId;

    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;
    const { selectedKanbanBoardColumn } = this.positionState.getStateSnapshot();
    this.isOutsourcedColumn = selectedKanbanBoardColumn?.slug === BoardColumnSlugs.Outsourced;

    this.initForm();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.form.invalid;
    });

    this.listenCandidateSearch();
  }

  onSearchInputChanges(name: string): void {
    this.form.get('search').setValue(name);
  }

  private listenCandidateSearch() {
    this.form
      .get('search')
      .valueChanges.pipe(
        debounceTime(300),
        switchMap((name: string) => {
          const detail = this.positionState.getStateSnapshot().positionDetail;

          if (name?.length >= AppConstants.minInputLength) {
            return this.candidateBoardApiService
              .searchCandidate(detail.id, name)
              .pipe(catchError(() => of([])));
          }
          return of([]);
        }),
        untilDestroyed(this)
      )
      .subscribe((candidates: CandidateSearchItem[]) => {
        this.options = candidates;
      });
  }

  private initForm() {
    const feorValidators =
      this.isLaborHireATS && this.isOutsourcedColumn
        ? [Validators.required, FormCustomValidators.feorNumberValidator()]
        : [];
    const baseValidators =
      this.isLaborHireATS && this.isOutsourcedColumn ? [Validators.required] : [];

    this.form = this.formBuilder.group({
      search: [null],
      id: [null, [Validators.required]],
      feorNumber: [null, feorValidators],
      membershipStartDate: [null, baseValidators],
      subPositionId: [null, baseValidators],
    });

    if (this.data.positionType === PositionTypes.Outsourced) {
      this.form.addControl('guarantee', new FormControl(null, baseValidators));
    }
  }

  addCandidate() {
    this.primaryButtonDisabled = true;

    this.candidatesKanbanBoardService
      .callAddCandidateToPosition(this.positionId, {
        candidateId: this.form.get('id').value as number,
        feorNumber: (this.form.get('feorNumber').value as string) || null,
        membershipStartDate: (this.form.get('membershipStartDate').value as string) || null,
        subPositionId: (this.form.get('subPositionId').value as number) || null,
        guarantee:
          this.data.positionType === PositionTypes.Outsourced
            ? (this.form.get('guarantee').value as boolean)
            : null,
      })
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
