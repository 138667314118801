import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  positionKanbanBoardTableInitialState,
  PositionKanbanBoardTableState,
} from '@shared/modules/state-manager/state/position-candidates/position-kanban-board-table.state';

@Injectable({
  providedIn: 'root',
})
export class PositionKanbanBoardTableStateService extends StateService<PositionKanbanBoardTableState> {
  constructor() {
    super(positionKanbanBoardTableInitialState);
  }
}
