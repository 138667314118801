<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'users.upload_avatar'">
    </app-modal-header>

    <div content class="upload-user-avatar modal-base h-auto">
        <app-form-field>
            <app-photo-upload
                [isHintShown]="true"
                placeholder="common.please_select"
                permanentHintText="partners.photo_hint"
                [formControl]="avatarControl">
            </app-photo-upload>
        </app-form-field>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [secondaryButtonText]="'common.dismiss'"
        [primaryButtonText]="'common.save'"
        (primaryButtonClicked)="uploadAvatar()">
    </app-modal-footer>

</app-modal-frame>
