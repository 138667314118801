import keys from 'lodash-es/keys';
import { ValidationErrors } from '@shared/classes/ValidationErrors';
import { FormGroup } from '@angular/forms';
import { lowerFirst, snakeCase } from 'lodash-es';
import { ValidationErrorResponse } from '@shared/classes/common/HttpErrorResponse';

export function parseBackendValidationErrors(
  errorResponse: ValidationErrorResponse
): ValidationErrors {
  const errors = errorResponse?.error?.errors;

  if (!errors) {
    return {};
  }

  const errorMap: ValidationErrors = {};

  (keys(errors) as Array<keyof Object>).forEach((field) => {
    errorMap[field] = keys(errors[field]).map((key) => snakeCase(lowerFirst(key)));
  });

  return errorMap;
}

export function addValidationErrorsToForm(form: FormGroup, errors: ValidationErrors): void {
  keys(errors).forEach((error: string) => {
    const control = form.get(error);
    if (control) {
      const controlErrorObject: { [key: string]: unknown } = {};

      errors[error].forEach((errorName) => {
        controlErrorObject[errorName] = errorName;
      });

      control.setErrors(controlErrorObject);
    }
  });
  form.markAllAsTouched();
  form.updateValueAndValidity();
}
