import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Contact } from '@pages/partners/classes/Contact';
import { getBasicInputLengthValidators } from '@shared/utils/form/form-input-length-validator.util';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { UserApiService } from '@pages/users/services/base/user-api.service';
import { head } from 'lodash-es';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { PartnerOrProject } from '@config/app.constant';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { UserMenuTypes } from '@pages/users/classes/UserMenuTypes';

@Injectable({
  providedIn: 'root',
})
export class UserFormService {
  constructor(
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private atsConfigService: ATSConfigService
  ) {}

  initManageUserForm(menuType: UserMenuTypes, user?: UserListItem): FormGroup {
    let controlsConfig: { [key: string]: AbstractControl } = {
      id: new FormControl(user?.id || null),
      firstName: new FormControl(user?.firstName || null, getBasicInputLengthValidators(true)),
      lastName: new FormControl(user?.lastName || null, getBasicInputLengthValidators(true)),
      email: new FormControl(
        { value: user?.email || null, disabled: !!user },
        [...getBasicInputLengthValidators(true), FormCustomValidators.emailValidator(false)],
        user ? [] : [FormCustomValidators.emailExistsAsyncValidator(this.userApiService)]
      ),
      phone: new FormControl(user?.phone || null, [
        ...getBasicInputLengthValidators(true),
        FormCustomValidators.phoneNumberValidator(false),
      ]),
      officeId: new FormControl(user?.office?.id || null, [Validators.required]),
    };

    if (menuType === UserMenuTypes.PartnerUsers) {
      controlsConfig = {
        ...controlsConfig,

        roleName: new FormControl(head<MultiUserRole>(user?.roles)?.name || null, [
          Validators.required,
        ]),
      };
    }

    if (!this.atsConfigService.isLaborHireATS && menuType !== UserMenuTypes.WHCUsers) {
      controlsConfig = {
        ...controlsConfig,

        position: new FormControl(user?.position || '', [Validators.required]),
        projectSpecializationId: new FormControl(user?.projectSpecialization?.id || null, [
          Validators.required,
        ]),
      };
    }

    return this.formBuilder.group(controlsConfig);
  }

  generateContactGroup(type: PartnerOrProject, contact?: Contact): FormGroup {
    const isControlDisabled = typeof contact?.id === 'number';

    let controlsConfig: { [key: string]: AbstractControl } = {
      id: new FormControl(contact?.id || null, [Validators.required]),
      name: new FormControl(contact?.name || '', getBasicInputLengthValidators(true)),
      email: new FormControl(
        { value: contact?.email || '', disabled: isControlDisabled },
        [Validators.required, FormCustomValidators.emailValidator(true)],
        [FormCustomValidators.emailExistsAsyncValidator(this.userApiService)]
      ),
      phone: new FormControl({ value: contact?.phone || '', disabled: isControlDisabled }, [
        Validators.required,
        FormCustomValidators.phoneNumberValidator(true),
      ]),
    };

    if (!this.atsConfigService.isLaborHireATS) {
      controlsConfig = {
        ...controlsConfig,

        isContractualContact: new FormControl(
          contact?.isContractualContact ?? null,
          type === 'partner' ? [Validators.required] : []
        ),
        hasAuthorizedSignatory: new FormControl(
          contact?.hasAuthorizedSignatory ?? null,
          type === 'partner' ? [Validators.required] : []
        ),
        position: new FormControl(
          contact?.position || null,
          type === 'project' ? [Validators.required] : []
        ),
        projectSpecializationId: new FormControl(
          contact?.projectSpecialization?.id || null,
          type === 'project' ? [Validators.required] : []
        ),
      };
    }

    return this.formBuilder.group(controlsConfig);
  }
}
