<div *ngFor="let group of languageArray.controls; let i = index">
    <ng-container [formGroup]="group">
        <app-form-field>
            <app-select
              [options]="availableLanguages"
              (selectionChange)="onSelectionChange($event, group.get('languageLevelId'))"
              bindValueByPropertyNameToControl="id"
              dropdownPosition="bottom"
              [clearable]="true"
              bindLabel="name"
              bindValue="id"
              label="common.language"
              formControlName="languageId">
            </app-select>
            <div *ngIf="languageArray.length > 1" right-side>
                <app-icon-button class="d-block" (buttonClick)="onRemoveElement(i)" textAlign="right"
                  hintText="common.delete" iconUrl="assets/image/close-icon.svg"></app-icon-button>
            </div>
        </app-form-field>

        <ng-container *ngIf="group.get('languageId').value">
            <app-form-field>
                <app-select
                  [options]="languageLevels"
                  bindValueByPropertyNameToControl="id"
                  bindLabel="name"
                  bindValue="id"
                  dropdownPosition="bottom"
                  label="candidates.language_level"
                  formControlName="languageLevelId">
                </app-select>
            </app-form-field>
        </ng-container>

        <div *ngIf="group.get('languageId').value && languageArray.valid && i === languageArray.length - 1" class="my-20px d-flex flex-column">
            <button type="button" (click)="onAddLanguageGroup()" class="add-more-button text-left p-0 m-0">
                {{'candidates.add_language_skill' | translate}}
            </button>
        </div>
    </ng-container>
</div>
