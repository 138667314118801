import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {
  @Input() delay = 0;
  @Input() inline = false;
  @Input() color: string = null;

  isLoaderVisible = false;

  ngOnInit(): void {
    setTimeout(() => (this.isLoaderVisible = true), this.delay);
  }
}
