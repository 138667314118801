import { Component, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-specialization-option-template',
  templateUrl: './specialization-option-template.component.html',
  styleUrls: ['./specialization-option-template.component.scss'],
})
export class SpecializationOptionTemplateComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
  assetUrl = environment.assetUrl;
}
