import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberInput]',
})
export class NumberInputDirective implements OnChanges {
  @Input() minValue: number;
  @Input() maxValue: number;

  constructor(private renderer: Renderer2, private element: ElementRef<HTMLInputElement>) {}

  ngOnChanges(): void {
    if (Number.isInteger(this.minValue)) {
      this.renderer.setAttribute(this.element.nativeElement, 'min', `${this.minValue}`);
    }

    if (Number.isInteger(this.maxValue)) {
      this.renderer.setAttribute(this.element.nativeElement, 'max', `${this.maxValue}`);
    }
  }
}
