import { popperVariation, tooltipVariation } from '@ngneat/helipopper';
import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';
import { AppConstants } from '@config/app.constant';

// configuration for Tippy popover module

export const tippyConfig: Partial<TippyConfig> = {
  defaultVariation: 'tooltip',
  variations: {
    tooltip: tooltipVariation,
    popper: popperVariation,
    // custom variations
    dotMenu: {
      theme: 'dot-menu', // defined in root styles -> tippy.override.scss
      arrow: false,
      animation: 'fade',
      trigger: 'click',
      interactive: true, // only close on outside click
      duration: 100,
      maxWidth: AppConstants.dotMenuWidth,
      offset: [0, -10],
    },
    hint: {
      theme: 'hint',
      arrow: false,
      animation: 'scale',
      trigger: 'mouseenter',
    },
    boardItemHint: {
      theme: 'hint',
      arrow: false,
      animation: 'scale',
      trigger: 'mouseenter',
      offset: [-10, -10],
    },
    overflowMenuItemTooltip: {
      theme: 'hint',
      arrow: false,
      animation: 'scale',
      trigger: 'mouseenter',
      duration: 100,
      offset: [0, -10],
    },
    dragItemTooltip: {
      theme: 'hint',
      arrow: false,
      animation: 'scale',
      trigger: 'click',
      duration: 100,
    },
    syncMessageTooltip: {
      theme: 'sync-feedback',
      arrow: false,
      animation: 'scale',
      trigger: 'mouseenter',
    },
  },
};
