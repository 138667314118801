import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormInputBaseComponent } from '../base/form-input-base.component';

@UntilDestroy()
@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent extends FormInputBaseComponent<string> implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: 'text' | 'email' | 'password' | 'number' = 'text';
  @Input() isHintShown = true;
  @Input() hintText: string;
  @Input() suffixTemplate: TemplateRef<unknown>;

  @Output() keyUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    // Retrieve the dependency only from the local injector,
    // not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form,
    // so we mark the dependency as optional.
    @Optional()
    private ngControl: NgControl
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    if (this.control) {
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        if ([null, undefined, ''].includes(this.control.value as string)) {
          // reset input field visually
          this.input.nativeElement.value = '';
        }
      });
    }

    return superCalled;
  }

  handleBlur() {
    this.onTouched();

    this.inputBlur.emit();
  }
}
