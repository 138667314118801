<ng-template
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
    <div class="d-flex justify-content-center">
        <app-checkbox
            checkboxColor="blue"
            [formControl]="checkBox"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)">
        </app-checkbox>
    </div>
</ng-template>
