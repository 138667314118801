import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';
import { getBasicInputLengthValidators } from '@shared/utils/form/form-input-length-validator.util';
import { AdvertisementLanguage } from '@pages/positions/classes/advertisements/AdvertisementLanguage';
import { AdvertisementProviderStatus } from '@pages/positions/classes/advertisements/AdvertisementProviderStatus';
import { AdvertisementProviderType } from '@pages/positions/classes/advertisements/AdvertisementProviderType';
import { AdvertisementProviderTypes } from '@pages/positions/classes/advertisements/AdvertisementProviderTypes';
import { AppConstants } from '@config/app.constant';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementFormService {
  private readonly FREE_TEXT_VALIDATORS = [
    Validators.minLength(AppConstants.minInputLength),
    Validators.maxLength(AppConstants.advertisementFreeTextMaxLength),
  ];

  constructor(private formBuilder: FormBuilder) {}

  initAdvertisementForm(detail: AdvertisementDetail): FormGroup {
    return this.formBuilder.group({
      id: [detail?.id || null],
      summary: [detail?.summary || null],
      attractive: [detail?.attractive || null, [...this.FREE_TEXT_VALIDATORS]],
      header: [detail?.header || null],
      tasks: [detail?.tasks || null],
      offers: [detail?.offers || null],
      requirements: [detail?.requirements || null],
      benefits: [detail?.benefits || null],
      local: [detail?.local || null],
      locality: [detail?.locality || null, [...getBasicInputLengthValidators(true)]],
      educationTypeId: [detail?.educationType?.id || null, [Validators.required]],
      workExperienceId: [detail?.workExperience?.id || null, [Validators.required]],
      languages: this.getLanguageArray(detail),
      tags: this.getTagArray(detail),
    });
  }

  initProviderForm(detail: AdvertisementProviderCardTableRow): FormGroup {
    const idValidators = detail ? [Validators.required] : [];

    const group = this.formBuilder.group({
      id: [detail?.id || null, idValidators],
      name: [
        {
          value: detail?.name || null,
          disabled: detail?.providerType?.type === AdvertisementProviderTypes.Automatic,
        },
        getBasicInputLengthValidators(true),
      ],
      status: [detail?.status || AdvertisementProviderStatus.Inactive, [Validators.required]],
      startDate: [detail?.startDate || null, [Validators.required]],
      endDate: [detail?.endDate || null, [Validators.required]],
      providerType: this.getProviderGroup(detail?.providerType),
    });

    if (detail) {
      group.get('startDate').markAsTouched();
      group.get('endDate').markAsTouched();
    }

    return group;
  }

  getTagArray(detail: AdvertisementDetail): FormArray {
    const tags = Array.isArray(detail?.tags) && detail.tags.length > 0 ? detail.tags : [];
    return this.formBuilder.array(
      tags.map((tag) => this.getTagGroup(tag)),
      [Validators.required]
    );
  }

  getLanguageArray(detail: AdvertisementDetail): FormArray {
    const languages =
      Array.isArray(detail?.languages) && detail.languages.length > 0 ? detail.languages : [null];
    return this.formBuilder.array(languages.map((language) => this.getLanguageGroup(language)));
  }

  getLanguageGroup(language: AdvertisementLanguage): FormGroup {
    return this.formBuilder.group({
      languageId: [language?.language?.id || null],
      languageLevelId: [language?.languageLevel?.id || null],
    });
  }

  getTagGroup(tag: TagDto): FormGroup {
    return this.formBuilder.group({
      id: [tag?.id || null, [Validators.required]],
      name: [tag?.name || null, [Validators.required]],
    });
  }

  getProviderGroup(provider: AdvertisementProviderType): FormGroup {
    return this.formBuilder.group({
      id: [{ value: provider?.id || null, disabled: !!provider }, [Validators.required]],
      name: [{ value: provider?.name || null, disabled: true }, [Validators.required]],
    });
  }
}
