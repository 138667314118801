import { Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { Observable } from 'rxjs';
import { ConfirmModalData } from '@shared/modules/modals/base-modals/confirm-modal/classes/ConfirmModalConfig';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent
  extends MatModalBaseComponent<ConfirmModalData>
  implements OnInit {
  modalText: string;
  confirmObservable: Observable<unknown>;
  cancelObservable: Observable<unknown>;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.modalText = this.data.modalText;
    this.confirmObservable = this.data.confirmObservable;
    this.cancelObservable = this.data.cancelObservable;
  }

  confirm() {
    if (this.confirmObservable) {
      this.primaryButtonDisabled = true;

      this.confirmObservable.pipe(take(1)).subscribe((result: HttpErrorResponse | unknown) => {
        if (!(result instanceof HttpErrorResponse)) {
          this.closeModal(result ?? true);
        } else {
          this.primaryButtonDisabled = false;
        }
      });
    } else {
      this.closeModal(true);
    }
  }

  cancel() {
    if (this.cancelObservable) {
      this.primaryButtonDisabled = true;

      this.cancelObservable.pipe(take(1)).subscribe(() => {
        this.closeModal(false);
      });
    } else {
      this.closeModal(false);
    }
  }
}
