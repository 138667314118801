import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { SharedModule } from '@shared/shared.module';
import { FilteringModule } from '@shared/modules/filter-components/filtering.module';
import { ModalFooterComponent } from './components/modal-frame/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-frame/modal-header/modal-header.component';
import { MatModalBaseComponent } from './mat-modal-base.component';
import { MatModalService } from './mat-modal.service';
import { ModalFrameComponent } from './components/modal-frame/modal-frame.component';
import { ModalGridComponent } from './components/modal-grid/modal-grid.component';
import { ModalSectionHeaderComponent } from './components/modal-section-header/modal-section-header.component';
import { ConfirmModalComponent } from '../modals/base-modals/confirm-modal/confirm-modal.component';
import { FilterModalComponent } from '../modals/base-modals/filter-modal/filter-modal.component';
import { BulkEditConfirmModalComponent } from '../modals/base-modals/bulk-edit-confirm-modal/bulk-edit-confirm-modal.component';
import { BulkEditResultModalComponent } from '../modals/base-modals/bulk-edit-result-modal/bulk-edit-result-modal.component';
import { ExportModalComponent } from '../modals/base-modals/export-modal/export-modal.component';

@NgModule({
  declarations: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalFrameComponent,
    ModalGridComponent,
    ModalSectionHeaderComponent,
    ConfirmModalComponent,
    FilterModalComponent,
    BulkEditConfirmModalComponent,
    BulkEditResultModalComponent,
    ExportModalComponent,
  ],
  exports: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalGridComponent,
    ModalSectionHeaderComponent,
    ModalFrameComponent,
  ],
  providers: [MatModalService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    TranslateModule,
    ButtonModule,
    InlineSVGModule,
    LoaderModule,
    PipesModule,
    FormComponentsModule,
    SharedModule,
    FilteringModule,
  ],
})
export class MatModalModule {}
