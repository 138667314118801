import { TableFilter } from '@shared/classes/table-filter/TableFilter';
import { TableDynamicFilterState } from '@shared/classes/table-filter/TableDynamicFilterState';

export interface PositionKanbanBoardTableState extends TableDynamicFilterState {}

export const positionKanbanBoardTableInitialState: PositionKanbanBoardTableState = {
  filtersLoaded: false,
  filters: new TableFilter(),
  selectedFilters: undefined,
};
