import { Injectable } from '@angular/core';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { AppConstants } from '@config/app.constant';
import * as moment from 'moment';
import { DurationInputArg2 } from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementProviderHelperService {
  constructor(private atsConfigService: ATSConfigService) {}

  isProfessionAndNotStudent(providerTypeName: string): boolean {
    return (
      !this.atsConfigService.isStudentATS &&
      this.isProviderType(providerTypeName, AppConstants.professionProviderName)
    );
  }

  isWhc(providerTypeName: string) {
    return this.isProviderType(providerTypeName, AppConstants.whcProviderName);
  }

  private isProviderType(providerTypeName: string, checkForTypeName: string): boolean {
    return (
      typeof providerTypeName === 'string' &&
      providerTypeName.toLocaleLowerCase().trim().includes(checkForTypeName.toLocaleLowerCase())
    );
  }

  addIntervalToDate(startDate: string, value: number, unit: DurationInputArg2): Date {
    if (!moment(startDate).isValid()) {
      return null;
    }

    return moment(new Date(startDate)).add(value, unit).toDate();
  }
}
