<ng-container [formGroup]="jobTypeGroup">
    <app-form-field>
        <app-select
          [options]="jobTypes"
          bindValueByPropertyNameToControl="id"
          bindLabel="name"
          bindValue="id"
          [clearable]="true"
          label="candidates.work_type"
          formControlName="typeId">
        </app-select>
    </app-form-field>

    <app-form-field *ngIf="subJobTypes.length > 0">
        <app-select
          [options]="subJobTypes"
          bindValueByPropertyNameToControl="id"
          bindLabel="name"
          bindValue="id"
          [isHintShown]="true"
          label="candidates.physical_work_type"
          formControlName="subTypeId">
        </app-select>
    </app-form-field>

    <div *ngIf="!alwaysHideSpecializations">
        <div *ngFor="let spec of specializationsGroup.controls; let i=index">
            <ng-container [formGroup]="spec">
                <app-form-field>
                    <app-select
                      (selectionChange)="onSelectionChange()"
                      [options]="availableSpecializations"
                      bindValueByPropertyNameToControl="id"
                      bindLabel="name"
                      bindValue="id"
                      [label]="specLabel"
                      [clearable]="clearable && i === 0"
                      formControlName="id">
                        <app-specialization-option-template #optionsTemplate></app-specialization-option-template>
                    </app-select>
                    <div *ngIf="specializationsGroup.controls.length > 1" right-side>
                        <app-icon-button class="d-block" (buttonClick)="onRemoveElement(i)" textAlign="right"
                                         hintText="common.delete" iconUrl="assets/image/close-icon.svg"></app-icon-button>
                    </div>
                </app-form-field>

                <div *ngIf="specializationsGroup.valid && i === specializationsGroup.controls.length - 1" class="my-20px d-flex flex-column">
                    <button type="button"
                            (click)="onAddSpecialization()" class="add-more-item text-left p-0 m-0">
                        {{'positions.add_categories' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

</ng-container>
