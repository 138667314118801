import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { FormGroup } from '@angular/forms';
import { InlineEditColumnParams } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-params';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { InlineEditColumnData } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';

export interface InlineEditDataList {
  [key: string]: InlineEditData;
}

export interface InlineEditData {
  rowIndex: number;
  property: string | number;

  params?: InlineEditColumnParams;

  isEditing: boolean;
  isLoading: boolean;
  saveStatus: InlineEditCellSaveStatus;
  errorMessage: string;

  inputStatus: InlineEditCellInputStatus;
  inputGroup: FormGroup;

  isSelected: boolean;
  active: boolean;
}

export enum InlineEditCellSaveStatus {
  Unchanged = 'unchanged',
  Updated = 'updated',
  Error = 'error',
}

export enum InlineEditCellInputStatus {
  Valid = 'valid',
  Invalid = 'invalid',
}

export interface InlineEditCellData {
  row: DataTableRow;
  rowIndex: number;
  column: TableColumn;
  value: unknown;
}

export interface InlineEditRowId {
  primaryKey: unknown;
  secondaryKey: unknown;
  rowIndex?: number;
  column?: TableColumn;
}

export interface InlineEditCellId {
  rowIndex: number;
  column: TableColumn;
}

export interface InlineEditTriggerCellRefresh {
  [key: string]: number;
}

export interface InlineEditCellAllData {
  cellData: InlineEditData;
  columnData: InlineEditColumnData;
}
