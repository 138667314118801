import { PartnerDetailMappedData } from '@pages/partners/classes/PartnerDetailMappedData';
import { getPartnerKeyValueData } from '@pages/partners/utils/get-partner-key-value-data.util';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { getContactKeyValueData } from '@pages/partners/utils/get-contact-key-value.util';

export function mapPartnerDetailToKeyValues(partnerDetail: PartnerDetail): PartnerDetailMappedData {
  const { partnerContacts } = partnerDetail;
  const partnerKeyValueData = getPartnerKeyValueData(partnerDetail);
  const partnerContactKeyValueData = getContactKeyValueData(partnerContacts, 'partner');

  return {
    partnerDetail,
    partnerContactKeyValueData,
    partnerKeyValueData,
  };
}
