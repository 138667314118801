import { Injectable, Injector } from '@angular/core';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { catchError, concatMap, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { AppConstants, PartnerOrProject } from '@config/app.constant';
import { ProjectDto } from '@pages/partners/classes/ProjectDto';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { PartnerApiService } from '@pages/partners/services/base/partner-api.service';
import { PartnerDto } from '@pages/partners/classes/PartnerDto';
import { FormGroup } from '@angular/forms';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { ProjectCardTableRow } from '@pages/partners/classes/ProjectCardTableRow';
import { createEmptyListData, ListData, ListTotalCount } from '@shared/classes/ListData';
import { Partner } from '@pages/partners/classes/Partner';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CardTableService } from '@shared/modules/card-table/services/card-table.service';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';
import { hasCommonError } from '@shared/utils/error-keys.util';
import { environment } from '@environments/environment';
import { getAddressString } from '@pages/partners/utils/get-address-string.util';
import { PositionCardTableRow } from '@pages/partners/classes/PositionCardTableRow';
import { head, isEmpty } from 'lodash-es';
import { Contact } from '@pages/partners/classes/Contact';
import { Specialization } from '@pages/candidates/classes/Specialization';
import * as moment from 'moment/moment';
import { PartnerStateService } from '@pages/partners/services/base/partner-state.service';
import { PartnerState } from '@shared/modules/state-manager/state/partners/partner.state';
import { PartnerHistoryService } from '@pages/partners/services/partner-history.service';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { TablePageService } from '@shared/classes/table/TablePageService';

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends TablePageService {
  private toast: ToastService;
  private headerService: HeaderService;

  private navigateBack$: Subject<void> = new Subject<void>();

  constructor(
    private partnerState: PartnerStateService,
    private partnerApiService: PartnerApiService,
    private partnerHistoryService: PartnerHistoryService,
    private cardTableService: CardTableService,
    private injector: Injector
  ) {
    super();

    this.toast = this.injector.get<ToastService>(ToastService);
    this.headerService = this.injector.get<HeaderService>(HeaderService);
  }

  private getStateSnapshot() {
    return this.partnerState.getStateSnapshot();
  }

  private setState(slice: Partial<PartnerState>) {
    return this.partnerState.setState(slice);
  }

  triggerNavigateBack(): void {
    this.navigateBack$.next();
  }

  onNavigateBack(): Observable<void> {
    return this.navigateBack$.asObservable();
  }

  getPartners(
    page: string,
    perPage: string,
    base64EncodedFilter?: string
  ): Observable<ListData<Partner>> {
    return this.partnerApiService.getPartners(page, perPage, base64EncodedFilter).pipe(
      catchError(() => {
        return of(createEmptyListData<Partner>());
      })
    );
  }

  getTotalCount(base64EncodedFilter?: string): Observable<ListTotalCount> {
    return this.partnerApiService.getPartnerTotalCount(base64EncodedFilter);
  }

  callCreatePartner(form: FormGroup): Observable<PartnerDetail> {
    const partnerFormData = form.getRawValue() as PartnerDto;
    const endpoint = this.partnerApiService.createPartner(partnerFormData).pipe(
      tap(() => {
        this.triggerRefreshList();
      })
    );

    return this.handlePartnerProjectSaveResponse(
      endpoint,
      'partner',
      false
    ) as Observable<PartnerDetail>;
  }

  callUpdatePartner(form: FormGroup): Observable<PartnerDetail> {
    const partnerFormData = form.getRawValue() as PartnerDto;
    const endpoint = this.partnerApiService.updatePartner(partnerFormData).pipe(
      tap((updatedDetail: PartnerDetail) => {
        this.setState({ headerText: updatedDetail.name });
        this.setState({ partnerDetail: updatedDetail });
        this.triggerRefreshList();
      }),
      concatMap((updatedDetail: PartnerDetail) => {
        return this.partnerHistoryService
          .getFirstHistoryPage(updatedDetail.id, 'partner')
          .pipe(map(() => updatedDetail));
      })
    );

    return this.handlePartnerProjectSaveResponse(
      endpoint,
      'partner',
      true
    ) as Observable<PartnerDetail>;
  }

  getAllProjects(partnerId: number): Observable<ListData<ProjectDetail>> {
    const { currentPage, perPage } = this.cardTableService.getStateSnapshot();

    return this.cardTableService.callGetItems<ProjectDetail, ProjectCardTableRow>(
      'projectRows',
      this.partnerApiService.getProjects(partnerId, 1, (currentPage + 1) * perPage),
      this.mapProjectsToCardTable.bind(this)
    );
  }

  callLoadMoreProject(partnerId: number): Observable<ListData<ProjectDetail>> {
    return this.cardTableService.callLoadMoreItems<ProjectDetail, ProjectCardTableRow>(
      partnerId,
      'projectRows',
      this.partnerApiService.getProjects.bind(this.partnerApiService),
      this.mapProjectsToCardTable.bind(this)
    );
  }

  callDeleteProject(partnerId: number, project: ProjectCardTableRow | ProjectDetail) {
    return this.partnerApiService.deleteProject(partnerId, project?.id).pipe(
      tap(() => {
        this.toast.showSuccess('partners.project_delete_success');
      }),
      catchError((err: MessageErrorResponse) => {
        if (!hasCommonError(err?.error?.errors)) {
          this.toast.showError('partners.project_delete_error');
        }
        return of(err);
      })
    );
  }

  callDeletePartner(partnerId: number) {
    return this.partnerApiService.deletePartner(partnerId).pipe(
      tap(() => {
        this.toast.showSuccess('partners.partner_delete_success');
      }),
      catchError((err: MessageErrorResponse) => {
        if (!hasCommonError(err?.error?.errors)) {
          this.toast.showError('partners.partner_delete_error');
        }
        return of(err);
      })
    );
  }

  callToggleProjectStatus(partnerId: number): Observable<ProjectDetail | HttpErrorResponse> {
    const selectedProject = this.cardTableService.getStateSnapshot().selectedProject;

    return this.partnerApiService
      .updateProjectStatus(partnerId, selectedProject.id, selectedProject.status)
      .pipe(
        tap(() => {
          this.cardTableService.setState({ selectedProject: undefined });
        }),
        concatMap((updatedProject: ProjectDetail) => {
          return this.partnerHistoryService
            .getFirstHistoryPage(partnerId, 'partner')
            .pipe(map(() => updatedProject));
        }),
        mergeMap((data) => {
          return this.getAllProjects(partnerId).pipe(mapTo(data));
        }),
        tap(() => {
          this.toast.showSuccess(getGeneralMessage('partners.status_modify', true));
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(getGeneralMessage('partners.status_modify', false));
          return of(err);
        })
      );
  }

  callCreateProject(form: FormGroup): Observable<ProjectDetail> {
    const projectFormData = form.getRawValue() as ProjectDto;
    const partnerId = this.getStateSnapshot().partnerDetail.id;

    const endpoint = this.partnerApiService.createProject(projectFormData, partnerId).pipe(
      concatMap((response) => {
        if (response instanceof HttpErrorResponse) {
          return of(response);
        }

        return this.partnerHistoryService
          .getFirstHistoryPage(partnerId, 'partner')
          .pipe(map(() => response));
      }),
      concatMap((data) => {
        return this.getAllProjects(partnerId).pipe(mapTo(data));
      })
    );

    return this.handlePartnerProjectSaveResponse(
      endpoint,
      'project',
      false
    ) as Observable<ProjectDetail>;
  }

  callUpdateProject(form: FormGroup): Observable<ProjectDetail> {
    const projectFormData = form.getRawValue() as ProjectDto;
    const partnerId = this.getStateSnapshot().partnerDetail.id;

    const endpoint = this.partnerApiService.updateProject(projectFormData, partnerId).pipe(
      tap((updatedDetail: ProjectDetail) => {
        this.setState({ headerText: updatedDetail.name });
      }),
      concatMap((updatedDetail: ProjectDetail) => {
        if (updatedDetail instanceof HttpErrorResponse) {
          return of(updatedDetail);
        }

        return this.partnerHistoryService
          .getFirstHistoryPage(updatedDetail.id, 'project')
          .pipe(map(() => updatedDetail));
      })
    );

    return this.handlePartnerProjectSaveResponse(
      endpoint,
      'project',
      true
    ) as Observable<ProjectDetail>;
  }

  private handlePartnerProjectSaveResponse(
    endpoint: Observable<PartnerDetail | ProjectDetail>,
    type: PartnerOrProject,
    isEditMode: boolean
  ): Observable<PartnerDetail | ProjectDetail | HttpErrorResponse> {
    const postFix = type === 'partner' ? 'partner' : 'project';
    const message = !isEditMode ? `partners.create_${postFix}` : `partners.update_${postFix}`;

    return endpoint.pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage(message, true));
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      })
    );
  }

  getAllPositions(projectId: number): Observable<ListData<ProjectPosition>> {
    const { currentPage, perPage } = this.cardTableService.getStateSnapshot();

    return this.cardTableService.callGetItems<ProjectPosition, PositionCardTableRow>(
      'positionRows',
      this.partnerApiService.getProjectPositions(projectId, 1, (currentPage + 1) * perPage),
      this.mapPositionToTableRow.bind(this)
    );
  }

  callLoadMorePosition(projectId: number): Observable<ListData<ProjectPosition>> {
    return this.cardTableService.callLoadMoreItems<ProjectPosition, PositionCardTableRow>(
      projectId,
      'positionRows',
      this.partnerApiService.getProjectPositions.bind(this.partnerApiService),
      this.mapPositionToTableRow.bind(this)
    );
  }

  exportTable(): Observable<void> {
    return this.headerService.openExportDataModal(this.callExportTable.bind(this));
  }

  private callExportTable(): Observable<void | HttpResponse<Blob> | HttpErrorResponse> {
    const partnerFilter = this.getStateSnapshot().filters;
    const sortedFilters = removeBlankAttributes(partnerFilter);
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }

    if (this.headerService.getStateSnapshot().filterTotalCount <= environment.exportCount) {
      return this.headerService.handleExportResponse(
        this.partnerApiService.exportPartnerTable(encodedFilters)
      );
    }

    return this.headerService.handleEmailExportResponse(
      this.partnerApiService.exportPartnerTableEmail(encodedFilters)
    );
  }

  refreshProjectPositionTable(projectId: number, toastText: string) {
    return (
      source: Observable<unknown>
    ): Observable<ListData<ProjectPosition> | HttpErrorResponse> =>
      source.pipe(
        concatMap(() => {
          return this.getAllPositions(projectId);
        }),
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  }

  private mapProjectsToCardTable(projects: ProjectDetail[]): ProjectCardTableRow[] {
    return projects.map((project) => ({
      id: project.id,
      name: project.name,
      identifier: project.identifier,
      address: getAddressString(project.address),
      status: project.status,
    }));
  }

  private mapPositionToTableRow(positions: ProjectPosition[]): PositionCardTableRow[] {
    return positions.map((position) => ({
      id: position.id,
      projectManager: head<Contact>(position.projectManagers)?.name,
      name: position?.name ?? '',
      nameIconUrl: `${environment.assetUrl}/${
        head<Specialization>(position.specializations)?.icon
      }`,
      status: position?.status,
      code: position?.code ?? '',
      date: !isEmpty(position.createdAt)
        ? moment(position.createdAt).format(AppConstants.dateFormat)
        : '',
    }));
  }
}
