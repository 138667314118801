import { Component, Injector, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { defaultModalData, ModalData } from '@shared/modules/mat-modal/classes/ModalData';
import { TranslateService } from '@ngx-translate/core';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-mat-modal-base',
  template: '<ng-content></ng-content>',
})
export class MatModalBaseComponent<DataType = ModalData> implements OnDestroy {
  private callSuperFoo = Symbol('Calling super.ngOnDestroy is mandatory');
  static modalConfig: MatDialogConfig = { data: defaultModalData, disableClose: true };

  protected modalService: MatModalService;
  protected translateService: TranslateService;

  dialogRef: MatDialogRef<MatModalBaseComponent>;
  data: DataType;
  loading: boolean;
  primaryButtonDisabled: boolean;

  constructor(protected injector: Injector) {
    this.modalService = injector.get<MatModalService>(MatModalService);
    this.translateService = injector.get<TranslateService>(TranslateService);

    this.dialogRef = this.injector.get(MatDialogRef, null) as MatDialogRef<MatModalBaseComponent>;
    this.data = this.injector.get(MAT_DIALOG_DATA, {}) as DataType;
  }

  handleConfirmActionResponse(result: unknown): void {
    if (result instanceof HttpErrorResponse) {
      this.primaryButtonDisabled = false;
    } else {
      this.closeModal(result);
    }
  }

  closeModal(data?: unknown): void {
    this.dialogRef.close(data);
  }

  getFormControl(formGroup: AbstractControl, name: string): FormControl {
    return formGroup?.get(name) as FormControl;
  }

  ngOnDestroy(): Symbol {
    this.modalService.removeDialog(this.dialogRef);
    return this.callSuperFoo;
  }
}
