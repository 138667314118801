import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobTypeSelectionComponent } from '@shared/modules/selections/job-type-specialization-selection/components/job-type-selection/job-type-selection.component';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { SpecializationOptionTemplateComponent } from './components/specialization-label-template/specialization-option-template.component';

@NgModule({
  declarations: [JobTypeSelectionComponent, SpecializationOptionTemplateComponent],
  exports: [JobTypeSelectionComponent, SpecializationOptionTemplateComponent],
  imports: [CommonModule, FormComponentsModule, ReactiveFormsModule, ButtonModule, TranslateModule],
})
export class JobTypeSpecializationSelectionModule {}
