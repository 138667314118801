import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { MultipleSelectionComponent } from './multiple-selection/multiple-selection.component';

@NgModule({
  declarations: [MultipleSelectionComponent],
  exports: [MultipleSelectionComponent],
  imports: [CommonModule, ReactiveFormsModule, FormComponentsModule, TranslateModule, ButtonModule],
})
export class MultipleSelectionModule {}
