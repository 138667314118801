import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-overflow-filter-footer',
  templateUrl: './overflow-filter-footer.component.html',
  styleUrls: ['./overflow-filter-footer.component.scss'],
})
export class OverflowFilterFooterComponent {
  @Input() showSecondaryButton: boolean = false;
  @Input() secondaryButtonText: string = '';
  @Input() applyButtonText: string;
  @Input() buttonSize: string;

  @Output() secondaryButtonClicked = new EventEmitter<void>();
  @Output() applyClicked = new EventEmitter<void>();

  emitSecondaryButtonClick(event: Event) {
    event.stopPropagation();

    this.secondaryButtonClicked.emit();
  }

  emitApply() {
    this.applyClicked.emit();
  }
}
