import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CandidateCustomField } from '@pages/candidates/classes/CandidateCustomField';
import { FormControl } from '@angular/forms';
import { CandidateCustomFieldOption } from '@pages/candidates/classes/CandidateCustomFieldOption';
import { CandidateCustomFieldType } from '@pages/candidates/classes/CandidateCustomFieldType';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectComponent } from '@shared/modules/form-components/select/select.component';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';

@UntilDestroy()
@Component({
  selector: 'app-custom-field-selection',
  templateUrl: './custom-field-selection.component.html',
  styleUrls: ['./custom-field-selection.component.scss'],
})
export class CustomFieldSelectionComponent implements OnInit {
  @ViewChild(SelectComponent) select: SelectComponent;

  @Input() typeControl: FormControl; // value: custom field type id
  @Input() valueControl: FormControl; // CandidateCustomFieldFormValue || number[]
  @Input() customFieldTypes: CandidateCustomField[];

  customFieldTypeOptions: CandidateCustomFieldOption[];
  selectedFieldType: CandidateCustomField;

  selectedTags: TagDto[];

  readonly DROPDOWN_TYPE = CandidateCustomFieldType;

  ngOnInit() {
    this.setSelectedFieldTypeAndOptions();
  }

  onCustomFieldTypeChange() {
    this.setSelectedFieldTypeAndOptions();

    this.valueControl.reset();
    this.valueControl.markAllAsTouched();
  }

  setValueFromTagSelect(tags: TagDto[]) {
    this.selectedTags = tags;
    this.valueControl.setValue(tags.map((tag) => tag.id));
  }

  setValueFromSelect(item: CandidateCustomFieldOption) {
    this.valueControl.setValue(item.id);
  }

  private setSelectedFieldTypeAndOptions() {
    this.selectedFieldType = this.customFieldTypes.find(
      (fieldType) => fieldType.id === this.typeControl?.value
    );

    this.setCustomFieldTypeOptions();
    this.setSelectedTags();
  }

  private setCustomFieldTypeOptions() {
    this.customFieldTypeOptions = this.selectedFieldType?.options ?? [];
  }

  private setSelectedTags() {
    if (this.selectedFieldType?.type === CandidateCustomFieldType.Multiselect) {
      const tagIds: { [key: string]: boolean } = {};
      const valueArray = this.valueControl.value as number[];

      for (const index in valueArray) {
        tagIds[valueArray[index]] = true;
      }

      this.selectedTags = this.customFieldTypeOptions.filter((option) => tagIds[option.id]);
    }
  }
}
