import { Component } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
})
export class StatusCellComponent extends CardTableCustomCellBaseComponent {
  statuses = ProjectStatus;
}
