import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ComponentType } from '@angular/cdk/overlay';
import { ModalPrimaryButtonColor } from '@shared/modules/mat-modal/classes/ModalPrimaryButtonColor';

export interface ModalData {
  contentComponent?: ComponentType<unknown>;
  title?: string;
  variant?: ModalTypes;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  primaryButtonColor?: ModalPrimaryButtonColor;
}

export const defaultModalData: Omit<ModalData, 'contentComponent' | 'title'> = {
  variant: ModalTypes.FullHeight,
  secondaryButtonText: 'common.cancel',
  primaryButtonText: 'common.save',
  primaryButtonColor: 'basic',
};
