import { Component, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-candidate-search-option-template',
  templateUrl: './candidate-search-option-template.component.html',
  styleUrls: ['./candidate-search-option-template.component.scss'],
})
export class CandidateSearchOptionTemplateComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
}
