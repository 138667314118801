import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent extends FormInputBaseComponent<string> implements OnInit {
  @Input() rows = 5;
  @Input() isHintShown = true;
  @Output() keyUp: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Self() @Optional() private ngControl: NgControl) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }
}
