import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';

import {
  TableViewInitialState,
  TableViewState,
} from '@shared/modules/custom-selectors/custom-table-view-selector/states/table-view.state';

@Injectable({
  providedIn: 'root',
})
export class TableViewStateService extends StateService<TableViewState> {
  constructor() {
    super(TableViewInitialState);
  }
}
