<div *ngFor="let formGroup of formGroups.controls, let i = index;" [class.top-separator]="i > 0">
    <div class="position-relative">
        <app-partner-project-position-selection
            [preFillData]="createdAtPositions"
            [isProjectDropdownAlwaysShown]="createdAtPositions"
            [partners]="partners"
            [partnerIdControl]="getFormControl(formGroup, 'partnerId')"
            [projectIdControl]="getFormControl(formGroup, 'projectId')"
            [positionIdControl]="getFormControl(formGroup, 'positionId')">
        </app-partner-project-position-selection>

        <div class="position-form-remove-icon">
            <app-icon-button
                (buttonClick)="removeForm(i)"
                class="d-block"
                textAlign="right"
                hintText="common.delete"
                iconUrl="assets/image/close-icon.svg">
            </app-icon-button>
        </div>
    </div>
</div>
<div *ngIf="!createdAtPositions" class="my-20px d-flex flex-column">
    <button (click)="addNewForm()"
            [disabled]="disabled"
            type="button"
            class="add-more-item text-left p-0 m-0">
        + {{'candidates.attach_title' | translate}}
    </button>
</div>
