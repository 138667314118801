<div class="d-flex align-items-center truncate">
    <div class="sum-icon-box mr-5px">
        <app-hint
          *ngIf="icon"
          [hintTypes]="icon.hintType"
          [hintText]="icon.tooltipTexts"
          variation="boardItemHint">
        </app-hint>
    </div>
    <div
      class="candidate-image mr-5px"
      [appSetBackgroundImage]="candidateSearchItem?.avatarThumb || 'assets/image/person-placeholder.svg'">
    </div>

    <div class="container">
        <div class="row">
            <div class="col pl-0 pr-2 truncate">{{candidateSearchItem?.name}}</div>
            <div class="col right-aligned-text">{{candidateSearchItem?.email}}</div>
        </div>
    </div>
</div>
