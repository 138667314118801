import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import keys from 'lodash-es/keys';
import get from 'lodash-es/get';
import { snakeCase } from 'lodash-es';

@Component({
  selector: 'app-hint-error-list',
  templateUrl: './hint-error-list.component.html',
  styleUrls: ['./hint-error-list.component.scss'],
})
export class HintErrorListComponent implements OnInit {
  @Input() errors: ValidationErrors;

  errorKeys: string[];
  correctKey: string;

  formatKeyToTranslate: string;

  ngOnInit() {
    const excludedKeys = ['userId'];

    this.errorKeys = keys(this.errors)
      .filter((key) => !excludedKeys.includes(key))
      .map((key) => snakeCase(key));

    this.correctKey = keys(this.errors).pop();

    this.formatKeyToTranslate = get(
      this.errors.format || this.errors[this.correctKey],
      keys(this.errors.format || this.errors[this.correctKey]),
      ''
    ) as string;
  }
}
