import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { ModalType } from '@shared/classes/ModalType';
import { UserRole } from '@shared/modules/auth/classes/UserRole';

const roleToModalTypeMap: Record<string, ModalType | undefined> = {
  [UserRole.PhoneContact]: ModalType.PhoneContact,
  [UserRole.UserContact]: ModalType.UserContact,
  [UserRole.Candidate]: ModalType.Candidate,
};

export function getModalTypeUtil(roles: MultiUserRole[]) {
  let modalType: ModalType | undefined;

  if (roles.findIndex((role) => role.name === UserRole.Candidate) !== -1) {
    modalType = ModalType.Candidate;
  } else {
    const nonCandidateRole = roles.find((role) => role.name !== UserRole.Candidate);

    if (nonCandidateRole) {
      modalType = roleToModalTypeMap[nonCandidateRole.name];
    }
  }

  return modalType;
}
