<button
        [ngClass]="buttonClass"
        [class.small-button] = "isSmallButton"
        [type]="type"
        (click)="buttonClick.emit()"
        [disabled]="disabled">
    <mat-icon *ngIf="icon">
        {{icon}}
    </mat-icon>
    {{text | translate}}
</button>
