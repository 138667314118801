<app-modal-frame [data]="data" [dialogRef]="dialogRef" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'common.bulk_edit_text'"
        [iconUrl]="'assets/image/building.svg'">
    </app-modal-header>

    <ng-container content *ngIf="!loading">
        <form [formGroup]="form" class="modal-base h-auto">
            <p class="basic-blue-normal-text">{{selectedItems$ | async}} {{'common.selected_items' | translate}}</p>

            <app-form-field *ngIf="!isLaborHireATS">
                <app-datepicker
                    [isHintShown]="true"
                    [clearable]="true"
                    label="candidates.membership_end"
                    [minDate]="membershipMinDate"
                    [formControl]="form.get('membershipEndDate')">
                </app-datepicker>
            </app-form-field>

            <app-position-selector
                [partnerControl]="partnerControl"
                [positionsControl]="positionsControl"
                [projectControl]="projectControl"
                [partners]="partners"
                [positions]="positions"
                [projects]="projects"
                (partnerChange)="onPartnerChange($event)"
                (projectChange)="resetStatusWithOptions()"
                (positionChange)="resetStatusWithOptions()">
            </app-position-selector>

            <app-form-field *ngIf="positionsControl.value">
                <app-select
                    label="common.status"
                    bindValue=""
                    bindLabel="name"
                    [options]="availableStatuses"
                    (selectionChange)="onStatusChange($event)"
                    [searchable]="true"
                    [clearable]="true"
                    [formControl]="statusControl">
                </app-select>
            </app-form-field>

            <app-form-field *ngIf="statusOption.length > 0">
                <app-select
                    label="positions.board.reason"
                    bindValue=""
                    bindLabel="name"
                    [options]="statusOption"
                    [clearable]="true"
                    [formControl]="statusOptionControl">
                </app-select>
            </app-form-field>

            <div *ngIf="isLaborHireATS && isOutsourced">

                <app-form-field>
                    <app-text-field
                        label="positions.feor_number"
                        formControlName="feorNumber">
                    </app-text-field>
                </app-form-field>

                <ng-container *ngIf="positionType === POSITION_TYPE.Outsourced">
                    <app-form-field>
                        <app-yes-no-select
                                label="candidates.guarantee"
                                formControlName="guarantee">
                        </app-yes-no-select>
                    </app-form-field>
                </ng-container>


                <app-form-field>
                    <app-datepicker
                        [isHintShown]="true"
                        [clearable]="true"
                        label="candidates.labor_start"
                        [maxDate]="membershipMaxDate"
                        [formControl]="form.get('membershipStartDate')">
                    </app-datepicker>
                </app-form-field>

                <app-position-selector
                    partnerLabel="positions.sub_partners"
                    positionLabel="common.sub_position"
                    [projectLabel]="{
                  student: 'common.sub_project',
                  pensioner: 'common.sub_project',
                  laborHire: 'common.sub_site'
              }"
                    [partnerControl]="subPartnerControl"
                    [positionsControl]="subPositionControl"
                    [projectControl]="subProjectControl"
                    [partners]="partners"
                    [positions]="subPositions"
                    [projects]="subProjects"
                >
                </app-position-selector>
            </div>

        </form>
    </ng-container>

    <app-modal-footer
        footer
        (primaryButtonClicked)="openConfirmBulkEditModal()"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [dialogRef]="dialogRef">
    </app-modal-footer>

</app-modal-frame>
