<ng-template
  let-column="column"
  let-rowIndex="rowIndex"
  let-value="value"
  let-row="row"
>
    <ng-container
        *appNgVar="inlineEditService.triggerCellRefresh[rowIndex + '-' + column.prop] | inlineEditCellData:rowIndex:column as data"
    >
        <app-inline-edit-cell-container
            [rowIndex]="rowIndex"
            [row]="row"
            [column]="column"
            [value]="value"
            [cellData]="data.cellData"
            [columnData]="data.columnData"
            [fillHandleDisabled]="data.columnData.params.disableFillHandle"
        >
            <form *ngIf="data?.cellData?.inputGroup" cell-inline-input [formGroup]="data.cellData.inputGroup">

                <div *ngIf="data.cellData.isEditing"
                     appClickOutside
                     [ignoreContainers]="['ng-dropdown-panel', 'mat-calendar']"
                     [ignoreClasses]="['mat-calendar-body-cell-content']"
                     [listenForClasses]="['datatable-body-cell-label']"
                     (clickOutside)="endCurrentCellEditing()">

                    <ng-container [ngSwitch]="data.columnData.params.type">
                        <ng-container *ngSwitchCase="COLUMN_TYPE.Text">
                            <input
                                appAutoFocus
                                class="w-100 inline-edit-text-input"
                                [class.input-invalid]="data.cellData.inputStatus === INPUT_STATUS.Invalid"
                                (keyup.enter)="endInlineEditAndSave(rowIndex, column, row, value)"
                                [placeholder]="data.columnData.params.placeholder ?? ''"
                                formControlName="value"
                            />
                        </ng-container>

                        <ng-container *ngSwitchCase="COLUMN_TYPE.Select">
                            <ng-select
                                class="inline-edit-ng-select ng-select-small-size"
                                [loading]="data.columnData.loading"
                                (change)="endInlineEditAndSave(rowIndex, column, row, value, true)"
                                [appendTo]="appendTo"
                                [clearable]="false"
                                [class.input-invalid]="data.cellData.inputStatus === INPUT_STATUS.Invalid"
                                [bindLabel]="data.columnData.bindLabel"
                                [items]="data.columnData.items"
                                (open)="onDropdownOpen()"
                                formControlName="value">
                            </ng-select>
                        </ng-container>

                        <ng-container *ngSwitchCase="COLUMN_TYPE.MultiSelect">
                            <ng-select
                                class="inline-edit-ng-select ng-select-small-size"
                                [placeholder]="'common.please_select' | translate"
                                [searchable]="false"
                                [multiple]="true"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [loading]="data.columnData.loading"
                                [appendTo]="'body'"
                                [class.input-invalid]="data.cellData.inputStatus === INPUT_STATUS.Invalid"
                                [bindLabel]="data.columnData.bindLabel"
                                [items]="data.columnData.items"
                                (open)="onDropdownOpen()"
                                formControlName="value">

                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div *ngIf="items.length  <= 1">
                                        <div *ngFor="let item of items | slice:0:1"
                                             class="ng-value multi-cell-value">
                                            <span class="ng-value-label">{{item.name}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="items.length > 1" class="ng-value multi-cell-value">
                                        <span class="ng-value-label">
                                            {{'common.number_of_selected_items' | appTranslateWithParams : { selectedItemNumber: items.length } | async }}
                                        </span>
                                    </div>

                                    <div (click)="endInlineEditAndSave(rowIndex, column, row, value)"
                                         class="apply-selected-icon">
                                        <img alt="save" src="assets/image/save_selection.svg" />
                                    </div>
                                </ng-template>

                                <ng-template ng-loadingtext-tmp>
                                    <div class="ng-option disabled">
                                        {{'common.loading' | translate}}
                                    </div>
                                </ng-template>
                            </ng-select>
                        </ng-container>

                        <ng-container *ngSwitchCase="COLUMN_TYPE.Date">
                            <app-datepicker
                                formControlName="value"
                                (dateChanged)="endInlineEditAndSave(rowIndex, column, row, value)"
                                [clearable]="false"
                                [maxDate]="data.columnData.params.maxDate"
                                placeholder="common.please_select"
                                [smallSize]="true"
                                [isHintShown]="true">
                            </app-datepicker>
                        </ng-container>
                    </ng-container>
                </div>

            </form>
        </app-inline-edit-cell-container>
    </ng-container>
</ng-template>
