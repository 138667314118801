import { Component, Input, OnInit } from '@angular/core';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { ValidationErrors } from '@angular/forms';
import includes from 'lodash-es/includes';
import { keys } from 'lodash-es';
import {
  hintComponentCurrentlyShownMap,
  hintComponentKeyMap,
} from '@shared/modules/hint/utils/hint-keys.util';
import { HintBaseComponent } from '@shared/modules/hint/components/hint-base/hint-base.component';
import { getHintTemplateType } from '@shared/modules/hint/utils/get-hint-template-type.util';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent extends HintBaseComponent implements OnInit {
  @Input() errors: ValidationErrors;

  @Input() set hintTypes(hintTypes: HintType[]) {
    this.hideAndResetAllHints();

    const foundHintKey = this.findHintByType(hintTypes);

    if (Number.isInteger(foundHintKey)) {
      this.setCurrentHint(foundHintKey);
    }
  }

  hintKeyMap = hintComponentKeyMap;
  hintsCurrentlyShownMap = { ...hintComponentCurrentlyShownMap };

  ngOnInit(): void {
    this.hintTemplateType = getHintTemplateType(this.hintText);
  }

  private setCurrentHint(foundHintKey: HintType): void {
    const foundConditionalKey = this.hintKeyMap[foundHintKey];
    this.hintsCurrentlyShownMap = { ...this.hintsCurrentlyShownMap, [foundConditionalKey]: true };
  }

  private findHintByType(hintTypes: HintType[]): HintType {
    return keys(this.hintKeyMap)
      .map((key: string) => +key)
      .find((hintKey) => includes(hintTypes, hintKey));
  }

  private hideAndResetAllHints(): void {
    keys(this.hintsCurrentlyShownMap).forEach(
      (key: string) => (this.hintsCurrentlyShownMap[key] = false)
    );
    this.hintsCurrentlyShownMap = { ...this.hintsCurrentlyShownMap };
  }
}
