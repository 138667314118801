<ng-template let-row="row" let-value="value">
    <div class="d-flex align-items-center">
        <div class="switch-cell-button">
            <app-switch
              [disabled]="false"
              [isActive]="value.status === statuses.Active"
              (click)="onToggleChange(value)"
              [beforeChange$]="beforeChange$">
            </app-switch>
        </div>
        <span>{{value.textValue | translate | appFirstLetterUppercase}}</span>
    </div>
</ng-template>
