import { Injectable } from '@angular/core';
import { TableViewApiService } from '@shared/modules/custom-selectors/custom-table-view-selector/services/table-view-api.service';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateTableViewDto } from '@shared/modules/custom-selectors/custom-table-view-selector/classes/CreateTableViewDto';
import {
  TableView,
  TableViewColumn,
} from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableView';
import { BaseDataBaseService } from '@pages/base-data/services/base-data-base.service';
import { TableNames } from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableNames';
import { tap } from 'rxjs/operators';
import { AppConstants } from '@config/app.constant';
import { TableViewStateService } from '@shared/modules/custom-selectors/custom-table-view-selector/states/table-view-state.service';

@Injectable({
  providedIn: 'root',
})
export class TableViewService {
  refreshList$: Subject<TableView> = new Subject<TableView>();

  constructor(
    private tableViewApiService: TableViewApiService,
    private baseDataBaseService: BaseDataBaseService,
    private tableViewState: TableViewStateService
  ) {}

  triggerRefreshList(tableView: TableView): void {
    this.refreshList$.next(tableView);
  }

  onRefreshList(): Observable<TableView> {
    return this.refreshList$.asObservable();
  }

  callGetTableViews(table: TableNames): Observable<TableView[]> {
    return this.tableViewApiService.getTableViews(table);
  }

  callGetTableColumns(table: TableNames): Observable<TableViewColumn[]> {
    return this.tableViewApiService.getTableViewColumns(table);
  }

  callCreateTableView(customField: CreateTableViewDto): Observable<TableView | HttpErrorResponse> {
    return this.tableViewApiService.createTableView(customField).pipe(
      this.baseDataBaseService.handleSaveSuccess('phone_contacts.save_view_success'),
      tap((newTableView) => {
        this.triggerRefreshList(newTableView);
      }),
      this.baseDataBaseService.handleSaveError('phone_contacts.save_view_fail', false)
    );
  }

  callEditTableView(
    tableView: CreateTableViewDto,
    tableViewId: number
  ): Observable<TableView | HttpErrorResponse> {
    return this.tableViewApiService.editTableView(tableView, tableViewId).pipe(
      this.baseDataBaseService.handleSaveSuccess('phone_contacts.save_view_success'),
      tap((newTableView) => {
        this.triggerRefreshList(newTableView);
      }),
      this.baseDataBaseService.handleSaveError('phone_contacts.save_view_fail', false)
    );
  }

  callDeleteTableView(tableViewId: number): Observable<void | HttpErrorResponse> {
    const successKey = 'phone_contacts.view_delete_success';

    return this.tableViewApiService.deleteTableView(tableViewId).pipe(
      this.baseDataBaseService.handleSaveSuccess(successKey),
      tap(() => {
        this.triggerRefreshList(null);
      }),
      this.baseDataBaseService.handleDeleteItemResponse(false)
    );
  }

  callGetDefaultTableColumns(table: TableNames): Observable<TableViewColumn[]> {
    return this.tableViewApiService.getDefaultTableViewColumns(table);
  }

  getSelectedColumnsFromState(): Partial<TableViewColumn>[] {
    const state = this.tableViewState.getStateSnapshot();

    return state.selectedTableColumnOptions.map((option) => {
      const columnOrder =
        state.orderedTableColumns?.findIndex((column) => {
          if (column.prop.toString().startsWith(AppConstants.customColumnIdPrefix)) {
            return option.value === column.prop;
          } else {
            return option.label === column.prop;
          }
        }) + 1;

      return {
        id: option.value.toString(),
        columnOrder,
      };
    });
  }
}
