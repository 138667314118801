import { Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormInputBaseComponent } from '@shared/modules/form-components/base/form-input-base.component';
import { IdNameFilterOption } from '@shared/modules/filter-components/components/truefalse-filtering/classes/IdNameFilterOption';
import { AppStateService } from '@shared/services/app-state.service';

@Component({
  selector: 'app-yes-no-select',
  templateUrl: './yes-no-select.component.html',
  styleUrls: ['./yes-no-select.component.scss'],
})
export class YesNoSelectComponent extends FormInputBaseComponent<boolean> implements OnInit {
  options: IdNameFilterOption[] = [];

  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private appStateService: AppStateService
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.options = this.appStateService.getYesNoOptionsPure();

    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }
}
