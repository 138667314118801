import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { getAddressString } from '@pages/partners/utils/get-address-string.util';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';
import { environment } from '@environments/environment';

export function getPartnerKeyValueData(detail: PartnerDetail): KeyValue<string>[] {
  const data: { key: string; value: string }[] = [];

  data.push(
    {
      key: 'partners.company_name',
      value: detail?.name ?? AppConstants.noDataTranslationKey,
    },
    {
      key: 'partners.site',
      value: getAddressString(detail?.address),
    },
    {
      key: 'common.tax_number',
      value: detail?.taxNumber ?? AppConstants.noDataTranslationKey,
    }
  );
  if (environment.atsType !== 'labor-hire') {
    data.push(
      {
        key: 'common.back_account_number',
        value: detail?.bankAccountNumber ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'common.company_registration_number',
        value: detail?.companyRegistrationNumber ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'partners.teaor',
        value: detail?.teaor ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'partners.partner_size',
        value: detail?.partnerSize?.toString() ?? AppConstants.noDataTranslationKey,
      }
    );
  }

  return data;
}
