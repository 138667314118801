import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import {
  sourceTableMenuConfigWithActive,
  sourceTableMenuConfigWithArchive,
  SourceTableMenuId,
} from '@pages/base-data/config/sources/source-table-menu-config';
import { SourceTableMenuCellData } from '@shared/modules/data-table/classes/SourceTableMenuCellData';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { SourceModalService } from '@pages/base-data/services/source/source-modal.service';
import { SourceApiService } from '@pages/base-data/services/source/base/source-api.service';
import { SourceIsUsed } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/classes/SourceIsUsed';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';

@Component({
  selector: 'app-source-table-menu-cell',
  templateUrl: './source-table-menu-cell.component.html',
  styleUrls: ['./source-table-menu-cell.component.scss'],
})
export class SourceTableMenuCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<SourceListItem>;

  @Input() menuConfig: DotMenuItem[] = [];
  @Input() sourceId: number;

  sourceIsUsed: SourceIsUsed;

  constructor(
    private sourceModalService: SourceModalService,
    private sourceApiService: SourceApiService,
    private toast: ToastService
  ) {}

  getMenuConfig(item: SourceTableMenuCellData): DotMenuItem[] {
    return item.archive ? sourceTableMenuConfigWithActive : sourceTableMenuConfigWithArchive;
  }

  handleSourceTableDotMenuClick(menu: DotMenuItem, item: SourceListItem): void {
    if (menu.id === SourceTableMenuId.Edit) {
      this.sourceModalService.openModalToUpdateSource(item.id, item);
    } else if (menu.id === SourceTableMenuId.Delete) {
      if (this.sourceIsUsed?.positions) {
        this.showSourceIsUsedInPositionError();
      } else {
        if (this.sourceIsUsed.isUsed) {
          this.sourceModalService.openDeleteSourceModal(item);
        } else {
          this.sourceModalService.openDeleteSourceConfirmModal(item);
        }
      }
    } else if (menu.id === SourceTableMenuId.Archive) {
      this.sourceModalService.openArchiveSourceConfirmModal(item);
    } else if (menu.id === SourceTableMenuId.Active) {
      this.sourceModalService.openActivateSourceConfirmModal(item);
    }
  }

  checkSource(source: SourceListItem) {
    this.sourceApiService
      .checkUsageSource(source?.id)
      .pipe(
        catchError((err: MessageErrorResponse) => {
          this.toast.showError('validation_errors.async_validation_error');
          return throwError(err);
        })
      )
      .subscribe((sourceIsUsed: SourceIsUsed) => {
        this.sourceIsUsed = sourceIsUsed;
      });
  }

  showSourceIsUsedInPositionError() {
    const allPositions = this.sourceIsUsed.positions.join(', ');

    this.toast.showError(
      allPositions,
      {
        autoClose: false,
        duration: 0,
        position: 'top-right',
        className: 'custom-toast-container',
      },
      'validation_errors.could_not_delete_because_use_in_positions'
    );
  }
}
