import { UserFilter } from '@shared/classes/users/UserFilter';
import { GetUserFilter } from '@shared/classes/users/GetUsersFilter';

export interface FilterManageState {
  selectedUserFilter: UserFilter;
  savedUserFilters: GetUserFilter[];
}

export const filterManageInitialState: FilterManageState = {
  selectedUserFilter: undefined,
  savedUserFilters: undefined,
};
