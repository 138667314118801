import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

@Directive({
  selector: '[appInjectHeader]',
})
export class InjectHeaderDirective implements OnDestroy {
  @Input()
  set appInjectHeader(headerComponent: ComponentType<unknown>) {
    this.injectHeaderComponent(headerComponent);
  }

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef
  ) {}

  private injectHeaderComponent(headerComponent: ComponentType<unknown>): void {
    this.clearView();
    if (headerComponent) {
      this.viewContainer.createComponent(headerComponent);
    }
  }

  private clearView(): void {
    this.viewContainer.clear();
  }

  ngOnDestroy(): void {
    this.clearView();
  }
}
