import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterTypes } from '@shared/modules/filter-components/classes/FilterTypes';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { Country } from '@shared/classes/Country';

@Component({
  selector: 'app-filter-container-content',
  templateUrl: './filter-container-content.component.html',
  styleUrls: ['./filter-container-content.component.scss'],
})
export class FilterContainerContentComponent {
  @Input() numberOfActiveFilter = 0;
  @Input() isLaborHireAts = false;
  @Input() countries: Country[];

  @Input() set filters(filters: CardFilterInterface[]) {
    this.setTransformedFilters(filters);
  }

  @Output() setFilter = new EventEmitter<Record<string, unknown>>();
  @Output() resetFilter = new EventEmitter<void>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  transformedFilters: CardFilterInterface[] = [];
  filterTypes = FilterTypes;
  translateKey = 'filters.';

  private setTransformedFilters(filters: CardFilterInterface[]) {
    this.transformedFilters = filters.map((filter: CardFilterInterface) => {
      filter.withCountry =
        filter.filterKey === 'city_id' && filter.translateKey === 'candidate_address';
      if (this.isLaborHireAts) {
        return filter.translateKey === 'candidate_membership_start_date'
          ? { ...filter, translateKey: 'labor_start' }
          : filter;
      }
      return filter;
    });

    this.changeDetector.detectChanges();
  }
}
