<label *ngIf="label && control" [for]="fieldId">
    <span class="field-label d-flex align-items-center">
        <span>
            {{label | translate | appFirstLetterUppercase}}
            <ng-container *ngIf="isOptionalField"> ({{'common.optional' | translate}}) </ng-container>
        </span>
        <span class="ml-10px" *ngIf="isHintShown && hintText">
        <app-hint [hintTypes]="[hintType.Help]" [hintText]="hintText"></app-hint>
    </span>
    <span class="ml-10px" *ngIf="isHintShown && control?.invalid && control?.touched">
        <app-hint [hintTypes]="[hintType.Error]" [errors]="control.errors"></app-hint>
    </span>
</span>
</label>
