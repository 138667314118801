import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { PartnerDto } from '@pages/partners/classes/PartnerDto';
import { EndpointsConfig } from '@config/endpoints.config';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ListData, ListTotalCount } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { PartnerDetailMappedData } from '@pages/partners/classes/PartnerDetailMappedData';
import { map } from 'rxjs/operators';
import { ProjectDto } from '@pages/partners/classes/ProjectDto';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { NormalizedProjectDto } from '@pages/partners/classes/NormalizedProjectDto';
import { Contact } from '@pages/partners/classes/Contact';
import { mapPartnerDetailToKeyValues } from '@pages/partners/utils/map-partner-detail-to-key-values.util';
import { AppConstants } from '@config/app.constant';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';
import { Partner } from '@pages/partners/classes/Partner';
import {
  HistoryMessageInputTypes,
  HistoryMessageType,
} from '@shared/modules/history-message/classes/HistoryMessageType';
import { getPaginatedParams } from '@shared/utils/get-page-per-page.util';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PartnerApiService {
  constructor(private http: HttpService) {}

  updateProjectStatus(
    partnerId: number,
    projectId: number,
    currentStatus: ProjectStatus
  ): Observable<ProjectDetail> {
    return this.http.put(EndpointsConfig.projectStatus(partnerId, projectId), {
      status:
        currentStatus === ProjectStatus.Active ? ProjectStatus.Inactive : ProjectStatus.Active,
    });
  }

  getPartners(page: string, perPage: string, filter?: string): Observable<ListData<Partner>> {
    const params: { [k: string]: string } = {
      page,
      perPage,
    };
    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.partners, params);
  }

  createPartner(partner: PartnerDto): Observable<PartnerDetail> {
    return this.http.post(EndpointsConfig.partners, this.normalizePartnerDto(partner, true));
  }

  updatePartner(partner: PartnerDto): Observable<PartnerDetail> {
    const id = partner.id;
    return this.http.put(
      `${EndpointsConfig.partners}/${id}`,
      this.normalizePartnerDto(partner, false)
    );
  }

  createProject(project: ProjectDto, partnerId: number): Observable<ProjectDetail> {
    return this.http.post(
      EndpointsConfig.projects(partnerId),
      this.normalizeProjectDto(project, true)
    );
  }

  updateProject(project: ProjectDto, partnerId: number): Observable<ProjectDetail> {
    const id = project.id as number;
    return this.http.put(
      EndpointsConfig.projectById(partnerId, id),
      this.normalizeProjectDto(project, false)
    );
  }

  getPartnerById(partnerId: number): Observable<PartnerDetailMappedData> {
    return this.http
      .get<PartnerDetail>(EndpointsConfig.partnerById(partnerId))
      .pipe(map((partnerDetail: PartnerDetail) => mapPartnerDetailToKeyValues(partnerDetail)));
  }

  getPartnerHistory(
    partnerId: number,
    page = 1,
    type?: HistoryMessageType
  ): Observable<ListData<HistoryMessage>> {
    return this.http.get(
      EndpointsConfig.partnerHistory(partnerId),
      getPaginatedParams(page, AppConstants.historyMessagesPerPage, type)
    );
  }

  getPartnerTotalCount(filter?: string): Observable<ListTotalCount> {
    const params: { [k: string]: string } = {};

    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.partnersTotalCount, params);
  }

  createPartnerHistoryMessage(
    partnerId: number,
    data: { message: string; type?: HistoryMessageInputTypes }
  ): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.partnerHistory(partnerId), data);
  }

  updatePartnerHistoryMessage(
    partnerId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.partnerHistoryById(partnerId, historyId), { message });
  }

  deletePartnerHistory(partnerId: number, historyId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.partnerHistoryById(partnerId, historyId));
  }

  createProjectHistoryMessage(
    partnerId: number,
    data: { message: string; type?: HistoryMessageInputTypes }
  ): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.projectHistory(partnerId), data);
  }

  updateProjectHistoryMessage(
    projectId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.projectHistoryById(projectId, historyId), { message });
  }

  deleteProjectHistory(projectId: number, historyId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.projectHistoryById(projectId, historyId));
  }

  getProjects(
    partnerId: number,
    page = 1,
    perPage = AppConstants.cardTableRowsPerPage
  ): Observable<ListData<ProjectDetail>> {
    return this.http.get(EndpointsConfig.projects(partnerId), getPaginatedParams(page, perPage));
  }

  getProjectHistory(
    projectId: number,
    page = 1,
    type?: HistoryMessageType
  ): Observable<ListData<HistoryMessage>> {
    return this.http.get(
      EndpointsConfig.projectHistory(projectId),
      getPaginatedParams(page, AppConstants.historyMessagesPerPage, type)
    );
  }

  getProjectById(partnerId: number, projectId: number): Observable<ProjectDetail> {
    return this.http.get(EndpointsConfig.projectById(partnerId, projectId));
  }

  deleteProject(partnerId: number, projectId: number): Observable<boolean> {
    return this.http.delete(EndpointsConfig.projectById(partnerId, projectId));
  }

  deletePartner(partnerId: number): Observable<boolean> {
    return this.http.delete(EndpointsConfig.partnerById(partnerId));
  }

  getProjectPositions(
    projectId: number,
    page = 1,
    perPage = AppConstants.cardTableRowsPerPage
  ): Observable<ListData<ProjectPosition>> {
    return this.http.get(
      EndpointsConfig.projectPositions(projectId),
      getPaginatedParams(page, perPage)
    );
  }

  getPartnersFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.partnersFirstLetters);
  }

  getPartnerFilters(): Observable<{ [key: string]: CardFilterInterface }> {
    return this.http.get(EndpointsConfig.partnersFilters);
  }

  exportPartnerTable(filter: string): Observable<HttpResponse<Blob>> {
    const params = filter ? { filter } : null;

    return this.http.getFile(EndpointsConfig.partnersExport, params);
  }

  exportPartnerTableEmail(filter: string): Observable<void> {
    const params = filter ? { filter } : null;

    return this.http.get(EndpointsConfig.partnersExportEmail, params);
  }

  private normalizePartnerDto(partner: PartnerDto, isCreateMode: boolean): PartnerDto {
    if (isCreateMode) {
      delete partner.id;
    }

    delete partner.identifier;

    const partnerContacts = this.normalizeNewContacts(partner.partnerContacts);

    return { ...partner, partnerContacts };
  }

  private normalizeProjectDto(project: ProjectDto, isCreateMode: boolean): NormalizedProjectDto {
    if (isCreateMode) {
      delete project.id;
    }

    const projectManagers = (project.projectManagers || []).map((data) => data.id);
    const contacts = this.normalizeNewContacts(project.contacts);

    return { ...project, projectManagers, contacts };
  }

  private normalizeNewContacts(contacts: Contact[]): Contact[] {
    if (!Array.isArray(contacts)) {
      return [];
    }

    return contacts.map((contact) => {
      if (typeof contact.id === 'string') {
        // new contact has a custom string id that's unnecessary for BE
        delete contact.id;
      }
      return contact;
    });
  }
}
