import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { BoardSortMode } from '@shared/modules/header/classes/BoardSortMode';
import { TableFilterState } from '@shared/classes/table-filter/TableFilterState';
import { TableFilter } from '@shared/classes/table-filter/TableFilter';

export interface PositionState extends TableFilterState {
  positionDetail: PositionDetail;
  positionDetailLoaded: boolean;

  advertisementDetail: AdvertisementDetail;
  advertisementDetailLoaded: boolean;

  providers: AdvertisementProviderCardTableRow[];
  selectedProvider: AdvertisementProviderCardTableRow;

  kanbanBoardLoading: boolean;
  isBoardDisabled: boolean;
  kanbanBoardColumns: BoardColumn[];
  candidatesByColumns: Map<number, BoardCandidate[]>;
  selectedKanbanBoardColumn: BoardColumn;
  selectedKanbanBoardCandidate: BoardCandidate;
  previousCandidatesByColumns: Map<number, BoardCandidate[]>;

  selectedPositionType: PositionTypes;
  selectedSortMode: BoardSortMode;
}

export const positionInitialState: PositionState = {
  filtersLoaded: false,
  filters: new TableFilter(),

  positionDetail: undefined,
  positionDetailLoaded: false,

  advertisementDetail: undefined,
  advertisementDetailLoaded: false,

  providers: [],
  selectedProvider: undefined,

  kanbanBoardLoading: false,
  isBoardDisabled: false,
  kanbanBoardColumns: [],
  candidatesByColumns: undefined,
  selectedKanbanBoardColumn: undefined,
  selectedKanbanBoardCandidate: undefined,
  previousCandidatesByColumns: undefined,

  selectedPositionType: undefined,
  selectedSortMode: undefined,
};
