<div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
    <form [formGroup]="distanceRangeGroup">
        <app-form-field class="mb-0">
            <app-text-field
                [minValue]="0"
                [suffixTemplate]="suffixTemplate"
                [placeholder]="'candidates.km_value' | translate"
                [tippy]="'candidates.select_city_required_hint' | translate"
                [lazy]="true"
                variation="hint"
                [isEnabled]="distanceRangeGroup.get('intValue').disabled"
                type="number"
                formControlName="intValue">
            </app-text-field>
        </app-form-field>
    </form>
</div>

<div class="filter-tags-wrapper auto-grow-tag">
    <app-filter-tag
        *ngIf="!!kmValueTagText"
        [value]="kmValueTagText"
        (deleteItem)="onResetKMValue()">
    </app-filter-tag>
</div>

<ng-template #suffixTemplate>
    <span> km</span>
</ng-template>
