import { Injectable } from '@angular/core';
import {
  positionInitialState,
  PositionState,
} from '@shared/modules/state-manager/state/positions/position.state';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { createEmptyListData, ListData, ListTotalCount } from '@shared/classes/ListData';
import { PositionListItem } from '@pages/positions/classes/PositionListItem';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { hasCommonError } from '@shared/utils/error-keys.util';
import { environment } from '@environments/environment';
import { PositionHistoryService } from '@pages/positions/services/details/position-history.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { TablePageService } from '@shared/classes/table/TablePageService';

@Injectable({
  providedIn: 'root',
})
export class PositionsService extends TablePageService {
  constructor(
    private headerService: HeaderService,
    private positionState: PositionStateService,
    private positionApiService: PositionsApiService,
    private toast: ToastService,
    private positionHistoryService: PositionHistoryService
  ) {
    super();
  }

  private getStateSnapshot() {
    return this.positionState.getStateSnapshot();
  }

  private setState(state: Partial<PositionState>) {
    return this.positionState.setState(state);
  }

  getPositions(
    page: string,
    perPage: string,
    base64EncodedFilter?: string
  ): Observable<ListData<PositionListItem>> {
    return this.positionApiService.getPositions(page, perPage, base64EncodedFilter).pipe(
      catchError(() => {
        return of(createEmptyListData<PositionListItem>());
      })
    );
  }

  getTotalCount(base64EncodedFilter?: string): Observable<ListTotalCount> {
    return this.positionApiService.getPositionTotalCount(base64EncodedFilter);
  }

  callCreatePosition(dto: PositionDto): Observable<PositionDetail | HttpErrorResponse> {
    return this.positionApiService.createPosition(dto).pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('positions.create', true));
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('positions.create', false));
        return of(err);
      }),
      finalize(() => {
        this.triggerRefreshList();
      })
    );
  }

  callUpdatePosition(dto: PositionDto): Observable<PositionDetail | HttpErrorResponse> {
    return this.positionApiService.updatePosition(dto).pipe(
      concatMap((detail: PositionDetail) => {
        return this.positionHistoryService.getFirstHistoryPage(detail.id).pipe(map(() => detail));
      }),
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('positions.modify', true));
      }),
      catchError((err: MessageErrorResponse) => {
        if (!hasCommonError(err?.error?.errors)) {
          this.toast.showError(getGeneralMessage('positions.modify', false));
        }
        return of(err);
      })
    );
  }

  callDeletePosition(positionId: number) {
    return this.positionApiService.deletePosition(positionId).pipe(
      tap(() => {
        this.toast.showSuccess('positions.position_delete_success');
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError('positions.position_delete_error');

        return of(err);
      })
    );
  }

  callTogglePositionStatus(
    position: TogglePositionStatus
  ): Observable<PositionDetail | HttpErrorResponse> {
    const toastText = 'partners.position_status_modify';
    return this.positionApiService
      .toggleStatus(
        position.id,
        position.isActive ? PositionStatus.Active : PositionStatus.Inactive
      )
      .pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
          this.triggerRefreshList();
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  }

  resetState() {
    this.setState({ ...positionInitialState });
  }

  exportTable(): Observable<void> {
    return this.headerService.openExportDataModal(this.callExportTable.bind(this));
  }

  private callExportTable(): Observable<void | HttpResponse<Blob> | HttpErrorResponse> {
    const positionFilter = this.getStateSnapshot().filters;
    const sortedFilters = removeBlankAttributes(positionFilter);
    let encodedFilters: string | undefined;

    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }

    if (this.headerService.getStateSnapshot().filterTotalCount <= environment.exportCount) {
      return this.headerService.handleExportResponse(
        this.positionApiService.exportPositionTable(encodedFilters)
      );
    }

    return this.headerService.handleEmailExportResponse(
      this.positionApiService.exportPositionTableEmail(encodedFilters)
    );
  }
}
