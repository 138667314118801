import {
  TableView,
  TableViewColumnsById,
} from '@shared/modules/custom-selectors/custom-table-view-selector/classes/TableView';
import { OverflowSelectOption } from '@shared/modules/overflow-filter-menu/overflow-filter-select-menu/classes/overflow-select-option';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';

export interface TableViewState {
  selectedTableView: TableView;
  selectedTableColumnOptions: OverflowSelectOption[];
  hasUnsavedChanges: boolean;

  orderedTableColumns: TableColumn[];
  currentColumnOptionsById: TableViewColumnsById;
}

export const TableViewInitialState: TableViewState = {
  selectedTableView: undefined,
  selectedTableColumnOptions: undefined,
  hasUnsavedChanges: false,

  orderedTableColumns: undefined,
  currentColumnOptionsById: {},
};
