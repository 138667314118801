import { Component, Input } from '@angular/core';
import { SmsStatus } from '@shared/classes/sms/SmsStatus';
import { HintType } from '@shared/modules/hint/classes/HintType';

@Component({
  selector: 'app-sms-status-indicator',
  templateUrl: './sms-status-indicator.component.html',
  styleUrls: ['./sms-status-indicator.component.scss'],
})
export class SmsStatusIndicatorComponent {
  @Input() status: SmsStatus;

  readonly HINT_TYPE = HintType;
}
