import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ATSType } from '@shared/classes/ATSType';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';

@Injectable({
  providedIn: 'root',
})
export class ATSConfigService {
  constructor(private http: HttpService) {}

  get isLaborHireATS(): boolean {
    return environment.atsType === 'labor-hire';
  }

  get isPensionerATS(): boolean {
    return environment.atsType === 'pensioner';
  }

  get isStudentATS(): boolean {
    return environment.atsType === 'student';
  }

  get atsType(): ATSType {
    return environment.atsType;
  }

  getBackendConfig(name: string): Observable<{ value: string }> {
    const params = {
      name,
    };

    return this.http.get(EndpointsConfig.backendConfig, params);
  }
}
