import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AuthUtils } from '../utils/auth.utils';
import { UserPermission } from '../classes/UserPermission';

@Directive({
  selector: '[appUserHasPermission], [appUserExceptPermission]',
})
export class UserHasPermissionDirective {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService
  ) {}

  @Input() set appUserHasPermission(userPermissions: UserPermission[]) {
    if (AuthUtils.isContainUserPermission(userPermissions, this.auth.getUserRoles())) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set appUserExceptPermission(userPermissions: UserPermission[]) {
    if (!AuthUtils.isContainUserPermission(userPermissions, this.auth.getUserRoles())) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
