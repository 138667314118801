import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { PositionKanbanBoardApiService } from '@pages/positions/services/candidates/base/position-kanban-board-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataTableInlineEditCellBaseComponent } from '@shared/modules/data-table-inline-edit/components/data-table-inline-edit-cell-base.component';

@UntilDestroy()
@Component({
  selector: 'app-status-selection-table-cell',
  templateUrl: './status-selection-table-cell.component.html',
  styleUrls: ['./status-selection-table-cell.component.scss'],
})
export class StatusSelectionTableCellComponent
  extends DataTableInlineEditCellBaseComponent
  implements OnInit {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<unknown>;

  positionStatuses: BoardColumn[];

  constructor(
    private candidateBoardApiService: PositionKanbanBoardApiService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadDependencies();
  }

  loadDependencies() {
    const dependencies = {
      positionStatuses: this.candidateBoardApiService.getColumns(),
    };

    dependencies.positionStatuses
      .pipe(untilDestroyed(this))
      .subscribe((statuses) => (this.positionStatuses = statuses));
  }
}
