import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnChanges, OnDestroy {
  @Input() dialogRef: MatDialogRef<MatModalBaseComponent>;
  @Input() title: string;
  @Input() iconUrl: string;
  @Input() syncControl: AbstractControl;

  syncOn: boolean;
  controlSub: Subscription;

  ngOnChanges({ syncControl }: SimpleChanges) {
    if (syncControl) {
      this.listenForControlValueChange();
    }
  }

  listenForControlValueChange() {
    if (this.controlSub) {
      this.controlSub.unsubscribe();
    }

    this.syncOn = this.syncControl?.value as boolean;

    this.controlSub = this.syncControl?.valueChanges.subscribe((value) => {
      this.syncOn = value as boolean;
    });
  }

  toggleSyncState() {
    this.syncOn = !this.syncOn;
    this.syncControl.setValue(this.syncOn);
  }

  ngOnDestroy() {
    this.controlSub?.unsubscribe();
  }
}
