import { Injectable } from '@angular/core';
import { ManageAdvertisementModalConfig } from '@shared/modules/modals/position-modals/manage-advertisement-modal/classes/ManageAdvertisementModalConfig';
import { ManageAdvertisementModalComponent } from '@shared/modules/modals/position-modals/manage-advertisement-modal/manage-advertisement-modal.component';
import { AppConstants } from '@config/app.constant';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { AdvertisementDto } from '@pages/positions/classes/advertisements/AdvertisementDto';
import { Observable } from 'rxjs';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { AdvertisementImage } from '@pages/positions/classes/advertisements/AdvertisementImage';
import { find } from 'lodash-es';
import { UploadAdvertisementImageModalConfig } from '@shared/modules/modals/position-modals/upload-advertisement-image-modal/classes/uploadAdvertisementImageModalConfig';
import { UploadAdvertisementImageModalComponent } from '@shared/modules/modals/position-modals/upload-advertisement-image-modal/upload-advertisement-image-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { AddProviderModalConfig } from '@shared/modules/modals/position-modals/manage-provider-modal/classes/AddProviderModalConfig';
import { ManageProviderModalComponent } from '@shared/modules/modals/position-modals/manage-provider-modal/manage-provider-modal.component';
import { ModalPrimaryButtonColor } from '@shared/modules/mat-modal/classes/ModalPrimaryButtonColor';
import { AdvertisementApiService } from '@pages/positions/services/advertisement/base/advertisement-api.service';
import { AdvertisementService } from '@pages/positions/services/advertisement/advertisement.service';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementModalService {
  constructor(
    private positionState: PositionStateService,
    private advertisementService: AdvertisementService,
    private advertisementApiService: AdvertisementApiService,
    private modalService: MatModalService
  ) {}

  openEditAdvertisementModal(advertisementId: number, positionId: number): void {
    this.modalService.openDialog<ManageAdvertisementModalConfig>(
      ManageAdvertisementModalComponent,
      {
        width: AppConstants.fullHeightModalWidth,
        data: {
          advertisementId,
          positionId,
        },
      }
    );
  }

  updateAdvertisement(
    positionId: number,
    advertisementId: number,
    dto: AdvertisementDto
  ): Observable<AdvertisementDetail | HttpErrorResponse> {
    return this.advertisementApiService.updateAdvertisement(positionId, advertisementId, dto).pipe(
      tap((advertisementDetail: AdvertisementDetail) => {
        this.positionState.setState({ advertisementDetail });
      }),
      this.advertisementService.handleModalCloseAndToast('positions.advertisements.modify'),
      this.advertisementService.handleSaveErrorSideEffects('positions.advertisements.modify')
    );
  }

  openCreateProviderModal(advertisementId: number): void {
    this.openManageProviderModal(advertisementId);
  }

  openDeleteProviderConfirmModal(
    advertisementId: number,
    provider: AdvertisementProviderCardTableRow
  ): void {
    this.openConfirmModal(
      'positions.advertisements.provider_delete_confirm',
      this.advertisementService.callDeleteProvider(advertisementId, provider)
    );
  }

  openToggleProviderStatusConfirmModal(
    advertisementId: number,
    provider: AdvertisementProviderCardTableRow
  ): void {
    this.openConfirmModal(
      'positions.advertisements.provider_status_confirm',
      this.advertisementService.callToggleProviderStatus(advertisementId, provider),
      'common.save',
      'basic'
    );
  }

  openDeleteImageConfirmModal(
    positionId: number,
    advertisementId: number,
    image: AdvertisementImage
  ): void {
    this.openConfirmModal(
      'positions.advertisements.image_delete_confirm',
      this.advertisementService.callDeleteImage(advertisementId, positionId, image)
    );
  }

  openModalToUpdateProvider(
    advertisementId: number,
    provider: AdvertisementProviderCardTableRow
  ): void {
    const { providers } = this.positionState.getStateSnapshot();
    this.positionState.setState({
      selectedProvider: find(providers, { id: provider.id }),
    });
    this.openManageProviderModal(advertisementId);
  }

  openImageUploadModal(positionId: number, advertisementId: number): void {
    this.modalService.openDialog<UploadAdvertisementImageModalConfig>(
      UploadAdvertisementImageModalComponent,
      {
        width: '460px',
        data: {
          variant: ModalTypes.DynamicHeight,
          positionId,
          advertisementId,
        },
      }
    );
  }

  private openManageProviderModal(advertisementId: number) {
    this.modalService.openDialog<AddProviderModalConfig>(ManageProviderModalComponent, {
      width: '460px',
      minHeight: '517px',
      data: {
        variant: ModalTypes.DynamicHeight,
        advertisementId,
      },
    });
  }

  private openConfirmModal(
    modalText: string,
    confirmObservable: Observable<boolean | unknown>,
    primaryButtonText: string = 'common.delete_confirm',
    primaryButtonColor: ModalPrimaryButtonColor = 'warn'
  ): void {
    this.modalService.openConfirmModal({
      data: {
        modalText,
        confirmObservable,
        primaryButtonText,
        primaryButtonColor,
      },
    });
  }
}
