import { Component, Input, OnChanges } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/form-components/button/button-base.components';

@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.scss'],
})
export class LoadMoreButtonComponent extends ButtonBaseComponent implements OnChanges {
  @Input() total: number;
  @Input() data: unknown[];
  @Input() perPage: number;
  @Input() currentPage: number;
  hasMoreCount = 0;

  ngOnChanges() {
    if (this.total <= this.perPage) {
      this.hasMoreCount = 0;
      return;
    }

    const visibleCount = this.perPage * this.currentPage;
    const remaining = this.total - visibleCount;

    this.hasMoreCount = remaining > this.perPage ? this.perPage : remaining;
  }
}
