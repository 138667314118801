import { Component, Injector, OnInit } from '@angular/core';
import { UserService } from '@pages/users/services/user.service';
import { UserFormService } from '@pages/users/services/base/user-form.service';
import { AppStateService } from '@shared/services/app-state.service';
import { mergeAll, startWith, take } from 'rxjs/operators';
import { forkJoin, merge, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Office } from '@pages/partners/classes/Office';
import { FormGroup } from '@angular/forms';
import { KeyValue } from '@shared/classes/KeyValue';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { keys, omit } from 'lodash-es';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { UserStateService } from '@pages/users/services/base/user-state.service';
import { UserDto } from '@pages/users/classes/UserDto';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { UserMenuTypes } from '@pages/users/classes/UserMenuTypes';
import { ManageUserModalData } from '@shared/modules/modals/user-modals/manage-user-modal/config/ManageUserModalConfig';

@UntilDestroy()
@Component({
  selector: 'app-manage-user-modal',
  templateUrl: './manage-user-modal.component.html',
  styleUrls: ['./manage-user-modal.component.scss'],
})
export class ManageUserModalComponent
  extends MatModalBaseComponent<ManageUserModalData>
  implements OnInit {
  offices: Office[];
  form: FormGroup;
  roles: KeyValue<string>[] = [];
  projectSpecialization: Specialization[] = [];
  isEditMode = false;
  selectedUser: UserListItem;
  modalTitle: string;
  menuType: UserMenuTypes;
  userMenuTypes = UserMenuTypes;

  constructor(
    private userState: UserStateService,
    private userService: UserService,
    private userFormService: UserFormService,
    private appService: AppStateService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.menuType = this.data.menuType;
    this.loading = true;
    this.loadDependencies();
  }

  private loadDependencies() {
    const dependencies = {
      offices: this.appService.getOffices().pipe(take(1)),
      projectSpecialization: this.appService.getProjectSpecializations().pipe(take(1)),
    };

    this.roles = this.userService.getTranslatedRoles();

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(({ offices, projectSpecialization }) => {
        this.offices = offices;
        this.projectSpecialization = projectSpecialization;
        const { selectedUser } = this.userState.getStateSnapshot();
        this.isEditMode = !!selectedUser;
        this.selectedUser = selectedUser;
        this.modalTitle = !selectedUser ? 'users.add_user' : 'users.edit_user';
        this.form = this.userFormService.initManageUserForm(this.menuType, selectedUser);

        this.listenFormChanges();

        this.loading = false;
      });
  }

  private listenFormChanges() {
    merge(of(this.form.valueChanges, this.form.statusChanges))
      .pipe(mergeAll(), startWith(this.form.getRawValue()), untilDestroyed(this))
      .subscribe(() => {
        this.primaryButtonDisabled = !this.checkFormValid();
      });
  }

  private checkFormValid(): boolean {
    if (!this.isEditMode) {
      return this.form.valid;
    }

    const controls = this.form.controls;
    // in edit mode, roleName may contain error to show the warning icon, but it is still valid
    return keys(omit(controls, 'roleName')).every(
      (key) => controls[key].disabled || controls[key].valid
    );
  }

  saveUser() {
    const dto = this.form.getRawValue() as UserDto;
    this.primaryButtonDisabled = true;

    if (this.isEditMode) {
      this.userService.callUpdateUser(dto).subscribe(this.handleConfirmActionResponse.bind(this));
    } else {
      this.userService.callCreateUser(dto).subscribe(this.handleConfirmActionResponse.bind(this));
    }
  }
}
