<ng-template #customCell let-item>
    <ng-container *ngIf="item">
        <ng-container
            [ngTemplateOutlet]="contentInside"
            [ngTemplateOutletContext]="{$implicit: item}">
        </ng-container>
    </ng-container>
</ng-template>

<div>
    <ng-container
        [ngTemplateOutlet]="contentInside"
        [ngTemplateOutletContext]="{$implicit: value}">
    </ng-container>
</div>

<ng-template #contentInside let-value>
    <div class="small-image-container d-flex align-items-center w-100">
        <img *ngIf="isImageShown && imageUrl" class="small-image"
             [style.border-radius]="imageBorderRadius"
             [src]="imageUrl"
             alt="Icon">
        <img *ngIf="isImageShown && imageKey && value && !placeholderPath"
             [style.border-radius]="imageBorderRadius"
             [src]="value[imageKey]"
             class="small-image"
             alt="Icon">
        <img *ngIf="isImageShown && imageKey && value && placeholderPath"
             [style.border-radius]="imageBorderRadius"
             [src]="value[imageKey] || placeholderPath"
             class="small-image"
             alt="Icon">
        <div class="text-value">
            {{value && valueKey ? value[valueKey] : value}}
        </div>
    </div>
</ng-template>

