import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TippyModule } from '@ngneat/helipopper';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { AuthModule } from '@shared/modules/auth/auth.module';
import { DotMenuComponent } from './dot-menu.component';

@NgModule({
  declarations: [DotMenuComponent],
  exports: [DotMenuComponent],
  imports: [CommonModule, TippyModule, InlineSVGModule, TranslateModule, PipesModule, AuthModule],
})
export class DotMenuModule {}
