import { TranslateService } from '@ngx-translate/core';
import { TranslationParam } from '@shared/classes/TranslationParam';

export class TranslateInstance {
  static translateService: TranslateService;

  static instant(
    key: string | Array<string>,
    interpolateParams?: TranslationParam,
    translateParam: boolean = false
  ): string {
    if (translateParam && interpolateParams) {
      for (const paramKey in interpolateParams) {
        interpolateParams[paramKey] = this.translateService.instant(
          interpolateParams[paramKey]
        ) as string;
      }
    }

    return this.translateService.instant(key, interpolateParams) as string;
  }
}
