import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { DataTableInlineEditService } from '@shared/modules/data-table-inline-edit/services/data-table-inline-edit.service';
import { InlineEditCellAllData } from '@shared/modules/data-table-inline-edit/classes/inline-edit-data-list';

@Pipe({
  name: 'inlineEditCellData',
})
export class InlineEditCellDataPipe implements PipeTransform {
  constructor(private inlineEditService: DataTableInlineEditService) {}

  transform(_: number, rowIndex: number, column: TableColumn): InlineEditCellAllData {
    return {
      cellData: this.inlineEditService.getCellEditingData(rowIndex, column.prop),
      columnData: this.inlineEditService.getColumnData(column.prop),
    };
  }
}
