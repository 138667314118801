import { Component } from '@angular/core';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

@Component({
  selector: 'app-advertisement-provider-type-cell',
  templateUrl: './advertisement-provider-type-cell.component.html',
  styleUrls: ['./advertisement-provider-type-cell.component.scss'],
})
export class AdvertisementProviderTypeCellComponent extends CardTableCustomCellBaseComponent {
  manualType: string;
  previousToastId: string;

  constructor(
    private translate: TranslateService,
    private clipboard: Clipboard,
    private toast: ToastService
  ) {
    super();

    this.manualType = TranslateInstance.instant('positions.advertisements.provider_manual');
  }

  onCopyUrlToClipboard(item: AdvertisementProviderCardTableRow) {
    if (item?.url) {
      this.clipboard.copy(item.url);
      if (this.previousToastId) {
        this.toast.removeToast(this.previousToastId);
      }

      this.previousToastId = this.toast
        .showSuccess('positions.advertisements.url_clipboard_copy_success')
        .getToast().id;
    } else {
      this.toast.showError('positions.advertisements.no_url');
    }
  }
}
