import { Injectable } from '@angular/core';
import { StateService } from '@shared/modules/state-manager/services/state.service';
import {
  phoneContactInitialState,
  PhoneContactState,
} from '@shared/modules/state-manager/state/phone-contacts/phone-contact.state';

@Injectable({
  providedIn: 'root',
})
export class PhoneContactStateService extends StateService<PhoneContactState> {
  constructor() {
    super(phoneContactInitialState);
  }
}
