import { Component } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/form-components/button/button-base.components';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-filter-menu-button',
  templateUrl: './filter-menu-button.component.html',
  styleUrls: ['./filter-menu-button.component.scss'],
})
export class FilterMenuButtonComponent extends ButtonBaseComponent {
  isSideFilterOpen: boolean;

  constructor(private sideFilterService: SideFilterService) {
    super();

    this.listenSideFilterToggleChanges();
  }

  toggleSideFilter(): void {
    this.sideFilterService.toggleSideFilter(!this.isSideFilterOpen);
  }

  private listenSideFilterToggleChanges(): void {
    this.sideFilterService
      .select('opened')
      .pipe(untilDestroyed(this))
      .subscribe((isOpen) => (this.isSideFilterOpen = isOpen));
  }
}
