export function getSyncIconPathUtil(synchronizedAt: string, isSynchronized: boolean): string {
  const pathPrefix: string = 'assets/image/';
  let iconPath: string = 'sync-problem.svg';

  if (!synchronizedAt) {
    iconPath = 'sync-pending.svg';
  } else if (isSynchronized) {
    iconPath = 'sync-success.svg';
  }

  return `${pathPrefix}${iconPath}`;
}
