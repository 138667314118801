import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextValueCellComponent } from '@shared/modules/card-table/components/custom-cells/text-value-cell/text-value-cell.component';
import { SmallImageTableCellComponent } from '@shared/modules/card-table/components/custom-cells/small-image-table-cell/small-image-table-cell.component';
import { CardTableCustomCellBaseComponent } from '@shared/modules/card-table/components/custom-cells/card-table-custom-cell-base/card-table-custom-cell-base.component';
import { ToggleCellComponent } from '@shared/modules/card-table/components/custom-cells/toggle-cell/toggle-cell.component';
import { MenuCellComponent } from '@shared/modules/card-table/components/custom-cells/menu-cell/menu-cell.component';
import { CardTableComponent } from '@shared/modules/card-table/card-table.component';
import { DotMenuModule } from '@shared/modules/dot-menu/dot-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { HintModule } from '@shared/modules/hint/hint.module';
import { RouterModule } from '@angular/router';
import { TippyModule } from '@ngneat/helipopper';
import { AdvertisementProviderTypeCellComponent } from './components/custom-cells/advertisement-provider-type-cell/advertisement-provider-type-cell.component';
import { RoleValueCellComponent } from '@shared/modules/card-table/components/custom-cells/role-value-cell/role-value-cell.component';
import { RoleValueLabelTemplateComponent } from '@shared/modules/card-table/components/custom-cells/role-value-cell/role-value-label-template/role-value-label-template.component';
import { TextValueGetterComponent } from './components/custom-cells/text-value-cell/text-value-getter/text-value-getter.component';
import { StatusCellComponent } from './components/custom-cells/status-cell/status-cell.component';
import { StatusSelectionComponent } from './components/custom-cells/status-selection-cell/status-selection/status-selection.component';
import { StatusSelectionCellComponent } from './components/custom-cells/status-selection-cell/status-selection-cell.component';
import { StatusSelectionLabelTemplateComponent } from './components/custom-cells/status-selection-cell/status-selection-label-template/status-selection-label-template.component';

@NgModule({
  declarations: [
    TextValueCellComponent,
    SmallImageTableCellComponent,
    CardTableCustomCellBaseComponent,
    ToggleCellComponent,
    MenuCellComponent,
    CardTableComponent,
    AdvertisementProviderTypeCellComponent,
    RoleValueCellComponent,
    RoleValueLabelTemplateComponent,
    TextValueGetterComponent,
    StatusCellComponent,
    StatusSelectionComponent,
    StatusSelectionCellComponent,
    StatusSelectionLabelTemplateComponent,
  ],
  imports: [
    CommonModule,
    DotMenuModule,
    TranslateModule,
    ButtonModule,
    InlineSVGModule,
    FormComponentsModule,
    ReactiveFormsModule,
    FormComponentsModule,
    ReactiveFormsModule,
    LoaderModule,
    PipesModule,
    HintModule,
    RouterModule,
    TippyModule,
  ],
  exports: [
    TextValueCellComponent,
    SmallImageTableCellComponent,
    CardTableCustomCellBaseComponent,
    ToggleCellComponent,
    MenuCellComponent,
    CardTableComponent,
    AdvertisementProviderTypeCellComponent,
    StatusSelectionCellComponent,
    RoleValueCellComponent,
    StatusSelectionComponent,
  ],
})
export class CardTableModule {}
