import { getAllRoles, UserRole } from '@shared/modules/auth/classes/UserRole';
import { AppConstants } from '@config/app.constant';

export interface SideNavItem {
  icon: string;
  navigateTo?: string | null;
  roles: UserRole[];
  showItem: boolean;
  navigateToValue?: string;
}

export const sideNavigationConfig: SideNavItem[] = [
  {
    icon: 'assets/image/common/whc-icon.svg',
    navigateTo: null,
    roles: getAllRoles,
    showItem: true,
  },
  {
    icon: 'assets/image/common/partner-icon.svg',
    navigateTo: 'partners',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
    showItem: true,
  },
  {
    icon: 'assets/image/common/position-icon.svg',
    navigateTo: 'positions',
    roles: getAllRoles,
    showItem: true,
  },
  {
    icon: 'assets/image/common/applicant-icon.svg',
    navigateTo: 'candidates',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
    showItem: true,
  },
  {
    icon: 'assets/image/common/contacts-icon.svg',
    navigateTo: 'contacts',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
    showItem: true,
  },
  {
    icon: 'assets/image/common/applicants.svg',
    navigateTo: 'position-applications',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
    showItem: true,
  },
  {
    icon: 'assets/image/common/base-data.svg',
    navigateTo: 'base-data',
    roles: [UserRole.SuperAdmin, UserRole.Admin],
    showItem: true,
  },
  {
    icon: 'assets/image/common/power-bi-icon.svg',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
    showItem: true,
    navigateToValue: AppConstants.powerBiReportUrl,
  },
];
