<ng-template let-item="item">
    <table class="option-table" aria-describedby="option-table-desc">
        <tr class="d-none" aria-hidden="true">
            <th>{{ 'positions.name' | translate }}</th>
            <th>{{ 'positions.code' | translate }}</th>
        </tr>

        <tr>
            <td class="name-column">
                <div class="truncate">
                    {{ item.name }}
                </div>
            </td>
            <td class="code-column">
                <div class="truncate">
                    {{ item.code }}
                </div>
            </td>
        </tr>
    </table>
</ng-template>
