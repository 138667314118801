import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Input() ignoreClasses: Array<string> = [];
  @Input() ignoreContainers: Array<string> = [];
  @Input() listenForClasses: Array<string> = [];

  @Output() clickOutside = new EventEmitter<void>();

  private stopListeners: Array<() => void> = [];

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.listenForClassClicks();
  }

  private listenForClassClicks() {
    for (const listenClass of this.listenForClasses) {
      const elements = document.getElementsByClassName(listenClass);

      for (let i = 0; i < elements.length; i++) {
        this.listenForClick(elements[i]);
      }
    }

    this.listenForClick('document');
  }

  private listenForClick(target: unknown) {
    this.stopListeners.push(this.renderer.listen(target, 'click', this.handleOnClick.bind(this)));
  }

  private handleOnClick(event: Event) {
    const target = event.target as HTMLElement;

    for (const ignoreClass of this.ignoreClasses) {
      if (target.classList.contains(ignoreClass)) {
        return;
      }
    }

    for (const containerClass of this.ignoreContainers) {
      const elements = document.getElementsByClassName(containerClass);

      for (let i = 0; i < elements.length; i++) {
        if (elements[i].contains(target)) {
          return;
        }
      }
    }

    const clickedInside = (this.elementRef.nativeElement as HTMLElement).contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }

  private stopListens() {
    for (const stopListener of this.stopListeners) {
      stopListener();
    }
  }

  ngOnDestroy() {
    this.stopListens();
  }
}
