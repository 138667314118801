import { ComponentType } from '@angular/cdk/overlay';
import { FilterContainerBaseComponent } from '@shared/modules/filter-components/components/base/filter-container-base.component';

export interface SideFilterState {
  opened: boolean;
  currentFilter: ComponentType<FilterContainerBaseComponent>;
}

export const sideFilterInitialState: SideFilterState = {
  opened: false,
  currentFilter: undefined,
};
