import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '@pages/users/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';

@UntilDestroy()
@Component({
  selector: 'app-upload-user-avatar-modal',
  templateUrl: './upload-user-avatar-modal.component.html',
  styleUrls: ['./upload-user-avatar-modal.component.scss'],
})
export class UploadUserAvatarModalComponent extends MatModalBaseComponent implements OnInit {
  avatarControl = new FormControl(null, [Validators.required]);

  constructor(private userService: UserService, protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.avatarControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.avatarControl.invalid;
    });
  }

  uploadAvatar() {
    this.userService
      .callUploadAvatar(this.avatarControl.value as string)
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
