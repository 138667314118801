import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetBackgroundImageDirective } from '@shared/directives/set-background-image.directive';
import { CandidateDetailScrollClickDirective } from '@shared/directives/candidate-detail-scroll-click.directive';
import { SetBodyBackgroundDirective } from './set-body-background.directive';
import { ImgFallbackDirective } from './img-fallback.directive';
import { NumberInputDirective } from './number-input.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { NgVarDirective } from './ng-var.directive';

@NgModule({
  declarations: [
    SetBackgroundImageDirective,
    SetBodyBackgroundDirective,
    ImgFallbackDirective,
    NumberInputDirective,
    CandidateDetailScrollClickDirective,
    AutoFocusDirective,
    ClickOutsideDirective,
    NgVarDirective,
  ],
  imports: [CommonModule],
  exports: [
    SetBackgroundImageDirective,
    SetBodyBackgroundDirective,
    ImgFallbackDirective,
    NumberInputDirective,
    CandidateDetailScrollClickDirective,
    AutoFocusDirective,
    ClickOutsideDirective,
    NgVarDirective,
  ],
})
export class DirectivesModule {}
