import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppConstants } from '@config/app.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRouteData } from '@shared/classes/common/AppRouteData';

@UntilDestroy()
@Directive({
  selector: '[appSetBodyBackground]',
})
export class SetBodyBackgroundDirective implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private element: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.traverseRouteChildrenRecursively(this.activatedRoute.firstChild)),
        untilDestroyed(this)
      )
      .subscribe((backgroundColor: string | null) => {
        this.renderer.setStyle(
          this.element.nativeElement,
          'backgroundColor',
          backgroundColor || AppConstants.defaultBackgroundColor
        );
      });
  }

  private traverseRouteChildrenRecursively(child: ActivatedRoute): string | null {
    while (child) {
      if (child.firstChild) {
        // eslint-disable-next-line no-param-reassign
        child = child.firstChild;
      } else if (child.snapshot.data.backgroundColor) {
        return (child.snapshot.data as AppRouteData).backgroundColor;
      } else {
        return null;
      }
    }
    return null;
  }
}
