<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'positions.advertisements.image_modal_title'">
    </app-modal-header>

    <ng-container content>
        <form *ngIf="!loading" [formGroup]="form" class="modal-base h-auto">
            <app-form-field>
                <app-photo-upload
                    [storeBinaryFile]="true"
                    placeholder="common.please_select"
                    permanentHintText="partners.photo_hint"
                    formControlName="image">
                </app-photo-upload>
            </app-form-field>
        </form>
    </ng-container>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonText]="'positions.advertisements.upload_button'"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (primaryButtonClicked)="uploadImage()">
    </app-modal-footer>

</app-modal-frame>
