import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ParamArray } from '@shared/modules/http/classes/ParamArray';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { HttpUrlParams } from '@shared/modules/http/classes/HttpUrlParams';
import { CustomHttpParamEncoder } from '@shared/utils/CustomHttpParamEncoder';

/** Http Service Layer */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /** generate url for backend call */
  generateFullUrl(url: string): string {
    let normalizedUrl = url;
    if (url.startsWith('/')) {
      normalizedUrl = url.substring(1);
    }

    return `${environment.apiUrl}/${environment.backendVersion}/${normalizedUrl}`;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(
    urlParams: HttpUrlParams = null,
    paramArray: ParamArray[] = []
  ): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          if (Array.isArray(urlParams[property])) {
            (urlParams[property] as string[]).forEach((value) => {
              params = params.append(`${property.toString()}[]`, value);
            });
          } else {
            params = params.append(property, urlParams[property] as string);
          }
        }
      });
    }

    if (Array.isArray(paramArray)) {
      paramArray.forEach((param) => {
        if (Array.isArray(param.value)) {
          param.value.forEach((value) => {
            params = params.append(`${param.key}[]`, value);
          });
        }
      });
    }

    return params;
  }

  get<ReturnType = unknown>(
    url: string,
    urlParams: HttpUrlParams = null,
    paramArray: ParamArray[] = []
  ): Observable<ReturnType> {
    return this.http.get<ReturnType>(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams, paramArray),
    });
  }

  getFile(
    url: string,
    urlParams: HttpUrlParams = null,
    paramArray: ParamArray[] = []
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams, paramArray),
      responseType: 'blob',
      observe: 'response',
    });
  }

  postFile(
    url: string,
    tableColumns: TableColumn[],
    urlParams: HttpUrlParams = null,
    paramArray: ParamArray[] = []
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(
      this.generateFullUrl(url),
      {
        columns: tableColumns,
      },
      {
        params: this.generateUrlQueryParams(urlParams, paramArray),
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  post<ReturnType = unknown>(
    url: string,
    data: unknown,
    urlParams: HttpUrlParams = null
  ): Observable<ReturnType> {
    return this.http.post<ReturnType>(this.generateFullUrl(url), data, {
      params: this.generateUrlQueryParams(urlParams),
    });
  }

  put<ReturnType = unknown>(url: string, data: unknown): Observable<ReturnType> {
    return this.http.put<ReturnType>(this.generateFullUrl(url), data);
  }

  patch<ReturnType = unknown>(url: string, data: unknown): Observable<ReturnType> {
    return this.http.patch<ReturnType>(this.generateFullUrl(url), data);
  }

  delete<ReturnType = unknown>(url: string): Observable<ReturnType> {
    return this.http.delete<ReturnType>(this.generateFullUrl(url));
  }
}
