import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-abc-filtering',
  templateUrl: './abc-filtering.component.html',
  styleUrls: ['./abc-filtering.component.scss'],
})
export class AbcFilteringComponent extends BaseComponent implements OnInit {
  selectedLetter: string;

  @Input() firstLetters: string[];
  @Output() filterLetterChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private filteringService: FilteringService) {
    super();
  }

  ngOnInit() {
    this.listenFilterResetAction();
    this.listenSavedFilterChange();
    this.listenSelectedLetterChanges();
    return super.ngOnInit();
  }

  private listenSelectedLetterChanges() {
    this.filteringService
      .select('selectedFirstLetter')
      .pipe(untilDestroyed(this))
      .subscribe((selectedLetter: string) => {
        this.selectedLetter = selectedLetter;
      });
  }

  private listenFilterResetAction(): void {
    this.filteringService
      .onResetFilter()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onButtonClick(null);
      });
  }

  private listenSavedFilterChange(): void {
    this.filteringService
      .getSavedFilterAsync('letter_filter')
      .pipe(
        tap((letter: unknown) => {
          this.filterLetterChange.emit(letter as string);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onButtonClick(letter: string) {
    this.filterLetterChange.emit(letter);
  }
}
