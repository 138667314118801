import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatModalModule } from '@shared/modules/mat-modal/mat-modal.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { CountrySettlementSelectionModule } from '@shared/modules/selections/country-settlement-selection/country-settlement-selection.module';
import { MultipleSelectionModule } from '@shared/modules/selections/multiple-selection/multiple-selection.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { JobTypeSpecializationSelectionModule } from '@shared/modules/selections/job-type-specialization-selection/job-type-specialization-selection.module';
import { JobTypeSelectionComponent } from '@shared/modules/selections/job-type-specialization-selection/components/job-type-selection/job-type-selection.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { PositionSelectorModule } from '@shared/modules/selections/position-selector/position-selector.module';
import { PartnerProjectPositionSelectionModule } from '@shared/modules/selections/partner-project-position-selection/partner-project-position-selection.module';
import { DocumentUploadModalComponent } from '@shared/modules/modals/candidate-modals/document-upload-modal/document-upload-modal.component';
import { DocumentPositionSelectionComponent } from '@shared/modules/modals/candidate-modals/document-upload-modal/document-position-selection/document-position-selection/document-position-selection.component';
import { DocumentPositionSelectionOptionTemplateComponent } from '@shared/modules/modals/candidate-modals/document-upload-modal/document-position-selection/document-position-selection-option-template/document-position-selection-option-template.component';
import { ManageCandidateModalComponent } from './manage-candidate-modal/manage-candidate-modal.component';
import { CandidateAttachPositionModalComponent } from './candidate-attach-position-modal/candidate-attach-position-modal.component';
import { BulkEditCandidateModalComponent } from './bulk-edit-candidate-modal/bulk-edit-candidate-modal.component';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { CustomFieldSelectionModule } from '@shared/modules/selections/custom-field-selection/custom-field-selection.module';

@NgModule({
  declarations: [
    ManageCandidateModalComponent,
    BulkEditCandidateModalComponent,
    CandidateAttachPositionModalComponent,
    DocumentUploadModalComponent,
    DocumentPositionSelectionComponent,
    DocumentPositionSelectionOptionTemplateComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatModalModule,
    FormComponentsModule,
    CountrySettlementSelectionModule,
    MultipleSelectionModule,
    TranslateModule,
    ButtonModule,
    JobTypeSpecializationSelectionModule,
    PipesModule,
    PositionSelectorModule,
    PartnerProjectPositionSelectionModule,
    LoaderModule,
    CustomFieldSelectionModule,
  ],
  exports: [JobTypeSelectionComponent],
})
export class CandidateModalsModule {}
