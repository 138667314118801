import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';

export enum SourceTableMenuId {
  'Edit',
  'Delete',
  'Archive',
  'Active',
}

export const sourceTableMenuConfigWithArchive: DotMenuItem[] = [
  {
    id: SourceTableMenuId.Edit,
    textValue: 'base_data.sources.edit',
    permissions: [UserPermission.ManageSource],
  },
  {
    id: SourceTableMenuId.Archive,
    textValue: 'base_data.sources.archive',
    permissions: [UserPermission.ManageSource],
  },
  {
    id: SourceTableMenuId.Delete,
    textValue: 'base_data.sources.delete',
    permissions: [UserPermission.ManageSource],
  },
];

export const sourceTableMenuConfigWithActive: DotMenuItem[] = [
  {
    id: SourceTableMenuId.Edit,
    textValue: 'base_data.sources.edit',
    permissions: [UserPermission.ManageSource],
  },
  {
    id: SourceTableMenuId.Active,
    textValue: 'base_data.sources.active',
    permissions: [UserPermission.ManageSource],
  },
  {
    id: SourceTableMenuId.Delete,
    textValue: 'base_data.sources.delete',
    permissions: [UserPermission.ManageSource],
  },
];
