<div class="main-page-header d-flex justify-content-between align-items-center">
    <div class="d-flex">
        <app-filter-menu-button
            *appUserHasPermission="filterPermissions">
        </app-filter-menu-button>

        <h1 class="page-header-title">
            {{(headerTitle$ | async) | translate}}
            <ng-container *ngIf="(filteredTotalCount$ | async ) && (isFilteredTotalCountLoading$ | async) === false">
                <span class="fade-in">({{filteredTotalCount$ | async}})</span>
            </ng-container>

            <app-loading
                    *ngIf="(isFilteredTotalCountLoading$ | async)"
                    class="header-count-loader ml-2">
            </app-loading>
        </h1>
    </div>
    <div class="d-flex">
        <app-fab-button
            *appUserHasPermission="exportPermissions"
            class="button-export mr-20px"
            iconUrl="assets/image/arrow-to-bottom.svg"
            isTransparent="true"
            (buttonClick)="triggerExportClick()">
        </app-fab-button>

        <app-primary-button
            *ngIf="isButtonShow"
            type="submit"
            [style]="'filled'"
            [text]="'page_header.header_button_title' | appTranslateWithParams : headerButtonParams$ | async"
            color="basic"
            [buttonClass]="'header-button'"
            (buttonClick)="triggerNewButtonClick()">
        </app-primary-button>
    </div>
</div>
