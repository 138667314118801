export class AppConfig {
  static loginUrl = '/auth-user';
  static partnersUrl = '/partners';
  static positionsUrl = '/positions';
  static candidatesUrl = '/candidates';
  static baseDataUrl = '/base-data';
  static contactsUrl = '/contacts';
  static applicationUrl = '/position-applications';

  static homeUrl = AppConfig.partnersUrl;

  static browsersNotSupported = {
    ie11: true,
    ie10: true,
    edge: false,
    safari: false,
  };

  static languages: string[] = ['hu', 'en'];
  static defaultLanguage: string = AppConfig.languages[0];
  static headerTitleTranslateMap = {
    [AppConfig.partnersUrl]: 'common.partner',
    [AppConfig.positionsUrl]: 'common.position',
    [AppConfig.candidatesUrl]: 'common.candidate',
    [AppConfig.baseDataUrl]: 'common.base_data',
    [AppConfig.contactsUrl]: 'common.contact',
    [AppConfig.applicationUrl]: 'common.position-application',
  };
}
