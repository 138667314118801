import { Component, OnInit } from '@angular/core';
import { HeaderPage } from '@shared/modules/header/classes/HeaderPage';
import { AppConfig } from '@config/app.config';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { NavigationService } from '@shared/services/navigation.service';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { Observable } from 'rxjs';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { BaseDataRoutes } from '@pages/base-data/classes/BaseDataRoutes';

@Component({
  selector: 'app-base-data-header',
  templateUrl: './base-data-header.component.html',
  styleUrls: ['./base-data-header.component.scss'],
})
export class BaseDataHeaderComponent implements OnInit {
  readonly BASIC_DATA_ROUTES = BaseDataRoutes;

  headerTitle$: Observable<string>;
  pages: HeaderPage[];

  constructor(
    private navigationService: NavigationService,
    private auth: AuthService,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.headerTitle$ = this.getHeaderTitle();

    // TODO change to state if possible
    this.navigationService.valueSubject.subscribe(() => {
      this.pages = this.getPages();
    });
  }

  removeLastNavigationHistory(): void {
    this.navigationService.popHistory();
  }

  private getHeaderTitle(): Observable<string> {
    return this.headerService.select('title');
  }

  private getPages() {
    const menus: HeaderPage[] = [
      {
        routerLink: [AppConfig.baseDataUrl, this.BASIC_DATA_ROUTES.Sources],
        text: 'base_data.header.sources',
        isActive: this.navigationService.isCurrentUrl(this.BASIC_DATA_ROUTES.Sources),
        permissions: [UserPermission.ManageSource],
      },
      {
        routerLink: [AppConfig.baseDataUrl, this.BASIC_DATA_ROUTES.CustomFields],
        text: 'base_data.header.custom_fields',
        isActive: this.navigationService.isCurrentUrl(this.BASIC_DATA_ROUTES.CustomFields),
        permissions: [UserPermission.ManageCustomFields],
      },
    ];

    return menus;
  }
}
