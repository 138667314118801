import { Component, Injector, Input } from '@angular/core';
import { DataTableInlineEditCellBaseComponent } from '@shared/modules/data-table-inline-edit/components/data-table-inline-edit-cell-base.component';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { DataTableRow } from '@shared/modules/data-table/classes/DataTableRow';
import { InlineEditColumnData } from '@shared/modules/data-table-inline-edit/classes/inline-edit-column-data';
import { InlineEditData } from '@shared/modules/data-table-inline-edit/classes/inline-edit-data-list';
import { HintType } from '@shared/modules/hint/classes/HintType';

@Component({
  selector: 'app-inline-edit-cell-container',
  templateUrl: './inline-edit-cell-container.component.html',
  styleUrls: ['./inline-edit-cell-container.component.scss'],
})
export class InlineEditCellContainerComponent extends DataTableInlineEditCellBaseComponent {
  @Input() rowIndex: number;
  @Input() row: DataTableRow;
  @Input() column: TableColumn;
  @Input() value: unknown;

  @Input() columnData: InlineEditColumnData;
  @Input() cellData: InlineEditData;

  readonly HINT_TYPE = HintType;

  constructor(protected injector: Injector) {
    super(injector);
  }

  endFillHandle(event: Event) {
    if (this.fillHandleDisabled) {
      return;
    }

    super.endFillHandle(event);
  }
}
