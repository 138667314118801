import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';

export function formatDate(date: string): string {
  if (!moment(date).isValid()) {
    return date;
  }

  return moment(new Date(date)).format(AppConstants.dateFormat);
}
