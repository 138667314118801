import { Injectable } from '@angular/core';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { Observable } from 'rxjs';
import { ModalPrimaryButtonColor } from '@shared/modules/mat-modal/classes/ModalPrimaryButtonColor';
import { SourceService } from '@pages/base-data/services/source/source.service';
import { SourceListItem } from '@pages/base-data/classes/sources/SourceListItem';
import { ManageSourceModalComponent } from '@shared/modules/modals/base-data-modals/sources/manage-source-modal/manage-source-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { find } from 'lodash-es';
import { SourcesStateService } from '@pages/base-data/services/source/sources-state.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { DeleteSourceModalComponent } from '@shared/modules/modals/base-data-modals/sources/delete-source-modal/delete-source-modal.component';
import { DeleteSourceModalConfig } from '@shared/modules/modals/base-data-modals/sources/manage-source-modal/classes/DeleteSourceModalConfig';

@Injectable({
  providedIn: 'root',
})
export class SourceModalService {
  constructor(
    private modalService: MatModalService,
    private sourceService: SourceService,
    private baseDataSourcesState: SourcesStateService
  ) {}

  openManageSourceModal() {
    this.modalService.openDialog<MatDialogConfig>(ManageSourceModalComponent, {
      width: '560px',
      data: {
        variant: ModalTypes.DynamicHeight,
      },
    });
  }

  openDeleteSourceModal(source: SourceListItem) {
    this.modalService.openDialog<DeleteSourceModalConfig>(DeleteSourceModalComponent, {
      width: '460px',
      data: {
        variant: ModalTypes.DynamicHeight,
        source,
      },
    });
  }

  openModalToUpdateSource(sourceId: number, source: SourceListItem): void {
    const { sources } = this.baseDataSourcesState.getStateSnapshot();
    this.baseDataSourcesState.setState({
      selectedSource: find(sources, { id: source.id }),
    });
    this.openManageSourceModal();
  }

  openDeleteSourceConfirmModal(source: SourceListItem): void {
    this.openConfirmModal(
      'base_data.sources.source_delete_confirm',
      this.sourceService.callDeleteSource(source.id),
      'common.delete_confirm',
      'common.no'
    );
  }

  openArchiveSourceConfirmModal(source: SourceListItem): void {
    this.openConfirmModal(
      'base_data.sources.source_archive_confirm',
      this.sourceService.callArchiveSource(source),
      'common.yes',
      'common.no'
    );
  }

  openActivateSourceConfirmModal(source: SourceListItem): void {
    this.openConfirmModal(
      'base_data.sources.source_activate_confirm',
      this.sourceService.callActivateSource(source),
      'common.yes',
      'common.no',
      'basic'
    );
  }

  private openConfirmModal(
    modalText: string,
    confirmObservable: Observable<boolean | unknown>,
    primaryButtonText: string,
    secondaryButtonText: string,
    primaryButtonColor: ModalPrimaryButtonColor = 'warn'
  ): void {
    this.modalService.openConfirmModal({
      data: {
        modalText,
        confirmObservable,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonColor,
      },
    });
  }
}
