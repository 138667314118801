<app-modal-frame [data]="data" [dialogRef]="dialogRef" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'candidates.upload_document'"
        [iconUrl]="'assets/image/upload-document.svg'">
    </app-modal-header>

    <form content [formGroup]="form" class="documents">
        <app-form-field class="d-block mb-10px">
            <app-file-upload [isHintShown]="true" label="common.file" formControlName="file"></app-file-upload>
        </app-form-field>

        <app-form-field class="d-block mb-10px">
            <app-select
                [hintText]="documentTypeSelectedHint"
                [options]="types$ | async"
                label="candidates.document_type"
                bindLabel="name"
                bindValue="id"
                bindValueByPropertyNameToControl="id"
                formControlName="documentTypeId">
            </app-select>
        </app-form-field>

        <app-form-field *ngIf="isExpiryDateFieldShown">
            <app-datepicker
                [isHintShown]="true"
                label="candidates.expiry_date"
                [minDate]="today"
                formControlName="expiryDate">
            </app-datepicker>
        </app-form-field>

        <app-document-position-selection
            *ngIf="isPositionSelectionFieldShown"
            [group]="form"
            class="d-block w-100">
        </app-document-position-selection>
    </form>

    <app-modal-footer
        footer
        (primaryButtonClicked)="uploadDocument()"
        [primaryButtonDisabled]="primaryButtonDisabled"
        [dialogRef]="dialogRef">
    </app-modal-footer>
</app-modal-frame>
