<app-modal-frame [dialogRef]="dialogRef" [data]="data" [loading]="loading">
    <app-modal-header
        header
        [dialogRef]="dialogRef"
        [title]="'positions.board.outsource_title'">
    </app-modal-header>

    <div content class="modal-base">
        <form *ngIf="!loading" [formGroup]="form">
            <app-form-field>
                <app-text-field
                    label="positions.feor_number"
                    formControlName="feorNumber">
                </app-text-field>
            </app-form-field>

            <ng-container *ngIf="data.positionType === POSITION_TYPE.Outsourced">
                <app-form-field>
                    <app-yes-no-select
                            label="candidates.guarantee"
                            formControlName="guarantee">
                    </app-yes-no-select>
                </app-form-field>
            </ng-container>


            <app-form-field>
                <app-datepicker
                    [maxDate]="maxDate"
                    [isHintShown]="true"
                    label="candidates.labor_start"
                    formControlName="membershipStartDate">
                </app-datepicker>
            </app-form-field>

            <app-partner-project-position-selection
                [partnerIdControl]="partnerIdControl"
                [projectIdControl]="projectIdControl"
                [positionIdControl]="form.get('subPositionId')">
            </app-partner-project-position-selection>
        </form>
    </div>

    <app-modal-footer
        footer
        [dialogRef]="dialogRef"
        [primaryButtonDisabled]="primaryButtonDisabled"
        (secondaryButtonClicked)="cancel()"
        (primaryButtonClicked)="confirm()">
    </app-modal-footer>

</app-modal-frame>
