import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HistoryEditMessageData } from '@shared/modules/history-message/classes/HistoryEditMessageData';
import { PartnerApiService } from '@pages/partners/services/base/partner-api.service';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PartnerOrProject } from '@config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class PartnerHistoryService {
  constructor(
    private partnerApiService: PartnerApiService,
    private historyService: HistoryMessageService
  ) {}

  getFirstHistoryPage(
    parentId: number,
    type: PartnerOrProject
  ): Observable<ListData<HistoryMessage> | HttpErrorResponse> {
    const isPartner = type === 'partner';
    const functionToCall = isPartner
      ? this.partnerApiService.getPartnerHistory.bind(this.partnerApiService)
      : this.partnerApiService.getProjectHistory.bind(this.partnerApiService);

    return this.historyService
      .getFirstHistoryPage(parentId, functionToCall)
      .pipe(catchError((err: HttpErrorResponse) => of(err)));
  }

  callLoadMoreHistory(
    partnerId: number,
    projectId: number,
    type: PartnerOrProject
  ): Observable<ListData<HistoryMessage>> {
    const isPartner = type === 'partner';
    const parentId = isPartner ? partnerId : projectId;
    const functionToCall = isPartner
      ? this.partnerApiService.getPartnerHistory.bind(this.partnerApiService)
      : this.partnerApiService.getProjectHistory.bind(this.partnerApiService);

    return this.historyService.callLoadMoreHistory(parentId, functionToCall);
  }

  callCreateHistory(
    id: number,
    message: string,
    type: PartnerOrProject
  ): Observable<HistoryMessage | HttpErrorResponse> {
    const createEndpoint =
      type === 'partner'
        ? this.partnerApiService.createPartnerHistoryMessage.bind(this.partnerApiService)
        : this.partnerApiService.createProjectHistoryMessage.bind(this.partnerApiService);

    return this.historyService.callCreateHistory(id, { message }, createEndpoint);
  }

  callUpdateHistory(
    id: number,
    messageData: HistoryEditMessageData,
    type: PartnerOrProject
  ): Observable<HistoryMessage | HttpErrorResponse> {
    const endpoint =
      type === 'partner'
        ? this.partnerApiService.updatePartnerHistoryMessage.bind(this.partnerApiService)
        : this.partnerApiService.updateProjectHistoryMessage.bind(this.partnerApiService);

    return this.historyService.callUpdateHistory(id, messageData, endpoint);
  }

  openDeleteHistoryConfirmModal(
    entityId: number,
    historyMessage: HistoryMessage,
    type: PartnerOrProject
  ): void {
    const endpoint =
      type === 'partner'
        ? this.partnerApiService.deletePartnerHistory.bind(this.partnerApiService)
        : this.partnerApiService.deleteProjectHistory.bind(this.partnerApiService);

    this.historyService.openDeleteHistoryConfirmModal(entityId, historyMessage, endpoint);
  }
}
