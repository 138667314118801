import { Component, Injector, OnInit } from '@angular/core';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { PartnerDropdownProject } from '@pages/partners/classes/PartnerDropdownProject';
import { PositionDropdownItem } from '@pages/positions/classes/PositionDropdownItem';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { CandidateAttachPositionModalData } from '@shared/modules/modals/candidate-modals/candidate-attach-position-modal/classes/candidate-attach-position-modal-config';

@UntilDestroy()
@Component({
  selector: 'app-candidate-attach-position-modal',
  templateUrl: './candidate-attach-position-modal.component.html',
  styleUrls: ['./candidate-attach-position-modal.component.scss'],
})
export class CandidateAttachPositionModalComponent
  extends MatModalBaseComponent<CandidateAttachPositionModalData>
  implements OnInit {
  form: FormGroup;
  candidateId: number;

  partners: PartnerDropdownItem[] = [];
  projects: PartnerDropdownProject[] = [];
  positions: PositionDropdownItem[] = [];

  constructor(
    private positionApiService: PositionsApiService,
    private candidateService: CandidatesService,
    private formBuilder: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primaryButtonDisabled = true;
    this.form = this.initForm();
    this.loading = true;
    this.candidateId = this.data.candidateId;

    this.loadDependencies();
  }

  private loadDependencies() {
    const dependencies = {
      partners: this.positionApiService.getPartnersForDropdown().pipe(take(1)),
    };

    forkJoin(dependencies)
      .pipe(untilDestroyed(this))
      .subscribe(({ partners }) => {
        this.partners = partners;
        this.loading = false;

        this.listenFormChanges();
      });
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      partnerId: [null, [Validators.required]],
      projectId: [null, [Validators.required]],
      positionId: [null, [Validators.required]],
    });
  }

  private listenFormChanges(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.form.invalid;
    });
  }

  attachToPosition() {
    this.primaryButtonDisabled = true;

    this.candidateService
      .callAttachToPosition(this.candidateId, this.form.get('positionId').value as number)
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }
}
