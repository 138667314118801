import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormComponentsModule } from '../form-components/form-components.module';
import { SharedModule } from '../../shared.module';
import { OverflowFilterSelectMenuComponent } from './overflow-filter-select-menu/overflow-filter-select-menu.component';
import { OverflowFilterFooterComponent } from './overflow-filter-footer/overflow-filter-footer.component';
import { ButtonModule } from '@shared/modules/form-components/button/button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateWithPrefixPipe } from '@shared/pipes/table-view-selectable-columns.pipe';

@NgModule({
  declarations: [
    OverflowFilterSelectMenuComponent,
    OverflowFilterFooterComponent,
    TranslateWithPrefixPipe,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    FormComponentsModule,
    SharedModule,
    ButtonModule,
    InlineSVGModule,
    PipesModule,
  ],
  exports: [OverflowFilterSelectMenuComponent],
})
export class OverflowFilterSelectMenuModule {}
